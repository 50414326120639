import React, { Component } from "react";
import { v4 as uuidv4 } from 'uuid';
import styled from "styled-components";
import * as Yup from "yup";
import { withRouter } from "react-router";
import { graphql } from "react-apollo";
import { compose } from "recompose";
import { Formik } from "formik";
import Notification from "../components/Notification";
import { formatENUM } from "../components/Global";
import { theme } from "../components/Global";
import { Field, Input, Error, Button } from "../components/Forms";

import ADD_INSURANCE from "../mutations/practice/addInsurance";
import UPDATE_INSURANCE from "../mutations/practice/updateInsurance";
import GET_PRACTICE_INSURANCES from "../queries/getPracticeInsurances";

const Form = styled.form``;

const Title = styled.h3`
  font-size: 36px;
  line-height: 44px;
  font-family: ${theme.font.rudi_thin};
  margin-bottom: 20px;
`;

const schema = Yup.object().shape({
  name: Yup.string()
    .matches(/^[a-zA-Z'\s]+$/, "Insurance Name is not in the correct format.")
    .max(30, "Insurance Name is too long (max 30 characters)")
    .required("Insurance Name is required.")
});

class AccountInsuranceForm extends Component {
  constructor(props) {
    super(props);

    const { name } = this.props;
    this.state = {
      success: false,
      successMessage: "",
      serverError: false,
      serverMessage: "",
      insuranceName: "",
      formSubmitted: false,
      uuid: uuidv4()
    };
  }

  mapSubmitData(values) {
    return {
      name: values.name,
      practiceId: this.props.practiceId,
      uuid: this.state.uuid
    };
  }

  // handleBlur = e => {
  //   console.log(e.target.value);
  // };

  // handleChange = e => {
  //   this.setState({
  //     insuranceName: e.target.value
  //   });
  //   // console.log("This is the input " + this.state.insuranceName);
  // };

  catchErrors = graphQLErrors => {
    this.setState({ serverError: true, serverMessage: graphQLErrors });
  };

  handleSubmit = (values, actions) => {
    console.log("This is the data > " + JSON.stringify(values));
    this.setState({ serverError: false, formSubmitted: true, uuid: uuidv4() });
    switch (this.props.action) {
      default:
      case "create":
        this.props
          .addInsurance({
            name: "addInsurance",
            variables: this.mapSubmitData(values),
            refetchQueries: [
              {
                query: GET_PRACTICE_INSURANCES,
                fetchPolicy: "network-only",
                variables: { practiceId: this.props.practiceId }
              }
            ]
          })
          .then(res => {
            window.scrollTo(0, 0);
            actions.setSubmitting(false);
            values.name = "";
            var data = res.data.addInsurance.result;
            if (!data || data.length == 0) {
              this.setState({
                serverError: true,
                serverMessage: data.exceptions || "Error > "
              });
              return;
            }
            this.setState({
              success: true,
              serverError: false,
              successMessage: "Insurance was added successfully"
            });
          })
          .catch(({ graphQLErrors }) => {
            if (graphQLErrors !== undefined) {
              console.log(graphQLErrors);
            }

            actions.setSubmitting(false);
            this.setState({
              successMessage: "",
              serverError: true,
              serverMessage: "An error has occured "
            });
          });
        break;
      case "edit":
        this.props
          .updateInsurance({
            name: "updateInsurance",
            variables: {
              uuid: this.props.id,
              name: this.mapSubmitData(values).name
            },
            awaitRefetchQueries: false,
            refetchQueries: [
              {
                query: GET_PRACTICE_INSURANCES,
                fetchPolicy: "network-only",
                variables: { practiceId: this.props.practiceId }
              }
            ]
          })
          .then(res => {
            var data = res.data.updatePracticeInsurance;
            if (!data || data.statusCode != 200) {
              this.setState({
                serverError: true,
                serverMessage: data && data.exceptions ? data.exceptions : "Error"
              });
              actions.setSubmitting(false);
              return;
            }
            this.setState({
              success: true,
              successMessage: "Insurance was updated"
            });
            window.scrollTo(0, 0);
            actions.setSubmitting(false);
            this.props.resultsHandler();
          })
          .catch(({ graphQLErrors }) => {
            actions.setSubmitting(false);
            this.setState({
              successMessage: false,
              serverError: true,
              serverMessage: "An error has occured "
            });
          });
        break;
    }
  };

  closeAlert = () => {
    this.setState({ serverError: false, success: false });
    console.log("Close the alert");
  };

  getInitialValue(value) {
    return {
      name: value || ""
    };
  }

  render() {
    const { action, value } = this.props;
    return (
      <Formik
        initialValues={this.getInitialValue(value)}
        validationSchema={schema}
        onSubmit={this.handleSubmit}
        render={({
          errors,
          isSubmitting,
          submitCount,
          values,
          handleBlur,
          handleSubmit,
          handleChange
        }) => (
          <Form onSubmit={handleSubmit}>
            {this.state.success && (
              <Notification
                success
                close={this.closeAlert}
                open={this.state.successMessage}
                message={
                  action === "edit"
                    ? "Your Insurance was updated successfully"
                    : "A new insurance has been added."
                }
              />
            )}
            {this.state.serverError && (
              <Notification
                close={this.closeAlert}
                open={this.state.serverMessage}
                message={
                  formatENUM(this.state.serverMessage) ||
                  "Error Processing Request"
                }
              />
            )}
            <div className="columns">
              <div
                className={
                  "column " +
                  (this.props.action == "edit"
                    ? "is-full"
                    : "is-three-quarters")
                }
              >
                <Field label="Insurance Name">
                  <Input
                    name="name"
                    type="text"
                    value={values.name}
                    onBlur={handleBlur}
                    placeholder="Enter Name"
                    onChange={handleChange}
                  />
                  {submitCount > 0 && errors.name && (
                    <Error>{errors.name}</Error>
                  )}
                </Field>
              </div>
            </div>
            <Button type="submit" primary disabled={isSubmitting}>
              {action === "create" ? "Add Insurance" : "Save Changes"}
            </Button>
          </Form>
        )}
      />
    );
  }
}

export default compose(
  withRouter,
  graphql(ADD_INSURANCE, { name: "addInsurance" }),
  graphql(UPDATE_INSURANCE, { name: "updateInsurance" })
)(AccountInsuranceForm);