import React from "react";
import styled, { css } from "styled-components";
import { theme } from "../Global";
import { Button } from "../buttons/Button";

const StyleInformation = styled.div`
  text-align: center;
  p {
    max-width: 360px;
    margin: 0 auto;
    font-size: 28px;
    line-height: 30px;
    padding-bottom: 30px;
    font-family: ${theme.font.medium};

    ${props =>
      props.danger &&
      css`
        color: ${theme.color.danger};
      `}

    ${props =>
      props.success &&
      css`
        color: ${theme.color.success};
      `}
  }

  .button {
    margin-bottom: 15px;
  }
`;

export const Information = props => {
  return (
    <StyleInformation {...props}>
      <p>{props.text}</p>
      <Button onClick={props.secondaryClickHandler} secondary>
        {props.secondaryText}
      </Button>
    </StyleInformation>
  );
};
