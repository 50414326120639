// import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { theme } from './Global';

export const YwaitLink = styled(Link)`
  color: ${props => props.linkcolor ? props.linkcolor : props.theme.color.text}; 
  font-family: ${theme.font.medium};
  :hover {
    color: ${props => props.theme.color.primary};
  }

  ${ (props) => props.active && css`   
    background-color: ${theme.color.light_gray};
    color: white;
    :hover {
      color: white;
    }
  `}

`;
