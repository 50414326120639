import React from "react";
import { Title } from "../../components/Typography";
import ChooseProvider from "../../components/ChooseProvider";

export default () => (
  <div className="container">
    <div className="columns is-centered">
      <div className="column is-8">
        <Title className="has-text-centered">Select Your Provider</Title>
        <ChooseProvider />
      </div>
    </div>
  </div>
);
