import React from "react";
import styled from "styled-components";
import { Button } from "../buttons/Button";

const StyleConfirmation = styled.div`
  text-align: center;
  p {
    max-width: 400px;
    margin: 0 auto;
    font-size: 18px;
    line-height: 24px;
    padding-bottom: 25px;
  }

  .button {
    margin-bottom: 15px;
    min-width: 335px;
  }
`;

export const Confirmation = props => {
  return (
    <StyleConfirmation>
      <p>{props.text}</p>
      <Button onClick={props.primaryClickHandler} primary>
        {props.primaryText}
      </Button>
      <Button onClick={props.secondaryClickHandler} secondary>
        {props.secondaryText}
      </Button>
    </StyleConfirmation>
  );
};
