import React from 'react'
import { Title } from '../../components/Typography'
import styled from 'styled-components'

const Column = styled.div`
  h1 {
    text-align: center;
    margin-bottom: 50px;
  } 

  h3 {
    font-weight: bold;
    padding-bottom: 12px;
  }

  p {
    padding-bottom: 20px;
  }

  ul {
    list-style-type: disc;
    padding-left: 50px;
    padding-bottom: 20px;
    li {
      padding-bottom: 5px;
    }
  }
`

export default () => (
  <div className="container">
    <div className="columns is-centered">
      <Column className="column is-8">            
        <Title>
          Terms of Use 
          <small>Last Modified: [February 6, 2019]</small>  
        </Title>

        <h3>Acceptance of the Terms of Use</h3>

        <p>These terms of use are entered into by and between You and YWait LLC 
          ("<strong>Company</strong>", "<strong>we</strong>" or "<strong>us</strong>"). The following terms and conditions, together with any 
          documents they expressly incorporate by reference (these "<strong>Terms of Use</strong>"), govern your 
          access to and use of www.ywaitdoc.com, including any content, functionality and 
          services offered on or through www.ywaitdoc.com (the "<strong>YWait Site</strong>"), whether as a guest 
          or a registered user.</p>

        <p>Please read the Terms of Use carefully before you start to use the YWait Site. <strong>By 
          clicking to accept or agree to the Terms of Use when this option is made available to you, 
          you accept and agree to be bound and abide by these Terms of Use and our Privacy Policy, 
          found at www.ywaitdoc.com/privacy-policy, incorporated herein by reference</strong>. If you do not 
          want to agree to these Terms of Use or the Privacy Policy, you must not access or use 
          the YWait Site. </p>

        <p>This YWait Site is offered and available to users who are 18 years of age or older and reside in the United States. By using this YWait Site, you represent and warrant that you are of legal age to form a binding contract with the Company and meet all of the foregoing eligibility requirements. If you do not meet all of these requirements, you must not access or use the YWait Site.</p>

        <p>The purpose of the YWait Site is to provide you a simple way to access and schedule premium appointment times with your healthcare provider. To be clear, the YWait Site is not meant to be, and cannot be used as, a substitute for your medical treatment. </p>

        <p>In the spirit of keeping things convenient, the default setting of the YWait Site is to keep you logged in and store your login information for 30 minutes so that you do not have to re-enter your username and password if you access the YWait Site multiple times within a 30-minute window. However, this feature only applies if you do not affirmatively logout of the YWait Site. If you do not want your login information saved and stored, simply logout every time you use the YWait Site.</p>

        <h3>Changes to the Terms of Use</h3>

        <p>We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them, and apply to all access to and use of the YWait Site thereafter. However, any changes to the dispute resolution provisions set forth in Governing Law and Jurisdiction will not apply to any disputes for which the parties have actual notice on or prior to the date the change is posted on the YWait Site.</p>
        <p>Your continued use of the YWait Site following the posting of revised Terms of Use means that you accept and agree to the changes. You are expected to check this page frequently so you are aware of any changes, as they are binding on you. </p>
        
        <h3>Disclaimer for Use of YWait Site</h3>

        <p className="is-uppercase">
        THE YWAIT SITE (AND THE SERVICES PROVIDED HEREIN) SOLELY PROVIDE YOU WITH AN OPPORTUNITY TO PURCHASE ACCESS TO A PREMIUM TIME APPOINTMENT AND DO NOT PROVIDE MEDICAL ADVICE. THE YWAIT SITE AND THE SERVICES THEREIN ARE FOR ADVANCED SCHEDULING PURPOSES ONLY AND ARE NOT A PERSONAL EMERGENCY RESPONSE SYSTEM. ACCORDINGLY, THE YWAIT SITE SHALL NOT BE USED FOR ACUTE CRISIS MANAGEMENT, REAL TIME MONITORING OR MEDICAL CARE OF ANY KIND.  THE SERVICES ARE ONE COMPONENT OF THE SCHEDULING SERVICES BETWEEN YOU AND YOUR HEALTHCARE PROVIDER. IT IS SOLELY YOUR RESPONSIBILITY TO MAINTAIN COORDINATED AND/OR DIRECT CHANNELS OF CARE AND COMMUNICATIONS, AND NOT THE COMPANY.  THE COMPANY SHALL NOT BE HELD LIABLE FOR ANY HEALTH CARE AND/OR PROFESSIONAL SERVICES THAT YOUR HEALTHCARE PROVIDER PROVIDES TO YOU, INCLUDING ALL PATIENT CARE DECISIONS RESULTING FROM OR INVOLVING THE USE OF THE SCHEDULING SERVICES OR ANY OTHER DATA OR INFORMATION PROVIDED IN THE YWAIT SITE.  THE COMPANY MAKES NO REPRESENTATIONS CONCERNING THE COMPLETENESS, ACCURACY, OR UTILITY OF ANY DATA YOU OR A HEALTHCARE PROVIDER PROVIDE THROUGH THE YWAIT SITE.  NEITHER YOU, THE HEALTHCARE PROVIDER NOR ANY OTHER PERSON WILL HAVE ANY CLAIM OR CAUSE OF ACTION AGAINST THE COMPANY AS A RESULT OF ANY PATIENT CARE OR OTHER SERVICES RENDERED OR WITHHELD IN CONNECTION WITH THE USE OF THE SCHEDULING SERVICES OR ANY OTHER DATA PROVIDED THROUGH THE YWAIT SITE. 
        </p>
        
        <p className="is-uppercase">
        YOU ACKNOWLEDGE AND UNDERSTAND THAT YOUR LOGIN AND ACCOUNT INFORMATION IS SAVED AND STORED TO RECOGNIZE YOU WHEN YOU RETURN TO OUR YWAIT SITE, BUT ONLY IF YOU DO NOT COMPLETE THE LOGOUT PROCESS AND ONLY FOR 30 MINUTES. SHOULD YOU NOT WANT YOUR LOGIN INFORMATION SAVED AND STORED, YOU MUST AFFIRMATIVELY LOGOUT EVERY TIME YOU USE THE YWAIT SITE. IF YOU FAIL TO LOGOUT, YOU ARE RESPONSIBLE FOR ANY INFORMATION OR DATA THAT IS ACCESSED BY A THIRD PARTY. BECAUSE OF THIS FEATURE, YWAIT STRONGLY RECOMMENDS THAT YOU ACCESS THE YWAIT SITE FROM A PRIVATE, PERSONAL COMPUTER AND AFFIRMATIVELY LOGOUT AFTER EACH VISIT TO THE YWAIT SITE.
        </p>

        <p>With respect to the provision of the YWait Site and the services provided herein and the protection of your protected health information, you acknowledge and agree that the Company may be acting as the your healthcare provider’s business 
          associate (as defined in the administrative simplification provision of the Health Insurance Portability and Accountability 
          Act of 1996 and its implementing regulations (“<strong>HIPAA</strong>”) and that it will comply with the business associate agreement entered into separately between the Company and your healthcare provider.</p>

        <h3>Payment Information and Accessing the YWait Site and Account Security</h3>      

        <p>We use Stripe, Inc. (“Stripe”) to process all payment information, which means 
          that by using the YWait Site you are agreeing to abide by Stripe’s Privacy Policy 
          (found here: <a href='https://stripe.com/us/privacy' target="_blank">https://stripe.com/us/privacy</a>) and Terms of Service (found 
          here: <a href="https://stripe.com/us/checkout/legal" target="_blank">https://stripe.com/us/checkout/legal</a> ), as each may be updated from time to time. We reserve the right keep your contact information,
          billing information and credit card information (where applicable) up to date. All 
          payment obligations are non-cancelable and all amounts paid are non-refundable. </p>


        <p>We reserve the right to withdraw or amend this YWait Site, and any service we provide on the YWait Site, in our sole discretion without notice. We will not be liable if for any reason all or any part of the YWait Site is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the YWait Site, or the entire YWait Site, to users, including registered users.</p> 

        <p>You are responsible for:</p>

        <ul>
          <li>Making all arrangements necessary for you to have access to the YWait Site; and</li>
          <li>Complying with these Terms of Use.</li>
        </ul>

        <p>To access the YWait Site or some of the resources it offers, you may be asked to provide certain registration details or other information. It is a condition of your use of the YWait Site that all the information you provide on the YWait Site is correct, current and complete. You agree that all information you provide to register with this YWait Site or otherwise, including but not limited to through the use of any interactive features on the YWait Site, is governed by our Privacy Policy www.ywaitdoc.com/privacy-policy, and you consent to all actions we take with respect to your information consistent with our Privacy Policy.</p>
        <p>If you choose, or are provided with, a user name, password or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to this YWait Site or portions of it using your user name, password or other security information. You agree to notify us immediately of any unauthorized access to or use of your user name or password or any other breach of security. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.</p>
        <p>You also agree to ensure that you logout from your account at the end of each session unless you want your login information saved and stored. As discussed above, the default setting of the YWait Site is to keep you logged in and store your login information for 30 minutes so that you do not have to re-enter your username and password if you access the YWait Site multiple times within a 30-minute window. However, this feature only applies if you do not affirmatively logout of the YWait Site. If you do not want your login information saved and stored, simply logout every time you use the YWait Site. </p>
        <p>We have the right to disable any user name, password or other identifier, whether chosen by you or provided by us, at any time if, in our opinion, you have violated any provision of these Terms of Use.</p>

        <h3>Intellectual Property Rights</h3>
        <p>The YWait Site and its entire contents, features and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are owned by the Company, its licensors or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws.</p>

        <p>These Terms of Use permit you to use the YWait Site for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store or transmit any of the material on our YWait Site, except as follows:</p>

        <ul>
          <li>Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.</li>
          <li>You may store files that are automatically cached by your Web browser for display enhancement purposes.</li>
          <li>You may print one copy of a reasonable number of pages of the YWait Site for your own personal, non-commercial use and not for further reproduction, publication or distribution.</li>
          <li>If we provide desktop, mobile or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound by our end user license agreement for such applications.</li>
        </ul>

        <p>You must not:</p>

        <ul> 
          <li>Modify copies of any materials from this site.</li>
          <li>Use any illustrations, photographs, video or audio sequences or any graphics separately from the accompanying text.</li>
          <li>Delete or alter any copyright, trademark or other proprietary rights notices from copies of materials from this site.</li>
        </ul>

        <p>You must not access or use for any commercial purposes any part of the YWait Site or any services or materials available through the YWait Site. </p>

        <p>If you print, copy, modify, download or otherwise use or provide any other person with access to any part of the YWait Site in breach of the Terms of Use, your right to use the YWait Site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title or interest in or to the YWait Site or any content on the YWait Site is transferred to you, and all rights not expressly granted are reserved by the Company. Any use of the YWait Site not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark and other laws.</p>

        <h3>Trademarks</h3>
        <p>The Company name and all related names, trademarks, logos, product and service names, designs and slogans are trademarks of the Company or its affiliates or licensors. You must not use such marks without the prior written permission of the Company. All other names, logos, product and service names, designs and slogans on this YWait Site are the trademarks of their respective owners.</p>

        <h3>Prohibited Uses</h3>

        <p>You may use the YWait Site only for lawful purposes and in accordance with these Terms of Use. You agree not to use the YWait Site:</p>

        <ul>
          <li>In any way that violates any applicable federal, state, local or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries). </li>
          <li>For the purpose of exploiting, harming or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information or otherwise.</li>
          <li>To send, knowingly receive, upload, download, use or re-use any material which does not comply with these Terms of Use.</li>
          <li>To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail", "chain letter" or "spam" or any other similar solicitation.</li>
          <li>To impersonate or attempt to impersonate the Company, a Company employee, another user or any other person or entity (including, without limitation, by using e-mail addresses associated with any of the foregoing).</li>
          <li>To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the YWait Site, or which, as determined by us, may harm the Company or users of the YWait Site or expose them to liability.</li>
        </ul>
         
        <p>Additionally, you agree not to:</p>
        <ul> 
          <li>Use the YWait Site in any manner that could disable, overburden, damage, or impair the site or interfere with any other party's use of the YWait Site, including their ability to engage in real time activities through the YWait Site.</li>
          <li>Use any robot, spider or other automatic device, process or means to access the YWait Site for any purpose, including monitoring or copying any of the material on the YWait Site.</li>
          <li>Use any manual process to monitor or copy any of the material on the YWait Site or for any other unauthorized purpose without our prior written consent.</li>
          <li>Use any device, software or routine that interferes with the proper working of the YWait Site.</li>
          <li>Introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious or technologically harmful.</li>
          <li>Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the YWait Site, the server on which the YWait Site is stored, or any server, computer or database connected to the YWait Site. </li>
          <li>Attack the YWait Site via a denial-of-service attack or a distributed denial-of-service attack.</li>
          <li>Otherwise attempt to interfere with the proper working of the YWait Site.</li>
        </ul>

        <h3>Monitoring and Enforcement; Termination</h3>

        <p>We have the right to:</p>
        <ul>
          <li>Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the YWait Site. </li>
          <li>Terminate or suspend your access to all or part of the YWait Site for any violation of these Terms of Use.</li>
        </ul>

        <p>
          Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the YWait Site. 
          <span className="is-uppercase">YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY THE COMPANY/ANY OF THE FOREGOING PARTIES DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER THE COMPANY/SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.</span>  
        </p>

        <h3>Reliance on Information Posted</h3>

        <p>The information presented on or through the YWait Site is made available solely for general information purposes and scheduling purposes. We do not warrant the accuracy, completeness or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the YWait Site, or by anyone who may be informed of any of its contents.</p>
        <p>This YWait Site may include content provided by third parties, including materials provided by physicians, healthcare providers and third-parties. All statements and/or opinions expressed in these materials, and all other content, other than the content provided by the Company, are solely the responsibility of the person or entity providing those materials. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties.</p>


        <h3>Changes to the YWait Site</h3>

        <p>We may update the content on this YWait Site from time to time, but its content is not necessarily complete or up-to-date. Any of the material on the YWait Site may be out of date at any given time, and we are under no obligation to update such material. </p>

        <h3>Information About You and Your Visits to the YWait Site</h3>

        <p>All information we collect on this YWait Site is subject to our Privacy Policy www.ywaitdoc.com/privacy-policy. By using the YWait Site, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy. </p>

        <h3>Links from the YWait Site</h3>

        <p>Aside from the links to Stripe or in connection with payment processing, if the YWait Site contains links to other sites and resources provided by third parties, these links are provided for your convenience only. This includes links contained in advertisements, including banner advertisements and sponsored links. We have no control over the contents of those sites or resources, and accept no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of the third party websites linked to this YWait Site, you do so entirely at your own risk and subject to the terms and conditions of use for such websites.</p>

        <h3>Geographic Restrictions</h3>

        <p>The owner of the YWait Site is based in the state of Illinois in the United States. We provide this YWait Site for use only by persons located in the United States. We make no claims that the YWait Site or any of its content is accessible or appropriate outside of the United States. Access to the YWait Site may not be legal by certain persons or in certain countries. If you access the YWait Site from outside the United States, you do so on your own initiative and are responsible for compliance with local laws.</p>

        <h3>Disclaimer of Warranties</h3>

        <p>You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the YWait Site will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. 
          <span className="is-uppercase">WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.  </span>
        </p>

        <p className="is-uppercase">YOUR USE OF THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. </p>
        <p className="is-uppercase">THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE.</p>
        <p className="is-uppercase">THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>

        <h3>Limitation on Liability</h3>
        <p className="is-uppercase">IN NO EVENT WILL THE COMPANY, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.</p>
        <p className="is-uppercase">THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>

        <h3>Indemnification</h3>
        <p>You agree to defend, indemnify and hold harmless the Company, its affiliates, licensors and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms of Use or your use of the YWait Site, including, but not limited to, any use of the YWait Site's content, services and products other than as expressly authorized in these Terms of Use or your use of any information obtained from the YWait Site.</p>

        <h3>Governing Law and Jurisdiction</h3>
        <p>All matters relating to the YWait Site and these Terms of Use and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of Illinois without giving effect to any choice or conflict of law provision or rule (whether of the State of Illinois or any other jurisdiction).</p>
        <p>Any legal suit, action or proceeding arising out of, or related to, these Terms of Use or the YWait Site shall be instituted exclusively in the federal courts of the United States or the courts of the State of Illinois in each case located in the City of Chicago and County of County; although we retain the right to bring any suit, action or proceeding against you for breach of these Terms of Use in your country of residence or any other relevant country. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</p>

        <h3>Waiver and Severability</h3>
        <p>No waiver of by the Company of any term or condition set forth in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.</p>
        <p>If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.</p>

        <h3>Entire Agreement</h3>    

        <p>The Terms of Use and our Privacy Policy constitute the sole and entire agreement between you and YWait LLC with respect to the YWait Site and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the YWait Site. </p>

        <h3>Your Comments and Concerns</h3>
        <p>This website is operated by YWait LLC.</p>
        <p>All other feedback, comments, requests for technical support and other communications relating to the YWait Site should be directed to: <a href="mailto:ywaitdoc@gmail.com">ywaitdoc@gmail.com</a>.</p>
      </Column>
    </div>
  </div>
)
  

