import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import { withRouter } from "react-router";
import { Query } from "react-apollo";

import { ProviderCard } from "./Cards";
import { theme, formatENUM } from "./Global";
import { LoadingIndicator, ErrorIndicator } from "./indicators";
import Notification from "./Notification";
import GET_PATIENT_DASHBOARD from "../queries/getPatientDashboard";

const StyleChooseProvider = styled.div`
  .provider-card {
    margin-bottom: 20px;
    max-width: 387px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
  }

  .button {
    min-width: 280px;
  }
`;

const Paragraph = styled.p`
  padding-top: 10px;
  text-align: center;
  font-size: 17px;
  line-height: 24px;
  a {
    color: ${theme.color.text};
    text-decoration: underline;
  }
`;

class MyProviders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serverError: false,
      serverMessage: ""
    };
  }

  closeAlert = () => {
    this.setState({ serverError: false });
  };
  handleClcik = hcp => {
    localStorage.setItem("selectedHcpId", hcp.id);
    localStorage.setItem("selectedPracticeId", hcp.practice.id);
    this.setState({ selectedHcpId: hcp.id });
    this.props.history.push("/select-date");
  };

  render() {
    return (
      <Query query={GET_PATIENT_DASHBOARD}>
        {({ error, data, loading }) => {
          if (error) {
            console.error(error);
            return <ErrorIndicator />;
          }
          const payload = data["patientDashboard"];
          if (!payload || loading) {
            return <LoadingIndicator />;
          }
          if (payload.statusCode != 200) {
            return (
              <Notification
                open={true}
                message={formatENUM(payload.exceptions)}
              />
            );
          }
          const patient = payload["result"];
          if (!patient || !patient.hCPs.length)
            return <Notification open={true} message="No Provider's Found" />;

          return (
            <StyleChooseProvider>
              {patient.hCPs.map((doc, key) => {
                return (
                  <ProviderCard
                    onClick={this.handleClcik.bind(this, doc)}
                    className="provider-card"
                    isDoctorName
                    selected={this.state.selectedHcpId === doc.id}
                    key={key}
                    doctorImage={
                      doc.avatar || "https://via.placeholder.com/44x44/222742"
                    }
                    doctorName={
                      doc.firstName + " " + doc.lastName + ", " + doc.title
                    }
                    practiceName={doc.practice.name}
                    id={doc.id}
                  />
                );
              })}

              <Paragraph>
                Not the right practice?{" "}
                <Link to="/access-code">Try a different Access Code</Link>
              </Paragraph>
            </StyleChooseProvider>
          );
        }}
      </Query>
    );
  }
}

export default compose(withRouter)(MyProviders);
