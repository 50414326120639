import React from "react";
import styled from "styled-components";
import { theme } from "../Global";
import YwaitSelect from "../Select";
import { Button } from "../buttons/Button";
import { YwaitDayPickerInput } from "../DatePicker";
import { Field, Input } from "../Forms";

const StyleAddAppointment = styled.div`
  width: 100%;

  p {
    font-size: 18px;
    line-height: 24px;
    padding-bottom: 25px;
  }

  .group-doctor {
    width: 265px;
  }
  .group-duration {
    width: 170px;
    margin-left: 30px;
  }
  .group-startday {
    width: 241px;
  }
  .group-starttime {
    width: 170px;
    margin-left: 30px;
  }
  .group-repeat {
    width: 230px;
  }
  .group-enddate {
    width: 265px;
    margin-left: 30px;
  }
  .group-cost {
    width: 154px;
    margin-right: 30px;
    input {
      width: 150px;
      margin-bottom: 0;
    }
  }
`;

const Title = styled.h3`
  font-size: 22px;
  font-family: ${theme.font.medium};
  margin-bottom: 10px;
  margin-top: -25px;
`;

const GroupField = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: start;
  margin-bottom: 8px;
`;

const timeOptions = [
  { value: "1", label: "20min" },
  { value: "2", label: "30min" },
  { value: "3", label: "40min" },
  { value: "3", label: "50min" }
];
const repeatOptions = [
  { value: "1", label: "weekly" },
  { value: "2", label: "montly" }
];

export const AddAppointment = props => (
  <StyleAddAppointment>
    <Title>Add an Appointment Slot</Title>

    <GroupField>
      <Field label="Choose a Provider" className="group-doctor">
        <YwaitSelect options={props.doctorOptions} />
      </Field>
      <Field label="Duration" className="group-duration">
        <YwaitSelect options={timeOptions} />
      </Field>
    </GroupField>
    <GroupField>
      <Field label="Start Day" className="group-startday">
        <YwaitDayPickerInput />
      </Field>
      <Field label="Start Time" className="group-starttime">
        <YwaitSelect options={timeOptions} />
      </Field>
    </GroupField>
    <GroupField>
      <Field label="Repeat" className="group-repeat">
        <YwaitSelect options={repeatOptions} />
      </Field>
      <Field label="End Date" className="group-enddate">
        <YwaitDayPickerInput />
      </Field>
    </GroupField>
    <GroupField>
      <Field label="Cost" className="group-cost">
        <Input value={props.price} />
      </Field>
      <Button primary>{props.primaryText}</Button>
    </GroupField>
  </StyleAddAppointment>
);
