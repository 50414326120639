import React from 'react'
import { Title } from '../../components/Typography'
import PracticeForgotPasswordForm from '../../forms/PracticeForgotPasswordForm'

export default () =>
  <div className="container">
    <div className="columns is-centered">
      <div className="column is-5">
        <Title className="has-text-centered">Forgot Your Password</Title>        
        <PracticeForgotPasswordForm />        
      </div>
    </div>
  </div>
