import gql from "graphql-tag";

export default gql`
  query getPracticeHCPsWithAppointmentSlots($practiceId: String!) {
    getPracticeHCPsWithAppointmentSlots(practiceId: $practiceId) {
      statusCode
      userRole
      exceptions
      loggedInUser {
        id
        title
        firstName
        lastName
      }
      result {
        id
        title
        firstName
        lastName
        avatar
        practice {
          id
          name
        }
      }
    }
  }
`;
