import React, { Component } from "react";
import styled from "styled-components";
import moment from "moment";
import { withRouter } from "react-router";
import { compose } from "recompose";
import { Query } from "react-apollo";
import { theme } from "../../components/Global";

import { Title, H2 } from "../../components/Typography";
import { Button } from "../../components/buttons/Button";
import { ProviderCard, PatientAppCard } from "../../components/Cards";
import { LoadingIndicator, ErrorIndicator } from "../../components/indicators";

import GET_PATIENT_DASHBOARD from "../../queries/getPatientDashboard";
import DocImage from "../../assets/dr-avatar.png";

const PatientDashboard = styled.div``;

const CardWrapper = styled.div`
  padding-bottom: 20px;
`;

const Paragraph = styled.p`
  margin-bottom: 25px;
  font-size: 18px;
  line-height: 24px;
  strong {
    font-family: ${theme.font.medium};
  }
`;
class DashboardPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      successMessage: false,
      serverError: false
    };

    localStorage.removeItem("newPatientIsFirstVisit");
    localStorage.removeItem("selectedDate");
    localStorage.removeItem("selectedHcpId");
    localStorage.removeItem("selectedPracticeId");
  }

  handleClick(id) {
    if (id) {
      localStorage.setItem("newPatientIsFirstVisit", false);
      localStorage.setItem("selectedHcpId", id);
      this.props.history.push("/select-date");
    }
  }

  render() {
    return (
      <PatientDashboard className="container">
        <Query query={GET_PATIENT_DASHBOARD}>
          {({ error, data, loading }) => {
            if (error) {
              return <ErrorIndicator error={error} />;
            }
            const payload = data["patientDashboard"];
            if (!payload || loading) {
              return <LoadingIndicator />;
            }
            const patientDashboard = payload["result"];
            if (patientDashboard.upcomingAppointments.length) {
              patientDashboard.upcomingAppointments.sort(function(a, b) {
                if (a.dateTime < b.dateTime) return -1;
                if (a.dateTime > b.dateTime) return 1;
                return 0;
              });
            }
            return (
              <React.Fragment>
                <div className="columns">
                  <div className="column v-center">
                    <Title>Hello, {payload.loggedInUser.firstName}!</Title>
                  </div>
                  <div className="column v-center h-right">
                    <Button href="/new-appointment" primary>
                      Schedule an Appointment
                    </Button>
                  </div>
                </div>
                <div className="columns spaced">
                  <div className="column is-two-thirds">
                    <H2>Upcoming Premium Appointments</H2>
                    {!patientDashboard.upcomingAppointments.length ? (
                      <Paragraph>
                        You do not have any upcoming appointments.
                      </Paragraph>
                    ) : (
                      <div className="level">
                        {patientDashboard.upcomingAppointments.map(appt => (
                          <CardWrapper key={`cw-${appt.appointmentId}`}>
                            <PatientAppCard
                              id={appt.bookedAppointmentId}
                              singleButton
                              status={appt.appointmentStatus}
                              key={`upc-${appt.bookedAppointmentId}`}
                              direction="horizontal"
                              appDate={moment
                                .utc(appt.dateTime)
                                .local()
                                .format("dddd[,] MMM[.] Do [at] h:mma")}
                              doctorImage={appt.hCP.avatar || DocImage}
                              doctorName={`${appt.hCP.firstName} ${appt.hCP.lastName}, ${appt.hCP.title}`}
                            />
                          </CardWrapper>
                        ))}
                        <br />
                        <Button href="/past-appointments" secondary>
                          See all appointments
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className="column is-one-third">
                    <H2>Your Providers</H2>
                    {!patientDashboard.hCPs.length ? (
                      <Paragraph>You have not added any Providers</Paragraph>
                    ) : (
                      <div>
                        {patientDashboard.hCPs.map(hcp => (
                          <ProviderCard
                            key={hcp.id}
                            footer
                            phone={hcp.practice.phone}
                            doctorImage={hcp.avatar || DocImage}
                            doctorName={`${hcp.firstName} ${hcp.lastName}, ${hcp.title}`}
                            handleClick={this.handleClick.bind(this, hcp.id)}
                          />
                        ))}
                      </div>
                    )}

                    <br />
                    <Button full href="/access-code" secondary>
                      Add a new Provider
                    </Button>
                  </div>
                </div>
              </React.Fragment>
            );
          }}
        </Query>
      </PatientDashboard>
    );
  }
}
export default compose(withRouter)(DashboardPage);
