
import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import { YwaitLink } from './Link'
import { theme, media } from './Global'
import logo from '../assets/svg/ywait.svg'
import moment from 'moment'

export const Footer = styled.footer`
  background-color: white;  
  padding-top: 32px;
  padding-bottom: 32px;
  max-width: 100%;
  margin: 0 auto;
 
  a {
    display: inline;
    line-height: 28px;    
    margin-right: 40px;
  }

  nav {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .copyright {
    text-align: left;
    width: 100%;  
    
    a {
      text-decoration: underline;
    }

    a, span {
      color: ${rgba(theme.color.black, .50)};
      display: inline;
      margin-right: 55px;
      font-family: ${theme.font.regular}
    }
  }

  @media (max-width: 1060px) { 
    img {
      margin: 0 auto;
    }

    padding-bottom: 40px;

    a { text-align: center; }

    nav {
      justify-content: center;
      a { margin: 0 20px; }
    }

    .copyright {
      a, span {
        display: block;
        margin-right: 0;
        text-align: center;
      }
    }
  }

  .contact-us {
    color: ${theme.color.text}; 
    font-family: ${theme.font.medium};
    :hover {
      color: ${theme.color.primary};
    }
  }

  ${media.phone`
    overflow: hidden;
    nav {
      flex-direction: column;
      a { display: block }
    }
  `}
`;

const Logo = styled.img`
  max-width: 171px;
  display: block;

  ${media.phone`
    margin: 0 auto;
  `}
`;


const clickLink = () => {
  window.scrollTo(0, 0);
}

let role = localStorage.getItem('role');
let patientRole = role === 'Patient' ? true : false;
let adminRole = (role === 'PracticeAdmin' || role === 'HCP' ) ? true : false;

export default () => (
  <Footer>
    <div className="container is-widescreen">
      <div className="columns is-multiline is-centered">
        <div className="column is-12-tablet is-3-desktop  is-offset-1-desktop">
          <Logo src={logo} alt="ywait logo" />
        </div>
        <div className="column is-12-tablet is-8-desktop">
          <nav>
            { !adminRole ?
             <React.Fragment>
              
              { !patientRole ? 
                <React.Fragment>
                  <YwaitLink onClick={clickLink} to='/sign-in'>Patient Login</YwaitLink> 
                  <YwaitLink onClick={clickLink} to='/admin'>Provider Login</YwaitLink> 
                </React.Fragment>
                : '' }
              
                <YwaitLink onClick={clickLink} to='/access-code'>Enter Your Access Code</YwaitLink> 
            </React.Fragment>
             : ''}
        
            <a className="contact-us" href="mailto:ywaitdoc@gmail.com">Contact YWait</a> 
          </nav>         
        </div>        
      </div>  
      <div className="columns">
        <div className="column is-12-tablet is-offset-4-desktop is-8-desktop">
          <div className="copyright">
            <span>© {moment().format('YYYY')} YWait All Rights Reserved.</span>
            <YwaitLink onClick={clickLink} to='/privacy'>Privacy Statement.</YwaitLink>
            <YwaitLink onClick={clickLink} to='/terms-of-use'>Terms Of Use</YwaitLink>
            <YwaitLink onClick={clickLink} to='mailto:developer@bytecraft.io'>Contact Support</YwaitLink>
          </div>
        </div>
      </div>  
    </div>
  </Footer>
)