import { css } from 'styled-components'

export const theme = {
  navHeight: '82px',
  color: {
    primary:    '#4A5EED',
    secondary:  '#00E0A5',
    purple:     '#7339E6',
    white:      '#EEF2F5',
    light_gray: '#B3BCCC',
    gray:       '#6D7185',
    black:      '#0D122B',
    text:       '#222742',
    danger:     '#ED4A75',
    success:    '#00D199',
    almost_white:'#FAFAFA'
  },
  sizes: {
    desktop: 1228,
    tablet: 769,
    phone: 768,
  },
  font: {
    regular: 'Pepi-Light',
    rudi_thin: 'Rudi-Thin',
    bold: 'Rudi-Bold',
    thin: 'Pepi-Thin',
    medium: 'Pepi-Medium',
    heavy: 'Pepi-Heavy',
  }
}


// Iterate through the sizes and create a media template
export const media = Object.keys(theme.sizes).reduce((acc, label) => {
  
  if (label === 'phone') {
    acc[label] = (...args) => css`
      @media (max-width: ${theme.sizes[label] / 16}em) {
        ${css(...args)}
      }
    `;
  } else {
    acc[label] = (...args) => css`
      @media (min-width: ${theme.sizes[label] / 16}em) {
        ${css(...args)}
      }
    `;
  }

  return acc
}, {});

export const formatENUM = (str) => {
  return str.toString().replace(/([A-Z])/g, ' $1');
}

export const statesOptions = [
  { value: 'AK', label: 'Alaska' },
  { value: 'AL', label: 'Alabama' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DC', label: 'District Of Columbia' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'GU', label: 'Guam' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'IA', label: 'Iowa' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MD', label: 'Maryland' },
  { value: 'ME', label: 'Maine' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MP', label: 'Northern Mariana Islands' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MT', label: 'Montana' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NY', label: 'New York' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VA', label: 'Virginia' },
  { value: 'VT', label: 'Vermont' },
  { value: 'WA', label: 'Washington' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WY', label: 'Wyoming' },
]


export let isMobile = () => document.body.clientWidth <= theme.sizes.phone;  