import React from "react";
import styled from "styled-components";
import { compose } from "recompose";
import { withRouter } from "react-router";
import { theme, formatENUM } from "./Global";
import GET_PRACTICE_INSURANCES from "../queries/getPracticeInsurances";
import { Query } from "react-apollo";
import { LoadingIndicator, ErrorIndicator } from "./indicators";
import Notification from "./Notification";
import { SelectInsurance } from "./modals/SelectInsurance";

const Title = styled.h3`
  font-size: 22px;
  font-family: ${theme.font.medium};
  margin-bottom: 10px;
  margin-top: -25px;
`;

class ListInsurances extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serverError: false,
      serverMessage: ""
    };
  }

  closeAlert = () => {
    this.setState({ serverError: false });
  };

  render() {
    let practiceId = localStorage.getItem("selectedPracticeId");
    return (
      <Query
        query={GET_PRACTICE_INSURANCES}
        variables={{ practiceId: practiceId }}
      >
        {({ error, data, loading }) => {
          if (error) {
            console.log("Selected > " + practiceId);
            return <ErrorIndicator />;
          }
          const payload = data["practiceInsurances"];
          console.log("Payload > " + payload);
          if (!payload || loading) {
            return <LoadingIndicator />;
          }
          if (payload.statusCode != 200) {
            return (
              <Notification
                open={true}
                message={formatENUM(payload.exceptions)}
              />
            );
          }

          const practiceInsurances = payload["result"];
          if (!practiceInsurances || !practiceInsurances.length)
            return (
              <>
                <Notification
                  open={true}
                  message="No available insurances found."
                />
              </>
            );

          return (
            <div className="scrollable">
              <Title>List of Insurances Accepted by this Practice</Title>
              {practiceInsurances.map(ins => {
                return <SelectInsurance key={ins.id} insurance={ins} />;
              })}
            </div>
          );
        }}
      </Query>
    );
  }
}

export default compose(withRouter)(ListInsurances);
