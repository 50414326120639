import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router";
import { compose } from "recompose";
// import { graphql } from 'react-apollo'

import { Nav } from "./AccountSettings";
import { theme } from "../../components/Global";
import { YwaitLink } from "../../components/Link";
import AccountSettingsPayment from "../../components/SettingsPayment";
import { Title, H3 } from "../../components/Typography";

const Header = styled.h3`
  font-size: 36px;
  line-height: 44px;
  font-family: ${theme.font.rudi_thin};
  margin-bottom: 20px;
`;

let accessCode = localStorage.getItem("accessCode");

class AccountSettings extends React.Component {
  render() {
    return (
      <div>
        <div className="columns is-multiline">
          <div className="column is-8 v-center">
            <Title>Account Settings</Title>
          </div>
          <div className="column is-8 v-center">
            <H3>
              Your Access Code: <b>{accessCode}</b>
            </H3>
          </div>
        </div>
        <div className="columns spaced">
          <div className="column is-3">
            <Nav>
              <YwaitLink to="/account-settings">Profile</YwaitLink>
              <YwaitLink active={1} to="/account-payment">
                Payment
              </YwaitLink>
              <YwaitLink to="/insurances">List of Insurances</YwaitLink>
            </Nav>
          </div>
          <div className="column is-9">
            <Header>Payment</Header>
            <AccountSettingsPayment></AccountSettingsPayment>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(withRouter)(AccountSettings);
