import gql from 'graphql-tag';

export default gql`
    query getAppointment($appointmentId: String!) {
        getAppointment(appointmentId: $appointmentId) {
            statusCode
            userRole
            exceptions
            loggedInUser{
                id,
                title,
                firstName,
                lastName
              }
            result{
                    appointmentId
                    dateTime
                    duration
                    status
                    patient {
                        id
                        firstName
                        lastName
                        cellPhone
                    }
                    hCP {
                        id
                        firstName
                        lastName
                        title
                        avatar
                    }
                    practice {
                        id
                        name
                        phone
                        address
                        address2
                        city
                        state
                        zip
                    }
                    cost
                    occurance
                    endDate
            }
        }
    }
`;