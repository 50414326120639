import React, { Component } from 'react'
import styled from 'styled-components'
import DocImage from '../assets/dr-avatar.png' 
import { UserPicture }  from './UserPicture'
import Modal, { Upload } from './modals/Modal'
import cameraIcon from '../assets/svg/camera.svg';

const UploadAvatarStyles = styled.div`
  max-width: 151px;
  text-align: center;
` 

const Link = styled.a`
  margin-top: 5px;
  display: block;
  text-decoration: underline;
`

const ChangePhoto = styled.div`
  position: relative;
  height: ${ (props) => props.size };
  cursor: pointer;
  
  :before {
    content: '';
    border-radius: 50%;
    left: 0;
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.7;
  }

  img {
    position: absolute;
    left: 50%;
    margin-left: -19px;
    top: 30%;
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -45px;
    text-align: center;
    color: white;
    width: 90px;
    line-height: 21px;
    font-weight: bold;
  }

  :before, img, span {
    display: none;
  }
  :hover {
    :before, img, span {
      display: block;
    }
  }
`

class UploadAvatar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      docImage: props.value ? props.value : DocImage
    }
  }

  onCloseModal = () => {
    this.setState({ isOpen: false });
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ isOpen: true });
  }

  handleFormikChange = value => {
    this.setState({ docImage: value });
    this.props.onFormikChange('avatar', value);
  };

  render() {
    return (
      <UploadAvatarStyles>
        { this.state.isOpen ?
          <Modal isOpen={true} large onClose={this.onCloseModal}>
            <Upload onChange={this.handleFormikChange} />
          </Modal>
          : null
        }  

        <ChangePhoto size={this.props.size} onClick={this.openModal}>
          <UserPicture picture={this.state.docImage} size={this.props.size}></UserPicture>
          <img src={cameraIcon} alt='icon' />
          <span>Change Photo</span>
        </ChangePhoto>

      </UploadAvatarStyles>
    )
  }
}


export default UploadAvatar;
