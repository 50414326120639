import React from "react";
import styled from "styled-components";
import moment from "moment";

import { Link } from "react-router-dom";
import { Query } from "react-apollo";

import { Title, H2 } from "../../components/Typography";
import { Button } from "../../components/buttons/Button";
import { ProviderCard } from "../../components/Cards";
import AppointmentCard from "../../components/AppCards";
import { LoadingIndicator, ErrorIndicator } from "../../components/indicators";
import GET_PRACTICE_DASHBOARD from "../../queries/getPracticeDashboard";
import DocImage from "../../assets/dr-avatar.png";

export const PracticeDashboard = styled.div``;

const CardWrapper = styled.div`
  padding-bottom: 20px;
`;

const Paragraph = styled.p`
  margin-bottom: 25px;
  font-size: 18px;
  line-height: 24px;
  strong {
    font-family: inherit;
    font-weight: 600;
  }
`;

export default () => (
  <PracticeDashboard className="container">
    <Query query={GET_PRACTICE_DASHBOARD}>
      {({ loading, error, data, refetch, networkStatus }) => {
        if (error) {
          // console.log("Error >" + error);
          return <ErrorIndicator error={error} />;
        }
        const payload = data["practiceDashboard"];
        if (!payload || loading) {
          return <LoadingIndicator />;
        }
        const practiceDashboard = payload["result"];

        practiceDashboard.pendingAppointments.sort(function(a, b) {
          if (a.dateTime < b.dateTime) return -1;
          if (a.dateTime > b.dateTime) return 1;
          return 0;
        });

        practiceDashboard.upcomingAppointments.sort(function(a, b) {
          if (a.dateTime < b.dateTime) return -1;
          if (a.dateTime > b.dateTime) return 1;
          return 0;
        });

        return (
          <React.Fragment>
            <div className="columns">
              <div className="column v-center">
                {<Title>Hello, {payload.loggedInUser.firstName}!</Title>}
              </div>
              <div className="column v-center h-right">
                <Button href="/calendar" primary>
                  Go To Calendar
                </Button>
              </div>
            </div>
            <div className="columns">
              <div className="column is-8">
                <H2 style={{ marginBottom: 10 }}>
                  Premium Appointments to be Added
                </H2>
                {practiceDashboard.pendingAppointments.length ? (
                  <Paragraph>
                    The below appointments need to be added to your primary
                    calendar. If an appointment card denotes{" "}
                    <strong>new patient</strong>, confirm the patient’s
                    insurance information. If it denotes{" "}
                    <strong>existing appointment</strong>, check your schedule
                    to cancel a previously booked appointment.
                  </Paragraph>
                ) : (
                  <Paragraph>
                    You do not have appointments to be added.
                  </Paragraph>
                )}
              </div>
            </div>
            <div className="columns is-multiline">
              {practiceDashboard.pendingAppointments.map(appt => (
                <div
                  key={`ped-${appt.appointmentId}`}
                  className="column is-half"
                >
                  <pre>{appt.isNewPatient}</pre>
                  <AppointmentCard
                    id={appt.appointmentId}
                    bookedAppointmentId={appt.bookedAppointmentId}
                    status={appt.status}
                    userName={`${appt.patient.firstName} ${appt.patient.lastName}`}
                    phone={appt.patient.cellPhone}
                    email={appt.patient.email}
                    birthday={appt.patient.dOB}
                    appDate={moment
                      .utc(appt.dateTime)
                      .local()
                      .format("dddd[,] MMM[.] Do [at] h:mma")}
                    doctorImage={appt.hCP.avatar || DocImage}
                    doctorName={`${appt.hCP.firstName} ${appt.hCP.lastName}, ${appt.hCP.title}`}
                    refund="/"
                    refetch={refetch}
                    isReplace={appt.hadExistingAppointment}
                    newPatient={
                      appt.hadExistingAppointment && appt.isNewPatient
                        ? false
                        : appt.isNewPatient
                    }
                  />
                </div>
              ))}
            </div>
            <div className="columns spaced">
              <div className="column is-three-fifths">
                <H2>Upcoming Appointments</H2>
                {!practiceDashboard.upcomingAppointments.length ? (
                  <Paragraph>
                    You do not have any upcoming appointments.
                  </Paragraph>
                ) : (
                  <div className="level">
                    {practiceDashboard.upcomingAppointments.map(appt => (
                      <CardWrapper key={`upa-${appt.appointmentId}`}>
                        <AppointmentCard
                          id={appt.appointmentId}
                          bookedAppointmentId={appt.bookedAppointmentId}
                          status={appt}
                          direction="horizontal"
                          userName={`${appt.patient.firstName} ${appt.patient.lastName}`}
                          phone={appt.patient.cellPhone}
                          email={appt.patient.email}
                          birthday={appt.patient.dOB}
                          appDate={moment
                            .utc(appt.dateTime)
                            .local()
                            .format("dddd[,] MMM[.] Do [at] h:mma")}
                          doctorImage={appt.hCP.avatar || DocImage}
                          doctorName={`${appt.hCP.firstName} ${appt.hCP.lastName}, ${appt.hCP.title}`}
                          refund="/"
                          upcoming
                          refetch={refetch}
                          singleButton
                          isReplace={appt.hadExistingAppointment}
                          newPatient={
                            appt.hadExistingAppointment && appt.isNewPatient
                              ? false
                              : appt.isNewPatient
                          }
                        />
                      </CardWrapper>
                    ))}
                    <br />
                    <Button href="/appointments" secondary>
                      See all appointments
                    </Button>
                  </div>
                )}
              </div>
              <div className="column is-two-fifths">
                <H2>Your Providers</H2>
                {!practiceDashboard.hCPs.length ? (
                  <Paragraph> You have not added any Providers </Paragraph>
                ) : (
                  <div>
                    {practiceDashboard.hCPs.map(hcp => (
                      <CardWrapper key={`hcp-${hcp.id}`}>
                        <Link to={`/provider/${hcp.id}`} key={hcp.id}>
                          <ProviderCard
                            isDoctorName
                            footer
                            doctorImage={hcp.avatar || DocImage}
                            doctorName={`${hcp.firstName} ${hcp.lastName}, ${hcp.title}`}
                          />
                        </Link>
                      </CardWrapper>
                    ))}
                  </div>
                )}
                <br />
                <Button href="/providers" secondary>
                  See all Providers
                </Button>
              </div>
            </div>
          </React.Fragment>
        );
      }}
    </Query>
  </PracticeDashboard>
);
