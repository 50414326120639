import React from "react";
import { Title } from "../../components/Typography";
import MyProviders from "../../components/MyProviders";

export default () => (
  <div className="container">
    <div className="columns is-centered">
      <div className="column is-8">
        <Title className="has-text-centered">Select a Provider</Title>
        <MyProviders />
      </div>
    </div>
  </div>
);
