import React from 'react'
import { withRouter } from 'react-router';
import { compose } from "recompose";
import moment from "moment";
import { graphql } from 'react-apollo'

import GET_PRACTICE_DASHBOARD from '../queries/getPracticeDashboard';
import { practiceDashboardGetter } from '../queries/propGetters/practiceDashboard'
import addCalendarHandlers from '../components/decorators/addCalendarHandlers';
import GET_PRACTICE_STATE from '../queries/getPracticeState'
import { practiceStateGetter } from '../queries/propGetters/practiceState'
import UPDATE_PRACTICE_STATE from '../mutations/updatePracticeState'
import DELETE_APPOINTMENT from '../mutations/appointments/deleteAppointment'
import DELETE_ALL_APPOINTMENTS from '../mutations/appointments/deleteAllAppointments'

import Modal, { 
  ConfirmationCard
}  from '../components/modals/Modal'


class PracticeAppointmentForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  render() {
    
    const { practiceState } = this.props

    let repeat = '';
    switch(practiceState.schedulerSelectedAppointment.occurance) {
        case 'Once':
        default:
            repeat = ''
            break;

        case 'Daily':
            repeat = 'Reoccurs every day until ' + moment(practiceState.schedulerSelectedAppointment.endDate).format('MMM. Do')
            break; 

        case 'Weekly':
            repeat = 'Reoccurs every ' + 
                moment(practiceState.schedulerSelectedAppointment.dateTime).format('dddd') + 
                ' until ' + moment(practiceState.schedulerSelectedAppointment.endDate).format('MMM. Do')
            break; 

        case 'Monthly':
            repeat = 'Reoccurs every month until ' + moment(practiceState.schedulerSelectedAppointment.endDate).format('MMM. Do')
            break; 
    }

    return (
      <Modal isOpen onClose={this.props.resetCalendarFlow}>
        <ConfirmationCard 
          booked={practiceState.schedulerSelectedAppointment.status === 'Booked'}
          open={practiceState.schedulerSelectedAppointment.status === 'Open'}
          date={`${moment(practiceState.schedulerSelectedAppointment.dateTime).format('MMM. Do [from] h:mm [to] ')}${moment(practiceState.schedulerSelectedAppointment.dateTime).add('minutes', practiceState.schedulerSelectedAppointment.duration).format('h:mma')}`}
          doctor={`${practiceState.schedulerSelectedAppointment.hCP.firstName} ${practiceState.schedulerSelectedAppointment.hCP.lastName}`}
          doctorColor={practiceState.schedulerHCPs.find(h => h.id === practiceState.schedulerSelectedAppointment.hCP.id).color}
          repeat={repeat}
          price={`$${practiceState.schedulerSelectedAppointment.cost}`}
          primaryText={'edit'}
          secondaryText={'delete'}
          onClickPrimary={e => {
            if(practiceState.schedulerSelectedAppointment.occurance!=='Once') {
              this.props.appointmentEditClick()
            } else{
              this.props.appointmentEditOnceClick()
            }
        }
        }
          onClickSecondary={e => {
              if(practiceState.schedulerSelectedAppointment.occurance!=='Once') {
                  this.props.appointmentWithOccuranceDeleteClick()
              } else {
                  this.props.appointmentDeleteClick()
              }
              
          }}
          />
      </Modal>
    )
  }
}

export default compose(
    withRouter,
    graphql(GET_PRACTICE_DASHBOARD, { props: practiceDashboardGetter }),
    graphql(GET_PRACTICE_STATE, { props: practiceStateGetter }),
    graphql(UPDATE_PRACTICE_STATE, { name: 'updatePracticeState' }),
    graphql(DELETE_APPOINTMENT, { name: 'deleteAppointment' }),
    graphql(DELETE_ALL_APPOINTMENTS, { name: 'deleteAllAppointments' }),
    addCalendarHandlers
)(PracticeAppointmentForm);