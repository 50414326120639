import React, { Component } from 'react'
import styled from 'styled-components'
import { compose } from 'recompose';
import * as Yup from 'yup';
import { Formik } from 'formik'
import Cleave from 'cleave.js/react';
import YwaitSelect from '../components/Select'
import { Title } from '../components/Typography'

import Notification from '../components/Notification'
import { theme, statesOptions } from '../components/Global'
import { Field, Input, InputWrapper, Error, Button } from '../components/Forms'

const Form = styled.form`
  margin: 0 auto;
  p {  
    color: ${theme.color.gray};
    a {
      color: ${theme.color.gray};
      text-decoration: underline;
    }
  }

  .input { margin-bottom: 10px }
  ${Error} { padding-bottom: 5px }

  .button {
    margin-top: 30px;
  } 
`

const schema = Yup.object().shape({
  name: Yup.string().matches(/^[a-zA-Z0-9\&'.,\s]+$/, 'Practice Name is not in the correct format.').max(50, 'Practice Name is too long (max 50 characters)').required('Practice Name is required.'),
  phone: Yup.string().matches(/^[0-9\-.()\s]+$/, 'Phone Name is not in the correct format.').max(20, 'Phone is too long (max 20 characters)').required('Phone is required.'),
  address: Yup.string().matches(/^[a-zA-Z0-9'.,\s]+$/, 'Address is not in the correct format.').max(50, 'First Name is too long (max 50 characters)').required('Address is required.'),
  city: Yup.string().matches(/^[a-zA-Z'\s]+$/, 'City is not in the correct format.').max(30, 'City is too long (max 30 characters)').required('City is required.'),
  zip: Yup.string().matches(/^[0-9\-.()\s]+$/, 'Zip is not in the correct format.').max(10, 'Zip is too long (max 10 characters)').required('Zip is required.')
});

class PracticeSignUpLocationForm extends Component {
  constructor(props) {
    super(props);

    var defaultValues = {
      address: '', address2: '',
      city: '', state: '',
      zip: ''
    }

    this.state = {
      serverError: false,
      serverMessage: 'Incorrect email or password.',
      defaultValues: { ...defaultValues }
    }

    if(Object.keys(this.props.values).length > 0)
    this.state = {
      serverError: false,
      serverMessage: 'Incorrect email or password.',
      defaultValues: { ...defaultValues, ...this.props.values }
    }
  }

  mapSubmitData(values) {
    return {
      name: values.name,
      phone: values.phone,
      address: values.address,
      address2: values.address2,
      city: values.city,
      state: values.state.value,
      zip: values.zip
    }
  }

  handleSubmit = (values, actions) => {
    this.setState({ serverError: false, successMsg: false });
    this.props.handleSubmit(this.mapSubmitData(values), actions);
  }
  closeAlert = () => {
    this.setState({ serverError: false, successMsg: false });
  }

  render() {
    return (
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-8">
            <Title className="has-text-centered">Enter Office Information</Title>
            <Formik
              initialValues={this.state.defaultValues}
              validationSchema={schema}
              onSubmit={this.handleSubmit}
              render={({
                values,
                errors,
                isSubmitting,
                submitCount,
                handleBlur,
                setFieldTouched,
                setFieldValue,
                handleChange,
                handleSubmit
              }) => (
                
                <Form onSubmit={handleSubmit}>
                  {this.state.serverError && <Notification
                    close={this.closeAlert}
                    open={this.state.serverError}
                    message={this.state.serverMessage}
                  />}

                  <div className="columns is-multiline">
                    <div className="column is-6">
                      <Field label="Practice Name">
                        <Input className="input"
                          placeholder=""
                          name="name"
                          type="text"
                          value={values.name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {(submitCount > 0 && errors.name) && <Error>{errors.name}</Error>}
                      </Field>
                    </div>
                    <div className="column is-6">
                      <Field label="Practice Phone Number">
                        <InputWrapper>
                          <Cleave
                            name="phone"
                            type="text"
                            value={values.phone}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            options={{ numericOnly: true, blocks: [0, 3, 3, 4], delimiters: ["(", ") ", "-"] }}
                          />
                        </InputWrapper>
                        {(submitCount > 0 && errors.phone) && <Error>{errors.phone}</Error>}
                      </Field>
                    </div>
                  </div>

                  <div className="columns">
                    <div className="column is-5">
                      <Field label="Practice Address">
                        <Input
                          name="address"
                          type="text"
                          value={values.address}
                          onBlur={handleBlur}
                          onChange={handleChange} />
                        {(submitCount > 0 && errors.address) && <Error>{errors.address}</Error>}

                        <br />
                        <br />

                        <Input
                          name="address2"
                          type="text"
                          value={values.address2}
                          onBlur={handleBlur}
                          onChange={handleChange} />
                        {(submitCount > 0 && errors.address2) && <Error>{errors.address2}</Error>}
                      </Field>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column is-4">
                      <Field label="City">
                        <Input
                          name="city"
                          type="text"
                          value={values.city}
                          onBlur={handleBlur}
                          onChange={handleChange} />
                        {(submitCount > 0 && errors.city) && <Error>{errors.city}</Error>}
                      </Field>
                    </div>
                    <div className="column is-4">
                      <Field label="State">
                        <YwaitSelect
                          name="state"
                          value={values.state}
                          onFormikChange={setFieldValue}
                          onFormikBlur={setFieldTouched}
                          options={statesOptions} />
                      </Field>
                      {(submitCount > 0 && errors.state) && <Error>{errors.state}</Error>}
                    </div>
                    <div className="column is-4">
                      <Field label="Zip Code">
                        <Input
                          name="zip"
                          type="text"
                          value={values.zip}
                          onBlur={handleBlur}
                          onChange={handleChange} />
                        {(submitCount > 0 && errors.zip) && <Error>{errors.zip}</Error>}
                      </Field>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column is-6">
                      <Button full onClick={this.props.handleGoBack} secondary disabled={isSubmitting}>back</Button>
                    </div>
                    <div className="column is-6">
                      <Button full type='submit' primary disabled={isSubmitting}>Next</Button>
                    </div>
                  </div>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default compose(
)(PracticeSignUpLocationForm);