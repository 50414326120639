import React from 'react'
import { Title } from '../../components/Typography'
import PracticeResetPasswordForm from '../../forms/PracticeResetPasswordForm'

export default () =>
  <div className="container">
    <div className="columns is-centered">
      <div className="column is-6">
        <Title className="has-text-centered">Reset Your Password</Title>        
        <PracticeResetPasswordForm />        
      </div>
    </div>
  </div>
