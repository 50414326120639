import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { graphql } from 'react-apollo'
import { compose } from 'recompose';
import * as Yup from 'yup';
import { Formik } from 'formik'
import Recaptcha from 'react-recaptcha'
import Cleave from 'cleave.js/react';
import { Title } from '../components/Typography'

import Notification from '../components/Notification' 
import { theme } from '../components/Global'
import { Field, Input, InputWrapper, Error, Button } from '../components/Forms'
import DOES_USER_EXIST from '../mutations/patient/doesUserExist';

const Form = styled.form`
  margin: 0 auto;
  p {  
    color: ${theme.color.gray};
    a {
      color: ${theme.color.gray};
      text-decoration: underline;
    }
  }

  .input { margin-bottom: 10px }

  ${Error} { padding-bottom: 5px }

  .button {
    margin-top: 40px;
    min-width: 350px;
  }
`

const Checkbox = styled.input`
  margin-right: 10px;
`

const schema = Yup.object().shape({
  firstname: Yup.string().required('First Name is required.'),
  lastname: Yup.string().required('Last Name is required.'),
  name: Yup.string().matches(/^[a-zA-Z0-9\&'.,\s]+$/, 'Practice Name is not in the correct format.').max(50, 'Practice Name is too long (max 50 characters)').required('Practice Name is required.'),
  phone: Yup.string().matches(/^[0-9\-.()\s]+$/, 'Phone Name is not in the correct format.').max(20, 'Phone is too long (max 20 characters)').required('Phone is required.'),
  email: Yup.string()
    .email('Please enter a valid email address.')
    .required('Email is required.'),
  verifyEmail: Yup.string()
    .email('Please enter a valid email address.').oneOf([Yup.ref('email')], 'Emails addresses do not match.')
    .required('Confirm Email is required.'),
  password:
    Yup.string()
      .min(6, 'Password minimum length is 6 characters')
      .required('Password is required.'),
  verifyPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords are not the same!')
    .required('Verify Password is required.'),
   recaptcha: Yup.string().required('Recaptcha is required.'),
  terms: Yup.boolean().oneOf([true], 'Terms and Conditions is required.'),
});



class PracticeSignUpForm extends Component {
  constructor(props) {
    super(props);
    var defaultValues = {
      firstname: '',
      lastname: '',
      name: '', phone: '',
      email: '', verifyEmail: '',
      password: '', verifyPassword: '',
      recaptcha: '',
      terms: false
    }

    this.state = {
      serverError: false,
      serverMessage: 'Incorrect email or password.',
      defaultValues: { ...defaultValues }
    }

    if (Object.keys(this.props.values).length > 0)
      this.state = {
        serverError: false,
        serverMessage: 'Incorrect email or password.',
        defaultValues: { ...this.props.values }
      }
  }

  mapSubmitData(values) {
    return {
      name: values.name,
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      password: values.password,
      phone: values.phone
    }
  }
  componentDidMount() {
    const script = document.createElement(`script`);
    script.src = `https://www.google.com/recaptcha/api.js`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  handleSubmit = (values, actions) => {
    this.setState({ serverError: false, successMsg: false });
    this.props.doesUserExist({
      name: 'doesUserExist',
      variables: this.mapSubmitData(values)
    }).then((res) => {

      const payload = res.data['doesUserExist'];
      if (!payload) {
        actions.setSubmitting(false);
        this.setState({ serverError: true, serverMessage: "Request Failed" });
        return;
      }

      if (payload.statusCode != 404) {
        actions.setSubmitting(false);
        this.setState({ serverError: true, serverMessage: "User Already Exist" });
        return;
      }

      this.props.handleSubmit(this.mapSubmitData(values), actions);
    })
      .catch(({ graphQLErrors }) => {
        console.log('graphQLErrors', graphQLErrors);
        this.setState({ serverError: true, serverMessage: "Request Failed" });
      });

  }

  closeAlert = () => {
    this.setState({ serverError: false, successMsg: false });
  }

  render() {
    return (
      <div>
        <Title className="has-text-centered">Become a Verified <br />Practice with YWait</Title>

        <Formik
          initialValues={this.state.defaultValues}
          validationSchema={schema}
          onSubmit={this.handleSubmit}
          render={({
            values,
            errors,
            isSubmitting,
            submitCount,
            handleBlur,
            setFieldValue,
            handleChange,
            handleSubmit
          }) => (
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-8">
                  <Form onSubmit={handleSubmit}>

                    {this.state.serverError && <Notification
                      close={this.closeAlert}
                      open={this.state.serverError}
                      message={this.state.serverMessage}
                    />}

                    <div className="columns is-multiline">
                      <div className="column is-6">
                        <Field label="Practice Name">
                          <Input className="input"
                            placeholder=""
                            name="name"
                            type="text"
                            value={values.name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {(submitCount > 0 && errors.name) && <Error>{errors.name}</Error>}
                        </Field>
                      </div>
                      <div className="column is-6">
                        <Field label="Practice Phone Number">
                          <InputWrapper>
                            <Cleave
                              name="phone"
                              type="text"
                              value={values.phone}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              options={{ numericOnly: true, blocks: [0, 3, 3, 4], delimiters: ["(", ") ", "-"] }}
                            />
                          </InputWrapper>
                          {(submitCount > 0 && errors.phone) && <Error>{errors.phone}</Error>}
                        </Field>
                      </div>
                      <div className="column is-6">
                        <Field label="First Name">
                          <Input className="input"
                            placeholder=""
                            name="firstname"
                            type="text"
                            value={values.firstname}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {(submitCount > 0 && errors.firstname) && <Error>{errors.firstname}</Error>}
                        </Field>
                      </div>
                      <div className="column is-6">
                        <Field label="Last Name">
                          <Input className="input"
                            placeholder=""
                            name="lastname"
                            type="text"
                            value={values.lastname}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {(submitCount > 0 && errors.lastname) && <Error>{errors.lastname}</Error>}
                        </Field>
                      </div>
                      <div className="column is-6">
                        <Field label="Email">
                          <Input className="input"
                            placeholder=""
                            name="email"
                            type="text"
                            value={values.email}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {(submitCount > 0 && errors.email) && <Error>{errors.email}</Error>}
                        </Field>
                      </div>
                      <div className="column is-6">
                        <Field label="Confirm Email">
                          <Input className="input"
                            placeholder=""
                            name="verifyEmail"
                            type="text"
                            value={values.verifyEmail}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {(submitCount > 0 && errors.verifyEmail) && <Error>{errors.verifyEmail}</Error>}
                        </Field>
                      </div>
                      <div className="column is-6">
                        <Field label="Create Password">
                          <Input className="input"
                            name="password"
                            type="password"
                            value={values.password}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {(submitCount > 0 && errors.password) && <Error>{errors.password}</Error>}
                        </Field>
                        <p>Password minimum length is 6 characters.</p>
                      </div>
                      <div className="column is-6">
                        <Field label="Verify Password">
                          <Input className="input"
                            name="verifyPassword"
                            type="password"
                            value={values.verifyPassword}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {(submitCount > 0 && errors.verifyPassword) && <Error>{errors.verifyPassword}</Error>}
                        </Field>
                      </div>
                      <div className="column is-12">

                        <Checkbox
                          name="terms"
                          type="checkbox"
                          value={values.terms}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />

                        I Agree to the <Link to='/terms-of-use'>Terms and Conditions</Link> of YWait.
                      </div>
                      <div className="column is-6">
                        <Recaptcha
                          sitekey="6LfmvbYUAAAAACR-ESDC7J224kKnDtzr64wrSNXy"
                          render="explicit"
                          theme="white"
                          verifyCallback={(response) => { setFieldValue("recaptcha", response); }}
                          onloadCallback={() => { console.log("done loading!"); }}
                        />

                        {(submitCount > 0 && errors.recaptcha) && <Error>{errors.recaptcha}</Error>}

                      </div>
                    </div>

                    {(submitCount > 0 && errors.terms) && <Error>{errors.terms}</Error>}

                    <div className="has-text-centered">
                      <Button type='submit' secondary disabled={isSubmitting}>Next</Button>
                    </div>

                  </Form>
                </div>
              </div>
            </div>
          )}
        />
      </div>

    )
  }
}

export default compose(
  graphql(DOES_USER_EXIST, { name: 'doesUserExist' }),
)(PracticeSignUpForm);