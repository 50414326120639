
import React, { Component } from 'react'

class ErrorIndicator extends Component {

    render() {
        if(this.props.error && this.props.error.message.indexOf('authorized') !== -1) {
            localStorage.clear();
            window.location.href = '/'; 
        }

        return (
            <div className="column loading-section">
                <section className="columns is-paddingless is-marginless">
                    <div className="column">
                        <div className="level h-center">
                            An error has occured while processing your request.
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default ErrorIndicator