import React, { Component } from 'react'
import Select, { components }  from 'react-select'
import styled from 'styled-components'
import {theme} from './Global'
import svgSelect from '../assets/svg/select.svg'

const YwaitSelectStyles = styled(Select)`
  .select__control {
    /* border-color: ${theme.color.light_gray}; */
    border-radius: 0;
    font-size: 16px;
    font-family: ${theme.font.medium}; 
    box-shadow: none; 
    
    &:hover {
      border-color: ${theme.color.light_gray};
      box-shadow: none;
    }
  }
  
  .select__control--is-focused, .is-selected {    
    border-color: ${theme.color.text} !important;
    color: ${theme.color.text};  
    
    .select__indicator-separator {      
      background-color: ${theme.color.text};
    }
  }
  
  .select__placeholder {
    color:  ${theme.color.light_gray};
    font-family: ${theme.font.regular};
  }

  .select__value-container {
    height: 60px;
    line-height: 60px;       
    padding: 2px 15px;
  }

  .select__indicators {   
    min-width: 60px;    
  }
  
  .select__indicator {
    margin: 0 auto;
  }

  .select__option--is-selected {
    background-color: ${theme.color.primary};
  }

  .select__indicator-separator {
    margin: 0;
    background-color: ${theme.color.light_gray};
  }

  .select__menu {
    border: 1px solid ${theme.color.text};
    box-shadow: none;
    border-radius: 0;
    border-top: none;
    margin-top: 1px;
    z-index: 100;
  }
  
  .select__menu-list {
    padding: 0;
    border-top: 1px solid ${theme.color.text};
  }

  .select__option {
    height: 60px;
    line-height: 60px; 
    padding: 0 15px;
    border-bottom: 1px solid ${theme.color.text};
    &:last-child {
      border-bottom: none;
    }
  }
`


const ControlComponent = (props) => (
  <components.Control {...props} className={ props.hasValue ? 'is-selected' : '' } />
);


const DropdownIndicator = (props) => {
  return components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <img src={svgSelect} alt="" />
    </components.DropdownIndicator>
  );
};

class YWaitSelect extends Component {


  handleFormikChange = value => {
    this.props.onFormikChange(this.props.name, value);
  };

  handleFormikBlur = () => {
    this.props.onFormikBlur(this.props.name, true);
  };

  render() {
    return (<YwaitSelectStyles 
      classNamePrefix="select" 
      onChange={this.props.onFormikChange ? this.handleFormikChange : this.props.onChange}
      components={{ Control: ControlComponent, DropdownIndicator }}
      value={this.props.value} 
      // menuIsOpen={true}
      isSearchable={this.props.isSearchable || false }
      menuPlacement={this.props.isSearchable || 'auto' }
      options={this.props.options}
      {...this.props} />)
  }
}

export default YWaitSelect;