import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router';
import { compose } from "recompose";
import { graphql } from 'react-apollo'

import { media } from './Global'
import { Button } from './buttons/Button'
import TimeSelector from './time-selector/TimeSelector';

const StyleSelectTime = styled.div`
  p {
    padding-bottom: 30px;
    text-align: center;
    max-width: 595px;
    margin: 0 auto;    
  }

  .DayPicker-Select {
    width: 286px;
    margin: 0 auto;
  }

  .button {
    padding-left: 56px;
    padding-right: 56px;
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 40px;

  .button { width: 263px; }
  .button:first-child { margin-right: 25px; }

  ${media.phone`
    .button { width: 100%; }
    .button:first-child { margin: 0 0 25px 0; }
  `}
`

const Paragraph = styled.p`
  margin-bottom: 25px;
  text-align: center;
  font-size: 18px;
`

class SelectTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serverError: false,
      serverMessage: '',
    }
  }

  render() {
    const handleGoBack = () => {
      this.props.history.goBack();
    }

    return (
      <StyleSelectTime>
        <Paragraph>Gain access to a premium appointment! </Paragraph>   
        <TimeSelector />
        <ButtonWrapper>
          <Button onClick={handleGoBack} secondary> Go Back </Button>
        </ButtonWrapper>
      </StyleSelectTime>
    )
  }
}

export default compose(
  withRouter
)(SelectTime);
