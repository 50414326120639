import gql from 'graphql-tag';

export default gql`
    mutation updatePractice($practice:PracticeInputType!, $practiceId: ID!) {
    updatePractice(practice:$practice, practiceId: $practiceId) {
        statusCode
        userRole
        exceptions
        loggedInUser{
            id,
            title,
            firstName,
            lastName
          }
        result{
            id
            ownerId
            firstName
            lastName
            email
            name
            accessCode
            phone
            address
            address2
            city
            state
            zip
            status
        }
    }
}
`;