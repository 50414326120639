import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router";
import { graphql } from "react-apollo";
import { compose } from "recompose";
import YwaitSelect from "../components/Select";
import * as Yup from "yup";
import { Formik } from "formik";
import { formatENUM } from "../components/Global";
import Cleave from "cleave.js/react";

import { theme, statesOptions } from "../components/Global";
import { Field, Input, InputWrapper, Error, Button } from "../components/Forms";
import Notification from "../components/Notification";
import GET_PATIENT_DASHBOARD from "../queries/getPatientDashboard";
import { patientDashboardGetter } from "../queries/propGetters/patientDashboard";
import UPDATE_PATIENT from "../mutations/patient/updatePatient";

const Form = styled.form``;

const AccountSettingsForm = styled.div``;

const Panel = styled.div`
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid ${theme.color.light_gray};
  .field {
    position: relative;
  }
`;

const Title = styled.h3`
  font-size: 36px;
  line-height: 44px;
  font-family: ${theme.font.rudi_thin};
  margin-bottom: 20px;
`;

const PasswordLink = styled.a`
  position: absolute;
  right: 0;
  top: 8px;
  text-decoration: underline;
`;

const AccountSettingsFormSubmitBar = styled.div`
  padding-top: 50px;

  .primary button {
    margin-right: 30px;
    /* width: 220px; */
  }

  .primary a {
    text-decoration: underline;
  }
`;
const dateRegExp = new RegExp(
  "^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{4})*$"
);

const schema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[a-zA-Z'\s]+$/, "First Name is not in the correct format.")
    .max(20, "First Name is too long (max 20 characters)")
    .required("First Name is required."),
  lastName: Yup.string()
    .matches(/^[a-zA-Z'\s]+$/, "Last Name is not in the correct format.")
    .max(20, "Last Name is too long (max 20 characters)")
    .required("Last Name is required."),
  email: Yup.string()
    .email("Email is not in the correct format.")
    .max(30, "Email is too long (max 30 characters)")
    .required("Email is required."),
  birthday: Yup.string()
    .matches(dateRegExp, "Invalid Date")
    .required("Date of Birth is required.")
    .nullable(),
  cellPhone: Yup.string()
    .matches(/^[0-9\-.()\s]+$/, "Phone is not in the correct format.")
    .max(20, "Phone is too long (max 20 characters)")
    .required("Phone is required."),
  homePhone: Yup.string()
    .matches(/^[0-9\-.()\s]+$/, "Phone is not in the correct format.")
    .max(20, "Phone is too long (max 20 characters)")
    .nullable(),
  workPhone: Yup.string()
    .matches(/^[0-9\-.()\s]+$/, "Phone is not in the correct format.")
    .max(20, "Phone is too long (max 20 characters)")
    .nullable(),
  address: Yup.string()
    .matches(/^[a-zA-Z0-9'.,\s]+$/, "Address is not in the correct format.")
    .max(50, "First Name is too long (max 50 characters)")
    .nullable(),
  city: Yup.string()
    .matches(/^[a-zA-Z'\s]+$/, "City is not in the correct format.")
    .max(30, "City is too long (max 30 characters)")
    .nullable(),
  zip: Yup.string()
    .matches(/^[0-9\-.()\s]+$/, "Zip is not in the correct format.")
    .max(10, "Zip is too long (max 10 characters)")
    .nullable(),
  newPassword: Yup.string()
    .min(6, "Password too short (min 6 characters)")
    .nullable()
});

class PatientAccountSettingsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      serverError: false,
      changePassword: false,
      serverMessage: ""
    };
  }

  mapSubmitData(values) {
    const { patientDashboard } = this.props;
    var state = "";
    if (values.state[0]) state = values.state[0].value;

    return {
      patient: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        name: values.name,
        dOB: values.birthday,
        workPhone: values.workPhone,
        cellPhone: values.cellPhone,
        newPassword: values.newPassword,
        oldPassword: values.oldPassword,
        homePhone: values.homePhone,
        address: values.address,
        address2: values.address2,
        city: values.city,
        state: state,
        zip: values.zip,
        defaultStripeCardToken: null
      },
      patientId: patientDashboard.result.patient.id
    };
  }

  catchErrors = graphQLErrors => {
    this.setState({ serverError: true, serverMessage: graphQLErrors });
  };

  handleSubmit = (values, actions) => {
    this.setState({ serverError: false });

    this.props
      .updatePatient({
        name: "updatePatient",
        variables: this.mapSubmitData(values)
      })
      .then(res => {
        window.scrollTo(0, 0);
        actions.setSubmitting(false);

        var data = res.data.updatePatient;
        if (!data || data.statusCode != 200) {
          this.setState({
            isSubmitting: false,
            successMsg: false,
            serverError: true,
            serverMessage: data.exceptions || "Error Processing Request"
          });
          return;
        }
        this.setState({ successMsg: true });
      })
      .catch(({ graphQLErrors }) => {
        actions.setSubmitting(false);
        this.setState({
          serverError: true,
          serverMessage: "An error has occured"
        });
      });
  };

  closeAlert = () => {
    this.setState({ serverError: false, successMsg: false });
  };

  getInitialValues(patient) {
    return {
      firstName: patient.firstName || "",
      lastName: patient.lastName || "",
      email: patient.email || "",
      birthday: patient.dOB || "",
      workPhone: patient.workPhone || "",
      cellPhone: patient.cellPhone || "",
      homePhone: patient.homePhone || "",
      address: patient.address || "",
      address2: patient.address2 || "",
      city: patient.city || "",
      state: statesOptions.filter(s => s.value === patient.state) || "",
      zip: patient.zip || "",
      defaultStripeCardToken: "",
      oldPassword: "",
      newPassword: ""
    };
  }

  handleChangePassword = () => {
    this.setState({ changePassword: true });
  };

  render() {
    const { patientDashboard } = this.props;
    if (!patientDashboard) return null;

    return (
      <AccountSettingsForm>
        <Formik
          initialValues={this.getInitialValues(patientDashboard.result.patient)}
          validationSchema={schema}
          onSubmit={this.handleSubmit}
          render={({
            values,
            errors,
            isSubmitting,
            submitCount,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched
          }) => (
            <Form onSubmit={handleSubmit}>
              {this.state.successMsg && (
                <Notification
                  success
                  close={this.closeAlert}
                  open={this.state.successMsg}
                  message="Your Account Settings were updated."
                />
              )}
              {this.state.serverError && (
                <Notification
                  close={this.closeAlert}
                  open={this.state.serverMessage}
                  message={
                    formatENUM(this.state.serverMessage) ||
                    "Error Processing Request"
                  }
                />
              )}
              <Title>Profile</Title>
              <Panel>
                <div className="columns">
                  <div className="column is-3-desktop is-4-tablet">
                    <Field label="Contact First Name">
                      <Input
                        name="firstName"
                        type="text"
                        value={values.firstName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {submitCount > 0 && errors.firstName && (
                        <Error>{errors.firstName}</Error>
                      )}
                    </Field>
                  </div>
                  <div className="column is-3-desktop is-4-tablet">
                    <Field label="Contact Last Name">
                      <Input
                        name="lastName"
                        type="text"
                        value={values.lastName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {submitCount > 0 && errors.lastName && (
                        <Error>{errors.lastName}</Error>
                      )}
                    </Field>
                  </div>
                  <div className="column is-3-desktop is-4-tablet">
                    <Field label="Date of Birth">
                      <InputWrapper>
                        <Cleave
                          className="input"
                          name="birthday"
                          value={values.birthday}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="MM/DD/YYYY"
                          options={{ date: true, datePattern: ["m", "d", "Y"] }}
                        />
                      </InputWrapper>
                      {submitCount > 0 && errors.birthday && (
                        <Error>{errors.birthday}</Error>
                      )}
                    </Field>
                  </div>
                </div>
              </Panel>

              <Panel>
                <div className="columns">
                  <div className="column is-5 is-4-tablet">
                    <Field label="New Password">
                      {!this.state.changePassword && (
                        <PasswordLink
                          onClick={this.handleChangePassword.bind(this)}
                        >
                          Change
                        </PasswordLink>
                      )}
                      <Input
                        disabled={!this.state.changePassword}
                        className="input"
                        name="newPassword"
                        type="password"
                        value={values.newPassword}
                        onChange={handleChange}
                      />
                      {submitCount > 0 && errors.newPassword && (
                        <Error>{errors.newPassword}</Error>
                      )}
                    </Field>
                  </div>
                  <div className="column is-5 is-4-tablet">
                    {this.state.changePassword && (
                      <Field label="Old Password">
                        <Input
                          className="input"
                          name="oldPassword"
                          type="password"
                          value={values.oldPassword}
                          onChange={handleChange}
                        />
                        {submitCount > 0 && errors.oldPassword && (
                          <Error>{errors.oldPassword}</Error>
                        )}
                      </Field>
                    )}
                  </div>
                </div>
              </Panel>

              <Panel>
                <div className="columns">
                  <div className="column is-6">
                    <Field label="Contact Email">
                      <Input
                        name="email"
                        type="text"
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {submitCount > 0 && errors.email && (
                        <Error>{errors.email}</Error>
                      )}
                    </Field>
                  </div>
                </div>
              </Panel>

              <Panel>
                <div className="columns">
                  <div className="column is-4">
                    <Field label="Cell">
                      <InputWrapper>
                        <Cleave
                          name="cellPhone"
                          type="text"
                          value={values.cellPhone}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          options={{
                            numericOnly: true,
                            blocks: [0, 3, 3, 4],
                            delimiters: ["(", ") ", "-"]
                          }}
                        />
                      </InputWrapper>
                      {submitCount > 0 && errors.cellPhone && (
                        <Error>{errors.cellPhone}</Error>
                      )}
                    </Field>
                  </div>
                  <div className="column is-4">
                    <Field label="Home (Optional)">
                      <InputWrapper>
                        <Cleave
                          name="homePhone"
                          type="text"
                          value={values.homePhone}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          options={{
                            numericOnly: true,
                            blocks: [0, 3, 3, 4],
                            delimiters: ["(", ") ", "-"]
                          }}
                        />
                      </InputWrapper>
                      {submitCount > 0 && errors.homePhone && (
                        <Error>{errors.homePhone}</Error>
                      )}
                    </Field>
                  </div>
                  <div className="column is-4">
                    <Field label="Work (Optional)">
                      <InputWrapper>
                        <Cleave
                          name="workPhone"
                          type="text"
                          value={values.workPhone}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          options={{
                            numericOnly: true,
                            blocks: [0, 3, 3, 4],
                            delimiters: ["(", ") ", "-"]
                          }}
                        />
                      </InputWrapper>
                      {submitCount > 0 && errors.workPhone && (
                        <Error>{errors.workPhone}</Error>
                      )}
                    </Field>
                  </div>
                </div>
              </Panel>

              <Panel>
                <div className="columns">
                  <div className="column is-3">
                    <Field label="Address (Optional)">
                      <Input
                        name="address"
                        placeholder="Street Address"
                        type="text"
                        value={values.address}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {submitCount > 0 && errors.address && (
                        <Error>{errors.address}</Error>
                      )}
                    </Field>
                  </div>
                  <div className="column is-3 is-offset-1">
                    <Field label="&nbsp;">
                      <Input
                        name="address2"
                        placeholder="Apt, Ste. Unit #"
                        type="text"
                        value={values.address2}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {submitCount > 0 && errors.address2 && (
                        <Error>{errors.address2}</Error>
                      )}
                    </Field>
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-4">
                    <Field label="">
                      <Input
                        name="city"
                        placeholder="City"
                        type="text"
                        value={values.city}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {submitCount > 0 && errors.city && (
                        <Error>{errors.city}</Error>
                      )}
                    </Field>
                  </div>
                  <div className="column is-4">
                    <Field label="">
                      <YwaitSelect
                        name="state"
                        value={values.state}
                        onFormikChange={setFieldValue}
                        onFormikBlur={setFieldTouched}
                        options={statesOptions}
                      />
                    </Field>
                    {submitCount > 0 && errors.state && (
                      <Error>{errors.state}</Error>
                    )}
                  </div>
                  <div className="column is-4">
                    <Field label="">
                      <Input
                        name="zip"
                        type="text"
                        placeholder="Zip Code"
                        value={values.zip}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {submitCount > 0 && errors.zip && (
                        <Error>{errors.zip}</Error>
                      )}
                    </Field>
                  </div>
                </div>
              </Panel>

              <AccountSettingsFormSubmitBar className="columns">
                <div className="primary column is-half v-center h-left">
                  <Button type="submit" primary disabled={isSubmitting}>
                    Save
                  </Button>
                </div>
              </AccountSettingsFormSubmitBar>
            </Form>
          )}
        />
      </AccountSettingsForm>
    );
  }
}

export default compose(
  withRouter,
  graphql(GET_PATIENT_DASHBOARD, { props: patientDashboardGetter }),
  graphql(UPDATE_PATIENT, { name: "updatePatient" })
)(PatientAccountSettingsForm);
