import React from 'react'
import { Title } from '../../components/Typography'
import Purchased from '../../components/Purchased'
import data from '../../components/time-selector/data';

export default () => (
  <div className="container">
    <div className="columns is-centered">
      <div className="column">
        <Title className="has-text-centered">Appointment Purchased!</Title>        
        <Purchased />
      </div>
    </div>
  </div>
)
  
