import React from 'react'
import { withRouter } from 'react-router';
import { compose } from "recompose";
import moment from "moment";
import "moment-timezone";
import { Query, graphql } from 'react-apollo'
import styled from 'styled-components'
import { theme, formatENUM } from '../components/Global'
import YwaitSelect from '../components/Select'
import {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import { YwaitDayPickerInput } from '../components/DatePicker'
import { Field, DollarInput } from '../components/Forms'
import { Button, Error } from '../components/Forms'
import { LoadingIndicator, ErrorIndicator } from '../components/indicators'

import GET_PRACTICE_DASHBOARD from '../queries/getPracticeDashboard';
import { practiceDashboardGetter } from '../queries/propGetters/practiceDashboard'
import CREATE_APPOINTMENT from '../mutations/appointments/createAppointment'
import UPDATE_APPOINTMENT from '../mutations/appointments/updateAppointment'
import addCalendarHandlers from '../components/decorators/addCalendarHandlers';
import GET_PRACTICE_STATE from '../queries/getPracticeState'
import { practiceStateGetter } from '../queries/propGetters/practiceState'
import UPDATE_PRACTICE_STATE from '../mutations/updatePracticeState'

import GET_PRACTICE_HCPS from '../queries/getPracticeHcps';
import Modal from '../components/modals/Modal'
import { SelectedDateStyle } from '../components/Scheduler.styles.js'

import * as Yup from 'yup';
import { Formik } from 'formik'

const Form = styled.form`
`
const Checkbox = styled.input`
margin-top:55px;
margin-left:30px;
margin-right:5px;
.temp{
  padding-top: 12px;padding-left: 20px;
}
  
`
const AppointmentForm = styled.div`
  width: 100%;
  
  p {    
    font-size: 18px;
    line-height: 24px;
    padding-bottom: 25px;
  } 

  .DayPickerInput input {
    color: hsl(0,0%,20%);
  }

  ${Error} {
    padding-top: 8px;
    line-height: 1.25em;
    padding-bottom: 8px;
  }

  .label {
    line-height: 36px;
  }

  .group-doctor { width: 265px; }
  .group-duration { width: 170px; margin-left: 30px }
  .group-startday { width: 241px; }
  .group-starttime { width: 170px; margin-left: 30px }
  .group-repeat { width: 230px; }
  .group-enddate { width: 265px; margin-left: 30px }
  .group-cost { 
    width: 154px; 
    margin-right: 30px;
    input { 
      width: 150px; 
      margin-bottom: 0;
    }
  }

  button {
    margin-top: 40px;
  }

  @media screen and (max-height: 870px) {
    .group-enddate .DayPicker-Select .DayPickerInput-Overlay {
      top: -210px;
      left: 270px;
    }
  }

`
const Title = styled.h3`
  font-size: 22px;
  font-family: ${theme.font.medium};
  margin-bottom: 10px;
  margin-top: -25px;
`
const GroupField = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;  
  margin-bottom: 8px;
  align-items: flex-start;
`
const Footer = styled.div`
  position: absolute;
  bottom: 0; 
`
const timeOptions = [
  { value: 20, label: '20min' },
  { value: 40, label: '40min' },
  { value: 60, label: '1hr' },
];

const repeatOptions = [
  { value: 'Once', label: 'Once' },
  { value: 'Daily', label: 'Daily' },
  { value: 'Weekly', label: 'Weekly' },
  { value: 'Monthly', label: 'Monthly' },
];

const schema = Yup.object().shape({
  hcpId: Yup.string().required('HCP is required.'),
  duration: Yup.string().required('Duration is required.'),
  dateTimeDay: Yup.date('Start day is required').required('Start day is required.').test('pastDate', 'The date you have selected is either today or in the past.',
    value => {
      return !moment(value).isBefore(moment(new Date(), 'day'));
    }
  ),
  dateTimeTime: Yup.string().required('Start time is required.'),
  occurance: Yup.string().required('Occurence is required.'),
  endDate: Yup.date().when("occurance", {
    is: ("Daily" || "Weekly" || "Monthly"),
    then: Yup.date().required("End date is required.")
  }),
  cost: Yup.number('Cost is not in the correct format.').lessThan(1000, 'Cost should be less than $1000')
});


class PracticeAppointmentForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      serverError: false,
      serverMessage: 'Request Failed',
    }
  }

  allowSubmit(values) {
    switch (this.props.practiceState.schedulerActiveFlow) {
      case 'createAppointment':
      case 'editAppointment':
      case "editOnceAppointment":
      case "editAllAppointment":
      default:
        if (values.occurance.value !== 'Once' && values.endDate === '') {
          return false;
        }

        return (
          values.hcpId !== '' &&
          values.cost !== '');
    }
  }

  mapSubmitData(values) {
    const apptDate = moment(values.dateTimeDay).set({
      'hour': values.dateTimeTime.value.split(':')[0],
      'minute': values.dateTimeTime.value.split(':')[1]
    });
    const apptEndDate =
      values.endDate != null ?
        moment(values.endDate).tz('America/Chicago') :
        null;
    
    const notify = values.notify;
    switch (this.props.practiceState.schedulerActiveFlow) {
      case 'createAppointment':
        return {
          appointment: {
            hcpId: values.hcpId.value,
            duration: values.duration.value,
            dateTime:  moment.utc(apptDate).toISOString(),
            occurance: values.occurance.value,
            endDate: apptEndDate !== null ? moment.utc(apptEndDate).toISOString() : null,
            cost: values.cost,
            type: 'General',
            notify : values.notify
          },
          practiceId: this.props.practiceDashboard.result.practice.id
        }
      case 'editAppointment':
      case "editOnceAppointment":
      case "editAllAppointment":
      default:
        return {
          appointment: {
            hcpId: values.hcpId.value,
            duration: values.duration.value,
            dateTime: moment.utc(apptDate).toISOString(),
            occurance: values.occurance.value,
            editOccurance: localStorage.getItem("editoccurence"),
            endDate: apptEndDate !== null ? moment.utc(apptEndDate).toISOString() : null,
            cost: values.cost,
            type: 'General'
          },
          appointmentId: this.props.practiceState.schedulerSelectedAppointment.appointmentId
        }
    }


  }

  makeTimeSlots() {
    const dayHours = [];

    let c = 0;
    for (var minutes = 0; minutes < 1440; minutes += 20) {
      dayHours[c] = { date: moment().startOf('day').clone() }
      dayHours[c].date.startOf('day').add(minutes, 'minutes');
      dayHours[c].value = dayHours[c].date.format('HH:mm');
      dayHours[c].label = dayHours[c].date.format('h:mma');
      c++;
    }

    return dayHours;
  }

  getInitialValues() {
    switch (this.props.practiceState.schedulerActiveFlow) {
      default:
      case 'createAppointment':
        return {
          hcpId: '',
          duration: { value: 20, label: '20min' },
          dateTimeDay: this.props.practiceState.schedulerSelectedSlot.toDate(),
          dateTimeTime: {
            value: this.props.practiceState.schedulerSelectedSlot.format('HH:mm'),
            label: this.props.practiceState.schedulerSelectedSlot.format('h:mma')
          },
          occurance: { value: 'Once', label: 'Once' },
          endDate: '',
          cost: '',
          notify : false
        }

      case 'editAppointment':
      case "editOnceAppointment":
      case "editAllAppointment":
        const appointment = this.props.practiceState.schedulerSelectedAppointment
        return {
          hcpId: {
            value: appointment.hCP.id,
            label: appointment.hCP.firstName + ' ' + appointment.hCP.lastName + ', ' + appointment.hCP.title
          },
          duration: { value: appointment.duration, label: appointment.duration + 'min' },
          dateTimeDay: appointment.dateTime,
          dateTimeTime: {
            value: moment(appointment.dateTime).format('HH:mm'),
            label: moment(appointment.dateTime).format('h:mma')
          },
          occurance: { value: appointment.occurance, label: appointment.occurance },
          endDate: moment(appointment.endDate).format('MM-DD-YYYY'),
          cost: appointment.cost
        }
    }
  }

  handleError = (exceptions) => {
     this.setState({serverError: true, serverMessage: exceptions || "Error Processing Request" });
  }
  
  handleSubmit = (values, actions) => {
    this.setState({ serverError: false });
    switch (this.props.practiceState.schedulerActiveFlow) {
      case 'createAppointment':
        this.props.appointmentCreateClick(this.mapSubmitData(values), this.props.resetCalendarFlow, this.handleError,actions)
        break;
      case 'editAppointment':
      case "editOnceAppointment":
      case "editAllAppointment":
      default:
        this.props.appointmentUpdateClick(this.mapSubmitData(values), this.props.resetCalendarFlow, this.handleError,actions)
        break;
    }
  }

  render() {

    const dayHours = this.makeTimeSlots()

    return (
      
      <Modal isOpen={true} large={true} onClose={this.props.resetCalendarFlow}>

        <Query query={GET_PRACTICE_HCPS}>
          {({ error, data, loading }) => {
            if (error) {
              console.error(error);
              return <ErrorIndicator error={error} />
            }
            const payload = data['practiceDashboard'];
            if (!payload || loading) {
              return (
                <LoadingIndicator />
              )
            }
            const practiceDashboard = payload['result'];

            var hCPsSelectListValues = practiceDashboard.hCPs.map((h, i) => {
              return {
                value: h.id,
                label: h.firstName + ' ' + h.lastName + ', ' + h.title
              }
            })

            const modalTitle = this.props.practiceState.schedulerActiveFlow === 'createAppointment' ? 'Add an Appointment Slot' : this.props.practiceState.schedulerActiveFlow === 'editAllAppointment' ? 'Update all appointments' : 'Update appointment'
            const submitButtonLabel = this.props.practiceState.schedulerActiveFlow === 'createAppointment' ? 'Add Appointment Slot' : this.props.practiceState.schedulerActiveFlow === 'editAllAppointment' ? 'Update all appointments' : 'Update appointment'
            
            return (
              <AppointmentForm>

                <Formik
                  initialValues={this.getInitialValues()}
                  validationSchema={schema}
                  onSubmit={this.handleSubmit}
                  render={({
                    values,
                    errors,
                    isSubmitting,
                    submitCount,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    setFieldTouched
                  }) => (
                      <Form onSubmit={handleSubmit}>
                        <Title>{modalTitle}</Title>
                        <GroupField>
                          <Field label="Choose a Provider" className="group-doctor">
                            <YwaitSelect
                              name={'hcpId'}
                              value={values.hcpId}
                              onFormikChange={setFieldValue}
                              onFormikBlur={setFieldTouched}
                              onChange={(item) => {
                                var hcp = this.props.practiceDashboard.result.hCPs.find(x => x.id === item.value);
                                if (hcp) {
                                  setFieldValue('cost', hcp.defaultCost);
                                  setFieldValue('hcpId', item);
                                }
                              }}
                              options={hCPsSelectListValues} />
                            {(submitCount > 0 && errors.hcpId) && <Error>{errors.hcpId}</Error>}
                          </Field>
                          <Field label="Duration" className="group-duration">
                            <YwaitSelect
                              name={'duration'}
                              value={values.duration}
                              onFormikChange={setFieldValue}
                              onFormikBlur={setFieldTouched}
                              options={timeOptions} />
                            {(submitCount > 0 && errors.duration) && <Error>{errors.duration}</Error>}
                          </Field>
                        </GroupField>
                        <GroupField>
                          <Field label="Start Day" className="group-startday">
                            <style>{SelectedDateStyle}</style>
                            <YwaitDayPickerInput
                              name={'dateTimeDay'}
                              value={values.dateTimeDay}
                              formatDate={formatDate}
                              parseDate={parseDate}
                              format="MM-DD-YYYY"
                              placeholder="MM-DD-YYYY"
                              dayPickerProps={{
                                showOutsideDays: true,
                                format: "MM-DD-YYYY",
                                disabledDays: [
                                  {
                                    before: moment().add(1, 'days').toDate()
                                  }
                                ]
                              }}
                              onDayChange={(value) => { setFieldValue('dateTimeDay', value) }} />
                            {(submitCount > 0 && errors.dateTimeDay) && <Error>{errors.dateTimeDay}</Error>}
                          </Field>
                          <Field label="Start Time" className="group-starttime">
                            <YwaitSelect
                              name={'dateTimeTime'}
                              value={values.dateTimeTime}
                              formatDate={formatDate}
                              onFormikChange={setFieldValue}
                              onFormikBlur={setFieldTouched}
                              options={dayHours} />
                            {(submitCount > 0 && errors.dateTimeTime) && <Error>{errors.dateTimeTime}</Error>}
                          </Field>
                        </GroupField>
                        {this.props.practiceState.schedulerActiveFlow === 'createAppointment' ?
                          <GroupField>
                            <Field label="Repeat" className="group-repeat">
                              <style>{SelectedDateStyle}</style>
                              <YwaitSelect
                                name={'occurance'}
                                value={values.occurance}
                                onFormikChange={setFieldValue}
                                onFormikBlur={setFieldTouched}
                                options={repeatOptions} />
                              {(submitCount > 0 && errors.occurance) && <Error>{errors.occurance}</Error>}
                            </Field>
                            <Field className="group-repeat">
                            <Checkbox name="notify" 
                              value={values.notify}
                              onChange={handleChange} type="checkbox"
                            />Notify Waitlisted Patients
                            {(submitCount > 0 && errors.notify) && <Error>{errors.notify}</Error>}
                            </Field>
                            {values.occurance.value !== 'Once' ?
                              <Field label="End Date" className="group-enddate">

                                <YwaitDayPickerInput
                                  name={'endDate'}
                                  value={values.endDate}
                                  showOverlay={true}
                                  placeholder="MM-DD-YYYY"
                                  format="MM-DD-YYYY"
                                  dayPickerProps={{
                                    showOutsideDays: true,
                                    format: "MM-DD-YYYY",
                                    disabledDays: [
                                      {
                                        before: moment(values['dateTimeDay']).add(1, 'days').toDate()
                                      }
                                    ]
                                  }}
                                  formatDate={formatDate}
                                  parseDate={parseDate}
                                  showOutsideDays={true}
                                  onDayChange={(value) => { setFieldValue('endDate', value) }} />
                                {(submitCount > 0 && errors.endDate) && <Error>{errors.endDate}</Error>}
                              </Field>
                              :
                              <React.Fragment>
                              </React.Fragment>
                            }

                          </GroupField>
                          :
                          <></>
                        }
                        <GroupField>
                          <Field label="Cost" className="group-cost">
                            <DollarInput
                              type='number'
                              name="cost"
                              value={values.cost}
                              onChange={handleChange} />
                            {(submitCount > 0 && errors.cost) && <Error>{errors.cost}</Error>}
                          </Field>
                          
                          <Button type='submit' primary disabled={!this.allowSubmit(values) || isSubmitting}>
                            {submitButtonLabel}
                          </Button>
                        </GroupField>
                        
                      </Form>
                    )} />
              </AppointmentForm>
            )
          }}
        </Query>
       <Footer> {this.state.serverError ? <Error>{formatENUM(this.state.serverMessage || "Request Failed")}</Error> : 
                              <React.Fragment>
                              </React.Fragment>}
                              </Footer>
      </Modal>
    )
  }
}

export default compose(
  withRouter,
  graphql(GET_PRACTICE_DASHBOARD, { props: practiceDashboardGetter }),
  graphql(CREATE_APPOINTMENT, { name: 'createAppointment' }),
  graphql(UPDATE_APPOINTMENT, { name: 'updateAppointment' }),
  graphql(GET_PRACTICE_STATE, { props: practiceStateGetter }),
  graphql(UPDATE_PRACTICE_STATE, { name: 'updatePracticeState' }),
  addCalendarHandlers
)(PracticeAppointmentForm);