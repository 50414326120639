import React from 'react'
import { Helmet } from 'react-helmet'

import fav16 from '../assets/favicon/favicon-16x16.png'
import fav32 from '../assets/favicon/favicon-32x32.png'
import fav96 from '../assets/favicon/favicon-96x96.png'
import favicon from '../assets/favicon/favicon.ico'

export default () => (
  <Helmet>
    <link rel="icon" type="image/png" sizes="32x32" href={fav32} />
    <link rel="icon" type="image/png" sizes="96x96" href={fav96} />
    <link rel="icon" type="image/png" sizes="16x16" href={fav16} />
    <link rel="icon" type="image/png" href={favicon} sizes="32x32" />
  </Helmet>
)
