import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router";
import { compose } from "recompose";
import { graphql } from "react-apollo";

import { theme } from "../../components/Global";
import { YwaitLink } from "../../components/Link";
import GET_PRACTICE_STATE from "../../queries/getPracticeState";
import UPDATE_PRACTICE_STATE from "../../mutations/updatePracticeState";
import { practiceStateGetter } from "../../queries/propGetters/practiceState";
import AccountSettingsProfile from "../../forms/PracticeAccountSettingsForm";

import { Title, H3 } from "../../components/Typography";

export const Nav = styled.nav`
  width: 90%;
  border-top: 1px solid ${theme.color.gray};

  a {
    padding: 14px 12px;
    display: block;
    border-bottom: 1px solid ${theme.color.gray};
  }
`;
let accessCode = localStorage.getItem("accessCode");

class AccountSettings extends React.Component {
  render() {
    return (
      <div>
        <div className="columns is-multiline">
          <div className="column is-8 v-center">
            <Title>Account Settings</Title>
          </div>
          <div className="column is-8 v-center">
            <H3>
              Your Access Code: <b>{accessCode}</b>
            </H3>
          </div>
        </div>
        <div className="columns spaced">
          <div className="column is-3">
            <Nav>
              <YwaitLink active={1} to="/account-settings">
                Profile
              </YwaitLink>
              <YwaitLink to="/account-payment">Payment</YwaitLink>
              <YwaitLink to="/insurances">List of Insurances</YwaitLink>
            </Nav>
          </div>
          <div className="column is-9">
            <AccountSettingsProfile></AccountSettingsProfile>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  graphql(GET_PRACTICE_STATE, { props: practiceStateGetter }),
  graphql(UPDATE_PRACTICE_STATE, { name: "updatePracticeState" })
)(AccountSettings);
