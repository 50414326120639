import gql from 'graphql-tag';

export default gql`
    mutation updatePracticeState($practiceState: PracticeState!) {
        updatePracticeState(practiceState: $practiceState) @client {
            __typename
            schedulerSelectedDate
            schedulerView
            schedulerHCPs
            schedulerHCPsWereSet
            schedulerColorLegend
            schedulerActiveFlow
            schedulerSelectedSlot
            schedulerSelectedAppointment
            appointmentsHCPs
            appointmentsUpcomingFilter
            appointmentsPatientNewFilter
            appointmentsSortBy
            updateAppointmentSelectionParams
        }
    }
`;