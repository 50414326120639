import React, { Component } from 'react'
import styled from 'styled-components'
import { Button } from './buttons/Button'
import { Title } from './Typography'
import { media } from './Global'
import { withRouter } from 'react-router';
import { graphql } from 'react-apollo'
import { compose } from "recompose";

const StyleContainer = styled.div`
  max-width: 610px;
  margin: 0 auto;
  h1 { text-align: center; }

  .button {
    padding-left: 30px;
    padding-right: 30px;
    max-width: 280px;
    margin: 0 12px;
    height: 80px;
    line-height: 22px;
    border-width: 2px;

    ${media.phone`
      margin-bottom: 20px;
    `}
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 20px;
`

class AreYouNewPatientForm extends Component {
  constructor(props) {
    super(props);
  
  }
  handleClick = (value,actions)=>{
    localStorage.setItem('newPatientIsFirstVisit', value);
    this.props.history.push('/select-provider');
  }
  
  render() {
    return (<StyleContainer>      
       <Title>Are you a new patient with this practice&#63;</Title>
   
       <ButtonWrapper>
         <Button onClick={this.handleClick.bind(this,true)} secondary> Yes, I am a new Patient </Button>
         <Button onClick={this.handleClick.bind(this,false)} secondary> No, I am an existing Patient </Button>
       </ButtonWrapper>      
    </StyleContainer>  )
     }
}


export default compose(
  withRouter,
)(AreYouNewPatientForm);

