import React, { Component } from "react";
import styled, { css } from "styled-components";
import { rgba } from "polished";

const StyledModal = styled.div`
  display: none;
  box-shadow: 0 3px 12px 0 ${rgba("#6D7185", 0.2)};
  position: fixed;
  background-color: white;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 466px;
  min-height: 250px;
  z-index: 20;

  ${props =>
    props.large &&
    css`
      min-width: 580px;
    `}

  ${props =>
    props.isOpen &&
    css`
      display: block;
    `}
`;

const BackgroundModal = styled.div`
  content: "";
  height: 100%;
  width: 100%;
  background-color: ${rgba("black", 0.4)};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 19;
`;

const Content = styled.div`
  padding: 60px 30px 60px 30px;
  display: flex;
  justify-content: center;
`;

const Button = styled.span`
  position: absolute;
  right: 20px;
  top: 21px;
  width: 35px;
  height: 35px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }

  :before,
  :after {
    position: absolute;
    left: 17px;
    content: " ";
    height: 35px;
    width: 1px;
    background-color: currentColor;
  }

  :before {
    transform: rotate(45deg);
  }
  :after {
    transform: rotate(-45deg);
  }
`;

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  handleClick = () => {
    this.setState({ isOpen: !this.state.isOpen });
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  closeModal = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
    this.setState({ isOpen: false });
  };

  render() {
    const children = React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        closeModal: () => this.closeModal()
      });
    });

    return (
      <React.Fragment {...this.state.open}>
        <BackgroundModal />
        <StyledModal {...this.state}>
          <Button onClick={this.handleClick}></Button>
          <Content>{children}</Content>
        </StyledModal>
      </React.Fragment>
    );
  }
}

export default Modal;

export * from "./Upload";
export * from "./Information";
export * from "./Confirmation";
export * from "./AddApointment";
export * from "./DeleteOccurence";
export * from "./ConfirmationCard";
export * from "./SelectAppointment";
export * from "./SelectInsurance";