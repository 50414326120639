import React from 'react'
import styled from 'styled-components'
import { Button } from './buttons/Button'
import { theme } from '../components/Global'
import { compose } from "recompose";
import { graphql } from 'react-apollo'
import {
  CardElement,
  Elements,
  injectStripe,
  StripeProvider
} from 'react-stripe-elements';
import Notification from '../components/Notification'
import UPDATE_PATIENT from '../mutations/patient/updatePatient'
import config from '../config';

const Form = styled.div` 
  margin: 0 auto;

  .field{
    background-color: white; 
    border: 1px solid ${theme.color.light_gray};
    padding: 0 15px;
    max-width: 510px;
    margin-bottom: 20px;
  }
`
class _StripeCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serverError: false,
      isSubmitting: false,
      serverMessage: '',
    }
  }
  createOptions = (fontSize, padding) => {
    return {
        style: {
        base: {
          fontSize: '16px',
          '::placeholder': {
            color: theme.color.light_gray
          },
          fontFamily: theme.font.regular,
          color: theme.color.black,
          height: '60px',
          lineHeight: '60px',
          width: '510px'
        },
        invalid: {
          color: '#9e2146',
        },
      },
    };
  };

  handleSubmit = (ev) => {
    if (this.props.stripe) {
      this.setState({ serverError: false, isSubmitting: true });
      this.props.stripe
        .createToken()
        .then((payload) => {
          if (payload.token) {
            var token = payload.token.id;
            return (token);
          } else {
            var exception = 'Invalid credit card information. Please check and re-enter.';
            this.setState({ isSubmitting:false, serverError: true, serverMessage: exception });
             return Promise.reject(exception);
          }
        }).then(this.props.getToken)
        .catch((errors) => {
          this.setState({ isSubmitting: false, serverError: true, serverMessage: errors });
        });
    } else {
    }
  }

  render() {
    return (
      <Form>
        {this.state.serverError && <Notification
          close={this.closeAlert}
          open={this.state.serverError} message={this.state.serverMessage} />}
        <div className="field">
          <CardElement  {...this.createOptions(this.props.fontSize)} />
        </div>
        <Button secondary disabled={this.state.isSubmitting} onClick={this.handleSubmit.bind(this)}>{this.props.submitButtonText}</Button>
      </Form>
    );
  }
}
const StripeCard = injectStripe(_StripeCard);

class _StripeElement extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="PaymentInfo">
        <Elements {...{fonts:[
          {
            cssSrc: '/fonts/fonts.json',
            family: theme.font.regular,
            style: 'normal',
          }
        ]
      }}>
          <StripeCard  {...this.props} />
        </Elements>
      </div>
    );
  }
}


class StripeElement extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <StripeProvider apiKey={config.stripeKey}>
        <_StripeElement {...this.props} />
      </StripeProvider>
    );
  }
}

export default compose(
  graphql(UPDATE_PATIENT, { name: 'updatePatient' })
)(StripeElement);
