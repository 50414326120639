import React from 'react'
import styled from 'styled-components'
import { theme } from './Global'
import { compose } from "recompose";
import { withRouter } from 'react-router';

export const CheckboxSetList = styled.ul`  
  list-style-type: none;
`;

export const CheckboxItem = styled.li`
  font-family: ${theme.font.medium};

  label {
    cursor: pointer;
  }
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

const Icon = styled.svg`
  fill: none;
  stroke: #000;
  stroke-width: 2px;
`
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  background: ${props => (props.checked ? 'salmon' : 'papayawhip')};
  transition: all 150ms;
  cursor: pointer;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`

const Checkbox = ({ className, checked, color, ...props }) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked} style={{ backgroundColor: color }}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
)

class CheckboxSet extends React.Component {
    render() {
      return(
        <CheckboxSetList>
        {
            this.props.values.map(value => 
              <CheckboxItem key={value.id}>
                  <label>
                    <Checkbox
                        checked={value.checked}
                        color={value.color}
                        onChange={this.props.onChange.bind(this, value)}
                    />
                    <span style={{ marginLeft: 15 }}>{value.label}</span>
                  </label>
              </CheckboxItem>
            )
        }
        </CheckboxSetList>
      );
    }

}

export default compose(
    withRouter
)(CheckboxSet);