import React from "react";
import AccountInsuranceForm from "../../forms/AccountInsuranceForm";
import { Title } from "../../components/Typography";

import { withRouter } from "react-router";

class NewInsurancePage extends React.Component {
  render() {
    return (
      <div>
        <Title className="has-text-centered">New Insurance Form</Title>
        <AccountInsuranceForm />
      </div>
    );
  }
}
export default withRouter(NewInsurancePage);
