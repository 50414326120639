import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "../Global";
import { Button } from "../buttons/Button";
import { Error } from "../Forms";

const StyleUpload = styled.div`
  text-align: left;
  width: 97%;

  h4 {
    font-size: 22px;
    line-height: 26px;
    font-family: ${theme.font.bold};
    padding-bottom: 15px;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 8px;
  }
`;

const UploadInput = styled.div`
  display: flex;
  width: 100%;

  input[type="text"] {
    min-width: 65%;
    margin-right: 15px;
    padding-left: 10px;
  }

  label {
    width: 35%;
  }

  input[type="file"] {
    display: none;
  }
`;

const Footer = styled.div`
  margin-top: 20px;
  text-align: center;

  .button {
    width: 190px;
  }
`;

export class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageSrc: "",
      input: "",
      error: false
    };
  }

  onFileChange = (e, file) => {
    var file = file || e.target.files[0],
      pattern = /image-*/,
      reader = new FileReader();

    if (!file.type.match(pattern)) {
      this.setState({ input: file.name, error: "Invalid Format." });
      return;
    }

    if (file.size > 500 * 1024) {
      this.setState({
        input: file.name,
        error:
          "The file size is too large. please choose another file under 0.5Mb."
      });
      return;
    }

    this.setState({ input: file.name });

    const _this = this;
    reader.onload = e => {
      this.setState({ imageSrc: reader.result, error: false });
      this.props.onChange(reader.result);
    };

    reader.readAsDataURL(file);
  };

  closeModal = () => {
    this.props.closeModal();
  };

  render() {
    return (
      <StyleUpload>
        <h4>Upload a new Avatar</h4>
        <p>Choose an image (Max. size limit 0.5MB)</p>

        <UploadInput>
          <input type="text" defaultValue={this.state.input} />
          <label htmlFor="file-upload">
            <Button primary>Browse</Button>
          </label>
          <input
            type="file"
            ref="input"
            onChange={this.onFileChange}
            id="file-upload"
            name="avatar"
            accept="image/png, image/jpeg"
          />
        </UploadInput>

        <Footer>
          {this.state.error ? <Error>{this.state.error}</Error> : null}
          <Button secondary onClick={this.closeModal}>
            Upload
          </Button>
        </Footer>
      </StyleUpload>
    );
  }
}
