import React, { Component } from 'react'
import { compose } from 'recompose';
import styled from 'styled-components'
import { Button } from './buttons/Button'
import { withRouter } from 'react-router';
import { theme } from './Global'
import GET_PRACTICE from '../queries/getPractice'
import StripeWorks from '../assets/stripe/stripe-works.png'
import { Query, graphql } from 'react-apollo'
import { LoadingIndicator, ErrorIndicator } from '../components/indicators'
import Notification from '../components/Notification'
import queryString from 'query-string'
import ADD_STRIPE_ACCOUNT from '../mutations/practice/addStripeAccount'

const PaymentStyles = styled.div``;

const ButtonWrapper = styled.div`
  width: 100%;
  text-align: left;

  .button { width: 220px; }
  .button:first-child { margin-right: 25px; }
  .button:last-child { 
    width: auto;
    text-decoration: underline;
    text-transform: capitalize;
  }
`
const Image = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 280px;
`

const Panel = styled.div`
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid ${theme.color.light_gray};
  .field { position: relative }
`
class SettingsPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serverError: false
    }
  }

  componentDidMount() {
    var stripeAccountCode = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true }).code;
    if (stripeAccountCode)
      this.retriveAccountId(stripeAccountCode);
  }

  retriveAccountId(stripeAccountCode) {
    this.setState({ serverError: false, loading: true });

    this.props.addStripeAccount({
      name: 'addStripeAccount',
      variables: { accessCode: stripeAccountCode, practiceId: localStorage.getItem('practiceId') }
    }).then((res) => {
      this.setState({ loading: false });

      const payload = res.data['addStripeAccount'];
      if (!payload) {
        this.setState({ serverError: true, serverMessage: "Request Failed" });
        return;
      }
      if (payload.statusCode != 200) {
        this.setState({ serverError: true, serverMessage: payload.exceptions });
        return;
      }
    })
      .catch(({ graphQLErrors }) => {
        console.log('graphQLErrors', graphQLErrors);
      });
  }

  handleConnectWithStripe = (practice) => {
    var clientId = "ca_EUFKJhPShhgIucycEvbAOxQEOmo3kaml";
    var redirectURL = window.location.origin + "/account-payment";

    var params = {
      "stripe_user[business_type]": "company",
      "stripe_user[business_name]": practice.name,
      "stripe_user[first_name]": practice.firstName,
      "stripe_user[last_name]": practice.lastName,
      "stripe_user[email]": practice.email,
      "stripe_user[country]": "US",
      "stripe_user[phone_number]": practice.phone,
      "stripe_user[city]": practice.city,
      "stripe_user[state]": practice.state,
      "stripe_user[zip]": practice.zip,
    }

    window.location.href = 'https://connect.stripe.com/express/oauth/authorize?redirect_uri=' + redirectURL + '&client_id=' + clientId + '&' + this.serialize(params);
  }
  serialize = function (obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }
  render() {
    return (
      <PaymentStyles>
        <div className="columns is-centered">
          <div className="column is-7">
            <Notification
              close={this.closeAlert}
              open={this.state.serverError}
              message={this.state.serverMessage}
            />
          </div>
        </div>
        <Query query={GET_PRACTICE}>
          {({ error, data, loading }) => {
            if (error) {
              console.error(error);
              return <ErrorIndicator error={error} />
            }
            const payload = data['practiceDashboard'];
            if (!payload || loading) {
              return <LoadingIndicator />
            }

            const result = payload['result'];
            const practice = result.practice;
            if (practice.stripeAccountId)
              return (
                <Button success>Stripe Connected</Button>
              )
            else
              return (
                <Button onClick={this.handleConnectWithStripe.bind(this, practice)} secondary>Connect With Stripe</Button>
              )
          }}
        </Query>
      </PaymentStyles>
    )
  }
}

export default compose(
  withRouter,
  graphql(ADD_STRIPE_ACCOUNT, { name: 'addStripeAccount' })
)(SettingsPayment);

