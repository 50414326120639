import React, { Component } from 'react'
import styled from 'styled-components'
import config from '../config';

export const UserPictureFrame = styled.div`
  height: ${props => props.size ? props.size : '60px'};
  width: ${props => props.size ? props.size : '60px'};
  border-radius: 50%;
  display: inline-block;

  background-color: #eee;
  background-size: cover;
  background-position: center center;
`;

export class UserPicture extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <UserPictureFrame 
        className='avatar'
        style={{backgroundImage: `url(${`${(this.props.picture.startsWith('data') ? '' : config.apiUrl)}${this.props.picture}`})`}} 
        size={this.props.size}>
      </UserPictureFrame>
    );
  }
}