import React from 'react'
import GetStartedWithYWait from '../../components/GetStartedWithYWait'

export default () => (
  <div className="container">
    <div className="columns is-centered">
      <div className="column is-8">            
        <GetStartedWithYWait />
      </div>
    </div>
  </div>
)
  

