import gql from 'graphql-tag';

export default gql`
    mutation deleteHcp($hcpId:ID!) {
    deleteHcp(hcpId:$hcpId){
        statusCode
        userRole
        exceptions
        loggedInUser{
            id,
            title,
            firstName,
            lastName
          }
    }
}
`;