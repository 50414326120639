import React from 'react'
import styled from 'styled-components'
import { Button } from './buttons/Button'
import { Title } from './Typography'
import { media } from './Global'

import { withRouter } from 'react-router';
import { compose } from "recompose";
import { graphql } from 'react-apollo'

const StyleContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  h1 { text-align: center; }

  .button {
    padding-left: 30px;
    padding-right: 30px;
    min-width: 200px;
    margin: 0 12px;
    height: 80px;
    line-height: 22px;
    border-width: 2px;

    ${media.phone`
      margin-bottom: 20px;
    `}
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 20px;
`

class ReplaceCurrentApt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serverError: false,
      serverMessage: '',
    }
  }
  handleClick = (value) => {
    localStorage.setItem('appointmentIsExisting',value);
    
      if (localStorage.getItem("token")) {
        if (localStorage.getItem("role") === "Patient")
          this.props.history.push('/confirm-appointment');
      } else {
        this.props.history.push('/sign-in-booking');
      }
  }
  
  render() {

    return (
      <StyleContainer>
        <Title>
          Does this YWait appointment
          replace a currently scheduled
          appointment with this provider?
    </Title>

        <ButtonWrapper>
          <Button onClick={this.handleClick.bind(this, true)} secondary> Yes </Button>
          <Button onClick={this.handleClick.bind(this, false)} secondary> No</Button>
        </ButtonWrapper>
      </StyleContainer>
    )
  }
}

export default compose(
  withRouter
)(ReplaceCurrentApt);
