import React, { Component } from 'react'
import Select, { components }  from 'react-select'
import styled from 'styled-components'
import {theme} from './Global'
import '../scss/table.css'

const Checkbox = styled.input`
  margin-right: 10px;
`

class YWaitTable extends React.Component {
    constructor(props) {
       super(props)
       this.state = {
          students: [
             { weekday: 'Monday', morning: '<Checkbox name="one" type="checkbox" value="Monday-morning" onBlur={handleBlur} onChange={this.handleDaySelect}/>', 
             afternoon: '<Checkbox name="one" type="checkbox" value="Monday-morning" onBlur={handleBlur} onChange={this.handleDaySelect}/>', 
             evening: '<Checkbox name="one" type="checkbox" value="Monday-morning" onBlur={handleBlur} onChange={this.handleDaySelect}/>' }
          ],
          days: []
       }
    }

    handleDaySelect  = (event)  => {
        var dayArr = [...this.state.days];
        const value = event.target.value
        const index = dayArr.findIndex(day => day === value);
        if(index > -1) {
            dayArr = [...dayArr.slice(0, index), ...dayArr.slice(index + 1)]
        } else {
            dayArr.push(value);
        }
        this.state.days = dayArr;
        //this.setState({days: dayArr});
     }
 
    renderTableHeader() {
        return <tr><th>Weekday</th><th>Morning ()</th><th>Afternoon ()</th><th>Evening ()</th></tr>
    }
 
    renderTableData() {
       return   <tr>
                    <td>Monday</td>
                    <td><Checkbox name="one" type="checkbox" value="Monday-morning" onChange={this.handleDaySelect}/></td>
                    <td><Checkbox name="two" type="checkbox" value="Monday-afternoon" onChange={this.handleDaySelect}/></td>
                    <td><Checkbox name="three" type="checkbox" value="Monday-evening" onChange={this.handleDaySelect}/></td>
                </tr>
    }
 
    render() {
       return (
          <div>
             <h1 id='title'>React Dynamic Table</h1>
             <table id='students'>
                <tbody>
                   <tr>{this.renderTableHeader()}</tr>
                   {this.renderTableData()}
                </tbody>
             </table>
          </div>
       )
    }
 }

 export default YWaitTable;