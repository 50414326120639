import gql from "graphql-tag";

export default gql`
  query getPracticeInsurances($practiceId: String!) {
    practiceInsurances(practiceID: $practiceId) {
      exceptions
      statusCode
      userRole
      result {
        name
        id
      }
    }
  }
`;
