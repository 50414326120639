import gql from 'graphql-tag';

export default gql`
    query hCPProfile($hcpId: ID!) {
        hCPProfile(hcpId: $hcpId) {
            statusCode
            userRole
            exceptions
            loggedInUser{
                id,
                title,
                firstName,
                lastName
              }
            result{
                hCP {
                    id
                    title
                    firstName
                    lastName
                    email
                    defaultCost
                    avatar
                    status
                }
                appointments {
                    appointmentId
                    status
                    dateTime
                    hCP {
                        id
                        firstName
                        lastName
                        title
                        avatar
                    }
                    patient {
                        firstName
                        lastName
                        dOB
                        homePhone
                        cellPhone
                    }
                }
            }
        }
    }
`;