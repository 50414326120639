import AccessCode from "../pages/patient/AccessCode";
import AreYouPatient from "../pages/patient/AreYouPatient";
import SelectProvider from "../pages/patient/SelectProvider";
import SelectDate from "../pages/patient/SelectDate";
import SelectTime from "../pages/patient/SelectTime";
import ReplaceCurrentApt from "../pages/patient/ReplaceCurrentApt";
import PaymentInfo from "../pages/patient/PaymentInfo";
import LoginPage from "../pages/patient/Login";
import LoginWithSignUp from "../pages/patient/LoginWithSignUp";
import SignUpPage from "../pages/patient/SignUp";
import PracticeSignUpPage from "../pages/practice/SignUp";
import AdminLoginPage from "../pages/practice/Login";
import ForgotPassword from "../pages/practice/ForgotPassword";
import ResetPassword from "../pages/practice/ResetPassword";
import CheckEmail from "../pages/practice/CheckEmail";
// import RecieptPage from '../pages/patient/Reciept'
import AppointmentPurchased from "../pages/patient/AppointmentPurchased";
import Privacy from "../pages/patient/Privacy";
import TermsOfUse from "../pages/patient/TermsOfUse";
import SignupWaitlist from "../pages/patient/SignupWaitlist";
import EditSignupWaitlist from "../pages/patient/EditSignUpWaitlist";
import GetStartedWithYWait from "../pages/patient/GetStartedWithYWait";

export default [
  {
    path: "/",
    component: AccessCode,
    title: "YWait - Access Code"
  },
  {
    path: "/access-code",
    component: AccessCode,
    title: "YWait - Access Code"
  },
  {
    path: "/are-you-new-patient",
    component: AreYouPatient,
    title: "YWait - Are you a new Patient"
  },
  {
    path: "/select-provider",
    component: SelectProvider,
    title: "YWait - Select Provider"
  },
  {
    path: "/select-date",
    component: SelectDate,
    title: "YWait - Select Date"
  },
  {
    path: "/select-time",
    component: SelectTime,
    title: "YWait - Select Time"
  },
  {
    path: "/replace-current-apt",
    component: ReplaceCurrentApt,
    title: "YWait - Replace Apt"
  },
  {
    path: "/payment-info",
    component: PaymentInfo,
    title: "YWait - Payment Info"
  },
  {
    path: "/appointment-purchased",
    component: AppointmentPurchased,
    title: "YWait - Appointment Purchased"
  },
  {
    path: "/sign-in",
    component: LoginPage,
    title: "YWait - Sign In"
  },
  {
    path: "/sign-in-booking",
    component: LoginWithSignUp
  },
  {
    path: "/sign-up",
    component: SignUpPage,
    title: "YWait - Sign Up"
  },
  {
    path: "/sign-up-practice",
    component: PracticeSignUpPage,
    title: "YWait - Practice Sign Up"
  },
  {
    path: "/admin",
    component: AdminLoginPage,
    title: "YWait - Admin"
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    title: "YWait - Forgot Password"
  },
  {
    path: "/reset-password",
    component: ResetPassword,
    title: "YWait - Reset Password"
  },
  {
    path: "/check-email",
    component: CheckEmail,
    title: "YWait - Check Email"
  },
  {
    path: "/privacy",
    component: Privacy,
    title: "YWait - Privacy"
  },
  {
    path: "/terms-of-use",
    component: TermsOfUse,
    title: "YWait - Terms of Use"
  },
  {
    path: "/signup-waitlist",
    component: SignupWaitlist,
    title: "YWait - Sign up for Waitlist"
  },
  {
    path: "/edit-signup-waitlist",
    component: EditSignupWaitlist,
    title: "YWait - Sign up for Waitlist"
  },
  {
    path: "/get-started-with-ywait",
    component: GetStartedWithYWait,
    title: "YWait - Get Started with YWait"
  }
];
