import React from 'react'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import { graphql } from 'react-apollo'

import GET_PRACTICE_DASHBOARD from '../queries/getPracticeDashboard';
import { practiceDashboardGetter } from '../queries/propGetters/practiceDashboard'
import addCalendarHandlers from '../components/decorators/addCalendarHandlers';
import GET_PRACTICE_STATE from '../queries/getPracticeState'
import { practiceStateGetter } from '../queries/propGetters/practiceState'
import UPDATE_PRACTICE_STATE from '../mutations/updatePracticeState'
import DELETE_APPOINTMENT from '../mutations/appointments/deleteAppointment'
import DELETE_ALL_APPOINTMENTS from '../mutations/appointments/deleteAllAppointments'

import Modal from '../components/modals/Modal'
import { DeleteOccurence } from '../components/modals/DeleteOccurence'

class PracticeAppointmentDeleteOccuranceForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  render() {
    return (
      <Modal isOpen onClose={this.props.resetCalendarFlow}>
        <DeleteOccurence 
          onDeleteOnce={e => {
              this.props.appointmentDeleteClick()
          }}
          onDeleteAll={e => {
              this.props.appointmentDeleteAllClick()
          }}
          onCancel={e => {
              this.props.resetCalendarFlow()
          }}
          />
      </Modal>
    )
  }
}

export default compose(
    withRouter,
    graphql(GET_PRACTICE_DASHBOARD, { props: practiceDashboardGetter }),
    graphql(GET_PRACTICE_STATE, { props: practiceStateGetter }),
    graphql(UPDATE_PRACTICE_STATE, { name: 'updatePracticeState' }),
    graphql(DELETE_APPOINTMENT, { name: 'deleteAppointment' }),
    graphql(DELETE_ALL_APPOINTMENTS, { name: 'deleteAllAppointments' }),
    addCalendarHandlers
)(PracticeAppointmentDeleteOccuranceForm);