import React from 'react'
import styled, { css } from 'styled-components'
import { theme } from './Global'

const StyledNotification = styled.div`
  display: none;
  position: relative;
  border-width: 1px;
  border-style: solid;
  padding: 15px 40px 17px 20px;
  margin-bottom: 30px;

  color: ${theme.color.danger};   
  border-color: ${theme.color.danger};   
  background-color: #FFF0F4;      
  
  ${ (props) => props.success && css`
    color: ${theme.color.success};   
    border-color: ${theme.color.success};   
    background-color: #EFFFFB;  
  `}

  ${ (props) => props.open && css`
    display: block;
  `}

  ${ (props) => props.alert && css`
    ${Button} {
      display: block;
    }
  `}
  
`

const Message = styled.span`
  font-family: ${theme.font.regular};
`

const Button = styled.span`
  position: absolute;
  right: 15px;
  top: 16px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: none;

  :hover { opacity: 0.7; }

  :before, :after {
    position: absolute;
    left: 11px;
    content: ' ';
    height: 22px;
    width: 2px;
    background-color: currentColor;
  }

  :before { transform: rotate(45deg); }
  :after { transform: rotate(-45deg); }
`

const Notification = (props) => (
  <StyledNotification {...props}>
    <Button onClick={props.close}></Button>
    <Message>{props.message}</Message>
  </StyledNotification>

)

export default Notification;

