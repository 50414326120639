import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import * as Yup from 'yup';
import { Formik } from 'formik'
import Recaptcha from 'react-recaptcha'
import LOGIN from '../mutations/login'
import Cleave from 'cleave.js/react';
import { Query, graphql } from 'react-apollo'
import { LoadingIndicator, ErrorIndicator } from "../components/indicators";
import Notification from '../components/Notification'
import { theme, formatENUM } from '../components/Global'
import { Field, Input, InputWrapper, Error, Button } from '../components/Forms'
import SIGNUP from '../mutations/patient/createPatient'
import UPDATE_GLOBAL_STATE from '../mutations/updateGlobalState'
import CREATE_WAITLIST from '../mutations/patient/updateWaitlist.js'
import GET_WAITLIST from '../queries/getWaitlistById'
import '../scss/table.css'
import queryString from 'query-string'
import WaitlistForm from '../components/WaitlistForm';


const Form = styled.form`
  margin: 0 auto;
  p {  
    color: ${theme.color.gray};
    a {
      color: ${theme.color.gray};
      text-decoration: underline;
    }
  }

  .input { margin-bottom: 10px }

  ${Error} { padding-bottom: 5px }

  .button {
    margin-top: 40px;
    min-width: 350px;
  }
`

const Checkbox = styled.input`
  margin-right: 10px;
`

const RadioButton = styled.input`
  margin-right: 10px;
`

const dateRegExp = new RegExp("^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{4})*$");

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address.')
    .required('Email is required.'),
  terms: Yup.boolean().oneOf([true], 'Terms and Conditions is required.'),
  mobileno: Yup.string().matches(/^[0-9\-.()\s]+$/, 'Phone is not in the correct format.')
                    //  .min(12, 'Phone is too short (min 9 characters)')
                    //  .max(20, 'Phone is too long (max 20 characters)')
                     .required('Phone Number is required.')
});

//const dayArr = [];
const defaultValues = {
  firstname: '', lastname: '',
  mobileno: '',email: '',
  terms: false
}

const checkedArr = {
  monmorn : false,
  monafter : false,
  momeven : false,
  tuesmorn : false,
  tuesafter : false,
  tueseven : false,
  wenmorn : false,
  wenafter : false,
  weneven : false,
  thurmorn : false,
  thurafter : false,
  thureven : false,
  frimorn : false,
  friafter : false,
  frieven : false,
  satmorn : false,
  satafter : false,
  sateven : false,
  sunmorn : false,
  sunafter : false,
  suneven : false
};

const radioChecked = {
  value : 'text'
};
const radioNotify = {
  value : '1'
};

var days = [];

let search = window.location.search;
    let params = new URLSearchParams(search);
    let waitlistId = params.get('waitlistId');

class EditSignUpWaitlistForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      serverError: false,
      serverMessage: 'Incorrect email or password. Please correct the error and try again, or click forgot password.',
      selectedOption: 'text',
      notifySelectedOption: "1",
      deValues : {},
      checked:false
    }
    
    this.handleNotifyOptionChange = this.handleNotifyOptionChange.bind(this);
  }

  get(){
    this.props.getWaitlistById({
        name: 'getWaitlistById',
        variables: {
            waitlistId: waitlistId
        }
      }).then((res) => {
          
        var data = res.data.getWaitlistById;
        if (!data || data.statusCode != 200) {
          this.setState({ serverError: true });
          return false;
        }
        var t = {
            firstName : data.result.firstName,
            lastName : data.result.lastName,
            email : data.result.email,
            mobileNo : data.result.mobileNo,
            notificationPreference : data.result.notificationPreference,
            preferredTimeOfTheDay : data.result.preferredTimeOfTheDay
        };
        
        return t;
      });
   
  }
  
  getDefaultVaue(value){
    
    this.setState({ checked: true });
    return {
      firstname: value.firstName,
      lastname: value.lastName,
      email: value.email,
      mobileno: value.phone
    };
  }

  handleDaySelect  = (event)  => {
    
    var dayArr = days;//[...this.state.days];
    const value = event.target.value
    const index = dayArr.findIndex(day => day === value);
    if(index > -1) {
        dayArr = [...dayArr.slice(0, index), ...dayArr.slice(index + 1)]
    } else {
        dayArr.push(value);
    }
    days = dayArr;
    if(event.target.value.toLowerCase() == "monday-morning")
        checkedArr.monmorn = true;
      if(event.target.value.toLowerCase() == "monday-afternoon")
        checkedArr.monafter = true;
      if(event.target.value.toLowerCase() == "monday-evening")
        checkedArr.momeven = true;
      if(event.target.value.toLowerCase() == "tuesday-morning")
        checkedArr.tuesmorn = true;
      if(event.target.value.toLowerCase() == "tuesday-afternoon")
        checkedArr.tuesafter = true;
      if(event.target.value.toLowerCase() == "tuesday-evening")
        checkedArr.tueseven = true;
      if(event.target.value.toLowerCase() == "wednesday-morning")
        checkedArr.wenmorn = true;
      if(event.target.value.toLowerCase() == "wednesday-afternoon")
        checkedArr.wenafter = true;
      if(event.target.value.toLowerCase() == "wednesday-evening")
        checkedArr.weneven = true;
      if(event.target.value.toLowerCase() == "thursday-morning")
        checkedArr.thurmorn = true;
      if(event.target.value.toLowerCase() == "thursday-afternoon")
        checkedArr.thurafter = true;
      if(event.target.value.toLowerCase() == "thursday-evening")
        checkedArr.thureven = true;
      if(event.target.value.toLowerCase() == "friday-morning")
        checkedArr.frimorn = true;
      if(event.target.value.toLowerCase() == "friday-afternoon")
        checkedArr.friafter = true;
      if(event.target.value.toLowerCase() == "friday-evening")
        checkedArr.frieven = true;
      if(event.target.value.toLowerCase() == "saturday-morning")
        checkedArr.satmorn = true;
      if(event.target.value.toLowerCase() == "saturday-afternoon")
        checkedArr.satafter = true;
      if(event.target.value.toLowerCase() == "saturday-evening")
        checkedArr.sateven = true;
      if(event.target.value.toLowerCase() == "sunday-morning")
        checkedArr.sunmorn = true;
      if(event.target.value.toLowerCase() == "sunday-afternoon")
        checkedArr.sunafter = true;
      if(event.target.value.toLowerCase() == "sunday-evening")
        checkedArr.suneven = true;
 }

 handleOptionChange = (changeEvent) => {
  this.setState({
    selectedOption: changeEvent.target.value
  });
}

handleNotifyOptionChange = (changeEvent) => {
  
  this.state.notifySelectedOption = changeEvent.target.value
    // this.setState({
    //   notifySelectedOption: changeEvent.target.value
    // });
  }

  mapSubmitData(values) {
    var waitlist = {
        firstName: values.firstname,
        lastName: values.lastname,
        email: values.email,
        mobileNo: values.mobileno,
        preferredTimeOfTheDay : days.join(','),
        notificationPreference : this.state.selectedOption,
        notificationOn : this.state.notifySelectedOption
      };
      console.log(waitlist,"waitlist");
    return {
        waitlist,
      waitlistId: waitlistId
    }
  }
  
  getInitialValues(value)
  {
    console.log("value",value);
    radioChecked.value = value.notificationPreference.toLowerCase();
    radioNotify.value = value.notificationOn.toString();
    var str_array = value.preferredTimeOfTheDay.split(',');

    for(var i = 0; i < str_array.length; i++) {
      if(str_array[i].toLowerCase() == "monday-morning")
        checkedArr.monmorn = true;
      if(str_array[i].toLowerCase() == "monday-afternoon")
        checkedArr.monafter = true;
      if(str_array[i].toLowerCase() == "monday-evening")
        checkedArr.momeven = true;
      if(str_array[i].toLowerCase() == "tuesday-morning")
        checkedArr.tuesmorn = true;
      if(str_array[i].toLowerCase() == "tuesday-afternoon")
        checkedArr.tuesafter = true;
      if(str_array[i].toLowerCase() == "tuesday-evening")
        checkedArr.tueseven = true;
      if(str_array[i].toLowerCase() == "wednesday-morning")
        checkedArr.wenmorn = true;
      if(str_array[i].toLowerCase() == "wednesday-afternoon")
        checkedArr.wenafter = true;
      if(str_array[i].toLowerCase() == "wednesday-evening")
        checkedArr.weneven = true;
      if(str_array[i].toLowerCase() == "thursday-morning")
        checkedArr.thurmorn = true;
      if(str_array[i].toLowerCase() == "thursday-afternoon")
        checkedArr.thurafter = true;
      if(str_array[i].toLowerCase() == "thursday-evening")
        checkedArr.thureven = true;
      if(str_array[i].toLowerCase() == "friday-morning")
        checkedArr.frimorn = true;
      if(str_array[i].toLowerCase() == "friday-afternoon")
        checkedArr.friafter = true;
      if(str_array[i].toLowerCase() == "friday-evening")
        checkedArr.frieven = true;
      if(str_array[i].toLowerCase() == "saturday-morning")
        checkedArr.satmorn = true;
      if(str_array[i].toLowerCase() == "saturday-afternoon")
        checkedArr.satafter = true;
      if(str_array[i].toLowerCase() == "saturday-evening")
        checkedArr.sateven = true;
      if(str_array[i].toLowerCase() == "sunday-morning")
        checkedArr.sunmorn = true;
      if(str_array[i].toLowerCase() == "sunday-afternoon")
        checkedArr.sunafter = true;
      if(str_array[i].toLowerCase() == "sunday-evening")
        checkedArr.suneven = true;
    }
    return {
      firstname:value.firstName,
      lastname:value.lastName,
      email:value.email,
      mobileno:value.mobileNo,
      monmorn : true
    };
    
  }

  handleSubmit = (values, actions) => {
    
    this.setState({ serverError: false, successMsg: false });
    this.props.signupwaitlist({
      name: 'signupwaitlist',
      variables: this.mapSubmitData(values)
    }).then((res) => {
      
      const payload = res.data['updateWaitlist'];

      if (!payload) {
        actions.setSubmitting(false);
        this.setState({ serverError: true, serverMessage: "Request Failed" });
        return;
      }

      if (payload.statusCode != 200) {
        actions.setSubmitting(false);
        this.setState({ serverError: true, serverMessage: formatENUM(payload.exceptions) });
        return;
      }
      window.location.href = '/get-started-with-ywait';

    })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors,"graphQLErrors");
        actions.setSubmitting(false);
        this.setState({ serverError: true, serverMessage:graphQLErrors });
      });
  }

  closeAlert = () => {
    this.setState({ serverError: false, successMsg: false });
  }

  render() {
    const value=queryString.parse(this.props.location.search);
    const waitlistId=value.waitlistId;
    console.log('tokwaitlistIden',waitlistId)
    return(<Query query={GET_WAITLIST} variables={{ waitlistId:  waitlistId}}>
      
          {({ error, data, loading }) => {
            if (error) {
              console.error(error);
              return <ErrorIndicator error={error} />
            }
            const payload = data['getWaitlistById'];
            if (!payload || loading) {
              return (
                <LoadingIndicator />
              )
            }
            return(       
            <WaitlistForm post={payload['result']}/>
            )
          }}
        </Query>
    )}
}

export default compose(
  withRouter,
  graphql(CREATE_WAITLIST,{name: 'signupwaitlist'})
)(EditSignUpWaitlistForm);