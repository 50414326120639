import styled from 'styled-components'
import { theme } from './Global'

export const SchedulerFrame = styled.div`
    .calendars {
        .column {
            padding: 0;
            + .column { padding: 0}

            &:first-child {
                flex: 0 0 281px;
            }

            &:last-child {
                flex: 1 0 0;
            }
        }

        .DayPicker-Calendar .DayPicker-wrapper {
            border-right: 0;
        }
    }
`;

export const SchedulerMyDoctors = styled.div`
    .level {
        margin: 20px 0 10px 0;
        font-family: ${theme.font.medium};
    }
`;

export const SelectedDateStyle = `
    .DayPickerInput-OverlayWrapper {
        z-index: 20 !important;
    }
    .DayPicker-Day, .DayPickerInput-Day {
        color: ${theme.color.text};
    }
    .DayPicker-Day--outside, .DayPickerInput-Day--outside {
        color: ${theme.color.light_gray};
    }
    .DayPicker-Select, .DayPickerInput-Select {
        overflow: visible;
    }
    .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover,
    .DayPickerInput:not(.DayPicker--interactionDisabled) .DayPickerInput-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
        background-color: ${theme.color.primary};
        color: #fff;
    }
    .DayPicker-Day--selectedDate, .DayPickerInput-Day--selectedDate {
        background-color: ${theme.color.primary};
        color: #fff;
    }
    .DayPicker-Day--disabled, .DayPickerInput-Day--disabled {
        pointer-events: none;
        cursor: not-allowed;
        color: ${theme.color.light_gray};
    }
    .CurrentMonth{
        margin-left:350px
    }
`;