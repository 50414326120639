import gql from "graphql-tag";

export default gql`
  query {
    practiceDashboard {
      statusCode
      userRole
      exceptions
      loggedInUser {
        id
        title
        firstName
        lastName
      }
      result {
        practice {
          id
          name
        }
        hCPs {
          id
          title
          firstName
          defaultCost
          lastName
          avatar
        }
      }
    }
  }
`;
