import gql from 'graphql-tag';

export default gql`
    query {
        practiceDashboard {
            statusCode
            userRole
            exceptions
            loggedInUser {
                id,
                title,
                firstName,
                lastName
                }
            result{
                practice {
                    id
                    firstName
                    lastName
                    email
                    name
                    phone
                    address
                    address2
                    city
                    state
                    zip
                }
                hCPs {
                    id
                    title
                    firstName
                    lastName
                    defaultCost
                    avatar
                }
                pendingAppointments {
                    bookedAppointmentId
                    appointmentId
                    status
                    dateTime
                    hadExistingAppointment
                    isNewPatient
                    hCP {
                        id
                        firstName
                        lastName
                        title
                        defaultCost
                        avatar
                    }
                    patient {
                        firstName
                        lastName
                        dOB
                        homePhone
                        cellPhone
                        email
                    }
                }
                upcomingAppointments {
                    bookedAppointmentId
                    appointmentId
                    status
                    dateTime
                    hadExistingAppointment
                    isNewPatient
                    hCP {
                        id
                        firstName
                        lastName
                        title
                        defaultCost
                        avatar
                    }
                    patient {
                        firstName
                        lastName
                        dOB
                        homePhone
                        cellPhone
                        email
                    }
                }
                pastAppointments {
                    bookedAppointmentId
                    appointmentId
                    status
                    dateTime
                    hadExistingAppointment
                    isNewPatient
                    hCP {
                        id
                        firstName
                        lastName
                        title
                        defaultCost
                        avatar
                    }
                    patient {
                        firstName
                        lastName
                        dOB
                        homePhone
                        cellPhone
                        email
                    }
                }
            }
        }
    }
`;