import React from "react";
import moment from "moment";
import { compose } from "recompose";
import { withRouter } from "react-router";
import { Query } from "react-apollo";
import { LoadingIndicator, ErrorIndicator } from "./indicators";
import {
  SchedulerCalendarFrame,
  SchedulerCalendarWeekContainer,
  SchedulerCalendarWeekHours,
  SchedulerCalendarWeekDays,
  SchedulerCalendarWeekDay,
  SchedulerCalendarWeekDayHour,
  SchedulerCalendarWeekHour,
  SchedulerCalendarWeekTimezoneIndicator,
  SchedulerCalendarHourAppointments,
  SchedulerCalendarHourAppointment
} from "./SchedulerCalendar.styles";

import GET_PRACTICE_APPOINTMENTS from "../queries/getPracticeAppointments";

class SchedulerCalendarDayView extends React.Component {
  buildDay(date, appointments) {
    let day = [];

    var momentObj = moment(new Date(date));

    day.date = momentObj;
    day.hours = [];

    for (var hour = 0; hour < 24; hour++) {
      day.hours[hour] = {
        date: momentObj.clone()
      };
      day.hours[hour].date.startOf("day").add(hour, "hours");
      day.hours[hour].appointments = appointments.filter(
        this.props.filterAppointments(day.hours[hour].date, "hour")
      );
    }

    return day;
  }

  buildDayParams(date, hcps) {
    const params = {
      fromDate: moment(new Date(date))
        .startOf("day")
        .toISOString(),
      toDate: moment(new Date(date))
        .endOf("day")
        .toISOString(),
      hcpIds: hcps.map(function(h) {
        return h.id;
      })
    };

    localStorage.setItem("calendarAppointmentParams", JSON.stringify(params));

    return params;
  }

  render() {
    const { practiceState } = this.props;
    const dayOfWeek = this.props.buildDaysOfWeekNames();
    var queryDayParams = this.buildDayParams(
      practiceState.schedulerSelectedDate,
      practiceState.schedulerHCPs
    );

    return (
      <SchedulerCalendarFrame>
        <Query
          query={GET_PRACTICE_APPOINTMENTS}
          variables={queryDayParams}
          fetchPolicy="network-only"
        >
          {({ error, data, loading }) => {
            if (error) {
              console.error(error);
              return <ErrorIndicator error={error} />;
            }
            const payload = data["practiceAppointments"];
            if (!payload || loading) {
              return <LoadingIndicator />;
            }
            const practiceAppointments = payload["result"];

            const appointmentSlots = practiceAppointments.appointmentSlots.map(
              a => {
                return {
                  ...a,
                  dateTime: moment
                    .utc(a.dateTime)
                    .local()
                    .toDate()
                };
              }
            );

            var day = this.buildDay(
              practiceState.schedulerSelectedDate,
              appointmentSlots
            );

            return (
              <>
                <SchedulerCalendarWeekContainer className="header">
                  <SchedulerCalendarWeekHours></SchedulerCalendarWeekHours>
                  <SchedulerCalendarWeekDays>
                    <SchedulerCalendarWeekDay
                      className={`dayHeader ${
                        day.selectedDay ? `selected` : ``
                      }`}
                    >
                      <SchedulerCalendarWeekDayHour>
                        <span className="dayOfWeek">
                          {dayOfWeek[day.date.format("d")]}
                          <br />
                        </span>
                        <span className="dayNumber selected">
                          {day.date.format("D")}
                        </span>
                      </SchedulerCalendarWeekDayHour>
                    </SchedulerCalendarWeekDay>
                  </SchedulerCalendarWeekDays>
                </SchedulerCalendarWeekContainer>
                <SchedulerCalendarWeekContainer>
                  <SchedulerCalendarWeekHours className="scrollable">
                    <SchedulerCalendarWeekTimezoneIndicator>
                      CST
                    </SchedulerCalendarWeekTimezoneIndicator>
                    {day.hours.map(hour => (
                      <React.Fragment key={hour.date}>
                        {hour.date.format("ha") !== "12am" ? (
                          <SchedulerCalendarWeekHour>
                            {hour.date.format("ha")}
                          </SchedulerCalendarWeekHour>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                      </React.Fragment>
                    ))}
                  </SchedulerCalendarWeekHours>
                  <SchedulerCalendarWeekDays>
                    <SchedulerCalendarWeekDay
                      className={day.selectedDay ? `selected` : ``}
                    >
                      {day.hours.map(hour => (
                        <SchedulerCalendarWeekDayHour
                          key={hour.date}
                          onClick={this.props.slotClick.bind(this, hour.date)}
                        >
                          <SchedulerCalendarHourAppointments>
                            {hour.appointments.map((a, i) => (
                              <SchedulerCalendarHourAppointment
                                key={i}
                                onClick={this.props.appointmentClick.bind(
                                  this,
                                  a
                                )}
                                style={{
                                  outlineColor: practiceState.schedulerHCPs.find(
                                    hcp => hcp.id === a.hCP.id
                                  ).color,
                                  backgroundColor: practiceState.schedulerHCPs.find(
                                    hcp => hcp.id === a.hCP.id
                                  ).color,
                                  ...this.props.buildStyleProps(
                                    i,
                                    a,
                                    hour.appointments
                                  )
                                }}
                              >
                                {`${moment(a.dateTime).format(
                                  "h:mma"
                                )} - ${moment(a.dateTime)
                                  .add(a.duration, "minutes")
                                  .format("h:mma")} - ${a.hCP.firstName} ${
                                  a.hCP.lastName
                                }, ${a.hCP.title}`}
                              </SchedulerCalendarHourAppointment>
                            ))}
                          </SchedulerCalendarHourAppointments>
                        </SchedulerCalendarWeekDayHour>
                      ))}
                    </SchedulerCalendarWeekDay>
                  </SchedulerCalendarWeekDays>
                </SchedulerCalendarWeekContainer>
              </>
            );
          }}
        </Query>
      </SchedulerCalendarFrame>
    );
  }
}

export default compose(withRouter)(SchedulerCalendarDayView);
