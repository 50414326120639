import React from "react";
import styled from "styled-components";
import { Button } from "../buttons/Button";

const StyleDeleteOccurence = styled.div`
  p {
    font-size: 18px;
    line-height: 24px;
    padding-bottom: 25px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  margin-top: 20px;
  .button:first-child {
    padding-left: 30px;
    padding-right: 30px;
  }
`;
export const DeleteOccurence = props => {
  return (
    <StyleDeleteOccurence>
      <p>
        Would you like to delete this one appointment or all occurring at this
        time?
      </p>

      <Button primary onClick={props.onDeleteOnce}>
        {props.primaryText || "just once"}
      </Button>
      <ButtonGroup>
        <Button secondary onClick={props.onDeleteAll}>
          {props.secondaryText || "delete all"}
        </Button>
        <Button onClick={props.onCancel}>{props.cancelText || "cancel"}</Button>
      </ButtonGroup>
    </StyleDeleteOccurence>
  );
};
