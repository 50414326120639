import gql from 'graphql-tag';

export default gql`
    query closestAppointments($fromDate: DateTime!, $hcpId: ID!) {
        closestAppointments(fromDate: $fromDate, hcpId: $hcpId) {
            statusCode
            userRole
            exceptions
            loggedInUser{
                id,
                title,
                firstName,
                lastName
              }
            result {
                timeContainers {
                    date
                    title
                    appointments {
                        appointmentId
                        dateTime
                        duration
                        status
                        hCP {
                            id
                            firstName
                            lastName
                            title
                            avatar
                        }
                        cost
                        occurance
                        endDate
                    }
                }
                hCP {
                    id
                    title
                    firstName
                    lastName
                    avatar
                }
            }
        }
    }
`;