import LoginPage from "../pages/practice/Login";
import ForgotPassword from "../pages/practice/ForgotPassword";
import ResetPassword from "../pages/practice/ResetPassword";
import CheckEmail from "../pages/practice/CheckEmail";
import DashboardPage from "../pages/practice/Dashboard";
import SchedulerPage from "../pages/practice/Scheduler";
import AppointmentsPage from "../pages/practice/Appointments";
import ProvidersPage from "../pages/practice/Providers";
import ProviderProfile from "../pages/practice/ProviderProfile";
import AccountSettingsPage from "../pages/practice/AccountSettings";
import AccountPaymentsPage from "../pages/practice/AccountPayments";
import AccountCreatedPage from "../pages/practice/AccountCreated";
import AccountInsurancesPage from "../pages/practice/AccountInsurancesPage";
import NewInsurancePage from "../pages/practice/NewInsurancePage";
import InsuranceProfile from "../pages/practice/InsuranceProfile";

export default [
  {
    path: "/",
    component: DashboardPage,
    title: "YWait - DashboardPage"
  },
  {
    path: "/login",
    component: LoginPage,
    title: "YWait - Login"
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    title: "YWait - Forgot Password"
  },
  {
    path: "/reset-password",
    component: ResetPassword,
    title: "YWait - Reset Password"
  },
  {
    path: "/check-email",
    component: CheckEmail,
    title: "YWait - Check Email"
  },
  {
    path: "/dashboard",
    component: DashboardPage,
    title: "YWait - Dashboard"
  },
  {
    path: "/calendar",
    component: SchedulerPage,
    title: "YWait - Calendar"
  },
  {
    path: "/appointments",
    component: AppointmentsPage,
    title: "YWait - Appointments"
  },
  {
    path: "/providers",
    component: ProvidersPage,
    title: "YWait - Providers"
  },
  {
    path: "/provider/:hcpId",
    component: ProviderProfile,
    title: "YWait - Provider Detail"
  },
  {
    path: "/account-settings",
    component: AccountSettingsPage,
    title: "YWait - Account Settings - Profile"
  },
  {
    path: "/account-payment",
    component: AccountPaymentsPage,
    title: "YWait - Account Settings - Payment"
  },
  {
    path: "/insurances",
    component: AccountInsurancesPage,
    title: "YWait - Account Settings - Insurances"
  },
  {
    path: "/insurance/:insId",
    component: InsuranceProfile,
    title: "YWait - Insurance Detail "
  },
  {
    path: "/new-insurance",
    component: NewInsurancePage,
    title: "YWait - Account Settings - New Insurances"
  },
  {
    path: "/account-created",
    component: AccountCreatedPage,
    title: "YWait - Account Created"
  },
  {
    path: "/stripe_hooks/express_callback",
    component: AccountCreatedPage,
    title: "YWait - Account Created"
  }
];
