import React, { Component } from 'react'
import Layout from './layouts/Layout'

import config from './config'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { withClientState } from 'apollo-link-state'
import { ApolloLink } from 'apollo-link'

import ScrollToTop from './components/ScrollToTop'
import GET_PRACTICE_STATE from './queries/getPracticeState'
import GET_PATIENT_STATE from './queries/getPatientState'
import IdleTimer from 'react-idle-timer'
import ReactGA from 'react-ga';
import GA from './utils/GoogleAnalytics'

// Cache provider (uses MemoryCache)
const cache = new InMemoryCache();

// Http link for graphql remote
const httpLink = new HttpLink({
  uri: config.graphql,
  credentials: 'same-origin',
  headers: {
  },
});

if(!localStorage.getItem('role')) {
  localStorage.setItem('role', 'Guest');
}

// Default application state.
const defaultState = {
  globalState: {
    __typename: 'GlobalState',
    token: '',
    userType: '',
    practiceId: '',
    patientId: ''
  },
  practiceState: {
    __typename: 'PracticeState',
    schedulerSelectedDate: Date.now(),
    schedulerView: 'Week',
    schedulerHCPs: [],
    schedulerHCPsWereSet: false,
    schedulerColorLegend: ['#FCB9A0', '#C5C5F4', '#FDE698', '#BDFD98', '#98EAFD', '#FDBB8F', '#8CFDEB'],
    schedulerActiveFlow: '',
    schedulerSelectedSlot: null,
    schedulerSelectedAppointment: null,
    appointmentsHCPs: null,
    appointmentsUpcomingFilter: 'all',
    appointmentsPatientNewFilter: 'all',
    appointmentsSortBy: 'dateDesc',
    updateAppointmentSelectionParams: null
  },
  patientState:{
    __typename: 'PatientState',
    selectedHcpId:null,
    newPatientAccessCode :"",
    newPatientIsFirstVisit :"",
    selectedPracticeId :"",
    selectedHcpId :"",
    selectedDate :"",
    selectedAppointmentId :"",
    appointmentIsExisting :""
  }
  
}

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

// Adds default state to cache.
const stateLink = withClientState({
  cache,
  defaults: defaultState,
  resolvers: {
    Mutation: {
      updatePracticeState:(_, { practiceState }, {cache}) => {

        const previousPracticeState = cache.readQuery({ query: GET_PRACTICE_STATE });

        const updatedPracticeState = {
              practiceState: {
                ...previousPracticeState,
                ...practiceState
              }
            };

        cache.writeQuery({ 
          query: GET_PRACTICE_STATE, 
          data: updatedPracticeState
          });

        return updatedPracticeState.practiceState
      },
      updatePatientState:(_, { patientState }, {cache}) => {

        const previousPatientState = cache.readQuery({ query: GET_PATIENT_STATE });

        const updatedPatientState = {
              patientState: {
                ...previousPatientState.patientState,
                ...patientState
              }
            };

        cache.writeQuery({ 
          query: GET_PATIENT_STATE, 
          data: updatedPatientState
          });

        return updatedPatientState.patientState
      }
    }

  }
})

// Create ApolloClient with client state link, remote graphql link and client cache
const client = new ApolloClient({
  link: ApolloLink.from([
    stateLink,
    authLink.concat(httpLink)
  ]),
  cache
});

const onAction = (e) =>{
}

const onActive = (e) => {
}

const onIdle = (e) => {
  localStorage.clear();
  window.location.href = '/';    
}

const idleTimer ={
  timer: null
}

// Injects/renders the application in the DOM. App is wrapped in ApolloProvider, so all Apollo functionality is 
// available. In turn ApolloProvider is wrapper in BrowserRouter so Apollo can access route info if needed.

class App extends Component {
  render() {
    return(
      <BrowserRouter>
        <>
        { GA.init() && <GA.RouteTracker /> }
        <ApolloProvider client={client}>
          <ScrollToTop>
          <IdleTimer
              ref={ref => { idleTimer.timer = ref }}
              element={document}
              onActive={onActive}
              onIdle={onIdle}
              onAction={onAction}
              debounce={250}
              timeout={1000 * 60 * 30} />
            <Layout />
          </ScrollToTop>
        </ApolloProvider>
        </>
      </BrowserRouter>
    )
  }
}

export default App;
