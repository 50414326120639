import React, { PureComponent } from "react";
import { compose } from "recompose";
import { withRouter } from "react-router";
import { Query, graphql } from "react-apollo";
// import GET_INSURANCE_PROFILE from "../../queries/getInsuranceState";
import { LoadingIndicator, ErrorIndicator } from "../../components/indicators";
import { Title, H2 } from "../../components/Typography";
import AccountInsuranceForm from "../../forms/AccountInsuranceForm";

class InsuranceProfile extends PureComponent {
  render() {
    const { insId } = this.props.match.params;
    return (
      <div>
        {/* <Query query={GET_INSURANCE_PROFILE} variables={{ insId: insId }}>
          {({ error, data, loading }) => {
            if (error) {
              console.error(error);
              return <ErrorIndicator error={error} />;
            }
            const payload = data["hCPProfile"];
            if (!payload || loading) {
              return <LoadingIndicator />;
            }
            const InsuranceProfile = payload["result"];

            return (
              <>
                <div className="columns">
                  <div className="column is-full v-center">
                    <Title>{InsuranceProfile.name} </Title>
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-full">
                    <H2>Basic Info</H2>
                    <AccountInsuranceForm
                      action="edit"
                      addInsuranceResultHandler={this.handleHCPResult.bind(
                        this
                      )}
                      //   practiceId={practiceId}
                    />
                  </div>
                </div>
              </>
            );
          }}
        </Query> */}
      </div>
    );
  }
}

export default compose(
  withRouter
  // graphql(GET_INSURANCE_PROFILE, {}),
  // graphql(UPDATE_INSURANCE_STATE, { name: 'updateInsuranceState' })
)(InsuranceProfile);
