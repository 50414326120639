import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { graphql } from "react-apollo";
import { compose } from "recompose";
import YwaitSelect from "../components/Select";
import { LoadingIndicator, ErrorIndicator } from "../components/indicators";
import Notification from "../components/Notification";
import { formatENUM } from "../components/Global";
import * as Yup from "yup";
import { Formik } from "formik";

import { Field, Input, DollarInput, Error, Button } from "../components/Forms";
import UploadAvatar from "../components/UploadAvatar";

import GET_PRACTICE_HCPS from "../queries/getPracticeHcps";
import CREATE_HCP from "../mutations/hcp/createHcp";
import UPDATE_HCP from "../mutations/hcp/updateHcp";
import DELETE_HCP from "../mutations/hcp/deleteHcp";

const Form = styled.form``;

const ProviderForm = styled.div`
  width: 100%;

  .group-title {
    width: 150px;
    margin-right: 30px;
  }
  .group-firstName,
  .group-lastName,
  .group-email,
  .group-defaultCost {
    width: 305px;
    margin-right: 30px;
    input {
      /* width: 150px;  */
      margin-bottom: 0;
    }
  }
`;

const ProviderFormFrame = styled.div`
  display: flex;
  flex-direction: row;
`;

const ProviderFormFrameUserPicture = styled.div`
  flex: 210px 0 0;
`;

const ProviderFormFrameFields = styled.div`
  flex: 0 0 1;
`;

const ProviderFormSubmitBar = styled.div`
  padding-top: 50px;

  .primary button {
    margin-right: 10px;
  }

  .primary a {
    text-decoration: underline;
  }
`;

const GroupField = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: start;
  margin-bottom: 8px;
  align-items: flex-start;
`;

const schema = Yup.object().shape({
  title: Yup.string().required("Title is required."),
  firstName: Yup.string()
    .matches(/^[a-zA-Z'\s]+$/, "First Name is not in the correct format.")
    .max(20, "First Name is too long (max 20 characters)")
    .required("HCP First Name is required."),
  lastName: Yup.string()
    .matches(/^[a-zA-Z'\s]+$/, "Last Name is not in the correct format.")
    .max(20, "Last Name is too long (max 20 characters)")
    .required("HCP Last Name is required."),
  email: Yup.string()
    .email("Email is not in the correct format.")
    .max(30, "Email is too long (max 30 characters)")
    .required("Email is required."),
  defaultCost: Yup.number("Default cost is not in the correct format.")
    .required("Default cost is required")
    .moreThan(1, "Default cost should be more than $1")
    .lessThan(1000, "Default cost should be less than $1000"),
  //avatar: Yup.string().required("Avatar is required.")
});

const hcpTitles = [{ value: "NP.", label: "NP" }, { value: "MD", label: "MD" }];

class PracticeProviderForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      successMessage: "",
      serverMessage: "",
      serverError: false
    };
  }

  mapSubmitData(values) {
    return this.props.action === "edit"
      ? {
          hcp: {
            title: values.title.value,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            defaultCost: parseInt(values.defaultCost),
            avatar: values.avatar
          },
          hcpId: this.props.hCP.id
        }
      : {
          hcp: {
            title: values.title.value,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            defaultCost: parseInt(values.defaultCost),
            avatar: values.avatar
          },
          practiceId: this.props.practiceId
        };
  }

  catchErrors = graphQLErrors => {
    this.setState({ serverError: true, serverMessage: graphQLErrors });
  };

  handleSubmit = (values, actions) => {
    this.setState({ successMessage: false, serverError: false });

    switch (this.props.action) {
      default:
      case "create":
        this.props
          .createHcp({
            name: "createHcp",
            variables: this.mapSubmitData(values),
            refetchQueries: [
              {
                query: GET_PRACTICE_HCPS,
                fetchPolicy: "network-only"
              }
            ]
          })
          .then(res => {
            var data = res.data.createHcp;
            if (!data || data.statusCode != 200) {
              this.setState({
                serverError: true,
                serverMessage: data.exceptions || "Error"
              });
              actions.setSubmitting(false);
              return;
            }

            actions.setSubmitting(false);
            actions.resetForm();
            this.state.successMessage = "A new HCP has been added.";
            this.props.creatHcpResultHandler(this.state);
            window.scrollTo(0, 0);
          });
        break;

      case "edit":
        this.props
          .updateHcp({
            name: "updateHcp",
            variables: this.mapSubmitData(values),
            awaitRefetchQueries: false,
            refetchQueries: [
              {
                query: GET_PRACTICE_HCPS,
                fetchPolicy: "network-only"
              }
            ]
          })
          .then(res => {
            var data = res.data.updateHcp;
            if (!data || data.statusCode != 200) {
              this.setState({
                serverError: true,
                serverMessage: data.exceptions || "Error"
              });
              actions.setSubmitting(false);
              return;
            }

            this.state.successMessage = "Provider Profile was updated.";
            //this.props.creatHcpResultHandler(this.state);
            window.scrollTo(0, 0);
            actions.setSubmitting(false);
          })
          .catch(({ graphQLErrors }) => {
            actions.setSubmitting(false);
            this.setState({
              successMessage: false,
              serverError: true,
              serverMessage: "An error has occured"
            });
          });
        break;
    }
  };

  closeAlert = () => {
    this.setState({ serverError: false, successMessage: false });
  };

  getInitialValues() {
    return this.props.action === "edit"
      ? {
          title: { value: this.props.hCP.title, label: this.props.hCP.title },
          firstName: this.props.hCP.firstName,
          lastName: this.props.hCP.lastName,
          email: this.props.hCP.email,
          defaultCost: this.props.hCP.defaultCost,
          avatar: this.props.hCP.avatar
        }
      : {
          title: "",
          firstName: "",
          lastName: "",
          email: "",
          defaultCost: "",
          avatar: ""
        };
  }

  render() {
    const { action } = this.props;

    return (
      <ProviderForm>
        <Formik
          initialValues={this.getInitialValues()}
          validationSchema={schema}
          onSubmit={this.handleSubmit}
          render={({
            values,
            errors,
            isSubmitting,
            submitCount,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched
          }) => (
            <Form onSubmit={handleSubmit}>
              {this.state.successMessage && (
                <Notification
                  success
                  close={this.closeAlert}
                  open={this.state.successMessage}
                  message={
                    action === "edit"
                      ? "Hcp Profile was updated."
                      : "A new HCP has been added."
                  }
                />
              )}
              {this.state.serverError && (
                <Notification
                  open={true}
                  message={
                    formatENUM(this.state.serverMessage) || "Request Failed"
                  }
                />
              )}
              <ProviderFormFrame>
                <ProviderFormFrameUserPicture>
                  <UploadAvatar
                    size="151px"
                    value={values.avatar}
                    onFormikChange={setFieldValue}
                    onFormikBlur={setFieldTouched}
                  ></UploadAvatar>
                  {submitCount > 0 && errors.avatar && (
                    <Error>{errors.avatar}</Error>
                  )}
                </ProviderFormFrameUserPicture>
                <ProviderFormFrameFields>
                  <GroupField>
                    <Field label="Provider Title" className="group-title">
                      <YwaitSelect
                        name="title"
                        value={values.title}
                        onFormikChange={setFieldValue}
                        onFormikBlur={setFieldTouched}
                        options={hcpTitles}
                      />
                      {submitCount > 0 && errors.title && (
                        <Error>{errors.title}</Error>
                      )}
                    </Field>
                    <Field
                      label="Provider First Name"
                      className="group-firstName"
                    >
                      <Input
                        name="firstName"
                        type="text"
                        value={values.firstName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {submitCount > 0 && errors.firstName && (
                        <Error>{errors.firstName}</Error>
                      )}
                    </Field>
                    <Field
                      label="Provider Last Name"
                      className="group-lastName"
                    >
                      <Input
                        className="input"
                        name="lastName"
                        type="text"
                        value={values.lastName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {submitCount > 0 && errors.lastName && (
                        <Error>{errors.lastName}</Error>
                      )}
                    </Field>
                  </GroupField>
                  <GroupField>
                    <Field label="Provider Email" className="group-email">
                      <Input
                        className="input"
                        name="email"
                        type="text"
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {submitCount > 0 && errors.email && (
                        <Error>{errors.email}</Error>
                      )}
                    </Field>
                    <Field
                      label="Provider Appointment Cost"
                      className="group-defaultCost"
                    >
                      <DollarInput
                        type="number"
                        className="input"
                        name="defaultCost"
                        value={values.defaultCost}
                        onChange={handleChange}
                      />
                      {submitCount > 0 && errors.defaultCost && (
                        <Error>{errors.defaultCost}</Error>
                      )}
                    </Field>
                  </GroupField>
                </ProviderFormFrameFields>
              </ProviderFormFrame>

              {action === "edit" ? (
                <ProviderFormSubmitBar className="columns">
                  <div className="primary column is-one-quarter v-center h-left">
                    <Button type="submit" primary disabled={isSubmitting}>
                      Save
                    </Button>
                  </div>
                  <div className="primary column is-three-quarters v-center h-left">
                    <Link to="/providers">Cancel</Link>
                  </div>
                </ProviderFormSubmitBar>
              ) : (
                <ProviderFormSubmitBar className="columns">
                  <div className="column is-one-quarter v-center h-left">
                    <Button type="submit" primary disabled={isSubmitting}>
                      Add a New Provider
                    </Button>
                  </div>
                </ProviderFormSubmitBar>
              )}
            </Form>
          )}
        />
      </ProviderForm>
    );
  }
}

export default compose(
  withRouter,
  graphql(CREATE_HCP, { name: "createHcp" }),
  graphql(UPDATE_HCP, { name: "updateHcp" }),
  graphql(DELETE_HCP, { name: "deleteHcp" })
)(PracticeProviderForm);
