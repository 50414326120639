import React from "react";
import styled from "styled-components";
import { Title } from "../../components/Typography";
import { theme, media } from "../../components/Global";
import PatientAccessCodeForm from "../../forms/PatientAccessCodeForm";

const H3 = styled.h3`
  font-size: 28px;
  line-height: 30px;
  font-weight: lighter;
  font-family: ${theme.font.regular};
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 40px;
`;

const Paragraph = styled.p`
  font-family: ${theme.font.medium};
  text-align: center;
  padding-top: 10px;
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  max-width: 500px;
  margin: 0 auto;
`;

export default () => {
  localStorage.removeItem("newPatientIsFirstVisit");
  localStorage.removeItem("selectedDate");
  localStorage.removeItem("selectedHcpId");
  localStorage.removeItem("selectedPracticeId");
  return (
    <div className="container">
      <div className="columns is-centered">
        <div className="column is-8">
          <Title className="has-text-centered">Welcome to YWait Doc</Title>

          <H3>
            We offer exclusive access to premium appointments with top
            physicians.{" "}
          </H3>

          <Paragraph>
            Enter the access code to view the premium appointments with your
            healthcare professional!
          </Paragraph>
          <PatientAccessCodeForm />
        </div>
      </div>
    </div>
  );
};
