import React from 'react'
import styled from 'styled-components'
import { compose } from 'recompose';
import { Query, graphql } from 'react-apollo'
import { withRouter } from 'react-router';
import moment from 'moment'

import { media, formatENUM } from '../../components/Global'
import { Title } from '../../components/Typography'
import { Button } from '../../components/buttons/Button'
import { ProviderCard } from '../../components/Cards'
import YwaitDayPicker from '../../components/DatePicker'
import { LoadingIndicator, ErrorIndicator } from '../../components/indicators'
import Notification from '../../components/Notification'
import GET_AVAILABLE_APPOINTMENTS from '../../queries/getAvailableAppointments'
import DocImage from '../../assets/dr-avatar.png'

const StyleSelectAppointment = styled.div`
  .provider-card {
    margin-bottom: 30px;
    max-width: 387px;
    margin-left: auto;
    margin-right: auto;
  }

  h1 {
    margin-bottom: 0;
  }

  .DayPicker-Scheduling {
    text-align: center;
  }

  .date-notification {
    max-width: 387px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 0;
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 60px;

  .button { width: 263px; }
  
  ${media.phone`
    .button { width: 100%; }
  `}
`

const Paragraph = styled.p`
  margin-bottom: 25px;
  text-align: center;
  font-size: 18px;
`

class SelectDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serverError: false,
      serverMessage: '',
      currentMonthStart: moment().startOf('month'),
      currentMonthEnd: moment().endOf('month')
    }
  }

  render() {
    var dates = [];
    const handleClick = (date) => {
      this.setState({ serverError: false });
      localStorage.setItem('selectedDate', date);

      var isAvalibleDate = dates.find(item => { return moment(item).format('MMDDYY') == moment(date).format('MMDDYY') });
      if (isAvalibleDate) {
        this.props.history.push('/select-time');
      } else {
        this.setState({ serverError: true, serverMessage: 'No appointments available on this date. Please select a date highlighted with a blue circle.' });
        return;
      }
    }

    const handleMonthChange = (refetch, date) => {
      this.setState({
        currentMonthStart: moment(date).startOf('month'),
        currentMonthEnd: moment(date).endOf('month')
      });
      refetch();
    }

    
    const handleGoBack = () => {
      this.props.history.goBack();
    }

    return (
      <Query query={GET_AVAILABLE_APPOINTMENTS} variables={{ fromDate: this.state.currentMonthStart.format(moment.HTML5_FMT.DATETIME_LOCAL), toDate: this.state.currentMonthEnd.format(moment.HTML5_FMT.DATETIME_LOCAL), hcpId: localStorage.getItem('selectedHcpId') }}>
        {({ loading, error, data, refetch, networkStatus }) => {
          if (error) {
            console.error(error);
            return <ErrorIndicator />
          }

          const payload = data['availableAppointments'];
          if (!payload || loading) {
            return <LoadingIndicator />
          }
          if (payload.statusCode != 200) {
            return <Notification open={true} message={formatENUM(payload.exceptions)} />
          }

          const availableAppointments = payload['result'];
          if (!availableAppointments)
            return <Notification open={true} message="No Apppointments Available" />

          const doc = availableAppointments.hCP
          if (!availableAppointments || !doc)
            return <Notification open={true} message="Provider Not Found" />

          dates = availableAppointments.appointmentSlots.map((item, index) => {
            if (moment(item.dateTime).isAfter(moment()))
              return moment.utc(item.dateTime).local().toDate();
          });

          const availableDates = {
            selectable: dates,
            selected: moment(localStorage.getItem('selectedDate')).local().toDate()
          };
          return (
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-12">
                  <Title style={{ margin: 0 }} className="has-text-centered">Select Your Preferred Date</Title>
                  <Paragraph>Gain access to a premium appointment! </Paragraph>

                  <StyleSelectAppointment>
                    <ProviderCard
                      className='provider-card'
                      isDoctorName
                      doctorImage={doc.avatar || DocImage}
                      doctorName={doc.firstName + " " + doc.lastName + ", " + doc.title}
                      speciality={doc.speciality}
                    />

                    <YwaitDayPicker onMonthChange={handleMonthChange.bind(this, refetch)} onDayClick={handleClick.bind(this)} modifiers={availableDates} month={this.state.currentMonthStart.toDate()} />
                    {this.state.serverError && <Notification className="date-notification" close={this.closeAlert}
                      open={this.state.serverMessage} message={formatENUM(this.state.serverMessage) || "Error Processing Request"}
                    />}

                    <ButtonWrapper>
                      <Button onClick={handleGoBack} secondary> Go Back </Button>
                    </ButtonWrapper>

                  </StyleSelectAppointment>
                </div>
              </div>
            </div>
          )
        }}
      </Query>
    )
  }

}

export default compose(
  withRouter
  )(SelectDate);
