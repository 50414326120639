import gql from 'graphql-tag';

export default gql`
    query {
        practiceDashboard {
            statusCode
            userRole
            exceptions
            loggedInUser{
                id,
                title,
                firstName,
                lastName
              }
            result{
                practice {
                    id
                    firstName
                    lastName
                    email
                    name
                    phone
                    address
                    address2
                    city
                    state
                    zip
                    accessCode
                    stripeAccountId
                }
            }
        }
    }
`;