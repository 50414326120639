
import gql from 'graphql-tag';

export default gql`
    mutation updateWaitlist($waitlist:WaitlistInputType!, $waitlistId: ID!) {
        updateWaitlist(waitlist:$waitlist waitlistId: $waitlistId) {
        statusCode
        userRole
        exceptions
        loggedInUser{
            id,
            title,
            firstName,
            lastName
          }
        result{
            id
            firstName
            lastName
            email
        }
    }
}
`;