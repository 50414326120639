import React from 'react'
import { Title } from '../../components/Typography'
import SelectTime from '../../components/SelectTime'

export default () => (
  <div className="container">
    <div className="columns is-centered">
      <div className="column is-full">
        <Title  style={{margin: 0}} className="has-text-centered">Select Your Time</Title>        
        <SelectTime />
      </div>
    </div>
  </div>
)
  

