import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { YwaitLink } from '../Link'
import { UserPicture }  from '../UserPicture'
import { media, theme, isMobile } from '../Global'
import svgSelect from '../../assets/svg/select.svg'
import docImage from '../../assets/dr-avatar.png'
import patientImage from '../../assets/patient-avatar.png'

const StyleLogInMenu = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  a {
    display: inline-block;
    font-size: 15px;
    margin: 0 27px;   
  }

  img {
    border-radius: 50%;
  }

  ${media.phone`
    display: none;

    ${ (props) => props.active && css`
      position: absolute;
      top: ${theme.navHeight};
      
      left: 0;
      height: calc(100vh - ${theme.navHeight});
      width: 100%;
      background-color: white;
      z-index: -1;
      padding: 0 44px;
      display: block !important;
  
      nav {
        padding-top: 20px;
  
        a {
          margin: 0;
          display: block;
          font-size: 20px;
          line-height: 20px;
          padding-top: 30px;
        }
      }
  
      ${User} {
        margin-left: 0;
        margin-top: 50px;
        padding-top: 20px;
        border-top: 1px solid #EEEEEE;
        display: block;
        height: auto;
      }
      
      ${Select} {
        display: none;
      }
      
      ${SelectOptions} {
        position: relative;
        top: auto;
        width: 100%;
        a { 
          padding-top: 10px;
          padding-bottom: 10px;
          text-align: left;
          :last-child {          
            border-top: none; 
          }
        }
      }
    `}
  `};

`

const User = styled.div`
  margin-left: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
`;

const Select = styled.img`
  margin-left: 5px;
`

const SelectOptions = styled.nav`
  position: absolute;
  top: ${theme.navHeight};
  right: 0;
  background-color: white;
  width: 220px;
  z-index: -1;

  a {
    margin: 0;
    text-align: center;
    width: 100%;
    padding: 18px 0;
    display: block;
    :last-child { border-top: 1px solid #EEEEEE; }
  }
`

export class PracticeLogInMenu extends Component {  
  handleMouseDown = (e) => {    
    document.removeEventListener('mousedown', this.handleMouseDown, false);

    if (this.containerRef && 
        this.containerRef.contains(e.target)) {      
      return;
    }    

    this.props.onPress();
  }
  
  logout = () => {
    localStorage.clear();
    window.location.href = '/';    
  } 

  render() { 
    
    const { active, onPress, onToggle } = this.props;

    if (active && !isMobile()) {
       document.addEventListener('mousedown', this.handleMouseDown, false);
    }

    return ( 
      <StyleLogInMenu active={active} ref={cmp => this.containerRef = cmp}>
        <nav>
          <YwaitLink onClick={onPress} to="/dashboard">Dashboard</YwaitLink>
          <YwaitLink onClick={onPress} to="/calendar">Calendar</YwaitLink>
          <YwaitLink onClick={onPress} to="/appointments">Appointments</YwaitLink>
          <YwaitLink onClick={onPress} to="/providers">Providers</YwaitLink>
        </nav>
        <User onClick={onToggle}>
          <UserPicture picture={patientImage} size="44px" />
          <Select src={svgSelect} alt="" />
        </User>    
        
        { active ? (
          <SelectOptions>
            <YwaitLink to="/account-settings" onClick={onPress}>Account Settings</YwaitLink>
            <YwaitLink to="#" onClick={this.logout}>Log Out</YwaitLink>
          </SelectOptions>
        ) : '' }          
      </StyleLogInMenu>
    )
  }
}