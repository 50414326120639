import gql from "graphql-tag";

export default gql`
  mutation deletePracticeInsurance($id: String!, $name: String!) {
    deletePracticeInsurance(insuranceDelete: { id: $id, name: $name }) {
      exceptions
      statusCode
      userRole
      result {
        id
        name
      }
    }
  }
`;
