import React from 'react'
import { Title } from '../../components/Typography'

import SignUpWaitlistForm from '../../forms/SignUpWaitlistForm'

export default () => (
  <div className="container">
    <div className="columns is-centered">
      <div className="column is-8">
               
        <SignUpWaitlistForm />
      </div>
    </div>
  </div>
)
  

