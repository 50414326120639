import gql from 'graphql-tag';

export default gql`
    mutation confirmAppointment($bookedAppointmentId:ID!) {
        confirmAppointment(bookedAppointmentId:$bookedAppointmentId) {
        statusCode
        userRole
        exceptions
        loggedInUser{
            id,
            title,
            firstName,
            lastName
          }
        result{
            appointmentId
            bookedAppointmentId
            parentId
            stripeOrderId
            hCP {
                id
                firstName
                lastName
                title
            }
            patient {
                id
                firstName
                lastName
                title
                cellPhone
            }
            duration
            dateTime
            occurance
            endDate
            cost
            status
            type
         }
}
}
`;