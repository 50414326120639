import React from 'react'
import styled from 'styled-components'
import { theme } from './Global'
import { UserPicture } from './UserPicture'
import { Card, DoctorName, Speciality } from './Cards'

const AppCard = styled(Card)`  
  &.profile {
    text-align: center;
    padding: 30px;
    img { margin-bottom: 18px; }
  }
`;

const AppDoctorName = styled(DoctorName)`
  font-size: 16px;  
`

const ProfileDate = styled(DoctorName)`
  font-size: 18px;  
  color: ${theme.color.secondary};
  padding-bottom: 18px;
  max-width: 228px;
  margin: 0 auto;
`

const AppSpeciality = styled(Speciality)`
  font-size: 16px;
`;

const Address = styled.address`
  max-width: 200px;
  margin: 0 auto;
  margin-top: 10px;  
`

const Phone = styled.span`

`


export const ProfileCard = (props) => {
  return (
    <AppCard className="profile">           
      { props.doctorImage ? <UserPicture size='151px' picture={props.doctorImage}/> : null }      
      <ProfileDate>{props.appDate}</ProfileDate>  
      <AppDoctorName>{props.doctorName}</AppDoctorName>  
      { props.speciality ? <AppSpeciality>{props.speciality}</AppSpeciality> : null}   
      { props.address ? <Address>{props.address}</Address> : null}   
      { props.phone ? <Phone>{props.phone}</Phone> : null}   


    </AppCard>
  )
}