import React from 'react'
import { withRouter } from 'react-router';
import { compose } from "recompose";
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Query, graphql } from 'react-apollo'

import { Title, H2 } from './Typography'
import { ProviderCard } from './Cards'
import { LoadingIndicator, ErrorIndicator } from './indicators'

import GET_PRACTICE_STATE from '../queries/getPracticeState'
import UPDATE_PRACTICE_STATE from '../mutations/updatePracticeState'
import { practiceStateGetter } from '../queries/propGetters/practiceState'
import GET_PRACTICE_HCPS from '../queries/getPracticeHcps'
import PracticeProviderForm from '../forms/PracticeProviderForm'
import Notification from './Notification'

import DocImage from '../assets/dr-avatar.png'
import { Button } from '../components/Forms'
export const HcpsList = styled.div``;

const CardWrapper = styled.div`
  padding-bottom: 20px;
`


class Hcps extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            successMessage: "",
            serverError: false
        }
    }
    handleHCPResult(state) {
        this.setState(state);
    }
    render() {
        return (
            <div>
                <Query query={GET_PRACTICE_HCPS}>
                    {({ error, data, loading }) => {
                        if (error) {
                            console.error(error);
                            return <ErrorIndicator error={error} />
                        }
                        const payload = data['practiceDashboard'];
                        if (!payload || loading) {
                            return <LoadingIndicator />
                        }
                        const practiceDashboard = payload['result'];
                        const hasProviders = (practiceDashboard.hCPs.length > 0);
                        return (
                            <>
                                <div className="columns spaced">
                                    <div className="column is-4">
                                        <HcpsList>
                                            {hasProviders ? 
                                                
                                                practiceDashboard.hCPs.map(hcp =>
                                                    <CardWrapper key={hcp.id}>
                                                            <ProviderCard isDoctorName
                                                                doctorImage={hcp.avatar || DocImage}
                                                                doctorName={`${hcp.firstName} ${hcp.lastName}, ${hcp.title}`} />
                                                    </CardWrapper>
                                                )

                                            :
                                            //else
                                            <Notification open message="Please Add A Provider"/>
                                            }
                                        </HcpsList>
                                    </div>
                                </div>
                                <div className="columns spaced">
                                    <div className="column is-full">
                                        <H2>Add New Provider</H2>
                                        <PracticeProviderForm action="create" creatHcpResultHandler={this.handleHCPResult.bind(this)} practiceId={practiceDashboard.practice.id} />
                                    </div>
                                </div>
                                
                        <div className="has-text-centered">
                            <Button onClick={this.props.handleNext} secondary disabled={!hasProviders}>Next</Button>
                        </div>
                            </>
                            
                        )
                    }}
                </Query>
            </div>
        )
    }
}

export default compose(
    withRouter,
    graphql(GET_PRACTICE_STATE, { props: practiceStateGetter }),
    graphql(UPDATE_PRACTICE_STATE, { name: 'updatePracticeState' })
)(Hcps);