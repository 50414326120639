import React from 'react'

export const LoadingSVG = ({ className }) => (
  <div className={`LoadingSVG ${className || ''}`}>
    <svg width="65px" height="65px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <circle cx="50" cy="50" fill="none" stroke="#4A5EED" strokeWidth="6" r="40"
        strokeDasharray="188.49555921538757 64.83185307179586">
        <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1"
          dur="2s" begin="0s" repeatCount="indefinite"></animateTransform>
      </circle>
    </svg>
  </div>
)
