import gql from "graphql-tag";

export default gql`
query getWaitlistById($waitlistId: String!) {
  getWaitlistById(waitlistId: $waitlistId) {
      statusCode
      userRole
      exceptions
      loggedInUser {
        id
        title
        firstName
        lastName
      }
      result {
        id
        firstName
        lastName
        email
        mobileNo
        preferredTimeOfTheDay
        notificationPreference
        notificationOn
        notificationsCutOffDate
      }
    }
  }
`;
