import React from "react";
import DayPicker from "react-day-picker";
import DayPickerInput from "react-day-picker/DayPickerInput";
import svgSelect from "../assets/svg/select.svg";
import "react-day-picker/lib/style.css";
import "../scss/day-picker.scss";

export const YwaitDayPicker = props => {
  return (
    <div className="DayPicker-Scheduling">
      <DayPicker {...props} />
    </div>
  );
};

export const YwaitDayPickerInput = props => {
  return (
    <div className="DayPicker-Select">
      <DayPickerInput {...props} />
      <div
        className={
          props.value
            ? "DayPicker-Indicator is-selected"
            : "DayPicker-Indicator"
        }
      >
        <img src={svgSelect} alt="icon" />
      </div>
    </div>
  );
};

export const YWaitCalendar = props => {
  return (
    <div className="DayPicker-Select DayPicker-Calendar">
      <DayPicker canChangeMonth {...props} />
    </div>
  );
};

export default YwaitDayPicker;
