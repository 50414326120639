import React, { Component } from 'react'
import styled from 'styled-components'
import { Button } from './buttons/Button'
import { Title } from './Typography'
import { media } from './Global'
import { withRouter } from 'react-router';
import { graphql } from 'react-apollo'
import { compose } from "recompose";
import Notification from '../components/Notification'
import queryString from 'query-string'

const StyleContainer = styled.div`
  max-width: 610px;
  margin: 0 auto;
  h1 { text-align: center; }

  .button {
    padding-left: 30px;
    padding-right: 30px;
    max-width: 280px;
    margin: 0 12px;
    height: 80px;
    line-height: 22px;
    border-width: 2px;

    ${media.phone`
      margin-bottom: 20px;
    `}
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 20px;
`
let search = window.location.search;
let params = new URLSearchParams(search);
let success = params.get('success');
class GetStartedWithYWait extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serverError: false,
      successMsg: queryString.parse(props.location.search).success == "true" ? true : false,
      message: 'You have been added to our waitlist. We will notify you once any appointment slot becomes available.',
    }
  }

  closeAlert = () => {
    this.setState({ serverError: false, successMsg: false });
  }

  handleClick = (value,actions)=>{
    if(actions == "waitlist")
    {
      this.props.history.push('/signup-waitlist');
    }
    else
        this.props.history.push('/are-you-new-patient');
  }
  
  render() {
    return (
      <React.Fragment>
          <Notification
              success
              alert
              close={this.closeAlert}
              open={this.state.successMsg}
              message={this.state.message} />
          <StyleContainer>      
              <Title>Get Started with YWait!</Title>
             <ButtonWrapper>
                <Button onClick={this.handleClick.bind(this,false,"waitlist")} secondary> Sign-Up for Waitlist </Button>
                <Button onClick={this.handleClick.bind(this,true)} secondary> Book Premium Appointment </Button>
              </ButtonWrapper>      
          </StyleContainer> 
    </React.Fragment> 
    )
     }
}


export default compose(
  withRouter,
)(GetStartedWithYWait);

