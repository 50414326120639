export default [
  {
    id: 'day-001',
    date: 'Sat., Sept. 1',        
    list:  [
      {
        id: 'time-01',
        time: '8:45 am',
        price: '$45',
      },
      {
        id: 'time-02',
        time: '9:15 am',
        price: '$20'
      },      
      {
        id: 'time-03',
        time: '6:00 pm',
        price: '$40',
      },
    ]
  },
  {
    id: 'day-002',
    date: 'Sun., Sept. 12',        
    list:  [
      {
        id: 'time-04',
        time: '12:00 pm',
        price: '$40',
        selected: true
      },
      {
        id: 'time-05',
        time: '12:00 pm',
        price: '$30'
      },      
    ]
  },
  {
    id: 'day-003',
    date: '12: 00 p.m.',        
    list:  [
        {
          id: 'time-06',
          time: '9:15 am',
          price: '$20',          
        },
        {
          id: 'time-07',
          time: '8:45 am',
          price: '$40'
        },      
    ]
  },
  {
    id: 'day-004',
    date: 'Fri., Sept. 21',    
    list:  [
        {
          id: 'time-08',
          time: '12:00 pm',
          price: '$10'
        },
        {
          id: 'time-09',
          time: '6:00 pm',
          price: '$70'
        },      
    ]
  },
  {
    id: 'day-005',
    date: 'Fri., Oct. 15',    
    list:  [
      {
        id: 'time-010',
        time: '8:45 am',
        price: '$45',
      },
      {
        id: 'time-011',
        time: '9:15 am',
        price: '$20'
      },      
      {
        id: 'time-012',
        time: '5:30 pm',
        price: '$40',
      },
      {
        id: 'time-013',
        time: '6:00 pm',
        price: '$40',
      },
    ]
  },
];


