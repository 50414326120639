
import React from 'react'
import styled from 'styled-components'
import { media, theme } from '../Global'

const StyleHamburger = styled.span`
  background-color: transparent;
  color: ${theme.color.text};
  margin-right: 20px;

  span {
    height: 2px;
    width: 20px;
    left: calc(50% - 10px);
    &:nth-child(1) { top: calc(50% - 7px); }
    &:nth-child(2) { top: calc(50% - 0px);  }
    &:nth-child(3) { top: calc(50% + 7px);  }  
  }  

  &.is-active  {
    span:nth-child(1) { top: calc(50% - 4px); }
    span:nth-child(3) { top: calc(50% + 6px); }
    span { height: 2px; }
  }

  &:hover {
    color: ${theme.color.primary};
    background-color: transparent;
  }

  ${media.phone`
    display: block !important;
  `};

  ${media.tablet`
    display: none !important;
  `};
`

export const Hamburger = (props) => (
  <StyleHamburger 
    onClick={props.onPress} 
    className={`hamburger--menu ${props.active ? 'is-active' : ''} `}>        
    <span></span>
    <span></span>
    <span></span>        
  </StyleHamburger>
)
