import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { compose } from 'recompose';
import { StripeProvider } from 'react-stripe-elements'
import { Query } from 'react-apollo'
import { withRouter } from 'react-router';

import { Button } from '../../components/buttons/Button'
import { Title, H2 } from '../../components/Typography'
import { ProviderCard } from '../../components/Cards'
import { formatENUM, theme } from '../../components/Global'
import { LoadingIndicator, ErrorIndicator } from '../../components/indicators'
import PaymentInfoForm from '../../forms/PaymentInfoForm'
import Notification from '../../components/Notification'
import GET_APPOINTMENT from '../../queries/getAppointment'
import DocImage from '../../assets/dr-avatar.png' 
import { debug } from 'util';
import config from '../../config';

const Checkbox = styled.div`
  input {
    margin-right: 10px;
  }

  font-size: 16px;
  font-family: ${theme.font.thin};
`

class PaymentInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serverError: false,
      isSubmitting: false,
      serverMessage: '',
      checkPurchase: false
    }
  }

  handleChange = () => {
    this.setState({ checkPurchase: !this.state.checkPurchase, isSubmitting: false });
  }

  handleClick = () => {
    this.setState({ isSubmitting: true });
    localStorage.setItem("acceptedTerms",this.state.checkPurchase);
    if (!this.state.checkPurchase) { return; }

    this.child.handleSubmit(() => {
      this.setState({ isSubmitting: false });
    });
  }

  render() {
    return (
      <Query query={GET_APPOINTMENT} variables={{ appointmentId: localStorage.getItem("selectedAppointmentId") }}>
        {({ error, data, loading }) => {

          if (error) {
            console.error(error);
            return <ErrorIndicator />
          }
          const payload = data['getAppointment'];
          if (!payload || loading) {
            return <LoadingIndicator />
          }
          if (payload.statusCode != 200) {
            return <Notification open={true} message={formatENUM(payload.exceptions)} />
          }
          const appointment = payload['result'];
          const name = appointment.hCP.firstName + " " + appointment.hCP.lastName + ", " + appointment.hCP.title;

          return (
            <div className="container">
              <div className="columns is-centered is-multiline">
                <div className="column is-12">
                  <Title className="has-text-centered">
                    Enter Your Payment Info
                  </Title>
                </div>
                <div className="column is-4">
                  <H2>Payment</H2>
                  <StripeProvider apiKey={config.stripeKey}>
                    <PaymentInfoForm onRef={ref => (this.child = ref)} />
                  </StripeProvider>
                </div>
                <div className="column is-6">
                  <H2 style={{ paddingLeft: '22px' }}>Your Premium Appointment</H2>
                  <ProviderCard
                    edit
                    noEditLink
                    noPadding
                    doctorImage={appointment.hCP.avatar || DocImage}
                    doctorName={name}
                    speciality={appointment.hCP.speciality}
                    date={moment.utc(appointment.dateTime).local().format("dddd, MMMM Do")}
                    price={"$" + appointment.cost}
                    time={moment.utc(appointment.dateTime).local().format("hh:mm a")}
                  />
                </div>
                <div className="column is-10">
                  <Checkbox>
                    <input
                      name="terms"
                      onChange={this.handleChange}
                      value={this.state.checkPurchase}
                      type="checkbox"
                      />
                    By purchasing this appointment you will gain access to a premium appointment time with your healthcare provider. The purchase of this appointment is not refundable and does not entitle a patient to receive additional time or service beyond the standard appointment. YWait does not provide any medical advice. If you are experiencing a medical emergency, please seek treatment immediately outside of this service.    
                  </Checkbox>
                </div>

                <div className="column is-10">
                  { (this.state.isSubmitting && !this.state.checkPurchase ) && 
                    <Notification style={{display: 'inline-block'}} close={this.closeAlert} 
                    open={true} 
                    message={'Please agree to the conditions to proceed.'} />}
                </div>

                <div className="column is-5-tablet is-4-desktop">
                  <Button secondary full onClick={this.handleClick} disabled={this.state.isSubmitting}>Purchase Appointment</Button> 
                </div>
              </div>
            </div>
          )
        }}
      </Query>
    )
  }
}

export default compose(
  withRouter
)(PaymentInfo);

