import React, { Component } from 'react'
import { withRouter } from 'react-router';

import { compose } from 'recompose';
import { Query, graphql } from 'react-apollo'
import moment from 'moment'
import TimeList from './TimeList'
import { formatENUM } from '../Global'
import { StyleTimeSelector, TimeContainer, Arrow } from './Styles'
import GET_CLOSEST_APPOINTMENTS from '../../queries/getClosestAppointments'
import { LoadingIndicator, ErrorIndicator } from '../indicators'
import Notification from '../../components/Notification'

class TimeSelector extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      data: props.data,
      serverError: false,
      serverMessage: ''
    };
  }

  onClick = (fetch,date) => {
    localStorage.setItem('selectedDate', moment(date).toDate());
    this.setState({selectedDate: moment(date).toDate()});
  }

  render() {
    return (
      <Query query={GET_CLOSEST_APPOINTMENTS} variables={{ fromDate: moment(localStorage.getItem('selectedDate')).toDate(), hcpId: localStorage.getItem('selectedHcpId') }} fetchPolicy="network-only">
        {({ loading, error, data, refetch, networkStatus }) => {
          if (error) {
            console.error(error);
            return <ErrorIndicator />
          }
          const payload = data['closestAppointments'];

          if (!payload || loading) {
            return <LoadingIndicator />
          }
          if (payload.statusCode != 200) {
            return <Notification open={true} message={formatENUM(payload.exceptions)} />
          }

          const closestAppointments = payload['result'];
          if (!closestAppointments || !closestAppointments.timeContainers || !closestAppointments.timeContainers.length)
            return <Notification open={true} message="No Apppointments Available" />
            
          return (
            <StyleTimeSelector>
              {closestAppointments.timeContainers[1].appointments.length > 0 ?
              <Arrow left onClick={this.onClick.bind(this,refetch, closestAppointments.timeContainers[1].date)} />
              :
              <></>
              }
              <TimeContainer>
                {closestAppointments.timeContainers.map(t => {
                  return <TimeList index={t.date} key={`list-${t.date}`} {...t} />
                })}
              </TimeContainer>
              {closestAppointments.timeContainers[3].appointments.length > 0 ?
              <Arrow onClick={this.onClick.bind(this,refetch, closestAppointments.timeContainers[3].date)} />
              :
              <></>
              }
            </StyleTimeSelector>
          )
        }}
      </Query>
    )

  }
}

export default compose(
  withRouter
  )(TimeSelector);
