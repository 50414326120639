import gql from 'graphql-tag';

export default gql`
    mutation UpdateGlobalState($globalState: GlobalState!) {
        updateGlobalState(globalState: $globalState) @client {
            globalState {
                __typeName
                token
                userType
                practiceId
                patientId
            }
        }
    }
`;