import React from 'react'
import styled from 'styled-components'
import { Title } from '../../components/Typography'
import { ButtonLink } from '../../components/buttons/Button'
import PatientLoginForm from '../../forms/PatientLoginForm'
import { theme } from '../../components/Global'

const CreateAccount = styled.div`
  text-align: center;
  margin-top: 40px;

  .button { width: 400px; }
  span {
    display: block;
    font-size: 18px;
    padding: 25px 0;
    font-family: ${theme.font.medium};
  }
  
  p {
    display: block;
    font-size: 18px;
    padding-bottom: 25px;
    font-family: ${theme.font.medium};
  }
`

export default () =>
  <div className="container">
    <div className="columns is-centered">
      <div className="column is-6">
        <Title className="has-text-centered">Create an Account</Title>
        <CreateAccount>
          <ButtonLink to="/sign-up" primary={1}>SIGN UP WITH YWAIT</ButtonLink>
          <span> OR </span>
          <p>Already have an account? Login.</p>
        </CreateAccount>
        <PatientLoginForm />        
      </div>
    </div>
  </div>
