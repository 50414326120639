import styled, { css } from 'styled-components'
import { theme, media } from '../Global'

export const StyleTimeSelector = styled.div`
  margin-top: 50px;
  position: relative;
  margin: 0 auto;

  ${media.desktop`
    max-width: 1160px;
  `};

  .loading-section {
    text-align: center;
  }
`


export const TimeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;   
  padding-left: 50px;
  padding-right: 50px; 

  
  ${media.phone`
    flex-wrap: wrap;
  `};
`

export const Time = styled.span`
  font-family: ${theme.font.medium};
  font-size: 15px;
  line-height: 24px;
`

export const Price = styled.span`
  font-family: ${theme.font.medium};
  font-size: 15px;
  line-height: 24px;
  color: ${theme.color.secondary};
`

export const SelectorItem =  styled.div`
  max-width: 181px;
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between; 
  padding-left: 19px;
  padding-right: 25px;
  margin-bottom: 15px;
  border: 1px solid ${theme.color.light_gray};
  background-color: white;
  cursor: pointer;


  ${props => props.selected && css`
    background-color: ${theme.color.primary};
    border: 1px solid ${theme.color.primary};

    ${Time} { color: white; }
    ${Price} { color: white; }
  `}
`

export const ListItem = styled.div`
  max-width: 205px;
  width: 100%;
  padding: 0 15px 22px 15px;
  margin-left: 2px;
  margin-right: 2px;

  ${props => props.selected && css`
    background-color: #DEE3E8;
  `}
`

export const ListTitle = styled.h3`
  font-family: ${theme.font.medium};
  font-size: 18px;
  line-height: 40px;
  padding-top: 10px;
  padding-bottom: 15px;
  text-align: center;
`

export const Arrow = styled.div`  
  width: 48px;
  height: 48px;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -24px;
  background-color: white; 
  border: 1px solid #E8E8E8;
  box-shadow: 0 2px 5px 0 rgba(#6D7185, .10);
  right: 0;
  cursor: pointer;
  display: inline-block;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  border-radius: 50%;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiID8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij4KCTxkZWZzPgoJCTxjbGlwUGF0aCBpZD0iY2xpcF8wIj4KCQkJPHJlY3QgeD0iLTMxNCIgeT0iLTI3MSIgd2lkdGg9IjE0MzkiIGhlaWdodD0iMTM3NCIgY2xpcC1ydWxlPSJldmVub2RkIi8+CgkJPC9jbGlwUGF0aD4KCTwvZGVmcz4KCTxnIGNsaXAtcGF0aD0idXJsKCNjbGlwXzApIj4KCQk8cGF0aCBmaWxsPSJub25lIiBzdHJva2U9InJnYigzNCwzOSw2NikiIHN0cm9rZS13aWR0aD0iMSIgc3Ryb2tlLW1pdGVybGltaXQ9IjQiIHRyYW5zZm9ybT0ibWF0cml4KDEuMTkyNDllLTA4IC0xIDEgMS4xOTI0OWUtMDggOS4zOTA1IDE3LjM0NDgpIiBkPSJNMCAwTDUuMjg5NzkgNS4xNDE2TDEwLjYxMDYgMCIvPgoJPC9nPgoKPC9zdmc+');

  ${props => props.left && css`  
    left: 0;
    transform: rotateY(180deg);
  `}
`