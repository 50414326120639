import gql from 'graphql-tag';

export default gql`
    query {
        patientState @client {
            __typename
            newPatientAccessCode
            newPatientIsFirstVisit
            selectedPracticeId
            selectedHcpId
            selectedDate
            selectedAppointmentId
            appointmentIsExisting
        }
    }
`;