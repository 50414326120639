import React from "react";
import styled from "styled-components";
import { theme } from "../Global";

const StyleSelectInsurance = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-color: 0;
  border-style: none;
  border-width: 0px 0 0 0px;
  background-color: #fff;

  overflow-y: auto;

  .loading-indicator {
    margin-top: 100px;
  }

  &.scrollable {
    height: calc(60px * 24);
  }
`;

export const SelectInsurance = props => (
  <StyleSelectInsurance>{props.insurance.name}</StyleSelectInsurance>
);
