import gql from "graphql-tag";

export default gql`
  mutation addInsurance($uuid: String!, $name: String!, $practiceId: ID!) {
    addInsurance(
      insuranceAdd: { id: $uuid, name: $name, practiceID: $practiceId }
    ) {
      result {
        id
        name
        practiceID
      }
    }
  }
`;
