import React from 'react'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import { graphql } from 'react-apollo'

import GET_PRACTICE_DASHBOARD from '../queries/getPracticeDashboard';
import { practiceDashboardGetter } from '../queries/propGetters/practiceDashboard'
import addCalendarHandlers from '../components/decorators/addCalendarHandlers';
import GET_PRACTICE_STATE from '../queries/getPracticeState'
import { practiceStateGetter } from '../queries/propGetters/practiceState'
import UPDATE_PRACTICE_STATE from '../mutations/updatePracticeState'
import UPDATE_APPOINTMENT from '../mutations/appointments/updateAppointment'
import PracticeAppointmentForm from "../forms/PracticeAppointmentForm";

import Modal from '../components/modals/Modal'
import { EditOccurence } from '../components/modals/EditOccurence'

class PracticeAppointmentEditOccuranceForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  render() {
    let activeFlow = [];
    return (
      <Modal isOpen onClose={this.props.resetCalendarFlow}>
        <EditOccurence 
          onEditOnce={e => {
            this.props.appointmentEditOnceClick()
          }}
          onEditAll={e => {
            this.props.appointmentEditAllClick()
          }}
          onCancel={e => {
              this.props.resetCalendarFlow()
          }}
          
          />
          {activeFlow}
      </Modal>
    )
  }
}

export default compose(
    withRouter,
    graphql(GET_PRACTICE_DASHBOARD, { props: practiceDashboardGetter }),
    graphql(GET_PRACTICE_STATE, { props: practiceStateGetter }),
    graphql(UPDATE_PRACTICE_STATE, { name: 'updatePracticeState' }),
    graphql(UPDATE_APPOINTMENT, { name: 'updateAppointment' }),
    addCalendarHandlers
)(PracticeAppointmentEditOccuranceForm);