import React from 'react'
import styled  from 'styled-components'
import { compose } from 'recompose'
import moment from 'moment'
import { Query, graphql } from 'react-apollo'
import { withRouter } from 'react-router'

import Notification from '../../components/Notification'
import { Title, H2 } from '../../components/Typography'
import { ProviderCard } from '../../components/Cards'
import { formatENUM, theme } from '../../components/Global'
import { LoadingIndicator, ErrorIndicator } from '../../components/indicators'
import { Button } from '../../components/buttons/Button'
import GET_APPOINTMENT from '../../queries/getAppointment'
import BOOK_APPOINTMENT from '../../mutations/appointments/bookAppointment'
import UpdatePaymentInfo from '../../forms/UpdatePaymentInfoForm'


const Checkbox = styled.div`
  input {
    margin-right: 10px;
  }

  font-size: 16px;
  font-family: ${theme.font.thin};
`

class ConfirmAppointment extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      serverError: false,
      checkPurchase: false,
      isSubmitting: false,
      serverMessage: 'Request Failed',
    }
  }

  bookAppointment = () => {
    
    this.setState({ isSubmitting: true, serverError: false });
    localStorage.setItem("acceptedTerms",this.state.checkPurchase);

    if (!this.state.checkPurchase) { 
      return;
    }
    
    console.log('call');
    // var zone =  moment.tz.guess();
    // localStorage.setItem("localTimezone",zone);
    this.props.bookAppointment({
      name: 'bookAppointment',
      variables: {
        appointmentId: localStorage.getItem("selectedAppointmentId"),
        patientId: localStorage.getItem("patientId"),
        acceptedTerms: localStorage.getItem('acceptedTerms') === 'true',
        hadExistingAppointment: localStorage.getItem('appointmentIsExisting') === 'true',
        isNewPatient: (localStorage.getItem('newPatientIsFirstVisit') === 'true' || false),
        selectedTime : localStorage.getItem('selectedTime')
      }
    }).then((res) => {
      var data = res.data.bookAppointment;
      if (!data || data.statusCode != 200) {
        this.setState({isSubmitting:false, serverError: true, serverMessage: data.exceptions || "Error Processing Request" });
        return;
      }
      this.props.history.push('/appointment-purchased');
    })
    .catch(({ graphQLErrors }) => {
      this.setState({ serverError: true, isSubmitting: false });
    });
  }

  handleChange = () => {
    this.setState({ checkPurchase: !this.state.checkPurchase, isSubmitting: false });
  }

  render() {
    return (

      <div className="container">
        <div className="columns is-centered is-multiline">
          <div className="column is-10">
            <Title className="has-text-centered">Enter Your Payment Info</Title>
            {this.state.serverError && <Notification close={this.closeAlert} open={this.state.serverError} message={formatENUM(this.state.serverMessage)} />}
          </div>
          <div className="column is-4">
            <H2>Payment</H2>
            <UpdatePaymentInfo />
          </div>
          <div className="column is-6">
            <H2  style={{ paddingLeft: '22px' }}>Your Premium Appointment</H2>

            <Query query={GET_APPOINTMENT} variables={{ appointmentId: localStorage.getItem('selectedAppointmentId') }}>
              {({ error, data, loading }) => {

                if (error) {
                  console.error(error);
                  return <ErrorIndicator />
                }
                const payload = data['getAppointment'];
                if (!payload || loading) {
                  return <LoadingIndicator />
                }
                if (payload.statusCode != 200) {
                  return <Notification open={true} message={formatENUM(payload.exceptions)} />
                }
                const appointment = payload['result'];

                return (
                    <ProviderCard
                      edit
                      noEditLink
                      noPadding
                      doctorImage={appointment.hCP.avatar || "https://via.placeholder.com/44x44/222742"}
                      doctorName={appointment.hCP.title + " " + appointment.hCP.firstName + " " + appointment.hCP.lastName}
                      speciality={appointment.hCP.speciality}
                      date={moment.utc(appointment.dateTime).local().format("dddd, MMMM Do")}
                      price={"$" + appointment.cost}
                      time={moment.utc(appointment.dateTime).local().format("hh:mm a")}
                    />
                )
              }}
            </Query>
          </div>
          <div className="column is-10">
            <Checkbox>
              <input
                name="terms"
                onChange={this.handleChange}
                value={this.state.checkPurchase}
                type="checkbox"
                />
              By purchasing this appointment you will gain access to a premium appointment time with your healthcare provider. The purchase of this appointment is not refundable and does not entitle a patient to receive additional time or service beyond the standard appointment. YWait does not provide any medical advice. If you are experiencing a medical emergency, please seek treatment immediately outside of this service.    
            </Checkbox>
          </div>
                   
          <div className="column is-10">
            { (this.state.isSubmitting && !this.state.checkPurchase ) && 
              <Notification style={{display: 'inline-block'}} close={this.closeAlert} 
              open={true} 
              message={'Please agree to the conditions to proceed.'} />}
          </div>

          <div className="column is-4">
            <div className="has-text-centered">
              <Button secondary full disabled={this.state.isSubmitting} onClick={this.bookAppointment}>Purchase Appointment</Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default compose(
  withRouter,
  graphql(BOOK_APPOINTMENT, { name: 'bookAppointment' })
)(ConfirmAppointment);