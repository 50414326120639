import React from 'react'
import moment from 'moment'
import { compose } from "recompose";
import { withRouter } from 'react-router';
import { Query } from "react-apollo";
import { LoadingIndicator, ErrorIndicator } from './indicators'
import { SchedulerCalendarFrame, SchedulerCalendarWeekContainer, 
         SchedulerCalendarWeekHours, SchedulerCalendarWeekDays, SchedulerCalendarWeekDay, 
         SchedulerCalendarWeekDayHour, SchedulerCalendarWeekTimezoneIndicator, SchedulerCalendarHourAppointments,
         SchedulerCalendarHourAppointment, SchedulerCalendarWeekHour } from './SchedulerCalendar.styles'

import GET_PRACTICE_APPOINTMENTS from '../queries/getPracticeAppointments';

class SchedulerCalendarWeekView extends React.Component {

    buildWeek(date, appointments) {

        let week = [];

        var momentObj = moment(new Date(date));

        const daysInWeek = 7;
        const firstDayOfWeekDayOfWeek = parseInt(momentObj.startOf('week').format('d'));
        let daysPrior = (firstDayOfWeekDayOfWeek-1);
        let daysWeek = 0;
        let daysFollowing = 0;

        for(var day=0; day<7; day++) {
            week[day] = {
                date: momentObj.clone(),
                currentMonth: false
            }

            if(daysPrior>=0) {
                week[day].date.subtract(1, 'week').endOf('week').subtract(daysPrior, 'days');
                daysPrior-=1;
            } else if(daysWeek<daysInWeek) {
                week[day].date.startOf('week').add(daysWeek, 'days');
                week[day].currentMonth = true
                daysWeek++
            } else {
                week[day].date.add(1, 'week').startOf('week').add(daysFollowing, 'days');
                daysFollowing++
            }

            week[day].hours = [];
            for(var hour=0; hour<24; hour++) {
                 week[day].hours[hour] = {
                    date: week[day].date.clone()
                } 
                week[day].hours[hour].date.startOf('day').add(hour, 'hours');
                week[day].hours[hour].appointments = appointments.filter(this.props.filterAppointments(week[day].hours[hour].date,'hour'));
            }

            week[day].selectedDay = week[day].date.isSame(new Date(date), 'day')
            
        }

        return week
    }

    buildWeekParams(date, hcps) {
        const params = {
            fromDate: moment(new Date(date)).startOf('week').toISOString(),
            toDate: moment(new Date(date)).endOf('week').toISOString(),
            hcpIds: hcps.map(function(h) { return h.id })
        }

        localStorage.setItem('calendarAppointmentParams', JSON.stringify(params));

        return params;
    }

    render() {
        const { practiceState } = this.props;
        const dayOfWeek = this.props.buildDaysOfWeekNames()
        var queryWeekParams = this.buildWeekParams(practiceState.schedulerSelectedDate, practiceState.schedulerHCPs);

        return (
            <SchedulerCalendarFrame>
                <Query query={GET_PRACTICE_APPOINTMENTS} variables={queryWeekParams} fetchPolicy="network-only">
                {({ error, data, loading }) => {
                    if(error) {
                        console.error(error);
                        return <ErrorIndicator error={error} />
                    }
                    const payload = data['practiceAppointments'];
                    if (!payload || loading) {
                        return <LoadingIndicator />
                    }
                    const practiceAppointments = payload['result'];

                    const appointmentSlots = practiceAppointments.appointmentSlots.map(a => {            

                            return {
                                ...a,
                                dateTime: moment.utc(a.dateTime).local().toDate()
                            }
                        }
                    )

                    var week = this.buildWeek(practiceState.schedulerSelectedDate, appointmentSlots)

                    return (
                        <>
                            <SchedulerCalendarWeekContainer className="header">
                                <SchedulerCalendarWeekHours>
                                </SchedulerCalendarWeekHours>
                                <SchedulerCalendarWeekDays>
                                {week.map(day => 
                                    <SchedulerCalendarWeekDay 
                                        key={day.date} 
                                        className={`dayHeader ${day.selectedDay ? `selected` : ``}`}>
                                        <SchedulerCalendarWeekDayHour
                                            onClick={this.props.dayClick.bind(this, day.date)}>
                                            <span className="dayOfWeek">
                                                {dayOfWeek[day.date.format('d')]}<br/>
                                            </span>
                                            <span className="dayNumber">
                                                {day.date.format('D')}
                                            </span>
                                        </SchedulerCalendarWeekDayHour>
                                    </SchedulerCalendarWeekDay>
                                )}
                                </SchedulerCalendarWeekDays>
                            </SchedulerCalendarWeekContainer>
                            <SchedulerCalendarWeekContainer>
                                <SchedulerCalendarWeekHours className="scrollable">
                                    <SchedulerCalendarWeekTimezoneIndicator>
                                        CST
                                    </SchedulerCalendarWeekTimezoneIndicator>
                                    {week[1].hours.map(hour => 
                                        <React.Fragment key={hour.date}>
                                            {hour.date.format('ha') !== '12am' ?
                                            <SchedulerCalendarWeekHour>
                                                {hour.date.format('ha')}
                                            </SchedulerCalendarWeekHour>
                                            :
                                            <React.Fragment>
                                            </React.Fragment>
                                            }
                                        </React.Fragment>
                                    )}
                                </SchedulerCalendarWeekHours>
                                <SchedulerCalendarWeekDays>
                                {week.map(day => 
                                    <SchedulerCalendarWeekDay 
                                        key={day.date} 
                                        className={day.selectedDay ? `selected` : ``}>
                                        {day.hours.map(hour => 
                                        <SchedulerCalendarWeekDayHour 
                                            key={hour.date}
                                            onClick={this.props.slotClick.bind(this, hour.date)}>
                                            <SchedulerCalendarHourAppointments>
                                                {hour.appointments.map((a, i) => 
                                                    <SchedulerCalendarHourAppointment 
                                                    key={i} 
                                                    onClick={this.props.appointmentClick.bind(this, a)}
                                                    style={{ 
                                                        outlineColor: practiceState.schedulerHCPs.find(hcp => hcp.id === a.hCP.id).color, 
                                                        backgroundColor: practiceState.schedulerHCPs.find(hcp => hcp.id === a.hCP.id).color,
                                                        ...this.props.buildStyleProps(i, a, hour.appointments) }}>
                                                        {`${moment(a.dateTime).format('h:mma')} - ${moment(a.dateTime).add(a.duration, 'minutes').format('h:mma')}`}
                                                    </SchedulerCalendarHourAppointment>
                                                )}
                                            </SchedulerCalendarHourAppointments>
                                        </SchedulerCalendarWeekDayHour>
                                        )}
                                    </SchedulerCalendarWeekDay>
                                )}
                                </SchedulerCalendarWeekDays>
                            </SchedulerCalendarWeekContainer>
                        </>
                    )
                }}
                </Query>
            </SchedulerCalendarFrame>
        )
    }
}

export default compose(
    withRouter
)(SchedulerCalendarWeekView);