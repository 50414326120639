import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import { graphql } from 'react-apollo'

import { theme } from '../../components/Global'
import { YwaitLink } from '../../components/Link'
import GET_PRACTICE_STATE from '../../queries/getPracticeState'
import UPDATE_PRACTICE_STATE from '../../mutations/updatePracticeState'
import { practiceStateGetter } from '../../queries/propGetters/practiceState'
import AccountSettingsProfile from '../../forms/PatientAccountSettingsForm'

import { Title } from '../../components/Typography'

export const Nav = styled.nav`
  width: 90%;
  border-top: 1px solid ${theme.color.gray};

  a {
    padding: 14px 12px;
    display: block;
    border-bottom: 1px solid ${theme.color.gray};

  }
`

class AccountSettings extends React.Component {

    render() {
        return (
            <div>
                <div className="columns">
                    <div className="column is-full v-center">
                        <Title>Account Settings</Title>
                    </div>
                </div>
                <div className="columns spaced">
                    <div className="column is-3">
                       <Nav>
                         <YwaitLink active={1} to="/account-settings">Profile</YwaitLink>
                         <YwaitLink to="/account-payment">Payment</YwaitLink>
                       </Nav>
                    </div>
                    <div className="column is-9">
                      <AccountSettingsProfile></AccountSettingsProfile>
                    </div>
                </div>
            </div>
        )
    }
}

export default compose(
    withRouter,
    graphql(GET_PRACTICE_STATE, { props: practiceStateGetter }),
    graphql(UPDATE_PRACTICE_STATE, { name: 'updatePracticeState' })
)(AccountSettings);