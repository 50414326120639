import gql from 'graphql-tag';

export default gql`
    mutation login($userName:String, $password:String) {
    login(userName:$userName, password:$password) {
        statusCode
        userRole
        exceptions
        loggedInUser{
            id,
            title,
            firstName,
            lastName
          }
        result{
            role
            token
            patient{
                id
            }
            hcp{
                id
            }
            practice{
                id
                accessCode
            }
        }
    }
    }
`;