import gql from 'graphql-tag';

export default gql`
    query practiceAppointments($fromDate: DateTime!, $toDate: DateTime!, $hcpIds: [String]) {
        practiceAppointments(fromDate: $fromDate, toDate: $toDate, hcpIds: $hcpIds) {
            statusCode
            userRole
            exceptions
            loggedInUser{
                id,
                title,
                firstName,
                lastName
              }
            result{
                appointmentSlots {
                    appointmentId
                    dateTime
                    duration
                    status
                    hCP {
                        id
                        firstName
                        lastName
                        title
                        avatar
                    }
                    cost
                    occurance
                    endDate
                }
            }
        }
    }
`;