import styled from "styled-components";
import { theme } from "./Global";

export const SchedulerCalendarFrame = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-color: ${theme.color.light_gray};
  border-style: solid;
  border-width: 1px 0 0 1px;
  background-color: #fff;

  overflow-y: auto;
  height: 720px;

  .loading-indicator {
    margin-top: 100px;
  }
`;

// Month
export const SchedulerCalendarMonthWeek = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  flex: 1 0 0;
`;

export const SchedulerCalendarMonthDay = styled.div`
  flex: 1;
  height: 100%;
  border-color: ${theme.color.light_gray};
  border-style: solid;
  border-width: 0 1px 1px 0;
  background-color: #fff;
  position: relative;

  &:hover {
    cursor: pointer;
    background-color: ${theme.color.almost_white};
  }

  .dayLabel {
    padding: 9px;
    color: ${theme.color.text};

    &.disabledDayLabel {
      color: ${theme.color.light_gray};
    }

    .dayOfWeek {
      font-family: ${theme.font.medium};
      color: ${theme.color.light_gray};
      font-size: 11px;
    }

    .dayNumber {
      font-family: ${theme.font.light};
      font-size: 15px;
      display: inline-block;
      position: relative;
      line-height: 24px;
      z-index: 2;

      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      &.selectedDayNumber {
        display: block;
        content: "";
        width: 24px;
        height: 24px;
        line-height: 1.8em;
        border-radius: 50%;
        background-color: ${theme.color.primary};
        color: #fff;
        text-align: center;
      }
    }
  }
`;

export const SchedulerCalendarMonthDayAppointments = styled.ul`
  list-style-type: none;
  margin-left: 10px;
`;

export const SchedulerCalendarMonthDayAppointment = styled.li`
  font-size: 10px;
  font-family: ${theme.font.medium};

  &.booked {
    opacity: 0.8;
  }

  .dot {
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 4px;
  }
`;

// Week

export const SchedulerCalendarWeekContainer = styled.div`
  display: flex;
  flex-direction: row;

  flex: 0 0 1440px;
  &.header {
    position: sticky;
    top: 0;
    flex: 0 0 120px;
    z-index: 2;
  }

  .dayHeader {
    div {
      flex: 0 0 120px;
      background-color: #fff;
    }
    &.selected {
      background-color: #fff;
      div {
        background-color: rgba(74, 94, 237, 0.04);
      }
    }
  }
`;

export const SchedulerCalendarWeekHours = styled.div`
  flex: 0 0 60px;
  width: 60px;
  border-color: ${theme.color.light_gray};
  border-style: solid;
  border-width: 0 1px 1px 0;
  background-color: #fff;

  &.scrollable {
    height: calc(60px * 24);
  }
`;

export const SchedulerCalendarWeekTimezoneIndicator = styled.div`
  width: 59px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-color: ${theme.color.light_gray};
  border-style: solid;
  border-width: 0 0 1px 0;
  background-color: #fff;
  font-family: ${theme.font.medium};
  font-size: 12px;
  color: ${theme.color.primary};
  position: sticky;
  top: 120px;
`;

export const SchedulerCalendarWeekHour = styled.div`
  height: 60px;
  line-height: 60px;
  font-family: ${theme.font.medium};
  font-size: 13px;
  padding-right: 5px;
  color: ${theme.color.light_gray};
  text-align: right;

  flex: 0 0 60px;

  &:nth-child(2) {
    margin-top: 10px;
  }
`;

export const SchedulerCalendarWeekDays = styled.div`
  flex: 1 0 0;
  display: flex;
  flex-direction: row;
  width: 100%;

  .dayHeader > div {
    padding: 20px;
  }

  > div:not(.dayHeader) {
    height: calc(60px * 24);
  }
`;

export const SchedulerCalendarWeekDay = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  &.selected {
    background-color: rgba(74, 94, 237, 0.04);
  }
`;

export const SchedulerCalendarWeekDayHour = styled.div`
  border-color: ${theme.color.light_gray};
  border-style: solid;
  border-width: 0 1px 1px 0;
  flex: 0 0 60px;

  &:hover {
    cursor: pointer;
    background-color: ${theme.color.almost_white};
  }

  .dayOfWeek {
    font-family: ${theme.font.medium};
    font-size: 13px;
    line-height: 18px;
    color: ${theme.color.light_gray};
  }

  .dayNumber {
    font-family: ${theme.font.medium};
    font-size: 50px;
    line-height: 50px;

    &.selected {
      color: ${theme.color.primary};
    }
  }
`;

export const SchedulerCalendarHourAppointments = styled.div`
  position: relative;

  display: flex;
  flex-direction: row;
`;

export const SchedulerCalendarHourAppointment = styled.div`
  position: absolute;
  width: 100%;
  font-size: 10px;
  padding: 3px 6px;
  font-family: ${theme.font.medium};
  cursor: pointer;
  z-index: 1;
  overflow: hidden;
  white-space: nowrap;
  border: 1px solid white;

  &:hover {
    border: 1px solid black !important;
    z-index: 10;
  }
`;

export const SchedulerCalendarMonthDayMoreAppointments = styled.div`
  position: absolute;
  bottom: 0px;
  line-height: 12px;
  font-family: ${theme.font.medium};
  font-size: 10px;
  color: ${theme.color.primary};
  text-transform: uppercase;
  padding: 3px 6px;
  z-index: 10;
`;
