import React from 'react'
import styledNormalize from 'styled-normalize'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { theme, media } from './Global'
import Favicon from './Favicon'
import Fonts from '../assets/fonts/Fonts'


// your customization as described in the Bulma docs
import '../scss/bulma.scss';

const GlobalStyles = createGlobalStyle`  
  ${styledNormalize}
  
  body, html {
    font-family: 'Pepi-Light';
    font-size: 16px;
    margin: 0;
    padding: 0;
    height: 100%;
  }

  .large-body {
    font-size: 18px;
    line-height: 24px;
  }

  .small-body {
    font-size: 11px;
    line-height: 11px;
    font-family: ${theme.font.heavy};
    letter-spacing: 1.3px;
  }

  b {
    font-family: ${theme.font.bold};
  }

  p {
    font-size: 15px;
    line-height: 20px;
    padding-bottom: 10px;
  }

  .is-error {
    label { color: ${theme.color.danger}; }
    .input, .input:hover { border-color: ${theme.color.danger}; }
  }

  /* temp */
  /* .hcp-card.no-edit-link a {
    display: none;
  } */

`;

export default ({ children }) => (
  <ThemeProvider theme={theme}>
    <React.Fragment>
      <Favicon />
      <Fonts />
      <GlobalStyles />  
      {children}
    </React.Fragment>
  </ThemeProvider>
);