import React from "react";
import { withRouter } from "react-router";
import { compose } from "recompose";
import SchedulerCalendarDayView from "./SchedulerCalendarDayView";
import SchedulerCalendarWeekView from "./SchedulerCalendarWeekView";
import SchedulerCalendarMonthView from "./SchedulerCalendarMonthView";

class SchedulerCalendar extends React.Component {
  render() {
    const { practiceState } = this.props;

    switch (practiceState.schedulerView) {
      case "Day":
        return (
          <SchedulerCalendarDayView
            practiceState={practiceState}
            {...this.props}
          />
        );
      default:
      case "Week":
        return (
          <SchedulerCalendarWeekView
            practiceState={practiceState}
            {...this.props}
          />
        );
      case "Month":
        return (
          <SchedulerCalendarMonthView
            practiceState={practiceState}
            {...this.props}
          />
        );
    }
  }
}

export default compose(withRouter)(SchedulerCalendar);
