import React, { Component } from 'react'
import { compose } from 'recompose';

import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as Yup from 'yup';

import { Title } from '../components/Typography'
import { theme, media } from '../components/Global'
import { Error, Button } from '../components/Forms'


const Page = styled.div`
  h1 { 
    text-align: center; 
    margin-bottom: 60px;
  }
  p {
    padding-bottom: 20px; 
    font-size: 17px;
    line-height: 24px;
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: ${theme.color.light_gray};
    display: block;
    margin: 25px 0;
  }
`

const AccountSettingsFormSubmitBar = styled.div`
  padding-top: 30px;

  .primary button {
    margin-right: 30px;
  }

  .primary a {
    text-decoration: underline;
  }
`;


const Checkbox = styled.div`
  padding-bottom: 20px; 
  max-width: 600px;
  input {
    margin-right: 10px;
  }
`

const Form = styled.form`
  margin: 0 auto;
  p {  
    color: ${theme.color.gray};
    a {
      color: ${theme.color.gray};
      text-decoration: underline;
    }
  }

  .input { margin-bottom: 10px }

  ${Error} { padding-bottom: 5px }

  .button { min-width: 350px; }
  `

const Image = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 120px;
  margin-bottom: 20px;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  float: right;

  ${media.phone`
    float: none;
    margin: 0 auto 30px auto;
  `}
`
const schema = Yup.object().shape({
  policy: Yup.boolean().oneOf([true], 'Terms and Conditions is required.')
});

const defaultValues = {
  policy: false,
  terms: false
}

class PracticeSignUpPolicyForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      serverError: false,
      serverMessage: 'Invalid Request'
    }
  }

  mapSubmitData(values) {
    return {
      policy: values.policy,
    }
  }

  handleSubmit = (values, actions) => {
    this.setState({ serverError: false, successMsg: false });
    this.props.handleSubmit(this.mapSubmitData(values), actions);
  }

  closeAlert = () => {
    this.setState({ serverError: false, successMsg: false });
  }

  render() {
    return (
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-10">
            <Page >
            <Title>YWait’s Pricing Structure and Related Policies</Title>
              {/* <Image alt='' style={{ backgroundImage: 'url(https://via.placeholder.com/150)' }} /> */}
              <p>Our pricing is simple.  We do not believe we should make any money unless your practice is adding incremental revenue.  You determine the fee to charge for access to each premium appointment time on your calendar.  Once a patient books an appointment, YWait collects the credit card payment via Stripe.  We remove a service charge* and a small percentage for the payment processing and transfer fees, and we send the remaining amount back to you via Stripe. Ultimately the vast majority of the fee you charge for access to your calendar gets sent to your Stripe account on a monthly basis.  YWait does not provide refunds to patients**.</p>
              <p>What are you waiting for?</p>
              <p>* Our standard fee is 20%, however, this amount is subject to change based on the various levels of service we offer.  YWait can provide additional services to reduce the work of your office staff, which may increase our service fee percentage.</p>
              <p>** Prior to booking we directly communicate to patients that refunds are not provided.  If a situation arises where a service provider’s schedule changes or the patient cannot be seen, a refund can be provided to the patient.</p>
              <div className='line'></div>


              <Formik
                initialValues={defaultValues}
                validationSchema={schema}
                onSubmit={this.handleSubmit}
                render={({
                  values,
                  errors,
                  isSubmitting,
                  submitCount,
                  handleBlur,
                  handleChange,
                  handleSubmit
                }) => (

                    <Form onSubmit={handleSubmit}>
                      <Checkbox>
                        <input
                          name="policy"
                          type="checkbox"
                          value={values.policy}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />

                        I agree to the Pricing Structure and Related Policies of YWait.  
                          </Checkbox>
                          {(submitCount > 0 && errors.policy) && <Error>{errors.policy}</Error>}

                      <AccountSettingsFormSubmitBar className="columns">
                        <div className="primary column is-half v-center h-left">
                          <Button onClick={this.props.handleGoBack} secondary disabled={isSubmitting}>back</Button>
                          <Button type='submit' primary disabled={isSubmitting}>Create Account</Button>
                        </div>
                      </AccountSettingsFormSubmitBar>
                    </Form>
                  )}
              />
            </Page>
          </div>
        </div>
      </div>
    )
  }
}



export default compose(
)(PracticeSignUpPolicyForm);