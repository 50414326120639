import React from "react";
import moment from "moment";
import { compose } from "recompose";
import { withRouter } from "react-router";
import { Query } from "react-apollo";
import { LoadingIndicator, ErrorIndicator } from "./indicators";
import {
  SchedulerCalendarFrame,
  SchedulerCalendarMonthWeek,
  SchedulerCalendarMonthDay,
  SchedulerCalendarMonthDayAppointments,
  SchedulerCalendarMonthDayAppointment,
  SchedulerCalendarMonthDayMoreAppointments
} from "./SchedulerCalendar.styles";
import GET_PRACTICE_APPOINTMENTS from "../queries/getPracticeAppointments";

class SchedulerCalendarMonthView extends React.Component {
  buildWeeks(date, appointments) {
    let weeks = [];

    var momentObj = moment(new Date(date));

    const daysInMonth = momentObj.daysInMonth();
    const firstDayOfMonthDayOfWeek = parseInt(
      momentObj.startOf("month").format("d")
    );
    const numberOfWeeks = Math.ceil(
      (daysInMonth + firstDayOfMonthDayOfWeek + 1) / 7
    );
    let daysPrior = firstDayOfMonthDayOfWeek - 1;
    let daysMonth = 0;
    let daysFollowing = 0;

    for (var week = 0; week < numberOfWeeks; week++) {
      let currentWeek = [];

      for (var day = 0; day < 7; day++) {
        currentWeek[day] = {
          date: momentObj.clone(),
          currentMonth: false,
          appointments: []
        };

        currentWeek[day].firstWeek = week === 0;

        if (daysPrior >= 0) {
          currentWeek[day].date
            .subtract(1, "months")
            .endOf("month")
            .subtract(daysPrior, "days");
          daysPrior -= 1;
        } else if (daysMonth < daysInMonth) {
          currentWeek[day].date.startOf("month").add(daysMonth, "days");
          currentWeek[day].appointments = appointments.filter(
            this.props.filterAppointments(currentWeek[day].date, "day")
          );
          currentWeek[day].currentMonth = true;
          daysMonth++;
        } else {
          currentWeek[day].date
            .add(1, "months")
            .startOf("month")
            .add(daysFollowing, "days");
          daysFollowing++;
        }

        currentWeek[day].selectedDay = currentWeek[day].date.isSame(
          new Date(date),
          "day"
        );
      }
      weeks.push(currentWeek);
    }

    return weeks;
  }

  buildWeeksParams(date, hcps) {
    const params = {
      fromDate: moment(new Date(date))
        .startOf("month")
        .toISOString(),
      toDate: moment(new Date(date))
        .endOf("month")
        .toISOString(),
      hcpIds: hcps.map(function(h) {
        return h.id;
      })
    };

    localStorage.setItem("calendarAppointmentParams", JSON.stringify(params));

    return params;
  }

  render() {
    const { practiceState } = this.props;
    const dayOfWeek = this.props.buildDaysOfWeekNames();
    var queryMonthParams = this.buildWeeksParams(
      practiceState.schedulerSelectedDate,
      practiceState.schedulerHCPs
    );

    return (
      <SchedulerCalendarFrame>
        <Query
          query={GET_PRACTICE_APPOINTMENTS}
          variables={queryMonthParams}
          fetchPolicy="network-only"
        >
          {({ error, data, loading }) => {
            if (error) {
              console.error(error);
              return <ErrorIndicator error={error} />;
            }
            const payload = data["practiceAppointments"];
            if (!payload || loading) {
              return <LoadingIndicator />;
            }
            const practiceAppointments = payload["result"];

            const appointmentSlots = practiceAppointments.appointmentSlots.map(
              a => {
                return {
                  ...a,
                  dateTime: moment
                    .utc(a.dateTime)
                    .local()
                    .toDate()
                };
              }
            );

            const monthWeeks = this.buildWeeks(
              practiceState.schedulerSelectedDate,
              appointmentSlots
            );

            return (
              <>
                {monthWeeks.map(week => (
                  <SchedulerCalendarMonthWeek key={week[0].date}>
                    {week.map(day => (
                      <SchedulerCalendarMonthDay
                        key={day.date}
                        onClick={this.props.slotClick.bind(this, day.date)}
                      >
                        <div
                          className={`dayLabel${
                            !day.currentMonth ? " disabledDayLabel" : ""
                          }`}
                        >
                          {day.firstWeek ? (
                            <span className="dayOfWeek">
                              {dayOfWeek[day.date.format("d")]}
                              <br />
                            </span>
                          ) : (
                            <span></span>
                          )}
                          <span
                            className={`dayNumber${
                              day.selectedDay ? " selectedDayNumber" : ""
                            }`}
                            onClick={this.props.dayClick.bind(this, day.date)}
                          >
                            {day.date.format("D")}
                          </span>
                        </div>

                        <SchedulerCalendarMonthDayAppointments>
                          {day.appointments
                            .slice(0, day.firstWeek ? 1 : 4)
                            .map(a => (
                              <SchedulerCalendarMonthDayAppointment
                                key={`${a.dateTime}_${a.hCP.id}`}
                                onClick={this.props.appointmentClick.bind(
                                  this,
                                  a
                                )}
                              >
                                <span
                                  className="dot"
                                  style={{
                                    backgroundColor: practiceState.schedulerHCPs.find(
                                      hcp => hcp.id === a.hCP.id
                                    ).color
                                  }}
                                ></span>{" "}
                                {`${moment(a.dateTime).format(
                                  "h:mma"
                                )} - ${moment(a.dateTime)
                                  .add(a.duration, "minutes")
                                  .format("h:mma")}`}
                              </SchedulerCalendarMonthDayAppointment>
                            ))}
                        </SchedulerCalendarMonthDayAppointments>

                        {day.appointments.length - (day.firstWeek ? 1 : 4) >
                        0 ? (
                          <SchedulerCalendarMonthDayMoreAppointments
                            onClick={this.props.dayClick.bind(this, day.date)}
                          >
                            +{" "}
                            {day.appointments.length - (day.firstWeek ? 1 : 4)}{" "}
                            More
                          </SchedulerCalendarMonthDayMoreAppointments>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                      </SchedulerCalendarMonthDay>
                    ))}
                  </SchedulerCalendarMonthWeek>
                ))}
              </>
            );
          }}
        </Query>
      </SchedulerCalendarFrame>
    );
  }
}

export default compose(withRouter)(SchedulerCalendarMonthView);
