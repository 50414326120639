import gql from 'graphql-tag';

export default gql`
    mutation doesUserExist($email:String) {
        doesUserExist(email:$email){
        statusCode
        userRole
        exceptions
    }
}
`;