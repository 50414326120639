import React from 'react'
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import styled from 'styled-components'
import moment from 'moment'
import { Query, graphql } from 'react-apollo';

import { Title } from '../../components/Typography'
import { Button, ToggleButton } from '../../components/buttons/Button'
import { LoadingIndicator, ErrorIndicator } from '../../components/indicators'
import AppointmentCard from '../../components/AppCards'
import YwaitSelect from '../../components/Select'

import { practiceStateGetter } from '../../queries/propGetters/practiceState'
import GET_PRACTICE_STATE from '../../queries/getPracticeState'
import UPDATE_PRACTICE_STATE from '../../mutations/updatePracticeState'
import GET_PRACTICE_DASHBOARD_WITH_PAST_APPOINTMENTS from '../../queries/getPracticeDashboardWithPastAppointments';
import DocImage from '../../assets/dr-avatar.png'

export const PracticeAppointmentsFrame = styled.div`
    .is-one-third .level {
        padding-bottom: 20px;
    }
`;


const CardWrapper = styled.div`
  padding-bottom: 20px;
`

const appointmentsUpcomingFilterChoices = [
    { value: 'all', label: 'All' },
    { value: 'past', label: 'Past' },
    { value: 'upcoming', label: 'Upcoming' }
];

const appointmentsPatientNewFilterChoices = [
    { value: 'all', label: 'All' },
    { value: 'existing', label: 'Existing Patients' },
    { value: 'new', label: 'New Patients' }
];

const appointmentsSortBy = [
    { value: 'dateDesc', label: 'Sort by Date (Newest-Oldest)' },
    { value: 'dateAsc', label: 'Sort by Date (Oldest-Newest)' },
    { value: 'nameAsc', label: 'Sort by Patient Name (A-Z)' },
    { value: 'nameDesc', label: 'Sort by Patient Name (Z-A)' }
];

class PracticeAppointments extends React.Component {

    filterAppointments(pendingAppointments, upcomingAppointments, pastAppointments) {
        const { practiceState } = this.props;

        let filteredAppointments = [...pendingAppointments, ...upcomingAppointments, ...pastAppointments];

        if (practiceState.appointmentsHCPs !== null)
            filteredAppointments = filteredAppointments.filter(a => (a.hCP.id === practiceState.appointmentsHCPs.value));

        if (practiceState.appointmentsUpcomingFilter !== null) {
            switch (practiceState.appointmentsUpcomingFilter) {
                default:
                    break;
                case 'past':
                    filteredAppointments = filteredAppointments.filter( a => ( moment(a.dateTime).toDate() < new Date() ) );
                    break;
                case 'upcoming':
                    filteredAppointments = filteredAppointments.filter( a => ( moment(a.dateTime).toDate() > new Date() ) );
                    break;
            }
        }

        if (practiceState.appointmentsPatientNewFilter !== null) {
            switch (practiceState.appointmentsPatientNewFilter) {
                default:
                    break;
                case 'existing':
                    filteredAppointments = filteredAppointments.filter( a => a.isNewPatient === false );
                    break;
                case 'new':
                    filteredAppointments = filteredAppointments.filter( a => a.isNewPatient === true );
                    break;
            }
        }

        if (practiceState.appointmentsSortBy.value !== null) {
            switch (practiceState.appointmentsSortBy.value) {
                default:
                case 'dateDesc':
                    filteredAppointments = filteredAppointments.sort(function (a, b) {
                        return parseInt(moment(b.dateTime).format('X')) - parseInt(moment(a.dateTime).format('X'));
                    })
                    break;

                case 'dateAsc':
                    filteredAppointments = filteredAppointments.sort(function (a, b) {
                        return parseInt(moment(a.dateTime).format('X')) - parseInt(moment(b.dateTime).format('X'));
                    })
                    break;

                case 'nameAsc':
                    filteredAppointments = filteredAppointments.sort(function (a, b) {
                        return (a.patient.firstName + ' ' + a.patient.lastName).localeCompare(b.patient.firstName + ' ' + b.patient.lastName);
                    })
                    break;

                case 'nameDesc':
                    filteredAppointments = filteredAppointments.sort(function (a, b) {
                        return (b.patient.firstName + ' ' + b.patient.lastName).localeCompare(a.patient.firstName + ' ' + a.patient.lastName);
                    })
                    break;
            }
        }

        return filteredAppointments;
    }

    render() {
        const { practiceState } = this.props;
        return (
            <PracticeAppointmentsFrame className="container">
                <Query query={GET_PRACTICE_DASHBOARD_WITH_PAST_APPOINTMENTS}>
                    {({ error, data, loading }) => {
                        if (error) {
                            console.error(error);
                            return <ErrorIndicator error={error} />
                        }
                        const payload = data['practiceDashboard'];
                        if (!payload || loading) {
                            return <LoadingIndicator />
                        }
                        const practiceDashboard = payload['result'];

                        const upcomingFilterChoices = appointmentsUpcomingFilterChoices.map((c, i) => {
                            return {
                                label: c.label,
                                selected: c.value === practiceState.appointmentsUpcomingFilter,
                                value: c.value
                            }
                        });

                        const patientNewFilterChoices = appointmentsPatientNewFilterChoices.map((c, i) => {
                            return {
                                label: c.label,
                                selected: c.value === practiceState.appointmentsPatientNewFilter,
                                value: c.value
                            }
                        });

                        const hCPsSelectListValues = practiceDashboard.hCPs.map((h, i) => {
                            return {
                                value: h.id,
                                label: h.firstName + ' ' + h.lastName + ', ' + h.title
                            }
                        })

                        const appointments = this.filterAppointments(
                            practiceDashboard.pendingAppointments,
                            practiceDashboard.upcomingAppointments,
                            practiceDashboard.pastAppointments)

                        return (
                            <React.Fragment>
                                <div className="columns">
                                    <div className="column v-center">
                                        <Title>Booked Appointment</Title>
                                    </div>
                                    <div className="column v-center h-right">
                                        <Button href="/calendar" primary>Go To Calendar</Button>
                                    </div>
                                </div>
                                <div className="columns spaced">
                                    <div className="column is-one-third">
                                        <div className="level">
                                            <ToggleButton
                                                values={upcomingFilterChoices}
                                                onChange={(selectedValue) => {
                                                    const newPracticeState = {
                                                        ...practiceState,
                                                        appointmentsUpcomingFilter: selectedValue.value
                                                    };

                                                    this.props.updatePracticeState({
                                                        name: 'updatePracticeState',
                                                        variables: {
                                                            practiceState: newPracticeState
                                                        }
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className="level">
                                            <ToggleButton
                                                values={patientNewFilterChoices}
                                                onChange={(selectedValue) => {
                                                    const newPracticeState = {
                                                        ...practiceState,
                                                        appointmentsPatientNewFilter: selectedValue.value
                                                    };

                                                    this.props.updatePracticeState({
                                                        name: 'updatePracticeState',
                                                        variables: {
                                                            practiceState: newPracticeState
                                                        }
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className="level">
                                            <YwaitSelect
                                                placeholder="Filter by Provider"
                                                value={practiceState.appointmentsHCPs}
                                                onChange={(selectedValue) => {

                                                    const newPracticeState = {
                                                        ...practiceState,
                                                        appointmentsHCPs: selectedValue
                                                    };

                                                    this.props.updatePracticeState({
                                                        name: 'updatePracticeState',
                                                        variables: {
                                                            practiceState: newPracticeState
                                                        }
                                                    });
                                                }}
                                                options={hCPsSelectListValues} />
                                        </div>
                                        <div className="level">
                                            <YwaitSelect
                                                placeholder="Sort by"
                                                value={practiceState.appointmentsSortBy}
                                                onChange={(selectedValue) => {

                                                    const newPracticeState = {
                                                        ...practiceState,
                                                        appointmentsSortBy: selectedValue
                                                    };

                                                    this.props.updatePracticeState({
                                                        name: 'updatePracticeState',
                                                        variables: {
                                                            practiceState: newPracticeState
                                                        }
                                                    });
                                                }}
                                                options={appointmentsSortBy} />
                                        </div>
                                    </div>
                                    <div className="column is-two-thirds">
                                        <div className="level">
                                            {
                                                appointments.length > 0 ?
                                                    appointments.map(appt =>
                                                        (appt.patient !== null ?
                                                            <CardWrapper>
                                                                <AppointmentCard
                                                                    id={appt.appointmentId}
                                                                    bookedAppointmentId={appt.bookedAppointmentId}
                                                                    key={`${appt.appointmentId}`}
                                                                    direction='horizontal'
                                                                    userName={`${appt.patient.firstName} ${appt.patient.lastName}`}
                                                                    phone={appt.patient.cellPhone}
                                                                    email={appt.patient.email}
                                                                    birthday={appt.patient.dOB}
                                                                    appDate={moment.utc(appt.dateTime).local().format('dddd[,] MMM[.] Do [at] h:mma')}
                                                                    doctorImage={appt.hCP.avatar || DocImage}
                                                                    doctorName={`${appt.hCP.firstName} ${appt.hCP.lastName}, ${appt.hCP.title}`}
                                                                    refund='/'
                                                                    upcoming
                                                                    isReplace={appt.hadExistingAppointment}
                                                                    newPatient={(appt.hadExistingAppointment && appt.isNewPatient ? false : appt.isNewPatient)}
                                                                />
                                                            </CardWrapper>
                                                            :
                                                            <CardWrapper>
                                                                <AppointmentCard
                                                                    direction='horizontal'
                                                                    appDate={moment.utc(appt.dateTime).local().format('dddd[,] MMM[.] Do [at] h:mma')}
                                                                    doctorImage={appt.hCP.avatar || DocImage}
                                                                    doctorName={`${appt.hCP.firstName} ${appt.hCP.lastName}, ${appt.hCP.title}`}
                                                                    refund='/'
                                                                    id={appt.appointmentId}
                                                                    bookedAppointmentId={appt.bookedAppointmentId}
                                                                    upcoming
                                                                    isReplace={appt.hadExistingAppointment}
                                                                    newPatient={(appt.hadExistingAppointment && appt.isNewPatient ? false : appt.isNewPatient)}
                                                                />
                                                            </CardWrapper>
                                                        )
                                                    )
                                                    :
                                                    <div>No appointments found.</div>
                                            }
                                        </div>
                                    </div>

                                </div>
                            </React.Fragment>
                        )
                    }
                    }
                </Query>
            </PracticeAppointmentsFrame>
        )
    }
}

export default compose(
    withRouter,
    graphql(GET_PRACTICE_STATE, { props: practiceStateGetter }),
    graphql(UPDATE_PRACTICE_STATE, { name: 'updatePracticeState' })
)(PracticeAppointments);
