
import React from 'react'
import { LoadingSVG } from './LoadingSVG'

const LoadingIndicator = ({ className }) => (
    <div className="column loading-section">
        <section className="columns is-paddingless is-marginless">
            <div className="column">
                <div className="level h-center loading-indicator">
                    <LoadingSVG className="level-item" />
                </div>
            </div>
        </section>
    </div>
)

export default LoadingIndicator