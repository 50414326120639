import gql from 'graphql-tag';

export default gql`
    mutation bookAppointment($patientId:ID!, $appointmentId:ID!, $hadExistingAppointment:Boolean!, $isNewPatient:Boolean!, $acceptedTerms:Boolean!, $selectedTime:String!) {
        bookAppointment(patientId:$patientId, appointmentId:$appointmentId, hadExistingAppointment:$hadExistingAppointment, isNewPatient:$isNewPatient, acceptedTerms:$acceptedTerms, selectedTime:$selectedTime) {
        statusCode
        userRole
        exceptions
        loggedInUser{
            id,
            title,
            firstName,
            lastName
          }
        result{
            appointmentId
            bookedAppointmentId
            parentId
            stripeOrderId
            hCP {
                id
                firstName
                lastName
                title
            }
            patient {
                id
                firstName
                lastName
                title
                cellPhone
            }
            duration
            dateTime
            occurance
            endDate
            cost
            status
            type
         }
}
}
`;