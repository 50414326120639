import React, { Component } from 'react'
import { formatENUM } from '../../components/Global'

import { withRouter } from 'react-router';
import { graphql } from 'react-apollo'
import { compose } from 'recompose';
import PracticeSignUpForm from '../../forms/PracticeSignUpForm'
import PracticeSignUpLocationForm from '../../forms/PracticeSignUpLocationForm'
import PracticeSignUpPolicyForm from '../../forms/PracticeSignUpPolicyForm'
import PracticeSignUpHCPs from '../../forms/PracticeSignUpHCPs'
import PracticeSignUpLinkStripeInfo from '../../forms/PracticeSignUpLinkStripeInfo'
import CREATE_PRACTICE from '../../mutations/practice/createPractice'
import LOGIN from '../../mutations/login'



class PracticeSignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      values: {}
    }
    if (localStorage.getItem('role').toLowerCase() !== "guest")
      window.location.href = '/';

  }

  mapSubmitData(values) {
    return {
      practice: {
        name: this.state.values.name,
        phone: this.state.values.phone,
        address: this.state.values.address,
        address2: this.state.values.address2,
        city: this.state.values.city,
        state: this.state.values.state,
        zip: this.state.values.zip,
        firstName: this.state.values.firstname,
        lastName: this.state.values.lastname,
        email: this.state.values.email,
        newPassword: this.state.values.password
      }
    }
  }

  handleCreateAccount = (values, actions) => {
    var oldValues = this.state.values;
    this.setState({ values: { ...oldValues, ...values }, serverError: false, successMsg: false });

    var submitVariables = this.mapSubmitData(values);
    this.props.createPractice({
      name: 'createPractice',
      variables: submitVariables
    }).then((res) => {

      const payload = res.data['createPractice'];

      if (!payload) {
        actions.setSubmitting(false);
        this.setState({ serverError: true, serverMessage: "Request Failed" });
        return;
      }

      if (payload.statusCode != 200) {
        actions.setSubmitting(false);
        this.setState({ serverError: true, serverMessage: formatENUM(payload.exceptions) });
        return;
      }

      this.props.login({
        name: 'login',
        variables: {
          userName: submitVariables.practice.email,
          password: submitVariables.practice.newPassword,
        }
      }).then((res) => {

        if (!res.data.login) {
          actions.setSubmitting(false);
          this.setState({ serverError: true });
          return;
        }
        localStorage.setItem('token', res.data.login.result.token);
        localStorage.setItem('role', res.data.login.result.role);

        if (res.data.login.result.practice) {
          localStorage.setItem('practiceId', res.data.login.result.practice.id);
          localStorage.setItem('accessCode', res.data.login.result.practice.accessCode);
        }

        var oldValues = this.state.values;
        this.setState({ values: { ...oldValues, ...values }, step: 4 });

      })
        .catch(({ graphQLErrors }) => {
          console.log('graphQLErrors', graphQLErrors);
          actions.setSubmitting(false);
          this.setState({ serverError: true });
        });

    })
      .catch(({ graphQLErrors }) => {
        console.log('graphQLErrors', graphQLErrors);
        actions.setSubmitting(false);
        this.setState({ serverError: true });
      });
  }

  handleStepOne = (values, actions) => {
    this.setState({ values: values, step: 2 });
  }
  handleStepTwo = (values, actions) => {
    var oldValues = this.state.values;
    this.setState({ values: { ...oldValues, ...values }, step: 3 });
  }
  handleStepFour = (values, actions) => {
    this.setState({ step: 5 });
  }
  handleGoBack = (values, actions) => {
    this.setState({ step: this.state.step - 1 });
  }
  render() {
    return (
      <React.Fragment>
        {this.state.step == 1 && <PracticeSignUpForm handleSubmit={this.handleStepOne} values={this.state.values} />}
        {this.state.step == 2 && <PracticeSignUpLocationForm handleSubmit={this.handleStepTwo} values={this.state.values} handleGoBack={this.handleGoBack} />}
        {this.state.step == 3 && <PracticeSignUpPolicyForm handleSubmit={this.handleCreateAccount} handleGoBack={this.handleGoBack} />}
        {this.state.step == 4 && <PracticeSignUpHCPs handleNext={this.handleStepFour} />}
        {this.state.step == 5 && <PracticeSignUpLinkStripeInfo values={this.state.values} />}
      </React.Fragment>
    )

  }
}

export default compose(
  withRouter,
  graphql(LOGIN, { name: 'login' }),
  graphql(CREATE_PRACTICE, { name: 'createPractice' }),
)(PracticeSignUp);

