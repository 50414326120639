import React from "react";
import styled, { css } from "styled-components";
import { theme } from "../Global";
import { Link } from "react-router-dom";

export const ButtonMixin = css`
  font-family: ${theme.font.medium};
  position: relative; 
  height: 60px; 
  border-radius: 0;
  text-transform: uppercase; 
  transition: color 0.3s; 
  -webkit-appearance: none;
  align-items: center;
  border: 1px solid transparent;   
  box-shadow: none;
  display: inline-flex; 
  justify-content: center;  
  position: relative;
  vertical-align: top;
  cursor: pointer;
  width: 100%;
  max-width: 340px;

  &:hover {
    color: #283abd;
  }

  :before {
    content: '';
    width: 0%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: ${props =>
      props.primary ? "" : props.theme.color.primary};  
    position: absolute;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }
  
  span {
    position: relative;
  }
  
  ${props =>
    props.primary &&
    css`
      letter-spacing: 2.4px;
      color: white;
      background-color: ${theme.color.primary};
      border: 1px solid ${theme.color.primary};
      &:hover:before {
        width: 100%;
      }
      :hover {
        color: white;
      }
      :before {
        background-color: #283abd;
      }
    `}

  ${props =>
    props.success &&
    css`
      letter-spacing: 2.4px;
      color: white;
      background-color: ${theme.color.success};
      border: 1px solid ${theme.color.success};
      :hover {
        color: white;
      }
      cursor: default;
    `}

  ${props =>
    props.full &&
    css`
      max-width: 100%;
      justify-content: center;
    `}
  
  ${props =>
    props.secondary &&
    css`
      &:hover:before {
        width: 100%;
      }
      letter-spacing: 2.4px;
      color: ${theme.color.primary};
      background-color: transparent;
      border: 1px solid ${theme.color.primary};
      :hover {
        color: white;
      }
      :before {
        background-color: ${theme.color.primary};
      }
    `}
 
  ${props =>
    props.card &&
    css`
      width: 100%;
      color: ${theme.color.gray};
      background-color: white;
      justify-content: center;
      border: none;
      font-size: 15px;
      text-transform: capitalize;
      :before {
        display: none;
      }
    `}

  ${props =>
    props.active &&
    css`
      color: white;
      background-color: ${theme.color.primary};
      border: 1px solid ${theme.color.primary};
      :hover {
        color: white !important;
      }
      :before {
        background-color: #172bb8;
      }
      span {
        z-index: 1;
      }
    `}

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      /* opacity: 0.5; */
      background-color: ${theme.color.light_gray};
      border: 1px solid ${theme.color.light_gray};
      text-decoration: none;
      color: ${theme.color.white};

      &:hover:before {
        display: none;
      }
    `}


  &.disabled {
    background-color: grey;
    border: 1px solid grey;
    cursor: not-allowed;

    :before {
      display: none;
    }
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  border-top: 1px solid #eeeeee;

  a:last-child {
    border-left: 1px solid #eeeeee;
  }

  ${props =>
    props.direction === "horizontal" &&
    css`
      flex-direction: column;
      width: 30%;
      border-top: none;
      a,
      button {
        border-left: 1px solid #eeeeee;
        height: 50%;
        width: 100%;
      }

      a:last-child,
      button:last-child {
        border-top: 1px solid #eeeeee;
      }
    `};

  ${props =>
    props.toggle &&
    css`
      width: 100%;
      justify-content: space-evenly;

      a,
      button {
        font-size: 11px;
        font-family: ${theme.font.heavy};
        border: 1px solid ${theme.color.primary};
        width: 100%;
        justify-content: center;
        text-transform: capitalize;
        :before {
          display: none;
        }
        :first-child {
          border-right: none;
        }
        :last-child {
          border-left: none;
        }
        :hover {
          color: ${theme.color.primary};
        }
      }
    `};
`;

const StyledButton = styled.a`
  ${ButtonMixin}
`;
const StyledLink = styled(Link)`
  ${ButtonMixin}
`;

export const Button = props => {
  return (
    <StyledButton {...props} className={`button`}>
      <span>{props.children}</span>
    </StyledButton>
  );
};

export const ButtonLink = props => {
  return (
    <StyledLink {...props} className={`button`}>
      <span>{props.children}</span>
    </StyledLink>
  );
};

export * from "./ToggleButton";
