import React from "react";
import { withRouter } from "react-router";
import { compose } from "recompose";
import moment from "moment";
import { Query, Mutation, graphql } from "react-apollo";
import { LoadingIndicator, ErrorIndicator } from "./indicators";
import GET_PRACTICE_STATE from "../queries/getPracticeState";
import UPDATE_PRACTICE_STATE from "../mutations/updatePracticeState";
import { practiceStateGetter } from "../queries/propGetters/practiceState";
import GET_PRACTICE_HCPS from "../queries/getPracticeHcps";

import { Title } from "./Typography";
import { YWaitCalendar } from "./DatePicker";
import { SchedulerViewToggle } from "./SchedulerViewToggle";
import CheckboxSet from "./CheckboxSet";
import SchedulerCalendar from "./SchedulerCalendar";
import PracticeAppointmentForm from "../forms/PracticeAppointmentForm";
import PracticeAppointmentDeleteOccurenceForm from "../forms/PracticeAppointmentDeleteOccurenceForm";
import PracticeAppointmentEditOccurenceForm from "../forms/PracticeAppointmentEditOccuranceForm";
import PracticeAppointmentDetails from "../forms/PracticeAppointmentDetails";
import addCalendarHandlers from "./decorators/addCalendarHandlers";
import {
  SchedulerFrame,
  SchedulerMyDoctors,
  SelectedDateStyle
} from "./Scheduler.styles.js";
const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
  ];
class Scheduler extends React.Component {
  constructor(){
    
  let m = new Date().getMonth();
  var year = new Date().getFullYear();
    var currentMonth = monthNames[m]+ " "+year;
    super();
    this.state= {currentMonth: currentMonth}
 }
  render() {
    const { practiceState } = this.props;
    const modifiers = {
      selectedDate: new Date(practiceState.schedulerSelectedDate)
    };

    let activeFlow = [];
    
    switch (practiceState.schedulerActiveFlow) {
      
      case "createAppointment":
          activeFlow.push(<PracticeAppointmentForm key="createEdit" />);
          break;
      case "editAppointment":
          activeFlow.push(
            <PracticeAppointmentEditOccurenceForm key="editOccurence" />
            );
        break;

      case "deleteAppointmentWithOccurence":
        activeFlow.push(
          <PracticeAppointmentDeleteOccurenceForm key="deleteOccurence" />
        );
        break;

      case "showAppointment":
        activeFlow.push(<PracticeAppointmentDetails key="show" />);
        break;

      case "editOnceAppointment":
      case "editAllAppointment":
          activeFlow.push(<PracticeAppointmentForm key="createEdit" />);
        
        break;
      default:
        break;
    }

    return (
      <SchedulerFrame className="schedulerFrame">
        <div className="columns">
          <div className="column is-three-quarters v-center">
            <div>
              <Title>
                Premium Appointment Calendar
                <small>
                  Set appointment times to be sold by clicking in the calendar.
                </small>
              </Title>
            </div>
          </div>
          <div className="column is-one-quarter v-bottom h-right">
            <Mutation mutation={UPDATE_PRACTICE_STATE}>
              {(updatePracticeState, { data }) => (
                <SchedulerViewToggle
                  values={["Day", "Week", "Month"]}
                  onClick={selectedValue => {
                    const newPracticeState = {
                      ...practiceState,
                      schedulerView: selectedValue
                    };

                    updatePracticeState({
                      name: "updatePracticeState",
                      variables: {
                        practiceState: newPracticeState
                      }
                    });
                  }}
                  currentValue={practiceState.schedulerView}
                />
              )}
            </Mutation>
          </div>
        </div>
        <div className="columns calendars spaced">
          <div className="column is-one-quarter">
            <Mutation mutation={UPDATE_PRACTICE_STATE}>
              {(updatePracticeState, { data }) => (
                <React.Fragment>
                  <style>{SelectedDateStyle}</style>
                  <YWaitCalendar
                    modifiers={modifiers}
                    showOutsideDays={true}
                    disabledDays={[
                      {
                        before: moment().toDate()
                      }
                    ]}
                    onDayClick={(day, modifiers, event) => {
                      const newPracticeState = {
                        ...practiceState,
                        schedulerSelectedDate: Date.parse(day)
                      };

                      updatePracticeState({
                        name: "updatePracticeState",
                        variables: {
                          practiceState: newPracticeState
                        }
                      });
                    }}
                    onMonthChange={(month, modifiers, event) => {
                      this.setState({currentMonth:monthNames[month.getMonth()]+ " "+month.getFullYear()})
                      const newPracticeState = {
                        ...practiceState,
                        schedulerSelectedDate: Date.parse(month)
                      };

                      updatePracticeState({
                        name: "updatePracticeState",
                        variables: {
                          practiceState: newPracticeState
                        }
                      });
                    }}
                  />
                </React.Fragment>
              )}
            </Mutation>
            <Mutation mutation={UPDATE_PRACTICE_STATE}>
              {(updatePracticeState, { data }) => (
                <SchedulerMyDoctors>
                  <div className="level">My Providers</div>
                  <Query query={GET_PRACTICE_HCPS}>
                    {({ error, data, loading }) => {
                      if (error) {
                        console.error(error);
                        return <ErrorIndicator error={error} />;
                      }
                      const payload = data["practiceDashboard"];
                      if (!payload || loading) {
                        return <LoadingIndicator />;
                      }
                      const practiceDashboard = payload["result"];

                      var hCPs = practiceDashboard.hCPs;

                      var checkboxListValues = hCPs.map((h, i) => {
                        return {
                          id: h.id,
                          label:
                            h.firstName + " " + h.lastName + ", " + h.title,
                          checked:
                            practiceState.schedulerHCPs.filter(
                              shcp => shcp.id === h.id
                            ).length > 0,
                          color: practiceState.schedulerColorLegend[i]
                        };
                      });

                      if (!practiceState.schedulerHCPsWereSet) {
                        const newPracticeState = {
                          ...practiceState,
                          schedulerHCPs: checkboxListValues,
                          schedulerHCPsWereSet: true
                        };

                        updatePracticeState({
                          name: "updatePracticeState",
                          variables: {
                            practiceState: newPracticeState
                          }
                        });
                      }

                      return (
                        <React.Fragment>
                          <CheckboxSet
                            values={checkboxListValues}
                            onChange={selectedValue => {
                              let schedulerHCPs = [
                                ...practiceState.schedulerHCPs
                              ];

                              if (
                                schedulerHCPs.filter(
                                  shcp => shcp.id === selectedValue.id
                                ).length === 0
                              )
                                schedulerHCPs.push(selectedValue);
                              else
                                schedulerHCPs.splice(
                                  schedulerHCPs
                                    .map(function(s) {
                                      return s.id;
                                    })
                                    .indexOf(selectedValue.id),
                                  1
                                );

                              const newPracticeState = {
                                ...practiceState,
                                schedulerHCPs: schedulerHCPs
                              };

                              updatePracticeState({
                                name: "updatePracticeState",
                                variables: {
                                  practiceState: newPracticeState
                                }
                              });
                            }}
                          />
                        </React.Fragment>
                      );
                    }}
                  </Query>
                </SchedulerMyDoctors>
              )}
            </Mutation>
          </div>
          <div className="column is-three-quarters">
            <b className="CurrentMonth">{this.state.currentMonth}</b>
            <SchedulerCalendar practiceState={practiceState} {...this.props} />
          </div>
        </div>
        {activeFlow}
      </SchedulerFrame>
    );
  }
}

export default compose(
  withRouter,
  graphql(GET_PRACTICE_STATE, { props: practiceStateGetter }),
  graphql(UPDATE_PRACTICE_STATE, { name: "updatePracticeState" }),
  addCalendarHandlers
)(Scheduler);
