import React, { Component} from 'react'
import styled from 'styled-components'
import * as Yup from 'yup';
import { Formik } from 'formik'
import { formatENUM } from '../components/Global'

import { Field, Input, Error, Button } from '../components/Forms'
import { graphql } from 'react-apollo'
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import Notification from '../components/Notification' 

import FORGOT_PASSWORD from '../mutations/forgotPassword'

const Form = styled.form` 
  margin: 0 auto;
  
  .field {
    max-width: 410px;
    margin: 0 auto;
  }

  p {   
    margin: 0 auto;
    max-width: 410px;
    padding-bottom: 25px;
  }

  .button {
    margin-top: 40px;
  }
`

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address.')
    .required('Email is required.')
});

class PracticeForgotPasswordForm extends Component {
  constructor(props) {
    super(props);      
    this.state = { serverError: false }
  }

  handleSubmit = (values, actions) => {
    this.props.forgotPassword({
      name: 'forgotPassword', 
      variables: {
        email: values.email
      }
    }).then((res) => {
      if (res.data.forgotPassword) {
        this.props.history.push('/check-email', { email: values.email });
      }
    })
    .catch(({ graphQLErrors }) => {
      actions.setSubmitting(false);
      this.setState({ serverError: true });      
    });
  }
  
  closeAlert = () => {
    this.setState({ serverError: false });
  }
  
  render() {             
    return (
      <Formik
        initialValues={{email: ''}}   
        validationSchema={schema}      
        onSubmit={this.handleSubmit}
        render={({  
          values,
          errors,            
          isSubmitting,
          submitCount,
          handleBlur,
          handleChange,
          handleSubmit      
        }) => (
                      
          <Form onSubmit={handleSubmit}>

            {this.state.serverError && <Notification close={this.closeAlert} open={formatENUM(this.state.serverError)} message="Server Error" />} 

            <p>We'll email you a link to make a brand new password</p>

            <Field label="Email Address"  className={submitCount > 0 && errors.email ? 'is-error' : ''}>
              <Input className="input" 
                placeholder="Email"
                name="email"
                type="text"
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {(submitCount > 0 && errors.email) && <Error>{errors.email}</Error>}
            </Field>
                              
            <div className="has-text-centered">
              <Button type='submit' secondary disabled={isSubmitting}>Send Verification</Button>
            </div>  
                        
          </Form>      
        )}      
      />             
    )
  }
}

export default compose(
  withRouter,
  graphql(FORGOT_PASSWORD, { name: 'forgotPassword' }),
)(PracticeForgotPasswordForm);