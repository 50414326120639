import React from 'react'
import { graphql } from 'react-apollo'
import { withRouter } from 'react-router';

import { ListItem, SelectorItem, ListTitle, Price, Time } from './Styles'
import moment from 'moment'
import { compose } from "recompose";

class TimeList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serverError: false,
      serverMessage: '',
    }
  }

  render() {
    const handleClick = (id,time) => {
      localStorage.setItem('selectedTime', time);
      localStorage.setItem('selectedAppointmentId', id);
      this.setState({ selectedAppointmentId: id });
      this.props.history.push('/replace-current-apt');
    }

    const Item = ({ id, time, price, }) => (
      <SelectorItem onClick={handleClick.bind(this, id,time)} id={id} selected={this.state.selectedAppointmentId === id}>
        <Time>{time}</Time>
        <Price>{price}</Price>
      </SelectorItem>
    );

    return (
      <ListItem selected={moment(localStorage.getItem('selectedDate')).startOf('day').isSame(moment(this.props.date).startOf('day'))}>
        <ListTitle>{this.props.title}</ListTitle>
        {this.props.appointments.map((item, index) => {
          return <Item
            id={item.appointmentId}
            key={"item-" + index}
            time={moment.utc(item.dateTime).local().format("hh:mm a")}
            price={"$" + item.cost}
          />
        })}
      </ListItem>
    )
  }
}

export default compose(
  withRouter
)(TimeList);
