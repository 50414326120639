import React, { Component } from 'react'
import styled from 'styled-components'
import StripeElement from '../components/StripeElement'
import { withRouter } from 'react-router'
import { Query, graphql } from 'react-apollo'
import { compose } from 'recompose'

import Notification from '../components/Notification'
import { theme, formatENUM } from '../components/Global'
import { Button } from '../components/buttons/Button'
import GET_PATIENT_DASHBOARD from '../queries/getPatientDashboard';
import UPDATE_PATIENT from '../mutations/patient/updatePatient'
import GET_PAYMENT_CARDS from '../queries/getPatientPaymentMethods'
import { patientDashboardGetter } from '../queries/propGetters/patientDashboard'
import { PaymentCard } from '../components/Cards'
import { LoadingIndicator, ErrorIndicator } from '../components/indicators'

export const Nav = styled.nav`
  width: 90%;
  border-top: 1px solid ${theme.color.gray};

  a {
    padding: 14px 12px;
    display: block;
    border-bottom: 1px solid ${theme.color.gray};

  }
`

class UpdatePaymentInfo extends Component {
  constructor(props) {
    super(props);
    this.refetchCB = () => { };
    this.state = {
      serverError: false,
      refetch: null,
      editPaymentInfo: false
    }
  }


  handleEditClick = () => {
    this.setState({ editPaymentInfo: true });
  }
  render() {

    var handleUdatePaymentSource = (token) => {
      const { patientDashboard } = this.props
      this.setState({ serverError: false });

      return this.props.updatePatient({
        name: 'updatePatient',
        variables: {
          patient: { defaultStripeCardToken: token },
          patientId: patientDashboard.result.patient.id
        }
      }).then((res) => {
        var data = res.data.updatePatient;

        if (!data || data.statusCode != 200) {
          var exception = data.exceptions || "Error Processing Request";
          this.setState({ isSubmitting: false, serverError: true, serverMessage: exception });
         return Promise.reject(exception);
        }
        this.refetchCB().then(() => {
          this.setState({ editPaymentInfo: false });
        });
      })
        .catch((exception) => {
          console.error(exception);
          this.setState({ serverError: true, serverMessage: 'An error has occured' });
          return Promise.reject(exception);
        });

    }

    return (
      <Query query={GET_PAYMENT_CARDS}>
        {({ loading, error, data, refetch, networkStatus }) => {
          if (error) {
            console.error(error);
            return <ErrorIndicator />
          }
          const payload = data['patientPaymentMethods'];
          if (!payload || loading) {
            return <LoadingIndicator />
          }

          if (payload.statusCode != 200) {
            return <Notification open={true} message={formatENUM(payload.exceptions)} />
          }

          if (this.state.editPaymentInfo) {
            return (<StripeElement getToken={handleUdatePaymentSource} submitButtonText="Update Payment Info" />)
          }
          this.refetchCB = refetch;

          const cards = payload['result'];
          if (cards.length < 1) {
            return (<div><Notification open={true} message="No cards Found" />
              <Button full onClick={this.handleEditClick}>Add Card</Button>
            </div>);
          }
          return (<PaymentCard
            edit={this.handleEditClick}
            brand={cards[0].brand}
            name={payload.loggedInUser.firstName + " " + payload.loggedInUser.lastName}
            last4={cards[0].last4}
            address_zip={cards[0].address_zip}
          />
          )
        }}
      </Query>
    )
  }
}


export default compose(
  withRouter,
  graphql(GET_PATIENT_DASHBOARD, { props: patientDashboardGetter }),
  graphql(UPDATE_PATIENT, { name: 'updatePatient' }),
)(UpdatePaymentInfo);