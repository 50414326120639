import React from "react";
import { withRouter } from "react-router";
import { compose } from "recompose";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Query, graphql } from "react-apollo";

import { Title, H2 } from "../../components/Typography";
import { ProviderCard } from "../../components/Cards";
import { LoadingIndicator, ErrorIndicator } from "../../components/indicators";

import GET_PRACTICE_STATE from "../../queries/getPracticeState";
import UPDATE_PRACTICE_STATE from "../../mutations/updatePracticeState";
import { practiceStateGetter } from "../../queries/propGetters/practiceState";
import GET_PRACTICE_HCPS from "../../queries/getPracticeHcps";
import PracticeProviderForm from "../../forms/PracticeProviderForm";

import DocImage from "../../assets/dr-avatar.png";

export const ProvidersList = styled.div``;

const CardWrapper = styled.div`
  padding-bottom: 20px;
`;

class Providers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      successMessage: "",
      serverError: false
    };
  }

  handleHCPResult(state) {
    this.setState(state);
  }

  render() {
    return (
      <div>
        <div className="columns">
          <div className="column is-full v-center">
            <Title>My Providers</Title>
          </div>
        </div>
        <Query query={GET_PRACTICE_HCPS}>
          {({ error, data, loading }) => {
            if (error) {
              console.error(error);
              return <ErrorIndicator error={error} />;
            }
            const payload = data["practiceDashboard"];
            if (!payload || loading) {
              return <LoadingIndicator />;
            }
            const practiceDashboard = payload["result"];

            return (
              <>
                <div className="columns spaced">
                  <div className="column is-4">
                    <ProvidersList>
                      {practiceDashboard.hCPs.map(hcp => (
                        <CardWrapper key={hcp.id}>
                          <Link to={`/provider/${hcp.id}`}>
                            <ProviderCard
                              isDoctorName
                              doctorImage={hcp.avatar || DocImage}
                              doctorName={`${hcp.firstName} ${hcp.lastName}, ${hcp.title}`}
                            />
                          </Link>
                        </CardWrapper>
                      ))}
                    </ProvidersList>
                  </div>
                </div>
                <div className="columns spaced">
                  <div className="column is-full">
                    <H2>Add New Provider</H2>
                    <PracticeProviderForm
                      action="create"
                      creatHcpResultHandler={this.handleHCPResult.bind(this)}
                      practiceId={practiceDashboard.practice.id}
                    />
                  </div>
                </div>
              </>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default compose(
  withRouter,
  graphql(GET_PRACTICE_STATE, { props: practiceStateGetter }),
  graphql(UPDATE_PRACTICE_STATE, { name: "updatePracticeState" })
)(Providers);
