import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { compose, renderComponent } from "recompose";
import { withRouter } from "react-router";
import { ProviderCard } from "./Cards";
import { theme, media, formatENUM } from "./Global";
import GET_HCPS_WITH_APPOINTMENT_SLOTS from "../queries/getHCPsWithAppointmentSlots";
import { Query } from "react-apollo";
import { LoadingIndicator, ErrorIndicator } from "./indicators";
import Notification from "./Notification";

import DocImage from "../assets/dr-avatar.png";
import Modal from "./modals/Modal";
import ListInsurances from "./ListInsurances";

const StyleChooseProvider = styled.div`
  .provider-card {
    margin-bottom: 20px;
    max-width: 387px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
  }

  .button {
    min-width: 280px;
  }
`;

const Paragraph = styled.p`
  padding-top: 10px;
  text-align: center;
  font-size: 17px;
  line-height: 24px;

  a {
    color: ${theme.color.text};
    text-decoration: underline;
  }

  ${media.phone`
    max-width: 330px;
    margin: 0 auto;
  `}
`;

class ChooseProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serverError: false,
      serverMessage: ""
    };
  }

  closeAlert = () => {
    this.setState({ serverError: false });
  };

  handleClick = id => {
    localStorage.setItem("selectedHcpId", id);
    this.setState({ selectedHcpId: id });
    this.props.history.push("/select-date");
  };

  popupClick = e => {
    e.preventDefault();
    this.setState({ displayPopup: true });
  };

  toggleDisplayPopup = () => {
    this.setState(previousState => ({
      displayPopup: !previousState.displayPopup
    }));
  };

  render() {
    return (
      <Query
        query={GET_HCPS_WITH_APPOINTMENT_SLOTS}
        variables={{ practiceId: localStorage.getItem("selectedPracticeId") }}
      >
        {({ error, data, loading }) => {
          if (error) {
            console.error(error);
            return <ErrorIndicator />;
          }
          const payload = data["getPracticeHCPsWithAppointmentSlots"];
          if (!payload || loading) {
            return <LoadingIndicator />;
          }
          if (payload.statusCode != 200) {
            return (
              <Notification
                open={true}
                message={formatENUM(payload.exceptions)}
              />
            );
          }
          if (this.state.displayPopup) {
            return (
              <Modal
                children={<ListInsurances />}
                isOpen={true}
                onClose={this.toggleDisplayPopup}
                large={true}
              />
            );
          }
          const practiceHcps = payload["result"];
          if (!practiceHcps || !practiceHcps.length)
            return (
              <>
                <Notification
                  open={true}
                  message="No available provider found."
                />
              </>
            );

          return (
            <StyleChooseProvider>
              {practiceHcps.map((doc, key) => {
                return (
                  <ProviderCard
                    onClick={this.handleClick.bind(this, doc.id)}
                    className="provider-card"
                    isDoctorName
                    selected={this.state.selectedHcpId == doc.id}
                    key={key}
                    doctorImage={doc.avatar || DocImage}
                    doctorName={
                      doc.firstName + " " + doc.lastName + ", " + doc.title
                    }
                    practiceName={doc.practice.name}
                    id={doc.id}
                  />
                );
              })}
              <Paragraph>
                <a href="" onClick={this.popupClick}>
                  List of Accepted Insurances
                </a>
              </Paragraph>
              <Paragraph>
                Not the right practice?{" "}
                <Link to="/access-code">Try a different Access Code</Link>
              </Paragraph>
            </StyleChooseProvider>
          );
        }}
      </Query>
    );
  }
}

export default compose(withRouter)(ChooseProvider);
