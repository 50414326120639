import React, { Component } from "react";
import styled from "styled-components";
import Cleave from "cleave.js/react";
import * as Yup from "yup";
import { withRouter } from "react-router";
import { graphql } from "react-apollo";
import { compose } from "recompose";
import { Formik } from "formik";

import YwaitSelect from "../components/Select";
import Notification from "../components/Notification";
import UPDATE_PRACTICE from "../mutations/practice/updatePractice";
import GET_PRACTICE_DASHBOARD from "../queries/getPracticeDashboard";
import { formatENUM } from "../components/Global";
import { theme, statesOptions } from "../components/Global";
import { Field, Input, InputWrapper, Error, Button } from "../components/Forms";
import { practiceDashboardGetter } from "../queries/propGetters/practiceDashboard";

const Panel = styled.div`
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid ${theme.color.light_gray};
  .field {
    position: relative;
  }
`;

const Title = styled.h3`
  font-size: 36px;
  line-height: 44px;
  font-family: ${theme.font.rudi_thin};
  margin-bottom: 20px;
`;

const PasswordLink = styled.a`
  position: absolute;
  right: 0;
  top: 8px;
  text-decoration: underline;
`;

const AccountSettingsFormSubmitBar = styled.div`
  padding-top: 50px;

  .primary button {
    margin-right: 30px;
    /* width: 220px; */
  }

  .primary a {
    text-decoration: underline;
  }
`;

const schema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[a-zA-Z'\s]+$/, "First Name is not in the correct format.")
    .max(20, "First Name is too long (max 20 characters)")
    .required("First Name is required."),
  lastName: Yup.string()
    .matches(/^[a-zA-Z'\s]+$/, "Last Name is not in the correct format.")
    .max(20, "Last Name is too long (max 20 characters)")
    .required("Last Name is required."),
  email: Yup.string()
    .email("Email is not in the correct format.")
    .max(30, "Email is too long (max 30 characters)")
    .required("Email is required."),
  name: Yup.string()
    .matches(
      /^[a-zA-Z0-9\&'.,\s]+$/,
      "Practice Name is not in the correct format."
    )
    .max(50, "Practice Name is too long (max 50 characters)")
    .required("Practice Name is required."),
  phone: Yup.string()
    .matches(/^[0-9\-.()\s]+$/, "Phone Name is not in the correct format.")
    .max(20, "Phone is too long (max 20 characters)")
    .required("Phone is required."),
  address: Yup.string()
    .matches(/^[a-zA-Z0-9'.,\s]+$/, "Address is not in the correct format.")
    .max(50, "First Name is too long (max 50 characters)")
    .required("Address is required."),
  city: Yup.string()
    .matches(/^[a-zA-Z'\s]+$/, "City is not in the correct format.")
    .max(30, "City is too long (max 30 characters)")
    .required("City is required."),
  zip: Yup.string()
    .matches(/^[0-9\-.()\s]+$/, "Zip is not in the correct format.")
    .max(10, "Zip is too long (max 10 characters)")
    .required("Zip is required.")
});

class PracticeAccountSettingsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      serverError: false,
      changePassword: false,
      serverMessage: ""
    };
  }

  mapSubmitData(values) {
    return {
      practice: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        name: values.name,
        phone: values.phone,
        address: values.address,
        address2: values.address2,
        city: values.city,
        state: values.state.value,
        zip: values.zip,
        newPassword: values.newPassword,
        oldPassword: values.oldPassword
      },
      practiceId: this.props.practiceDashboard.result.practice.id
    };
  }

  catchErrors = graphQLErrors => {
    this.setState({ serverError: true, serverMessage: graphQLErrors });
  };

  handleSubmit = (values, actions) => {
    this.setState({ serverError: false });

    this.props
      .updatePractice({
        name: "updatePractice",
        variables: this.mapSubmitData(values)
      })
      .then(res => {
        window.scrollTo(0, 0);
        actions.setSubmitting(false);

        var data = res.data.updatePractice;
        if (!data || data.statusCode != 200) {
          this.setState({
            isSubmitting: false,
            successMsg: false,
            serverError: true,
            serverMessage: data.exceptions || "Error Processing Request"
          });
          return;
        }
        this.setState({ successMsg: true });
      })
      .catch(({ graphQLErrors }) => {
        actions.setSubmitting(false);
        console.error(graphQLErrors);
        this.setState({
          serverError: true,
          serverMessage: "An error has occured"
        });
      });
  };

  closeAlert = () => {
    this.setState({ serverError: false, successMsg: false });
  };

  closeAlert = () => {
    this.setState({ serverError: false, successMsg: false });
  };

  getInitialValues(practice) {
    return {
      firstName: practice.firstName,
      lastName: practice.lastName,
      email: practice.email,
      name: practice.name,
      phone: practice.phone,
      address: practice.address,
      address2: practice.address2,
      city: practice.city,
      state: statesOptions.filter(s => s.value === practice.state),
      zip: practice.zip,
      oldPassword: "",
      newPassword: ""
    };
  }

  handleChangePassword = () => {
    this.setState({ changePassword: true });
  };

  render() {
    const { practiceDashboard } = this.props;

    if (!practiceDashboard) return null;

    return (
      <Formik
        initialValues={this.getInitialValues(practiceDashboard.result.practice)}
        validationSchema={schema}
        onSubmit={this.handleSubmit}
        render={({
          values,
          errors,
          isSubmitting,
          submitCount,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldTouched
        }) => (
          <form onSubmit={handleSubmit}>
            {this.state.successMsg && (
              <Notification
                success
                close={this.closeAlert}
                open={this.state.successMsg}
                message="Your Account Settings were updated."
              />
            )}
            {this.state.serverError && (
              <Notification
                close={this.closeAlert}
                open={this.state.serverMessage}
                message={
                  formatENUM(this.state.serverMessage) ||
                  "Error Processing Request"
                }
              />
            )}
            <Title>Profile</Title>
            <Panel>
              <div className="columns">
                <div className="column is-3">
                  <Field label="Contact First Name">
                    <Input
                      name="firstName"
                      type="text"
                      value={values.firstName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {submitCount > 0 && errors.firstName && (
                      <Error>{errors.firstName}</Error>
                    )}
                  </Field>
                </div>
                <div className="column is-3 is-offset-1">
                  <Field label="Contact Last Name">
                    <Input
                      name="lastName"
                      type="text"
                      value={values.lastName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {submitCount > 0 && errors.lastName && (
                      <Error>{errors.lastName}</Error>
                    )}
                  </Field>
                </div>
              </div>
            </Panel>

            <Panel>
              <div className="columns">
                <div className="column is-4">
                  <Field label="Contact Email">
                    <Input
                      name="email"
                      type="text"
                      value={values.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {submitCount > 0 && errors.email && (
                      <Error>{errors.email}</Error>
                    )}
                  </Field>
                </div>
                <div className="column is-3 is-offset-1">
                  <Field label="New Password">
                    {!this.state.changePassword && (
                      <PasswordLink
                        onClick={this.handleChangePassword.bind(this)}
                      >
                        Change
                      </PasswordLink>
                    )}
                    <Input
                      disabled={!this.state.changePassword}
                      className="input"
                      name="newPassword"
                      type="password"
                      value={values.newPassword}
                      onChange={handleChange}
                    />
                    {submitCount > 0 && errors.newPassword && (
                      <Error>{errors.newPassword}</Error>
                    )}
                  </Field>
                </div>
                <div className="column is-3 is-offset-1">
                  {this.state.changePassword && (
                    <Field label="Old Password">
                      <Input
                        className="input"
                        name="oldPassword"
                        type="password"
                        value={values.oldPassword}
                        onChange={handleChange}
                      />
                      {submitCount > 0 && errors.oldPassword && (
                        <Error>{errors.oldPassword}</Error>
                      )}
                    </Field>
                  )}
                </div>
              </div>
            </Panel>

            <Title>Office Info</Title>

            <Panel>
              <div className="columns">
                <div className="column is-5">
                  <Field label="Practice Name">
                    <Input
                      name="name"
                      type="text"
                      value={values.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {submitCount > 0 && errors.name && (
                      <Error>{errors.name}</Error>
                    )}
                  </Field>
                </div>
                <div className="column is-5">
                  <Field label="Practice Phone Number">
                    <InputWrapper>
                      <Cleave
                        name="phone"
                        type="text"
                        value={values.phone}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        options={{
                          numericOnly: true,
                          blocks: [0, 3, 3, 4],
                          delimiters: ["(", ") ", "-"]
                        }}
                      />
                    </InputWrapper>
                    {submitCount > 0 && errors.phone && (
                      <Error>{errors.phone}</Error>
                    )}
                  </Field>
                </div>
              </div>
              <div className="columns">
                <div className="column is-5">
                  <Field label="Practice Address">
                    <Input
                      name="address"
                      type="text"
                      value={values.address}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {submitCount > 0 && errors.address && (
                      <Error>{errors.address}</Error>
                    )}

                    <br />
                    <br />

                    <Input
                      name="address2"
                      type="text"
                      value={values.address2}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {submitCount > 0 && errors.address2 && (
                      <Error>{errors.address2}</Error>
                    )}
                  </Field>
                </div>
              </div>
              <div className="columns">
                <div className="column is-4">
                  <Field label="City">
                    <Input
                      name="city"
                      type="text"
                      value={values.city}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {submitCount > 0 && errors.city && (
                      <Error>{errors.city}</Error>
                    )}
                  </Field>
                </div>
                <div className="column is-4">
                  <Field label="State">
                    <YwaitSelect
                      name="state"
                      value={values.state}
                      onFormikChange={setFieldValue}
                      onFormikBlur={setFieldTouched}
                      options={statesOptions}
                    />
                  </Field>
                  {submitCount > 0 && errors.state && (
                    <Error>{errors.state}</Error>
                  )}
                </div>
                <div className="column is-4">
                  <Field label="Zip Code">
                    <Input
                      name="zip"
                      type="text"
                      value={values.zip}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {submitCount > 0 && errors.zip && (
                      <Error>{errors.zip}</Error>
                    )}
                  </Field>
                </div>
              </div>
            </Panel>

            <AccountSettingsFormSubmitBar className="columns">
              <div className="primary column is-half v-center h-left">
                <Button type="submit" primary disabled={isSubmitting}>
                  Save
                </Button>
              </div>
            </AccountSettingsFormSubmitBar>
          </form>
        )}
      />
    );
  }
}

export default compose(
  withRouter,
  graphql(GET_PRACTICE_DASHBOARD, { props: practiceDashboardGetter }),
  graphql(UPDATE_PRACTICE, { name: "updatePractice" })
)(PracticeAccountSettingsForm);