import React from 'react'
import styled from 'styled-components'
import { YwaitLink } from '../Link'
import { theme, media } from '../Global'


const StyledLogOutMenu = styled.nav`  
  .enter-code {
    ${media.tablet`
      padding-left: 55px;    
    `}
  }

  ${media.phone`
    height: 100%;
    display: flex;

    a {
      border-left: 1px solid #EEEEEE;
      width: 110px;
      height: 100%;    
      display: flex;
      padding: 0 20px;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
  `};
`

export const LogOutMenu = () => (
  <StyledLogOutMenu>
    <YwaitLink to="/sign-in">Patient Login</YwaitLink>
    <YwaitLink className="enter-code" to="/admin">Provider Login</YwaitLink>
  </StyledLogOutMenu>
)