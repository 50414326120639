import React, { Component } from 'react'
import { Button, ButtonGroup } from './Button'

export class ToggleButton extends Component { 
  render() {   
    return (   
      <ButtonGroup toggle>
        {
            this.props.values.map(value => 
              <Button key={value.value}
                active={value.selected}
                onClick={this.props.onChange.bind(this, value)}>
                  {value.label}
              </Button>
            )
        }
      </ButtonGroup>        
    )
  }

} 
