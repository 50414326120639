import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { compose } from 'recompose';
import { Link } from 'react-router-dom'
import { Button } from '../components/buttons/Button'
import { Title } from '../components/Typography'
import { theme, media } from '../components/Global'

import PartnerStripe from '../assets/stripe/partner-stripe.png'
import StripeWorks from '../assets/stripe/stripe-works.png'
import StripeSignup from '../assets/stripe/stripe-signup.png'

const Page = styled.div`
  h1 { 
    text-align: center; 
    margin-bottom: 60px;
  }

  h2 {
    margin-top: 40px;
    text-align: center;
    margin-bottom: 12px;
    font-size: 40px;
    line-height: 45px;
    font-family: ${theme.font.bold};
    font-weight: bold;
  }

  .column.is-5 {
    margin-top: 50px;
  }

  p {
    padding-bottom: 20px; 
    font-size: 17px;
    line-height: 24px;
  }
`

const Image = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 280px;
  
  ${ (props) => props.left && css`
    background-position: left;
    /* margin-right: 60px; */
  `}
  
  ${ (props) => props.right && css`
    /* margin-left: 60px; */
  `}

  ${media.phone`
    width: 280px;
    height: 280px;
    float: none;
    margin: 0 auto 30px auto;
  `}
`

const InfoContent = styled.div`
  /* margin-top: 60px; */

  ${media.phone`
    margin-top: 30px;
    p {
      text-align: center;
      padding-bottom: 0;
    }
  `}
`

const StripeContent = styled.div`
  max-width: 600px;
  margin: 0 auto;
  text-align: center; 
`

const BackToTop = styled.div`
  text-align: center; 
  margin-top: 60px;
`
class PracticeSignUpLinkStripeInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step:1,
      values:{}
    }
  }

  handleConnectWithStripe = ()=>{
    var clientId = "ca_EUFKJhPShhgIucycEvbAOxQEOmo3kaml";
    var redirectURL =window.location.origin + "/stripe_hooks/express_callback";
    
    var values = this.props.values;
    if(Object.keys(values).length > 0)
  {
    var params = {
      "stripe_user[business_type]":"company",
      "stripe_user[business_name]":values.name,
      "stripe_user[first_name]": values.firstname,
      "stripe_user[last_name]":values.lastname,
      "stripe_user[email]":values.email,
      "stripe_user[country]":"US",
      "stripe_user[phone_number]":values.phone,
      "stripe_user[city]":values.city,
      "stripe_user[state]":values.state,
      "stripe_user[zip]":values.zip,
    }
  }
  
    window.location.href = 'https://connect.stripe.com/express/oauth/authorize?redirect_uri='+ redirectURL+'&client_id='+clientId+'&'+this.serialize(params);
  }
  serialize = function(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

  scrollTo = (e) => {
    e.preventDefault();
    var root = document.getElementById('root');
    root.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
  render() {
    return (
      <Page>
          <Title>Link YWait to your Stripe Account</Title>

          <StripeContent>
            <p>YWait partners with Stripe to process payments from patients and to transfer the payments back to you.</p>
            <p>Our goal at YWait is to make accessing premium appointment times as easy as possible for both you and your patients.  We partner with Stripe to reduce time and to simplify payment processing.  We all dislike waiting, so YWait!  Sign up for Stripe, and let’s get going.</p>

            <Button onClick={this.handleConnectWithStripe} secondary>Connect With Stripe</Button>
            <br/>
            <br/>
            <a href="/account-created">Skip this step, I'll add this info later</a>
          </StripeContent>

            <div className="columns is-centered is-vcentered is-multiline">
              <div className="column is-5">     
                <InfoContent>
                  <h2>Why we partner<br /> with Stripe: </h2>
                  <p>We have chosen Stripe as our partner for payment processing because of the ability to integrate end to end payment processing.   Stripe has an easy account set-up process, and within minutes you will be able to accept payments from your patients through YWait.  With millions of business partners, Stripe is the premier platform for end to end payment processing.  </p>
                </InfoContent>
              </div>
              <div className="column is-5">  
                <Image right style={{ backgroundImage: `url(${PartnerStripe})` }} />
              </div>
              <div className="column is-5">  
                <Image left style={{ backgroundImage: `url(${StripeWorks})`, height: '340px' }} />
              </div>
              <div className="column is-5">    
                <InfoContent>
                  <h2>How Stripe Works:</h2>
                  <p>Integrating with Stripe allows us to seamlessly accept payments from patients and send the payments on to you.  Stripe will process the payment from a patient when they purchase a premium appointment time through YWait.  The integrated Stripe system then allows us to send the payments on to you on a monthly basis.</p>
                </InfoContent>
              </div>
              <div className="column is-5">     
                <InfoContent>
                  <h2>The Stripe Sign-up Process:</h2>
                  <p>Signing up with Stripe is easy.  Simply click the connect link above this section, login or create a new account with Stripe, link your bank account or debit card, and you’re all set to receive payouts from YWait. Payments will be sent to you every month through Stripe, and your Stripe dashboard will allow you to see the payments coming from YWait.</p>
                </InfoContent>
              </div>
              <div className="column is-5">  
                <Image right style={{ backgroundImage: `url(${StripeSignup})`,  height: '372px' }} />
              </div>
            </div>

          <BackToTop>
            <a onClick={this.scrollTo} href="#"> Back to Top</a>
          </BackToTop>
        </Page>
      )
    }
  }

    export default compose(
    )(PracticeSignUpLinkStripeInfo);
    
    