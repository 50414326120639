import React from 'react'
import { Title } from '../../components/Typography'

import SignUpForm from '../../forms/SignUpForm'

export default () => (
  <div className="container">
    <div className="columns is-centered">
      <div className="column is-8">
        <Title className="has-text-centered">Create a YWait Account</Title>        
        <SignUpForm />
      </div>
    </div>
  </div>
)
  

