import gql from 'graphql-tag';

export default gql`
  mutation resetPassword($email:String!, $password:String!, $token:String!) {
    resetPassword(email:$email, password: $password, token: $token){
      statusCode
      userRole
      exceptions
      loggedInUser{
        id,
        firstName,
        lastName
      }
    }
  }
`;