import React, { Component } from 'react'
import { Title } from '../../components/Typography'
import styled from 'styled-components'
import StripeElement from '../../components/StripeElement'
import { YwaitLink } from '../../components/Link'
import { theme } from '../../components/Global'

import GET_PATIENT_DASHBOARD from '../../queries/getPatientDashboard';
import { patientDashboardGetter } from '../../queries/propGetters/patientDashboard'
import UPDATE_PATIENT from '../../mutations/patient/updatePatient'
import { compose } from "recompose"
import { PaymentCard } from '../../components/Cards'
import { withRouter } from 'react-router'
import GET_PAYMENT_CARDS from '../../queries/getPatientPaymentMethods'
import { LoadingIndicator, ErrorIndicator } from '../../components/indicators'
import Notification from '../../components/Notification'
import { Query, Mutation, graphql } from 'react-apollo'
import UpdatePaymentInfo from '../../forms/UpdatePaymentInfoForm'

export const Nav = styled.nav`
  width: 90%;
  border-top: 1px solid ${theme.color.gray};

  a {
    padding: 14px 12px;
    display: block;
    border-bottom: 1px solid ${theme.color.gray};

  }
`

class AccountPayment extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <div className="columns">
          <div className="column is-full v-center">
            <Title>Account Settings</Title>
          </div>
        </div>
        <div className="columns spaced">
          <div className="column is-3">
            <Nav>
              <YwaitLink to="/account-settings">Profile</YwaitLink>
              <YwaitLink active={1} to="/account-payment">Payment</YwaitLink>
            </Nav>
          </div>
          <div className="column is-9">
          <UpdatePaymentInfo/>
        
          </div>
        </div>
      </div>
    )
  }
}

export default compose(
  withRouter,
  graphql(GET_PATIENT_DASHBOARD, { props: patientDashboardGetter }),
  graphql(UPDATE_PATIENT, { name: 'updatePatient' }),
)(AccountPayment);