import React from 'react'
import { Route,Switch } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import Footer from '../components/Footer'
import Navigation from '../components/navigation/Navigation'
import Theme from '../components/Theme'
import { media } from '../components/Global'
import GuestMap from '../routemaps/GuestMap'
import PracticeMap from '../routemaps/PracticeMap'
import PatientMap from '../routemaps/PatientMap'
import RedirectMap from '../routemaps/RedirectMap'
import RecieptPage from '../pages/patient/Reciept'
import { Title } from '../components/Typography'

const Main = styled.div`

  ${media.tablet`
    padding-top: 70px;
    padding-bottom: 100px;
  `}

  max-width: 1200px;
  margin: 0 auto;
  display: block;

  @media (min-height: 690px) { 
    min-height: 638px;
  }


  .v-top {
    display: flex;
    align-items: flex-start;
  }

  .v-center {
    display: flex;
    align-items: center;

    h1 {
      margin: 0;
    }
  }

  .v-bottom {
    display: flex;
    align-items: flex-end;
  }

  .h-left {
    display: flex;
    justify-content: flex-start;
  }

  .h-center {
    display: flex;
    justify-content: center;
  }

  .h-right {
    display: flex;
    justify-content: flex-end;
  }

  .spaced {
    margin-top: 50px;
  }

  ${media.phone`
    padding: 40px 30px 70px 30px;
  `}
`

let LayoutMap = [ ...GuestMap ];
let currentRole = localStorage.getItem('role');

switch(currentRole) {
  case 'Patient':
    LayoutMap = [ 
      ...GuestMap.filter(m => PracticeMap.filter(pm => pm.path === m.path).length === 0), 
      ...PatientMap
    ];
    break;

  case 'PracticeAdmin':
  case 'HCP':
    LayoutMap = [ 
      ...GuestMap.filter(m => PracticeMap.filter(pm => pm.path === m.path).length === 0), 
      ...PracticeMap
    ];
    break;
  default: 
    LayoutMap = [ 
      ...GuestMap,
      ...RedirectMap
    ];
    break;
}


const Body = props => (
  <React.Fragment>    
    <Helmet>
      <title>{props.title || 'YWait'}</title>
    </Helmet>
    <props.cmp />
  </React.Fragment>
)


const NoMatch = () => (
  <div className="container">
    <div className="columns is-centered">            
      <Title>
        404 - Page not Found 
      </Title>
    </div>
  </div>
)

export default () => {
  return (
    <Switch>
      <Route path="/reciept" key="reciept"
          render={() => <Body cmp={RecieptPage} title="Reciept Page" /> }
        />   
        
      <Theme>
        <Navigation role={currentRole} />               
        <Main className="layout-main">
          <Switch>
          {LayoutMap.map(({path, component, title}, key) =>           
            <Route exact path={path} key={key} 
              render={() => <Body cmp={component} title={title} /> }
            />)}
            <Route component={NoMatch} />
          </Switch>
        </Main>  
        <Footer />
      </Theme>
    </Switch>
  )
}
