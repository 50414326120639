import React from "react";
import { withRouter } from "react-router";
import { compose } from "recompose";
import styled from "styled-components";
import moment from "moment";

import { Title } from "../../components/Typography";
import { LoadingIndicator, ErrorIndicator } from "../../components/indicators";
import AppointmentCard from "../../components/AppCards";
import { Query } from "react-apollo";

import GET_PATIENT_DASHBOARD from "../../queries/getPatientDashboard";

export const PastAppointmentsFrame = styled.div``;

export const CardWrapper = styled.div`
  padding-bottom: 20px;
`;

class PatientPastAppointments extends React.Component {
  filterAppointments(pendingAppointments, appointmentHistory) {
    let filteredAppointments = [...pendingAppointments, ...appointmentHistory];

    filteredAppointments.sort(function(a, b) {
      if (a.dateTime < b.dateTime) return -1;
      if (a.dateTime > b.dateTime) return 1;
      return 0;
    });
    return filteredAppointments;
  }

  render() {
    return (
      <PastAppointmentsFrame className="container">
        <Query query={GET_PATIENT_DASHBOARD}>
          {({ error, data, loading }) => {
            if (error) {
              return <ErrorIndicator error={error} />;
            }
            const payload = data["patientDashboard"];
            if (!payload || loading) {
              return <LoadingIndicator />;
            }
            const patientDashboard = payload["result"];
            const appointments = this.filterAppointments(
              patientDashboard.upcomingAppointments,
              patientDashboard.appointmentHistory
            );

            return (
              <React.Fragment>
                <div className="columns">
                  <div className="column v-center">
                    <Title>Your Premium Appointments</Title>
                  </div>
                </div>
                <div className="columns spaced">
                  <div className="column is-full">
                    <div className="level">
                      {appointments.length > 0 ? (
                        appointments.map(appt =>
                          payload.loggedInUser !== null ? (
                            <CardWrapper key={`pacw-${appt.appointmentId}`}>
                              <AppointmentCard
                                id={appt.appointmentId}
                                bookedAppointmentId={appt.bookedAppointmentId}
                                key={`aptc-${appt.appointmentId}`}
                                direction="horizontal"
                                userName={`${payload.loggedInUser.firstName} ${payload.loggedInUser.lastName}`}
                                phone={appt.patient.cellPhone}
                                email={appt.patient.email}
                                birthday={appt.patient.dOB}
                                appDate={moment
                                  .utc(appt.dateTime)
                                  .local()
                                  .format("dddd[,] MMM[.] Do [at] h:mma")}
                                doctorImage={
                                  appt.hCP.avatar ||
                                  "https://via.placeholder.com/44x44/222742"
                                }
                                doctorName={`${appt.hCP.firstName} ${appt.hCP.lastName}, ${appt.hCP.title}`}
                                upcoming
                                singleButton={true}
                              />
                            </CardWrapper>
                          ) : (
                            <CardWrapper key={`pacw-${appt.appointmentId}`}>
                              <AppointmentCard
                                id={appt.appointmentId}
                                bookedAppointmentId={appt.bookedAppointmentId}
                                direction="horizontal"
                                appDate={moment
                                  .utc(appt.dateTime)
                                  .local()
                                  .format("dddd[,] MMM[.] Do [at] h:mma")}
                                doctorImage={
                                  appt.hCP.avatar ||
                                  "https://via.placeholder.com/44x44/222742"
                                }
                                doctorName={`${appt.hCP.firstName} ${appt.hCP.lastName}, ${appt.hCP.title}`}
                                upcoming
                                singleButton={true}
                              />
                            </CardWrapper>
                          )
                        )
                      ) : (
                        <div>No appointments found.</div>
                      )}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          }}
        </Query>
      </PastAppointmentsFrame>
    );
  }
}

export default compose(withRouter)(PatientPastAppointments);
