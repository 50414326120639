import React from 'react'
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { Query, graphql } from 'react-apollo'

import { Title, H2 } from '../../components/Typography'
import { LoadingIndicator, ErrorIndicator } from '../../components/indicators'

import GET_PRACTICE_STATE from '../../queries/getPracticeState'
import UPDATE_PRACTICE_STATE from '../../mutations/updatePracticeState'
import { practiceStateGetter } from '../../queries/propGetters/practiceState'
import GET_HCP_PROFILE from '../../queries/getHcpProfile'
import PracticeProviderForm from '../../forms/PracticeProviderForm'

class ProviderProfile extends React.Component {
  render() {
    const { hcpId } = this.props.match.params
    return (
      <div>
        <Query query={GET_HCP_PROFILE} variables={{ hcpId: hcpId }}>
              {({ error, data, loading }) => {
                  if (error) {
                      console.error(error);
                      return <ErrorIndicator error={error} />
                  }
                  const payload = data['hCPProfile'];
                  if (!payload || loading) {
                      return <LoadingIndicator />
                  }
                  const ProviderProfile = payload['result'];

                  return (
                    <>
                      <div className="columns">
                        <div className="column is-full v-center">
                          <Title>{ProviderProfile.hCP.firstName} {ProviderProfile.hCP.lastName}, {ProviderProfile.hCP.title}</Title>
                        </div>
                      </div>
                      <div className="columns">
                        <div className="column is-full">
                          <H2>Basic Info</H2>
                          <PracticeProviderForm action="edit" hCP={ProviderProfile.hCP} />
                        </div>
                      </div>
                    </>
                  )
              }}
          </Query>
      </div>
    )
  }
}

export default compose(
  withRouter,
  graphql(GET_PRACTICE_STATE, { props: practiceStateGetter }),
  graphql(UPDATE_PRACTICE_STATE, { name: 'updatePracticeState' })
)(ProviderProfile);