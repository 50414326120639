import React, { Component } from 'react'
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import queryString from 'query-string'
import { Title,H2 } from '../../components/Typography'
import ADD_STRIPE_ACCOUNT from '../../mutations/practice/addStripeAccount'
import { Button,ButtonLink} from '../../components/buttons/Button'
import styled from 'styled-components'
import GET_PRACTICE from '../../queries/getPractice'
import { Query, graphql } from 'react-apollo'
import { LoadingIndicator, ErrorIndicator } from '../../components/indicators'

export const ButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 40px;
`
const Paragraph = styled.p`
  text-align: center;
  padding-top: 10px;
  padding-bottom: 20px;
  text-align: center;  
  font-size: 18px;
  line-height: 24px;
`
const handleGoToDashboard = () => {
  window.location.href = '/';
}

class PracticeAccountCreated extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      loading: false,
      serverError: false,
      serverMessage: 'Incorrect email or password.',
    }
  }
  componentDidMount() {
   var qs = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true });
   if(qs.code){
   var stripeAccountCode = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true }).code;
    this.retriveAccountId(stripeAccountCode);
   }
  }
  
  retriveAccountId(stripeAccountCode){
    this.setState({ serverError: false, loading: true});
    
    this.props.addStripeAccount({
      name: 'addStripeAccount',
      variables: {accessCode:stripeAccountCode, practiceId: localStorage.getItem('practiceId')}
    }).then((res) => {
      this.setState({loading: false});
      
      const payload = res.data['addStripeAccount'];
      if (!payload) {
        this.setState({ serverError: true, serverMessage: "Request Failed" });
        return;
      }
      if (payload.statusCode != 200) {
        this.setState({ serverError: true, serverMessage: payload.exceptions });
        return;
      }
    })
    .catch(({ graphQLErrors }) => {
      console.log('graphQLErrors', graphQLErrors);
    });
  }
  
  render() {
    return (
  <div className="container">
    <div className="columns is-centered">
      <div className="column">
        <Title className="has-text-centered">Your YWait Account has Been Created.</Title>
        <Query query={GET_PRACTICE}>
          {({ error, data, loading }) => {
            if (error) {
              console.error(error);
              return <ErrorIndicator error={error} />
            }
            const payload = data['practiceDashboard'];
            if (!payload || loading) {
              return <LoadingIndicator />
            }
            const result = payload['result'];

            return (
                <H2 className="has-text-centered">Access Code: <b>{result.practice.accessCode}</b></H2>
            )
          }}
        </Query>
        <Paragraph>You are steps away from creating a new revenue stream and patient convenience for your practice.</Paragraph>
        <Paragraph>Getting started is easy.</Paragraph>
        <Paragraph>Once your signup is confirmed, you will need to work with your practice to designate your YWait premium appointment time slots.  Choosing the times and prices is simple and will be done by clicking on the Calendar tab on your dashboard.</Paragraph>

        <ButtonWrapper>
          <Button onClick={handleGoToDashboard} secondary>go to my dashboard</Button>
        </ButtonWrapper>

      </div>
    </div>
  </div>
)

}      
}
    
export default compose(
  withRouter,
  graphql(ADD_STRIPE_ACCOUNT, { name: 'addStripeAccount' }),
)(PracticeAccountCreated);