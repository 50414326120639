import { compose } from "recompose";
import React from 'react'
import { theme } from '../components/Global'
import styled from 'styled-components'
import { withRouter } from 'react-router';
import moment from "moment";
import "moment-timezone";
import {
  CardElement,
  Elements,
  injectStripe
} from 'react-stripe-elements';
import { Button } from '../components/buttons/Button'
import BOOK_APPOINTMENT from '../mutations/appointments/bookAppointment'
import UPDATE_PATIENT from '../mutations/patient/updatePatient'
import Notification from '../components/Notification'
import { graphql } from 'react-apollo'
import { globalStateGetter } from '../queries/propGetters/globalState'
import GET_GLOBAL_STATE from '../queries/getGlobalState'

const Form = styled.div` 
  margin: 0 auto;

  .field {
    background-color: white; 
    border: 1px solid ${theme.color.light_gray};
    padding: 0 15px;
    max-width: 510px;
    margin-bottom: 20px;
  }
`
class _PaymentInfoForm extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      serverError: false,
      serverMessage: '',
      isSubmitting: false
    };
  }

  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }
  
  createOptions = (fontSize, padding) => {
    return {
      style: {
        base: {
          fontSize: '16px',
          '::placeholder': {
            color: theme.color.light_gray
          },
          fontFamily: theme.font.regular,
          color: theme.color.black,
          height: '60px',
          lineHeight: '60px'
        },
        invalid: {
          color: '#9e2146',
        },
      },
    };
  };
  
  handleSubmit = (cb) => {
    if (this.props.stripe) {
      this.setState({ serverError: false, isSubmitting: true });
      this.props.stripe
      .createToken()
      .then((payload) => {
        if (payload.token) {
          var token = payload.token.id;
          return (token);
        } else {
          var exception = 'Failed to Save Payment Info';
          this.setState({ isSubmitting: false, serverError: true, serverMessage: exception });
          cb();
          return Promise.reject(exception);
        }
      }).then(this.updatePatient.bind(this))
      .then(this.bookAppointment.bind(this))
      .catch((errors) => {
        this.setState({ isSubmitting: false, serverError: true, serverMessage: errors });
        cb();
      });
    } else {
      cb();
    }
  };
  
  bookAppointment = () => {
    var zone =  moment.tz.guess();
    localStorage.setItem("localTimezone",zone);
    this.props.bookAppointment({
      name: 'bookAppointment',
      variables: {
        appointmentId: localStorage.getItem("selectedAppointmentId"),
        patientId: localStorage.getItem("patientId"),
        hadExistingAppointment: localStorage.getItem('appointmentIsExisting') === 'true',
        acceptedTerms: localStorage.getItem('acceptedTerms') === 'true',
        isNewPatient: (localStorage.getItem('newPatientIsFirstVisit') === 'true' || false),
        selectedTime : localStorage.getItem('selectedTime')
      }
    }).then((res) => {
      var data = res.data.bookAppointment;
      if (!data || data.statusCode != 200) {
        this.setState({ isSubmitting: false, serverError: true, serverMessage: data.exceptions || "Error Processing Request" });
        return;
      }
      this.props.history.push('/appointment-purchased');
    })
    .catch(({ graphQLErrors }) => {
      this.setState({ isSubmitting: false, serverError: true });
    });
  }
  
  updatePatient = (token) => {
    if (!token)
    this.setState({ serverError: true, serverMessage: "Failed to Save Payment Info" });
    
    const newPatientData = {
      defaultStripeCardToken: token
    };
    return this.props.updatePatient({
      name: 'updatePatient',
      variables: {
        patient: newPatientData,
        patientId: localStorage.getItem("patientId")
      }
    }).then((res) => {
      var data = res.data.updatePatient;
      if (!data || data.statusCode != 200) {
        this.setState({ isSubmitting: false, serverError: true, serverMessage: data.exceptions || "Error Processing Request" });
        return Promise.reject();
      }
    })
    .catch(({ graphQLErrors }) => {
      debugger;
      this.setState({ isSubmitting: false, serverError: true });
    });
  }

  render() {
    return (
      <Form>
        {this.state.serverError && <Notification
          close={this.closeAlert}
          open={this.state.serverError} message={this.state.serverMessage} />}
        <div className="field">
          <CardElement  {...this.createOptions(this.props.fontSize)} />
        </div>
        {/* <Button secondary full disabled={this.state.isSubmitting} onClick={this.handleSubmit.bind(this)}>Purchase Appointment</Button> */}
      </Form>
    );
  }
}

const PaymentInfoForm = injectStripe(_PaymentInfoForm);
    
class PaymentInfo extends React.Component {
  constructor(props) {
    super(props);
  }
  
  render() {
    return (
      <div className="PaymentInfo">
      <Elements {...{fonts:[
        {
          cssSrc: '/fonts/fonts.json',
          family: theme.font.regular,
          style: 'normal',
        }
      ]
    }}>
    <PaymentInfoForm  {...this.props} />
    </Elements>
    </div>
    );
  }
}
  
export default compose(
  withRouter,
  graphql(UPDATE_PATIENT, { name: 'updatePatient' }),
  graphql(BOOK_APPOINTMENT, { name: 'bookAppointment' }),
  graphql(GET_GLOBAL_STATE, { props: globalStateGetter })
)(PaymentInfo);