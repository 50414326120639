import React from 'react'
import { Title } from '../../components/Typography'
import LoginForm from '../../forms/LoginForm'

export default () =>
  <div className="container">
    <div className="columns is-centered">
      <div className="column is-6">
        <Title className="has-text-centered">Log in to your YWait Admin Portal</Title>        
        <LoginForm register="/sign-up-practice" />        
      </div>
    </div>
  </div>
