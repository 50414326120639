import React, { Component} from 'react'
import { Title } from '../../components/Typography'
import { Button } from '../../components/buttons/Button' 
import styled from 'styled-components'
import { withRouter } from 'react-router';
import { compose } from 'recompose';

const Paragraph = styled.p`
  text-align: center;
  padding-bottom: 30px;
`

const ButtonContainer = styled.div`
  padding: 0 30px;
`

class CheckEmail extends Component {  
  handleBack = () => {
    this.props.history.push('/'); 
  }

  render() {

    if (this.props.location.state === undefined) {
      this.props.history.push('/'); 
      return null;
    }

    return (
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-5">
            <Title className="has-text-centered">Check Your Email</Title>        

            <Paragraph>
              An Email has been sent to <b>{this.props.location.state.email}</b> <br />
              Click the link in the email to reset your password
            </Paragraph>

            <ButtonContainer>
              <Button secondary full onClick={this.handleBack}>Back to home</Button>
            </ButtonContainer>   
          </div>
        </div>
      </div>
    )
  }
}

export default compose(
  withRouter,
)(CheckEmail);
  

