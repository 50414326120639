import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router";
import { compose } from "recompose";
import { graphql } from "react-apollo";
import GET_PRACTICE_INSURANCES from "../../queries/getPracticeInsurances";
import DELETE_INSURANCE from "../../mutations/practice/deleteInsurance";
import UPDATE_INSURANCE from "../../mutations/practice/updateInsurance";
import { Nav } from "./AccountSettings";
import { theme, formatENUM } from "../../components/Global";
import { YwaitLink } from "../../components/Link";
import { Query } from "react-apollo";
import { CardWrapper } from "../patient/PastAppointments";
import { Title, H3 } from "../../components/Typography";
import AppointmentCard from "../../components/AppCards";
import { LoadingIndicator, ErrorIndicator } from "../../components/indicators";
import Notification from "../../components/Notification";
import * as Yup from "yup";
import AccountInsuranceForm from "../../forms/AccountInsuranceForm";
import Modal from "../../components/modals/Modal";

const Header = styled.h3`
  font-size: 36px;
  line-height: 44px;
  font-family: ${theme.font.rudi_thin};
  margin-bottom: 20px;
`;

const Form = styled.form`
  max-width: 400px;
  margin: 0 auto;

  p {
    text-align: center;
    color: ${theme.color.gray};

    a {
      color: ${theme.color.gray};
      text-decoration: underline;
    }
  }

  .button {
    margin-top: 40px;
  }
`;

const timeout = "";

class AccountInsurancesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      displayEditForm: false,
      selectedAction: "",
      accessCode: localStorage.getItem("accessCode"),
      practiceId: localStorage.getItem("practiceId")
    };

    this.handleResult = this.handleResult.bind(this);
  }

  handleDeleteClick = (id, name) => {
    this.props
      .deleteInsurance({
        name: "deleteInsurance",
        variables: {
          name: name,
          id: id
        },
        refetchQueries: [
          {
            query: GET_PRACTICE_INSURANCES,
            fetchPolicy: "network-only",
            variables: { practiceId: this.state.practiceId }
          }
        ],
        onError: () => {
          {
            console.log("Done with the mutation");
          }
        }
      })
      .then(res => {
        var data = res.data.deletePraticeInsurance;
        if (!data || data.statusCode != 200) {
          this.setState({
            serverError: true,
            serverMessage: data.exceptions || "Error > "
          });
          return;
        }
        window.scrollTo(0, 0);
        this.setState({
          success: true,
          successMessage: "Insurance was deleted successfully"
        });
      })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors);
        this.setState({
          successMessage: false,
          serverError: true,
          serverMessage: "An error has occured "
        });
      });
  };

  handleEditClick = (id, name) => {
    this.setState({
      displayEditForm: true,
      selectedInsuranceId: id,
      selectedInsuranceName: name,
      selectedAction: "edit"
    });
  };

  componentWillUnmount() {
    // clearTimeout(timeout);
  }

  handleResult = () => {
    console.log("You called the results handler");
    setTimeout(() => this.toggleDisplayFormState(), 1000);
  };

  toggleDisplayFormState = () => {
    this.setState(previousState => ({
      displayEditForm: !previousState.displayEditForm
    }));
  };

  render() {
    return (
      <Query
        query={GET_PRACTICE_INSURANCES}
        variables={{ practiceId: this.state.practiceId }}
      >
        {({ error, data, loading }) => {
          if (error) {
            return <ErrorIndicator />;
          }
          const payload = data["practiceInsurances"];
          if (!payload || loading) {
            return <LoadingIndicator />;
          }
          if (this.state.displayEditForm) {
            return (
              <Modal
                large={true}
                isOpen={true}
                onClose={this.toggleDisplayFormState}
              >
                <AccountInsuranceForm
                  value={this.state.selectedInsuranceName}
                  action="edit"
                  id={this.state.selectedInsuranceId}
                  practiceId={this.state.practiceId}
                  resultsHandler={this.handleResult}
                />
              </Modal>
            );
          }
          if (payload.statusCode != 200) {
            return (
              <Notification
                open={true}
                message={formatENUM(payload.exceptions)}
              />
            );
          }

          const practiceInsurances = payload["result"];
          return (
            <div>
              <div className="columns is-multiline">
                <div className="column is-8 v-center">
                  <Title>Account Settings</Title>
                </div>
                <div className="column is-8 v-center">
                  <H3>
                    Your Access Code: <b>{this.state.accessCode}</b>
                  </H3>
                </div>
              </div>
              <div className="columns spaced">
                <div className="column is-3">
                  <Nav>
                    <YwaitLink to="/account-settings">Profile</YwaitLink>
                    <YwaitLink to="/account-payment">Payment</YwaitLink>
                    <YwaitLink active={1} to="/insurances">
                      List of Insurances
                    </YwaitLink>
                  </Nav>
                </div>
                <div className="column is-9">
                  <Header>List of Insurances</Header>
                  {practiceInsurances.length > 0 ? (
                    practiceInsurances.map(ins => (
                      <CardWrapper key={`pacw-${ins.id}`}>
                        <AppointmentCard
                          id={ins.id}
                          key={`aptc-${ins.id}`}
                          direction="horizontal"
                          forInsurance={true}
                          userName={`${ins.name}`}
                          singleButton={false}
                          handleEdit={() =>
                            this.handleEditClick(ins.id, ins.name)
                          }
                          handleDelete={() =>
                            this.handleDeleteClick(ins.id, ins.name)
                          }
                        />
                      </CardWrapper>
                    ))
                  ) : (
                    <div>No Insurances yet</div>
                  )}
                  <AccountInsuranceForm
                    action="create"
                    value=""
                    resultHandler={this.handleResult}
                    practiceId={this.state.practiceId}
                  />
                </div>
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default compose(
  withRouter,
  graphql(GET_PRACTICE_INSURANCES, { name: "getPracticeInsurances" }),
  graphql(DELETE_INSURANCE, { name: "deleteInsurance" }),
  graphql(UPDATE_INSURANCE, { name: "updatePracticeInsurance" })
)(AccountInsurancesPage);