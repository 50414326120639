import React from "react";
import styled, { css } from "styled-components";
import { rgba } from "polished";
import { theme } from "./Global";
import { UserPicture } from "./UserPicture";
import { Button, ButtonGroup } from "./buttons/Button";

import AmericanExpress from "../assets/credit_cards/AmericanExpress.png";
import Visa from "../assets/credit_cards/Visa.png";
import DinersClub from "../assets/credit_cards/DinersClub.png";
import Discover from "../assets/credit_cards/Discover.png";
import MasterCard from "../assets/credit_cards/MasterCard.png";
import svgClock from "../assets/svg/clock.svg";
import UnionPay from "../assets/credit_cards/UnionPay.png";
import config from "../config";

export const Card = styled.div`  
  background-color: white; 
  border: 1px solid #E8E8E8;
  box-shadow: 0 3px 8px 0 ${rgba("#6D7185", 0.1)};

  ${props =>
    props.selected &&
    css`
      border-color: ${theme.color.primary};
      h4 {
        color: ${theme.color.primary};
      }
    `};

  ${props =>
    props.handleClick &&
    css`
      .button {
        border-top: 1px solid #eeeeee;
        max-width: 100%;
      }
    `}

  ${props =>
    props.noEditLink &&
    css`
      a {
        display: none;
      }
    `}
  
  ${props =>
    props.noPadding &&
    css`
      > div {
        padding: 0 22px;
      }
    `}

  ${props =>
    props.edit &&
    css`
      background-color: transparent;
      border: none;
      box-shadow: none;

      h4 {
        color: ${theme.color.text};
        font-family: ${theme.font.regular};
        font-size: 16px;
        line-height: 24px;
      }

      ${DoctorTime}, ${DoctorDate} {
        font-size: 16px;
        line-height: 24px;
      }

      ${DoctorPrice} {
        margin-top: 10px;
        line-height: 24px;
        color: ${theme.color.success};
      }

      ${CardContent} {
        align-items: flex-start;
      }
    `};
  
  ${props =>
    props.singleButton &&
    css`
      ${ButtonGroup} {
        .button {
          height: 100%;
        }
        .button span {
          font-size: 20px;
        }
      }
    `}; 

  ${props =>
    props.direction === "horizontal" &&
    css`
      display: flex;
      .content {
        width: 70%;
      }
    `}; 

  ${props =>
    props.isDoctorName &&
    css`
      h4:hover {
        color: ${theme.color.primary};
      }
    `};
  
  ${props =>
    props.scheduleUrl &&
    css`
      a,
      button {
        border-top: 1px solid #eeeeee;
        :hover {
          color: ${theme.color.primary};
        }
      }
    `};
`;

export const IconContent = styled.div`
  position: relative;
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  img {
    margin-right: 10px;
  }
  span {
    margin-right: 35px;
    color: ${theme.color.gray};
  }
`;

export const PatientName = styled.h4`
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  letter-spacing: 0.22;
  font-family: ${theme.font.medium};
  color: ${theme.color.text};
`;

export const DoctorName = styled.h4`
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.22;
  font-family: ${theme.font.medium};
  color: ${theme.color.text};
`;

export const DoctorNameLight = styled.h4`
  font-family: ${theme.font.light};
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.22;
  color: ${theme.color.text};
`;

export const AppDate = styled(DoctorName)`
  font-family: ${theme.font.medium};
  font-size: 15px;
`;

export const Title = styled.h4`
  font-size: 28px;
  text-align: center;
  line-height: 44px;
  font-family: ${theme.font.medium};
  color: ${theme.color.text};
`;

export const DoctorDate = styled.div`
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.22;
  font-family: ${theme.font.medium};
  color: ${theme.color.link};
  display: inline;
`;

export const DoctorPrice = styled.div`
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.22;
  font-family: ${theme.font.bold};
  color: ${theme.color.link};
`;

export const DoctorTime = styled.div`
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.22;
  font-family: ${theme.font.medium};
  color: ${theme.color.link};
  display: inline;
`;

export const Speciality = styled.h4`
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.22;
  color: ${theme.color.gray};
  font-family: ${theme.font.regular};
`;
export const Address = styled.h4`
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.22;
  color: ${theme.color.gray};
  font-family: ${theme.font.regular};
`;

const EditLink = styled.a`
  margin-top: 10px;
  display: block;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.22;
  color: ${theme.color.text};
  font-family: ${theme.font.regular};
  text-decoration: underline;
`;

const CardInfo = styled.div`
  padding-left: 22px;
  max-width: 285px;
`;

const CardContent = styled.div`
  padding: 22px;
  display: flex;
  align-items: center;
`;
const PaymentLogo = styled.img`
  height: ${props => (props.size ? props.size : "60px")};
  border-radius: 50%;
  display: inline-block;
`;

const UserName = DoctorName;
const UserPayment = DoctorDate;
const UserZip = Speciality;

export const ProviderCard = props => {
  return (
    <Card {...props}>
      <CardContent>
        {props.userName ? props.userName : ""}
        {props.doctorImage ? <UserPicture picture={props.doctorImage} /> : ""}
        <CardInfo>
          <DoctorName>{props.doctorName}</DoctorName>
          {props.practiceName ? (
            <Speciality>{props.practiceName}</Speciality>
          ) : (
            ""
          )}
          {props.date ? <DoctorDate>{props.date}</DoctorDate> : ""}
          {props.phone ? <DoctorDate>{props.phone}</DoctorDate> : ""}
          {props.email ? <DoctorDate>{props.email}</DoctorDate> : ""}
          {props.time ? (
            <DoctorTime>
              {" "}
              <span>at</span> {props.time}
            </DoctorTime>
          ) : (
            ""
          )}
          {props.price ? <DoctorPrice>{props.price}</DoctorPrice> : ""}

          {props.edit ? <EditLink>Edit</EditLink> : ""}
        </CardInfo>
      </CardContent>
      {props.handleClick ? (
        <Button card onClick={props.handleClick}>
          Schedule an Appointment
        </Button>
      ) : (
        ""
      )}
    </Card>
  );
};
export const PatientAppCard = props => {
  return (
    <Card {...props}>
      <CardContent className="content">
        {props.doctorImage ? <UserPicture picture={props.doctorImage} /> : ""}
        <CardInfo>
          <DoctorNameLight>{props.doctorName}</DoctorNameLight>
          <IconContent>
            <img src={svgClock} alt="" />
            <AppDate>{props.appDate}</AppDate>
          </IconContent>
        </CardInfo>
      </CardContent>
      <ButtonGroup direction={props.direction}>
        <Button card href={config.pdfReportUrl + "?id=" + props.id}>
          See Receipt
        </Button>
      </ButtonGroup>
    </Card>
  );
};

export const RecieptCard = props => {
  return (
    <Card {...props}>
      <CardContent className="reciept-card">
        <CardInfo>
          <Title>{"YWait Appointment Reciept"}</Title>
          {props.practiceName ? <Address>{props.practiceName}</Address> : ""}
          {props.practiceAddress ? (
            <Address>{props.practiceAddress}</Address>
          ) : (
            ""
          )}
          {props.practicePhone ? <Address>{props.practicePhone}</Address> : ""}
          <br />
          <PatientName>{props.patientName}</PatientName>
          <DoctorName>{props.doctorName}</DoctorName>
          {props.date ? <DoctorDate>{props.date}</DoctorDate> : ""}
          {props.time ? (
            <DoctorTime>
              {" "}
              <span>at</span> {props.time}
            </DoctorTime>
          ) : (
            ""
          )}
          <br />
          <br />
          {props.price ? <Title>{props.price}</Title> : ""}
        </CardInfo>
      </CardContent>
    </Card>
  );
};

export const PaymentCard = props => {
  var brand = "";
  switch (props.brand) {
    case "Visa":
      brand = Visa;
      break;
    case "DinersClub":
      brand = DinersClub;
      break;
    case "Discover":
      brand = Discover;
      break;
    case "MasterCard":
      brand = MasterCard;
      break;
    case "UnionPay":
      brand = UnionPay;
      break;
    case "AmericanExpress":
      brand = AmericanExpress;
      break;
  }
  return (
    <Card {...props}>
      <CardContent>
        {brand ? <PaymentLogo src={brand} /> : ""}
        <CardInfo>
          <UserName>{props.name}</UserName>
          {props.last4 ? (
            <UserPayment>{"xxx-xxxx-xxx-" + props.last4}</UserPayment>
          ) : (
            ""
          )}
          {props.address_zip ? (
            <UserZip>
              {" "}
              <span>Billing Zip Code: </span> {props.address_zip}
            </UserZip>
          ) : (
            ""
          )}

          {props.edit ? <EditLink onClick={props.edit}>Edit</EditLink> : ""}
        </CardInfo>
      </CardContent>
    </Card>
  );
};
