import React from 'react'
import { Title } from '../../components/Typography'
import styled from 'styled-components'

const Column = styled.div`
  h1 {
    text-align: center;
    margin-bottom: 50px;
  } 

  h3 {
    font-weight: bold;
    padding-bottom: 12px;
  }

  p {
    padding-bottom: 20px;
    br {
      line-height: 1;
    }
  }

  ul {
    list-style-type: disc;
    padding-left: 50px;
    padding-bottom: 20px;
    li {
      padding-bottom: 5px;
    }
  }
`

export default () => (
  <div className="container">
    <div className="columns is-centered">
      <Column className="column is-8">            
        <Title>
          PRIVACY POLICY 
          <small>Last Modified: [February 6, 2019]</small>  
        </Title>

        <h3>Introduction</h3>

        <p>
          YWait LLC ("<strong>Company</strong>" or "<strong>We</strong>") respect your privacy and are committed to protecting it through our compliance with this policy.
        </p>
        <p>
          This policy describes the types of information we may collect from you or that you may provide when you visit the website www.ywaitdoc.com (the "<strong>YWait Site</strong>") and our practices for collecting, using, maintaining, protecting, and disclosing that information. 
        </p>
        <p>
          The purpose of the YWait Site is to provide you a simple way to access and schedule premium appointment times with your healthcare provider. To be clear, the YWait Site is not meant to be, and cannot be used as, a substitute for your medical treatment. 
        </p>

        <p>In the spirit of keeping things convenient, the default setting of the YWait Site is to keep you logged in and store your login information for 30 minutes so that you do not have to re-enter your username and password if you access the YWait Site multiple times within a 30-minute window. However, this feature only applies if you do not affirmatively logout of the YWait Site. If you do not want your login information saved and stored, simply logout every time you use the YWait Site.</p>
        <p>We recognize that the privacy of your information is important to you, and it is of paramount importance to us. The purpose of this policy is to let you know how we handle the information you voluntarily provide to us or that we collect through your voluntary use of the YWait Site. Please understand that your use of the YWait Site is strictly voluntary. If you believe your use of the YWait Site is not voluntary, at any time, for any reason, please contact us immediately.</p>
        <p>Some information collected through the App may be considered “protected health information,” as that term is defined in the Health Insurance Portability and Accountability Act of 1996 (“HIPAA”) and its implementing regulations, as well as all other applicable laws. This policy is in addition to the HIPAA Notice of Privacy Practices provided by your health care professionals (collectively, “Health Care Professionals”), which describe how your Health Care Professional uses and/or discloses your protected health information. You should contact your Health Care Professional for its HIPAA Notice of Privacy Practices.</p>
        
        <p>This policy applies to information we collect:</p>

        <ul>
          <li>On this YWait Site.</li>
          <li>In email, text, and other electronic messages between you and this YWait Site.</li>
        </ul>
        
        <p>It does not apply to information collected by:</p>
        <ul>
          <li>us offline or through any other means, including on any other website operated by Company or any third party (including our affiliates and subsidiaries); or </li>
          <li>any third party, including through any application or content (including advertising) that may link to or be accessible from or on the YWait Site.</li>
        </ul>
                
        <p>Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use our YWait Site. By accessing or using this YWait Site, you agree to this privacy policy. This policy may change from time to time (see Changes to Our Privacy Policy). Your continued use of this YWait Site after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates. </p>
        <p>You must be 18 years of age or older and reside in the United States to use the YWait Site. You represent and warrant that any profile information you submit is true and accurate and that you are 18 years of age or older. The YWait Site is not intended for those under the age of 18 or those that do not reside in the United States. You may access and use YWait Site only in the United States. We may restrict access to YWait Site from other locations.</p>

        <h3>Persons Under the Age of 18</h3>

        <p>Our YWait Site is not intended for persons under 18 years of age. No one under age 18 may provide any personal information to or on the YWait Site. We do not knowingly collect personal information from persons under 18 years of age. If you are under 18, do not use or provide any information on this YWait Site or on or through any of its features/register on the YWait Site, make any purchases through the YWait Site, use any of the interactive or public comment features of this YWait Site or provide any information about yourself to us, including your name, address, telephone number, email address, or any screen name or user name you may use. If we learn we have collected or received personal information from a person under 18 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a person under 18, please contact us at ywaitdoc@gmail.com.</p>

        <h3>Information We Collect About You and How We Collect It</h3>

        <p>We collect several types of information from and about users of our YWait Site, including information:</p>
        
        <ul>
          <li>by which you may be personally identified, such as name, internet protocol (IP) address, e-mail address, telephone number or any other identifier by which you may be contacted online or offline ("<strong>personal information</strong>");</li>
          <li>that is about you but individually does not identify you; and/or</li>
          <li>bout your internet connection, the equipment you use to access our YWait Site and usage details.</li>
        </ul>

        <p>We collect this information:</p>

        <ul>
          <li>Directly from you when you provide it to us.</li>
          <li>Automatically as you navigate through the site. Information collected automatically may include usage details, IP addresses, and information collected through cookies, web beacons, and other tracking technologies.</li>
          <li>From your Health Care Professional.</li>
        </ul>

        <p><i>Information You Provide to Us </i></p>
        <p>The information we collect on or through our YWait Site may include:</p>

        <ul>
          <li>Information that you provide by filling in forms on our YWait Site. This includes information provided at the time of registering to use our YWait Site or requesting services. We may also ask you for information when you report a problem with our YWait Site.</li>
          <li>Your login and account information to recognize you when you return to our YWait Site, but only if you do not complete the logout process.  </li>
          <li>Records and copies of your correspondence (including email addresses), if you contact us.</li>
          <li>Details of transactions you carry out through our YWait Site and of the fulfillment of your orders. You may be required to provide financial information before placing an order through our YWait Site.</li>
        </ul>

        <p><i>Information We Collect Through Automatic Data Collection Technologies</i></p>
        <p>As you navigate through and interact with our YWait Site, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:</p>

        <ul>
          <li>Details of your visits to our YWait Site, including traffic data, location data, logs, and other communication data and the resources that you access and use on the YWait Site.</li>
          <li>Information about your computer and internet connection, including your IP address, operating system, and browser type.</li>
        </ul>

        <p>
          As discussed above, we also collect your login and account information to recognize you when you return to our YWait Site, but only if you do not complete the logout process.  
          <br />
          We also may use Google Analytics to track page views and time on a specific page and to collect other information about your online activities over time and across third-party websites. 
          <br />
          The information we collect automatically is statistical data and does not include personal information, but we may maintain it or associate it with personal information we collect in other ways or receive from third parties, including your internet service provider. It helps us to improve our YWait Site and to deliver a better and more personalized service, including by enabling us to:
        </p>

        <ul>
          <li>Estimate our audience size and usage patterns.
            The technologies we use for this automatic data collection may include:
          </li>
          <li><strong>Cookies (or browser cookies)</strong>. A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of our YWait Site. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our YWait Site. </li>
          <li><strong>Flash Cookies</strong>. Certain features of our YWait Site may use local stored objects (or Flash cookies) to collect and store information about your preferences and navigation to, from, and on our YWait Site. Flash cookies are not managed by the same browser settings as are used for browser cookies. For information about managing your privacy and security settings for Flash cookies, see Choices About How We Use and Disclose Your Information.</li>
        </ul>

        <h3>How We Use Your Information</h3>


        <p>We use information that we collect about you or that you provide to us, including any personal information:</p>

        <ul>
          <li>To present our YWait Site and its contents to you.</li>
          <li>To provide you with information, products, or services that you request from us.</li>
          <li>To fulfill any other purpose for which you provide it.</li>
          <li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.</li>
          <li>To notify you about changes to our YWait Site or any products or services we offer or provide though it.</li>
          <li>In any other way we may describe when you provide the information.</li>
          <li>For any other purpose with your consent.</li>
        </ul>

        <h3>Disclosure of Your Information</h3>

        <p>We may disclose aggregated information about our users, and information that does not identify any individual, without restriction. </p>
        <p>We may disclose personal information that we collect or you provide as described in this privacy policy:</p>

        <ul>
          <li>To your Health Care Professional.</li>
          <li>For any other purpose disclosed by us when you provide the information.</li>
          <li>With your consent.</li>
        </ul>

        <p>We may also disclose your personal information:</p>
        <ul>
          <li>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</li>
          <li>To enforce or apply our terms of use www.ywaitdoc.com/terms-of-use and other agreements, including for billing and collection purposes.</li>
          <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</li>
        </ul>

        <h3>Choices About How We Use and Disclose Your Information</h3>

        <p>We strive to provide you with choices regarding the personal information you provide to us. We have created mechanisms to provide you with the following control over your information: </p>

        <ul>
          <li><strong>Tracking Technologies and Advertising</strong>. You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. To learn how you can manage your Flash cookie settings, visit the Flash player settings page on Adobe's website. If you disable or refuse cookies, please note that some parts of this site may then be inaccessible or not function properly.</li>
        </ul>

        <p>We do not control third parties' collection or use of your information to serve interest-based advertising. However these third parties may provide you with ways to choose not to have your information collected or used in this way. </p>
        
        <p><u>Accessing and Correcting Your Information</u></p>

        <p>You can review and change your personal information by logging into the YWait Site and visiting your account profile page. </p>
        <p>You may also send us an email at ywaitdoc@gmail.com to request access to, correct or delete any personal information that you have provided to us. We cannot delete your personal information except by also deleting your user account. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.</p>

        <h3>Data Security</h3>

        <p>We have implemented measures designed to secure your personal information from accidental loss 
            and from unauthorized access, use, alteration, and disclosure. We use Stripe, Inc. (“Stripe”) 
            to process all payment information, which means that by using the YWait Site you are agreeing 
            to abide by Stripe’s Privacy Policy (found here: <a href='https://stripe.com/us/privacy' target="_blank">https://stripe.com/us/privacy</a>) and Terms of 
            Service (found here: <a href="https://stripe.com/us/checkout/legal" target="_blank">https://stripe.com/us/checkout/legal</a>), as each may be updated from 
            time to time. Any payment transactions will be encrypted using SSL technology.</p>
        <p>The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our YWait Site, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. </p>
        <p>As discussed above, the default setting of the YWait Site is to keep you logged in and store your login information for 30 minutes so that you do not have to re-enter your username and password if you access the YWait Site multiple times within a 30-minute window. However, this feature only applies if you do not affirmatively logout of the YWait Site. If you do not want your login information saved and stored, simply logout every time you use the YWait Site.</p>
        <p>Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our YWait Site. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the YWait Site.</p>

        <h3>Changes to Our Privacy Policy</h3>
        <p>It is our policy to post any changes we make to our privacy policy on this page with a notice that the privacy policy has been updated on the YWait Site home page. If we make material changes to how we treat our users' personal information, we will notify you by email to the email address specified in your account and/or through a notice on the YWait Site home page. The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting our YWait Site and this privacy policy to check for any changes.</p>

        <h3>Contact Information</h3>
        <p>To ask questions or comment about this privacy policy and our privacy practices, contact us at: 
          <br />
          <a href="mailto:ywaitdoc@gmail.com">ywaitdoc@gmail.com</a>
        </p>
        <p>
          To register a complaint or concern, please contact us at: 
          <br />
          <a href="mailto:ywaitdoc@gmail.com">ywaitdoc@gmail.com</a>.
        </p>

        
      </Column>
    </div>
  </div>
)
  

