import { createGlobalStyle } from 'styled-components'
import fontLight from './light.otf'
import fontMedium from './medium.otf'
import fontThin from './thin.otf'
import fontRudiThin from './rudi_thin.otf'
import fontRudiBold from './rudi_bold.otf'
import fontHeavy from './heavy.otf'

export default createGlobalStyle` 
  @font-face {
    font-family: 'Pepi-Light';
    src: url('${fontLight}') format('opentype');    
    font-display: fallback;
  }
  @font-face {
    font-family: 'Pepi-Medium';
    src: url('${fontMedium}') format('opentype');
    font-display: fallback;
  }
  @font-face {
    font-family: 'Pepi-Thin';
    src: url('${fontThin}') format('opentype');
    font-display: fallback;
  }
  @font-face {
    font-family: 'Rudi-Thin';
    src: url('${fontRudiThin}') format('opentype');
    font-display: fallback;
  }
  @font-face {
    font-family: 'Rudi-Bold';
    src: url('${fontRudiBold}') format('opentype');
    font-display: fallback;
  }
  @font-face {
    font-family: 'Pepi-Heavy';
    src: url('${fontHeavy}') format('opentype');
    font-display: fallback;
  }
`
