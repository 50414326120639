import React from 'react'
import { Title } from '../../components/Typography'

import EditSignUpWaitlistForm from '../../forms/EditSignUpWaitlistForm'

export default () => (
  <div className="container">
    <div className="columns is-centered">
      <div className="column is-8">
               
        <EditSignUpWaitlistForm />
      </div>
    </div>
  </div>
)
  

