import gql from 'graphql-tag';

export default gql`
    mutation createPatient($patient:PatientInputType!, $hcpId: ID!, $practiceId: ID!) {
    createPatient(patient:$patient, hcpId: $hcpId, practiceId: $practiceId) {
        statusCode
        userRole
        exceptions
        loggedInUser{
            id,
            title,
            firstName,
            lastName
          }
        result{
            id
            firstName
            lastName
            email
            dOB
            cellPhone
            homePhone
            workPhone
            address
            address2
            city
            state
            zip
            status
        }
    }
}
`;