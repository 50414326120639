import React from "react";
import styled, { css } from "styled-components";
import { theme } from "../Global";
import { Button } from "../buttons/Button";

const Text = styled.p`
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 2px;
  font-family: ${theme.font.medium};

  span {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
`;

const Label = styled.span`
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  font-family: ${theme.font.heavy};
`;

const Price = styled.p`
  font-size: 16px;
  line-height: 24px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-family: ${theme.font.heavy};
`;

const StyleCardInfo = styled.div`
  ${props =>
    props.booked &&
    css`
      ${Text} span {
        background-color: ${props =>
          props.doctorColor ? props.doctorColor : theme.color.primary};
      }

      ${Label} {
        color: ${theme.color.secondary};
      }
    `}

  ${props =>
    props.open &&
    css`
      ${Text} span {
        background-color: ${props =>
          props.doctorColor ? props.doctorColor : theme.color.primary};
      }

      ${Label} {
        color: #b3bccc;
      }
    `}
`;

const CardInfo = props => {
  const repeat = props.repeat ? <Text>{props.repeat}</Text> : "";
  const label = props.booked ? <Label>Booked</Label> : <Label>Open</Label>;

  return (
    <StyleCardInfo {...props}>
      {label}
      <Text>{props.date}</Text>
      <Text>
        <span></span> {props.doctor}
      </Text>
      {repeat}
      <Price>{props.price}</Price>
    </StyleCardInfo>
  );
};

const StyleConfirmation = styled.div`
  .button {
    padding-left: 55px;
    padding-right: 55px;
    height: 55px;
  }
`;

const GroupButton = styled.div`
  display: flex;
  .button:last-child {
    margin-left: 18px;
  }
`;

export const ConfirmationCard = props => {
  return (
    <StyleConfirmation>
      <CardInfo {...props}></CardInfo>
      <GroupButton>
        <Button primary onClick={props.onClickPrimary}>
          {props.primaryText}
        </Button>
        <Button secondary onClick={props.onClickSecondary}>
          {props.secondaryText}
        </Button>
      </GroupButton>
    </StyleConfirmation>
  );
};
