import gql from 'graphql-tag';

export default gql`
    mutation addStripeAccount($accessCode:String, $practiceId: ID!) {
        addStripeAccount(accessCode:$accessCode, practiceId:$practiceId){
        statusCode
        userRole
        exceptions
    }
}
`;