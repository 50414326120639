import gql from "graphql-tag";

export default gql`
  query {
    patientDashboard {
      statusCode
      userRole
      exceptions
      loggedInUser {
        id
        title
        firstName
        lastName
      }
      result {
        patient {
          id
          firstName
          lastName
          email
          workPhone
          dOB
          cellPhone
          homePhone
          address
          address2
          city
          state
          zip
        }
        hCPs {
          id
          title
          firstName
          lastName
          avatar
          practice {
            id
            name
            phone
          }
        }
        upcomingAppointments {
          bookedAppointmentId
          appointmentId
          status
          dateTime
          hCP {
            id
            firstName
            lastName
            title
            avatar
          }
          patient {
            firstName
            lastName
            homePhone
            dOB
          }
        }
        appointmentHistory {
          bookedAppointmentId
          appointmentId
          status
          dateTime
          hCP {
            id
            firstName
            lastName
            title
            avatar
          }
          patient {
            firstName
            lastName
            homePhone
            dOB
          }
        }
      }
    }
  }
`;
