import React, { Component } from "react";
import styled, { css } from "styled-components";
import { withRouter } from "react-router";
import { graphql } from "react-apollo";
import { compose } from "recompose";
import { theme } from "./Global";
import { UserPicture } from "./UserPicture";
import { Card, DoctorName } from "./Cards";
import { Button, ButtonGroup } from "./buttons/Button";
import Modal, { Confirmation, Information } from "./modals/Modal";

import svgPhone from "../assets/svg/phone.svg";
import svgEmail from "../assets/svg/email.svg";
import svgProfile from "../assets/svg/profile.svg";
import svgClock from "../assets/svg/clock.svg";
import svgCheckmark from "../assets/svg/checkmark.svg";
import CONFIRM_APPOINTMENT from "../mutations/appointments/confirmAppointment";
import CANCEL_APPOINTMENT from "../mutations/appointments/cancelAppointment";
import config from "../config";

const AppCard = styled(Card)`
  opacity: 1;
  transition: opacity 1.3s ease-in-out;

  ${props =>
    props.fade &&
    css`
      opacity: 0;
    `}

  &.appointment {
    display: block;
    .info {
      display: flex;
    }
    h4 {
      font-size: 15px;
    }

    ${props =>
      props.direction === "horizontal" &&
      css`
        display: flex;
        .content {
          width: 90%;
        }
      `};

    ${props =>
      props.singleButton &&
      css`
        ${ButtonGroup} {
          .button {
            height: 100%;
          }
          .button span {
            font-size: 20px;
          }
        }
      `};
  }
`;

const AppDoctorName = styled(DoctorName)`
  font-size: 16px;
  color: ${theme.color};
`;

const UserName = styled.span`
  display: inline-block;
  font-size: 18px;
  font-family: ${theme.font.regular};
  padding-bottom: 8px;
`;

const AppDate = styled(DoctorName)`
  font-size: 15px;
`;

const IconContent = styled.div`
  position: relative;
  display: flex;
  padding-bottom: 8px;
  img,
  .avatar {
    margin-right: 10px;
  }
  span {
    margin-right: 35px;
    color: ${theme.color.gray};
  }
`;

const NewPatient = styled.span`
  margin-left: 10px;
  top: -2px;
  position: relative;
  color: ${theme.color.secondary} !important;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1.8px;
`;
const IsReplace = styled.span`
  margin-left: 10px;
  top: -2px;
  position: relative;
  color: #f5ce36 !important;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.8px;
`;

const Upcoming = styled.div`
  position: absolute;
  right: 25px;
  top: 23px;
  border-radius: 50%;
  border: 2px solid #172380;
  background-color: ${props =>
    props.upcoming ? theme.color.primary : "transparent"};
  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  &.clickable {
    cursor: pointer;
  }
  img {
    margin: 0 auto;
  }
`;

const CardContent = styled.div`
  position: relative;
  padding: 22px 40px;
  ${props =>
    props.newPatient &&
    css`
      border-left: 6px solid ${theme.color.secondary};
    `};

  ${props =>
    props.isReplace &&
    css`
      border-left: 6px solid #f5ce36;
    `};
`;

export class AppointmentCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      serverError: false,
      animated: false,
      upcoming: props.upcoming,
      cancelAppt: false,
      issueRefund: false,
      confirmCancelAppt: false,
      appointmentCanceled: false
    };
  }

  render() {
    var handleIssueRefund = issueRefund => {
      this.setState({
        cancelAppt: false,
        appointmentCanceled: false,
        confirmCancelAppt: true,
        issueRefund: issueRefund
      });
    };

    var closeCancelationModals = () => {
      this.setState({
        cancelAppt: false,
        appointmentCanceled: false,
        confirmCancelAppt: false,
        issueRefund: false
      });
    };

    var closeSuccessModal = appt => {
      this.setState({ animated: true, upcoming: true });
      closeCancelationModals();
      setTimeout(() => {
        appt.refetch();
      }, 1000);
    };

    var handleCancelAppt = (appt, reOpenTimeSlot) => {
      this.props
        .cancelAppointment({
          name: "cancelAppointment",
          variables: {
            bookedAppointmentId: appt.bookedAppointmentId,
            issueRefund: this.state.issueRefund,
            reOpenTimeSlot: reOpenTimeSlot
          }
        })
        .then(res => {
          var data = res.data.cancelAppointment;
          if (!data || data.statusCode !== 200) {
            return;
          }

          this.setState({
            cancelAppt: false,
            confirmCancelAppt: false,
            appointmentCanceled: true
          });
        })
        .catch(({ graphQLErrors }) => {});
    };

    var handleConfirmApt = appt => {
      if (isPending(appt)) {
        this.setState({ animated: true, upcoming: true });

        this.props
          .confirmAppointment({
            name: "confirmAppointment",
            variables: { bookedAppointmentId: appt.bookedAppointmentId }
          })
          .then(res => {
            var data = res.data.confirmAppointment;
            if (!data || data.statusCode !== 200) {
              return;
            }

            // add opacity animation
            setTimeout(() => {
              appt.refetch();
            }, 1300);
          })
          .catch(({ graphQLErrors }) => {});
      }
    };

    var isPending = appt => {
      return (
        appt &&
        localStorage.getItem("role") === "PracticeAdmin" &&
        appt.status === "Booked"
      );
    };

    return (
      <AppCard
        fade={this.state.animated}
        direction={this.props.direction}
        singleButton={this.props.singleButton}
        className="appointment"
      >
        <CardContent
          className="content"
          newPatient={this.props.newPatient}
          isReplace={this.props.isReplace}
        >
          <Upcoming
            className={isPending.bind(this, this.props)() ? "clickable" : ""}
            upcoming={this.state.upcoming}
            onClick={handleConfirmApt.bind(this, this.props)}
          >
            {this.state.upcoming ? <img src={svgCheckmark} alt="" /> : ""}
          </Upcoming>

          {typeof this.props.userName !== "undefined" ? (
            <>
              <UserName>
                {this.props.userName}
                {this.props.newPatient ? (
                  <NewPatient>new patient</NewPatient>
                ) : (
                  ""
                )}

                {this.props.isReplace ? (
                  <IsReplace>Existing Appointment</IsReplace>
                ) : (
                  ""
                )}
              </UserName>

              <div className="info">
                {this.props.phone ? (
                  <IconContent>
                    <img src={svgPhone} alt="" />
                    <span>{this.props.phone}</span>
                  </IconContent>
                ) : (
                  <></>
                )}

                <IconContent>
                  <img src={svgProfile} alt="" />
                  <span>{this.props.birthday}</span>
                </IconContent>

                {this.props.email ? (
                  <IconContent>
                    <img src={svgEmail} alt="" />
                    <span>{this.props.email}</span>
                  </IconContent>
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : (
            <></>
          )}
          {this.props.appDate ? (
            <IconContent>
              <img src={svgClock} alt="" />
              <AppDate>{this.props.appDate}</AppDate>
            </IconContent>
          ) : (
            <></>
          )}

          <IconContent>
            {this.props.doctorImage ? (
              <UserPicture size="22px" picture={this.props.doctorImage} />
            ) : (
              ""
            )}
            <AppDoctorName>{this.props.doctorName}</AppDoctorName>
          </IconContent>
        </CardContent>
        {typeof this.props.userName !== "undefined" ? (
          <>
            <ButtonGroup direction={this.props.direction}>
              {!this.props.forInsurance ? (
                <Button
                  card
                  href={config.pdfReportUrl + "?id=" + this.props.bookedAppointmentId}
                >
                  See Receipt
                </Button>
              ) : (
                <>
                  <Button onClick={() => this.props.handleEdit()}>Edit</Button>
                  <Button onClick={() => this.props.handleDelete()}>
                    Delete
                  </Button>
                </>
              )}

              {this.props.refund ? (
                <Button
                  card
                  onClick={() => this.setState({ cancelAppt: true })}
                >
                  Cancel
                </Button>
              ) : (
                ""
              )}
            </ButtonGroup>
          </>
        ) : (
          <></>
        )}

        {this.state.cancelAppt && (
          <Modal isOpen large onClose={closeCancelationModals}>
            <Confirmation
              text={`You are cancelling this patient's appointment. Would you like to give the patient a refund?`}
              primaryText={"No, Just Cancel"}
              primaryClickHandler={handleIssueRefund.bind(this, true)}
              secondaryClickHandler={handleIssueRefund.bind(this, false)}
              secondaryText={"Request Refund"}
            />
          </Modal>
        )}

        {this.state.confirmCancelAppt && (
          <Modal isOpen large onClose={closeCancelationModals}>
            <Confirmation
              text={`Do you want to keep this as an open appointment on your YWait Calendar?`}
              primaryText={"Yes"}
              primaryClickHandler={handleCancelAppt.bind(
                this,
                this.props,
                true
              )}
              secondaryClickHandler={handleCancelAppt.bind(
                this,
                this.props,
                false
              )}
              secondaryText={"No, remove"}
            />
          </Modal>
        )}

        {this.state.appointmentCanceled && (
          <Modal
            isOpen
            large
            onClose={closeSuccessModal.bind(this, this.props)}
          >
            <Information
              danger
              text={`This appointment has been cancelled.`}
              secondaryText={"Close Window"}
              secondaryClickHandler={closeSuccessModal.bind(this, this.props)}
            />
          </Modal>
        )}
      </AppCard>
    );
  }
}

export default compose(
  withRouter,
  graphql(CONFIRM_APPOINTMENT, { name: "confirmAppointment" }),
  graphql(CANCEL_APPOINTMENT, { name: "cancelAppointment" })
)(AppointmentCard);
