import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { graphql } from "react-apollo";
import { compose } from "recompose";
import * as Yup from "yup";
import { Formik } from "formik";

import Notification from "../components/Notification";
import { theme } from "../components/Global";
import { Field, Input, Error, Button } from "../components/Forms";
import LOGIN from "../mutations/login";

const Form = styled.form`
  max-width: 400px;
  margin: 0 auto;

  p {
    text-align: center;
    color: ${theme.color.gray};

    a {
      color: ${theme.color.gray};
      text-decoration: underline;
    }
  }

  .button {
    margin-top: 40px;
  }
`;

const schema = Yup.object().shape({
  password: Yup.string().required("Password is required."),
  username: Yup.string()
    .email("Please enter a valid email address.")
    .required("Username is required.")
});

class PracticeLoginForm extends Component {
  constructor(props) {
    super(props);

    // reset password
    let resetPassword = false;

    if (props.location.state && props.location.state.reset_password) {
      resetPassword = true;
    }

    this.state = {
      serverError: false,
      serverMessage:
        "Incorrect email or password. Please correct the error and try again, or click forgot password.",
      successMsg: resetPassword
    };
  }

  handleSubmit = (values, actions) => {
    this.setState({ serverError: false, successMsg: false });

    this.props
      .login({
        name: "login",
        variables: {
          userName: values.username,
          password: values.password
        }
      })
      .then(res => {
        if (!res.data.login) {
          actions.setSubmitting(false);
          this.setState({ serverError: true });
          return;
        }
        localStorage.setItem("token", res.data.login.result.token);
        localStorage.setItem("role", res.data.login.result.role);

        if (res.data.login.result.patient) {
          localStorage.setItem("patientId", res.data.login.result.patient.id);
        }

        if (res.data.login.result.practice) {
          localStorage.setItem("practiceId", res.data.login.result.practice.id);
          localStorage.setItem(
            "accessCode",
            res.data.login.result.practice.accessCode
          );
        }
        window.location.href = "/dashboard";
      })
      .catch(({ graphQLErrors }) => {
        actions.setSubmitting(false);
        this.setState({ serverError: true });
      });
  };

  closeAlert = () => {
    this.setState({ serverError: false, successMsg: false });
  };

  render() {
    return (
      <Formik
        initialValues={{ username: "", password: "" }}
        validationSchema={schema}
        onSubmit={this.handleSubmit}
        render={({
          values,
          errors,
          isSubmitting,
          submitCount,
          handleBlur,
          handleChange,
          handleSubmit
        }) => (
          <Form onSubmit={handleSubmit}>
            {this.state.serverError && (
              <Notification
                close={this.closeAlert}
                mes
                open={this.state.serverError}
                message={this.state.serverMessage}
              />
            )}

            {this.state.successMsg && (
              <Notification
                success
                close={this.closeAlert}
                open={this.state.successMsg}
                message={"Password has been reset !"}
              />
            )}

            <Field label="Username">
              <Input
                className="input"
                placeholder="Email"
                name="username"
                type="text"
                value={values.username}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {submitCount > 0 && errors.username && (
                <Error>{errors.username}</Error>
              )}
            </Field>

            <Field label="Password">
              <Input
                className="input"
                name="password"
                type="password"
                value={values.password}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {submitCount > 0 && errors.password && (
                <Error>{errors.password}</Error>
              )}
            </Field>

            <p className="link-sign-up">
              Don't have an account?{" "}
              <Link to={this.props.register}>Get started</Link>{" "}
            </p>
            <p>
              <Link to="/forgot-password">Forgot Your Password</Link>{" "}
            </p>
            <div className="has-text-centered">
              <Button type="submit" secondary full disabled={isSubmitting}>
                Login
              </Button>
            </div>
          </Form>
        )}
      />
    );
  }
}

export default compose(
  withRouter,
  graphql(LOGIN, { name: "login" })
)(PracticeLoginForm);
