import DashboardPage from '../pages/patient/Dashboard'
import PastAppointmentsPage from '../pages/patient/PastAppointments'
import NewAppointmentPage from '../pages/patient/NewAppointment'
import ConfirmAppointmentPage from '../pages/patient/ConfirmAppointment'
import AccountPaymentPage from '../pages/patient/AccountPayment'
import AccountSettingsPage from '../pages/patient/AccountSettings'

export default [{
  path: '/',
  component: DashboardPage,
  title: 'YWait - Your Dashboard'
},
{
  path: '/dashboard',
  component: DashboardPage,
  title: 'YWait - Your Dashboard'  
},
{
  path: '/past-appointments',
  component: PastAppointmentsPage,
  title: 'YWait - Your Appointments'  
},
{
  path: '/new-appointment',
  component: NewAppointmentPage,
  title: 'YWait - New Appointments'  
},
{
  path: '/confirm-appointment',
  component: ConfirmAppointmentPage,
  title: 'YWait - Confirm Appointments'  
},
{
  path: '/account-settings',
  component: AccountSettingsPage,
  title: 'YWait - Account Settings - Profile'  
},
{
  path: '/account-payment',
  component: AccountPaymentPage,
  title: 'YWait - Account Payment - Profile'
}]