import gql from 'graphql-tag';

export default gql`
    query {
        globalState @client {
            __typename
            token
            userType
            practiceId
            patientId
        }
    }
`;