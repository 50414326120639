import React from 'react'
import { Query } from 'react-apollo'
import { compose } from 'recompose';
import moment from 'moment'
import { withRouter } from 'react-router';
import queryString from 'query-string'

import { RecieptCard } from '../../components/Cards'
import { formatENUM } from '../../components/Global'
import { LoadingIndicator, ErrorIndicator } from '../../components/indicators'
import Notification from '../../components/Notification'
import GET_APPOINTMENT from '../../queries/getAppointment'

class Reciept extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serverError: false,
      serverMessage: '',
    }
  }

  render() {
    var params = queryString.parse(this.props.location.search);
    if (!params.id) {
      return <Notification open={true} message="Appointment Not Found" />
    }
    return (
      <Query query={GET_APPOINTMENT} variables={{ appointmentId: params.id }}>
        {({ error, data, loading }) => {

          if (error) {
            console.error(error);
            return <ErrorIndicator />
          }
          const payload = data['getAppointment'];
          if (!payload || loading) {
            return <LoadingIndicator />
          }
          if (payload.statusCode != 200) {
            return <Notification open={true} message={formatENUM(payload.exceptions)} />
          }
          const appointment = payload['result'];

          return (
            <div className="container">
              <div className="columns is-centered is-multiline">
                <RecieptCard
                  patientName={ appointment.patient.firstName + " " + appointment.patient.lastName}
                  doctorName={ appointment.hCP.firstName + " " + appointment.hCP.lastName + ", "+ appointment.hCP.title}
                  practiceName={appointment.practice.name}
                  practiceAddress={appointment.practice.address +" "+appointment.practice.address2 +" "+appointment.practice.city+", "+appointment.practice.state +" "+appointment.practice.zip }
                  practicePhone={appointment.practice.phone}
                  date={moment.utc(appointment.dateTime).utcOffset('-06:00').format("dddd, MMMM Do")}
                  price={"$" + appointment.cost}
                  time={moment.utc(appointment.dateTime).utcOffset('-06:00').format("hh:mm a")}
                />

              </div>
            </div>
          )
        }}
      </Query>
    )
  }
}

export default compose(
  withRouter
)(Reciept);

