import React from "react";
import styled from "styled-components";
import YwaitSelect from "./Select";
import { theme } from "./Global";
import { ButtonMixin } from "./buttons/Button";

export const StyledButton = styled.button`
  ${ButtonMixin}
`;

export const InputWrapper = styled.div`
  input {
    font-size: 16px;
    font-family: ${theme.font.medium};
    font-weight: 100;
    border: 1px solid ${theme.color.light_gray};
    color: ${theme.color.text};
    padding: 0 15px;
    height: 60px;
    line-height: 60px;
    max-width: 100%;
    width: 100%;
    outline: none;

    ::placeholder {
      color: ${theme.color.light_gray};
      font-family: ${theme.font.regular};
    }

    :focus,
    :hover {
      border-color: ${theme.color.text};
      color: ${theme.color.text};
    }
  }

  input[value]:not([value=""]) {
    border-color: ${theme.color.text};
    color: ${theme.color.text};
  }
`;

export const Input = styled.input`
  font-size: 16px;
  font-family: ${theme.font.medium};
  font-weight: 100;
  border: 1px solid ${theme.color.light_gray};
  color: ${theme.color.text};
  padding: 0 15px;
  height: 60px;
  line-height: 60px;
  max-width: 100%;
  width: 100%;
  outline: none;

  ::placeholder {
    color: ${theme.color.light_gray};
    font-family: ${theme.font.regular};
  }

  :focus,
  :hover {
    border-color: ${theme.color.text};
    color: ${theme.color.text};
  }

  &[value]:not([value=""]) {
    border-color: ${theme.color.text};
    color: ${theme.color.text};
  }
`;

export const DollarInputWrapper = styled.div`
  position: relative;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input {
    -moz-appearance: textfield;
    text-indent: 10px;
  }

  &::after {
    font-size: 16px;
    font-family: ${theme.font.medium};
    font-weight: 100;
    content: "$";
    position: absolute;
    top: 18px;
    left: 14px;
  }
`;

export const Error = styled.div`
  color: ${theme.color.danger};
  padding-bottom: 10px;
`;

export const Label = styled.label`
  font-size: 15px;
  line-height: 40px;
`;

export const DollarInput = props => {
  return (
    <DollarInputWrapper>
      <Input {...props} />
    </DollarInputWrapper>
  );
};

export const Field = props => {
  return (
    <div className="field" {...props}>
      <Label className="label">{props.label}</Label>
      <div className="control">{props.children}</div>
    </div>
  );
};

export const Button = props => {
  return (
    <StyledButton {...props} className={`button`}>
      <span>{props.children}</span>
    </StyledButton>
  );
};

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" }
];

export default () => (
  <div>
    <Field label="Provider Phone">
      <Input
        className="input"
        name={""}
        type={""}
        value={""}
        placeholder={"Some text !!"}
      />
    </Field>

    <Field label="Provider Email*">
      <Input
        className="input"
        name={""}
        type={""}
        value={""}
        placeholder={"victoria.barbosa@daderma.com"}
      />
    </Field>

    <Field label="Select*">
      <YwaitSelect options={options} />
    </Field>
  </div>
);
