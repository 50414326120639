import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { Query, graphql } from 'react-apollo'
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { Button } from './buttons/Button'
import { ProfileCard } from './ProfileCard'
import { formatENUM } from './Global'
import GET_APPOINTMENT from '../queries/getAppointment'
import { LoadingIndicator, ErrorIndicator } from './indicators'
import Notification from './Notification'

import DocImage from '../assets/dr-avatar.png'

const StylePurchased = styled.div`
  p {
    padding-bottom: 30px;
    text-align: center;
    max-width: 595px;
    margin: 0 auto;    
  }

  .profile {
    max-width: 314px;
    margin: 0 auto; 
  }

  .button {
    padding-left: 56px;
    padding-right: 56px;
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 40px;
`


const Paragraph = styled.p`
  text-align: center;
  padding-top: 10px;
  padding-bottom: 20px;
  text-align: center;  
  font-size: 18px;
  line-height: 24px;
  max-width: 314px;
  margin: 0 auto; 
`

class Purchased extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serverError: false,
      serverMessage: '',
    }
  }

  render() {
    const handleGoToDashboard = () => {
      window.location.href = '/';
    }
    
    return (
      <Query query={GET_APPOINTMENT} variables={{ appointmentId: localStorage.getItem("selectedAppointmentId") }}>
        {({ error, data, loading }) => {

          if (error) {
            console.error(error);
            return <ErrorIndicator />
          }
          const payload = data['getAppointment'];
          if (!payload || loading) {
            return <LoadingIndicator />
          }
          if (payload.statusCode != 200) {
            return <Notification open={true} message={formatENUM(payload.exceptions)} />
          }
          const appointment = payload['result'];
          return (
            <StylePurchased>
              <p>
                If you are a new patient, allow 24 hours for a practice admin to contact you to
                register you and confirm insurance details. The practice admin will confirm your appointment.
            </p>

              <ProfileCard
                appDate={moment.utc(appointment.dateTime).local().format('dddd[,] MMM[.] Do [at] h:mma')}
                doctorImage={appointment.hCP.avatar || DocImage}
                doctorName={appointment.hCP.firstName + " " + appointment.hCP.lastName + ", " + appointment.hCP.title}
                speciality={appointment.hCP.speciality}
                phone={appointment.practice.phone}
                address={ appointment.practice.address + " " + appointment.practice.address2 }
              />

              <Paragraph>Your date and time are your premium appointment confirmation.</Paragraph>

              <ButtonWrapper>
                <Button onClick={handleGoToDashboard} secondary> go to dashboard  </Button>
              </ButtonWrapper>

            </StylePurchased>
          )
        }}
      </Query>
    )
  }

}
export default compose(
  withRouter
)(Purchased);