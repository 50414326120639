import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router';
import { graphql } from 'react-apollo'
import { compose } from "recompose";
import * as Yup from 'yup';
import { Formik } from 'formik'
import Notification from '../components/Notification'
import { theme, media } from '../components/Global'
import { Input, Error, Button } from '../components/Forms'
import GET_PRACTICE_BY_CODE from '../queries/getPracticeByCode'
import { formatENUM } from '../components/Global'

const Form = styled.form`
  max-width: 430px;
  margin: 0 auto;

  p {      
    a {
      color: ${theme.color.text};
      text-decoration: underline;
    }
  }

  .input {
    margin-top: 20px;
  }

  .button {
    margin-top: 20px;
    min-width: 280px;
  }
`

const Paragraph = styled.p`
  text-align: center;
  padding-top: 10px;
  padding-bottom: 20px;
  text-align: center;  
  font-size: 18px;
  line-height: 24px;
  ${media.phone`
    br { display: none; }
  `}
`

const schema = Yup.object().shape({
  code: Yup.string().required('Access Code is required.'),
});

class PatientAccessCodeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serverError: false,
      serverMessage: 'Incorrect access code. Please enter a valid access code or contact your provider for assistance.',
    }
  }

  handleSubmit = (values, actions) => {
    this.setState({ serverError: false });
    this.props.getPracticeByCode({
      name: 'getPracticeByCode',
      variables: {
        accessCode: values.code
      }
    }).then((res) => {
      var data = res.data.getPracticeByCode;
      if (!data || data.statusCode != 200) {
        actions.setSubmitting(false);
        this.setState({ serverError: true });
        return;
      }
      localStorage.setItem('selectedPracticeId', data.result.id);
      this.props.history.push('/get-started-with-ywait');
    });
  }

  closeAlert = () => {
    this.setState({ serverError: false });
  }

  render() {
    return (
      <Formik
        initialValues={{ code: '' }}
        validationSchema={schema}
        onSubmit={this.handleSubmit}
        render={({
          values,
          errors,
          isSubmitting,
          submitCount,
          handleBlur,
          handleChange,
          handleSubmit
        }) => (
            <Form onSubmit={handleSubmit}>
              {this.state.serverError && <Notification close={this.closeAlert} open={this.state.serverError} message={formatENUM(this.state.serverMessage)} />}

              <Input className="input"
                placeholder="Your 5-digit code"
                name="code"
                type="text"
                value={values.code}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {(submitCount > 0 && errors.code) && <Error>{errors.code}</Error>}

              <Paragraph>
                Don't have an Access Code? Contact your <br />
                doctor to recieve a YWait code.
              </Paragraph>


              {!localStorage.getItem('role') &&
                <Paragraph>Already have an account? <Link to='/sign-in'>Login</Link> </Paragraph>}

              <div className="has-text-centered">
                <Button type='submit' secondary disabled={isSubmitting}>Next</Button>
              </div>

            </Form>
          )}
      />
    )
  }
}

export default compose(
  withRouter,
  graphql(GET_PRACTICE_BY_CODE, { name: 'getPracticeByCode' })
)(PatientAccessCodeForm);