import React from 'react'
import styled from 'styled-components'
import { theme, media } from './Global';

export const Title = styled.h1`
  font-size: 50px;
  line-height: 55px;
  font-family: ${theme.font.bold};
  font-weight: bold;
  margin-bottom: 20px;
  display: block;

  
  ${media.phone`
    font-size: 40px;
    line-height: 45px;
  `};

  small {
    display: block;
    font-size: 20px;
    line-height: 20px;
    font-weight: normal;
    font-family:  ${theme.font.regular}; 
    padding-top: 5px;
  }

`;

export const H2 = styled.h2`
  font-size: 36px;
  line-height: 44px;
  font-family:  ${theme.font.rudi_thin};  
  margin-bottom: 20px;
`;

export const H3 = styled.h3`
  font-size: 18px;
  line-height: 24px;
  font-family: ${theme.font.rudi_thin};
  margin-bottom: 20px;
`;

export default () => (
  <div>
    <Title>Title Style 50px/55px</Title>
    <H2>Title Style 36px/44px</H2>
    <H3>Title Style 36px/44px</H3>
    <p className="large-body">Large Body Light & Medium 18px/24px</p>
    <p>Regular Body Light & Medium 15px/18px</p>
    <p className="small-body">MICRO 11px/11px</p>
  </div>
)