import gql from 'graphql-tag';

export default gql`
    query {
        practiceState @client {
            __typename
            schedulerSelectedDate
            schedulerView
            schedulerHCPs
            schedulerHCPsWereSet
            schedulerColorLegend
            schedulerActiveFlow
            schedulerSelectedSlot
            schedulerSelectedAppointment
            appointmentsHCPs
            appointmentsUpcomingFilter
            appointmentsPatientNewFilter
            appointmentsSortBy
            updateAppointmentSelectionParams
        }
    }
`;