const local = {
   apiUrl: "http://localhost:41000",
   graphql: "http://localhost:41000/api/graphql",
   pdfReportUrl: "http://localhost:41000/reciept",
   enableGA: false,
   stripeKey: "pk_test_uSOoDwKWnuGo4JhrKJYSLF7x00tLQc9JtN"
};

/* if you wish you can change this to localhost db (use code above) 
if needed otherwise it will point to azure 

const local = {
  apiUrl: "https://ywaitapi.azurewebsites.net",
  graphql: "https://ywaitapi.azurewebsites.net/api/graphql",
  pdfReportUrl: "https://ywaitapi.azurewebsites.net/reciept/",
  enableGA: false,
  stripeKey: "pk_test_uSOoDwKWnuGo4JhrKJYSLF7x00tLQc9JtN"
};
*/
const dev = {
  apiUrl: "https://ywaitapi.bytecraft.io",
  graphql: "https://ywaitapi.bytecraft.io/api/graphql",
  pdfReportUrl: "https://ywaitapi.bytecraft.io/reciept/",
  enableGA: false,
  stripeKey: "pk_test_uSOoDwKWnuGo4JhrKJYSLF7x00tLQc9JtN"
};

const staging = {
  apiUrl: "https://ywait-stage-api.2577aead700f.healthcareblocks.com",
  graphql: "https://ywait-stage-api.2577aead700f.healthcareblocks.com/api/graphql",
  pdfReportUrl: "https://ywait-stage-api.2577aead700f.healthcareblocks.com/reciept/",
  enableGA: false,
  stripeKey: "pk_test_uSOoDwKWnuGo4JhrKJYSLF7x00tLQc9JtN"
};
const production = {
  apiUrl: "https://ywait-prod-api.3b8c7957577b.healthcareblocks.com",
  graphql: "https://ywait-prod-api.3b8c7957577b.healthcareblocks.com/api/graphql",
  pdfReportUrl: "https://ywait-prod-api.3b8c7957577b.healthcareblocks.com/reciept/",
  enableGA: true,
  gaKey: "UA-134922830-1",
  gaDebug: true,
  stripeKey: "pk_live_AjrDKm1TyQ1HXRoYPAq6cpYG"
};

/*
const production = {
  apiUrl: 'https://api.ywaitdoc.com',
  graphql: 'https://api.ywaitdoc.com/api/graphql',
  pdfReportUrl: 'https://api.ywaitdoc.com/reciept',
  enableGA: true,
  gaKey: 'UA-134922830-1',
  gaDebug: true,
  stripeKey:"pk_live_AjrDKm1TyQ1HXRoYPAq6cpYG"
};
*/
let config = local;

switch (process.env.REACT_APP_STAGE) {
  case "dev":
    config = dev;
    break;

  case "staging":
    config = staging;
    break;

  case "production":
    config = production;
    break;

  default:
  case "local":
    config = local;
    break;
}

export default {
  ...config
};
