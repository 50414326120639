import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { rgba } from 'polished'
import { media, theme } from '../Global'
import { LogOutMenu } from './LogOutMenu'
import { PracticeLogInMenu } from './PracticeLogInMenu'
import { PatientLogInMenu } from './PatientLogInMenu'
import { Hamburger } from './Hamburger'

import logo from '../../assets/svg/ywait.svg'

export const StyledNavigation = styled.div`
  position: relative;
  z-index: 1;
  height: ${theme.navHeight};
  background-color: white;

  /* display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  align-items: center;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  -webkit-box-pack: space-between; */
  
  padding: 0 33px 0 44px;  
  width: 100%;
  margin: 0 auto;
  
  :before {
    content: '';
    box-shadow: 0 3px 12px 0 ${rgba('#6D7185', .20)};
    width: 100%;
    position: absolute;
    left: 0; top: calc(${theme.navHeight} - 2px);
    height: 2px;
  }

  > a {
    display: inline-block;
  }

  a {
    text-transform: capitalize;
  }

  ${media.phone`
    padding-right: 0;    
  `};
`;


const NavWrapper = styled.div`
  float: right;
  height: ${theme.navHeight};
  display: flex;
  align-items: center;
`

const Logo = styled.img`
  width: 107px;
  height: 47px;
  margin-top: 18px;
  display: inline-block;

  ${media.phone`
    width: 75px;
    height: 32px;    
    margin-top: 28px;
  `};
`;

class Navigation extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      practiceNav: 
        (props.role === 'PracticeAdmin' || 
         props.role === 'HCP' ),
      patientNav: 
        (props.role === 'Patient'),
      active: props.active
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
  }
    
  componentWillUnmount () {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    if (!document) return;
    const clientWidth = document.body.clientWidth;
    if (clientWidth >= theme.sizes.phone) {      
      this.setState({ active: false })
    }
  }

  hamburgerClick = () =>  this.setState({ active: !this.state.active })

  closeNav = () =>  this.setState({ active: false })
  
  render() {
    return (
      <StyledNavigation>
        
        <Link to="/">
          <Logo src={logo} alt="YWait logo" />
        </Link>   
        
        <NavWrapper>
          { this.state.practiceNav ? 
            <PracticeLogInMenu active={this.state.active} onPress={this.closeNav} onToggle={this.hamburgerClick} /> :
            ( this.state.patientNav ? 
              <PatientLogInMenu active={this.state.active} onPress={this.closeNav} onToggle={this.hamburgerClick} /> : 
                <LogOutMenu />)  }        
          { this.state.practiceNav || this.state.patientNav ? <Hamburger onPress={this.hamburgerClick} active={this.state.active} /> : '' }
        </NavWrapper>    

      </StyledNavigation>
    )
  }
}

export default Navigation;
