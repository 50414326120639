import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router';
import { graphql } from 'react-apollo'
import { compose } from 'recompose';
import * as Yup from 'yup';
import { Formik } from 'formik'
import Recaptcha from 'react-recaptcha'
import LOGIN from '../mutations/login'
import Cleave from 'cleave.js/react';

import Notification from '../components/Notification'
import { theme, formatENUM } from '../components/Global'
import { Field, Input, InputWrapper, Error, Button } from '../components/Forms'
import SIGNUP from '../mutations/patient/createPatient'
import UPDATE_GLOBAL_STATE from '../mutations/updateGlobalState'

const Form = styled.form`
  margin: 0 auto;
  p {  
    color: ${theme.color.gray};
    a {
      color: ${theme.color.gray};
      text-decoration: underline;
    }
  }

  .input { margin-bottom: 10px }

  ${Error} { padding-bottom: 5px }

  .button {
    margin-top: 40px;
    min-width: 350px;
  }
`

const Checkbox = styled.input`
  margin-right: 10px;
`

const MarginDiv = styled.div`
  margin: 3rem 0 0;
`


const dateRegExp = new RegExp("^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{4})*$");

const schema = Yup.object().shape({
  firstname: Yup.string().required('First Name is required.'),
  lastname: Yup.string().required('Last Name is required.'),
  birthday: Yup.string()
    .matches(dateRegExp, 'Date of Birth is not in the correct format.')
    .required('Date of Birth is required.'),
  phone: Yup.string().matches(/^[0-9\-.()\s]+$/, 'Phone is not in the correct format.')
                     .min(13, 'Phone is too short (min 9 characters)')
                     .max(20, 'Phone is too long (max 20 characters)')
                     .required('Phone Number is required.'),
  email: Yup.string()
    .email('Please enter a valid email address.')
    .required('Email is required.'),
  verifyEmail: Yup.string()
    .email('Please enter a valid email address.').oneOf([Yup.ref('email')], 'Emails addresses do not match.')
    .required('Confirm Email is required.'),
  password:
    Yup.string()
      .min(6, 'Password minimum length is 6 characters')
      .required('Password is required.'),
  verifyPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords are not the same!')
    .required('Verify Password is required.'),
  recaptcha: Yup.string().required('Recaptcha is required.'),
  terms: Yup.boolean().oneOf([true], 'Terms and Conditions is required.'),
});


const defaultValues = {
  firstname: '', lastname: '',
  birthday: '', phone: '',
  email: '', verifyEmail: '',
  password: '', verifyPassword: '',
  recaptcha: '',
  terms: false
}

class SignUpForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      serverError: false,
      serverMessage: 'Incorrect email or password. Please correct the error and try again, or click forgot password.'
    }
  }

  mapSubmitData(values) {
    return {
      patient: {
        title: "",
        firstName: values.firstname,
        lastName: values.lastname,
        email: values.email,
        dOB: values.birthday,
        newPassword: values.password,
        cellPhone: values.phone
      },
      practiceId: localStorage.getItem('selectedPracticeId'),
      hcpId: localStorage.getItem('selectedHcpId'),
    }
  }
  componentDidMount() {
    const script = document.createElement(`script`);
    script.src = `https://www.google.com/recaptcha/api.js`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  handleSubmit = (values, actions) => {

    this.setState({ serverError: false, successMsg: false });

    this.props.signup({
      name: 'signup',
      variables: this.mapSubmitData(values)
    }).then((res) => {

      const payload = res.data['createPatient'];

      if (!payload) {
        actions.setSubmitting(false);
        this.setState({ serverError: true, serverMessage: "Request Failed" });
        return;
      }

      if (payload.statusCode != 200) {
        actions.setSubmitting(false);
        this.setState({ serverError: true, serverMessage: formatENUM(payload.exceptions) });
        return;
      }

      this.props.login({
        name: 'login',
        variables: {
          userName: values.email,
          password: values.password,
        }
      }).then((res) => {

        if (!res.data.login) {
          actions.setSubmitting(false);
          this.setState({ serverError: true });
          return;
        }
        localStorage.setItem('token', res.data.login.result.token);
        localStorage.setItem('role', res.data.login.result.role);
        if (res.data.login.result.patient) {
          localStorage.setItem('patientId', res.data.login.result.patient.id);
        }
        window.location.href = '/payment-info';
      })
        .catch(({ graphQLErrors }) => {
          actions.setSubmitting(false);
          this.setState({ serverError: true });
        });

    })
      .catch(({ graphQLErrors }) => {
        actions.setSubmitting(false);
        this.setState({ serverError: true });
      });
  }

  closeAlert = () => {
    this.setState({ serverError: false, successMsg: false });
  }

  render() {
    return (
      <Formik
        initialValues={defaultValues}
        validationSchema={schema}
        onSubmit={this.handleSubmit}
        render={({
          values,
          errors,
          isSubmitting,
          submitCount,
          handleBlur,
          setFieldValue,
          handleChange,
          handleSubmit
        }) => (

            <Form onSubmit={handleSubmit}>

              {this.state.serverError && <Notification
                close={this.closeAlert}
                open={this.state.serverError}
                message={this.state.serverMessage}
              />}

              <div className="columns is-multiline">
                <div className="column is-6">
                  <Field label="First Name">
                    <Input className="input"
                      placeholder=""
                      name="firstname"
                      type="text"
                      value={values.firstname}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {(submitCount > 0 && errors.firstname) && <Error>{errors.firstname}</Error>}
                  </Field>
                </div>
                <div className="column is-6">
                  <Field label="Last Name">
                    <Input className="input"
                      placeholder=""
                      name="lastname"
                      type="text"
                      value={values.lastname}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {(submitCount > 0 && errors.lastname) && <Error>{errors.lastname}</Error>}
                  </Field>
                </div>
                <div className="column is-6">
                  <Field label="Date of Birth">
                    <InputWrapper>
                      <Cleave
                        className="input"
                        name="birthday"
                        value={values.birthday}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="MM/DD/YYYY"
                        options={{ date: true, datePattern: ['m', 'd', 'Y'] }}
                      />
                    </InputWrapper>
                    {(submitCount > 0 && errors.birthday) && <Error>{errors.birthday}</Error>}
                  </Field>
                </div>
                <div className="column is-6">
                  <Field label="Cell Number">
                    <InputWrapper>
                      <Cleave
                        name="phone"
                        type="text"
                        value={values.phone}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        options={{ numericOnly: true, blocks: [0, 3, 3, 4], delimiters: ["(", ") ", "-"] }}
                      />
                    </InputWrapper>
                    {(submitCount > 0 && errors.phone) && <Error>{errors.phone}</Error>}
                  </Field>
                </div>
                <div className="column is-6">
                  <Field label="Email">
                    <Input className="input"
                      placeholder=""
                      name="email"
                      type="text"
                      value={values.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {(submitCount > 0 && errors.email) && <Error>{errors.email}</Error>}
                  </Field>
                </div>
                <div className="column is-6">
                  <Field label="Confirm Email">
                    <Input className="input"
                      placeholder=""
                      name="verifyEmail"
                      type="text"
                      value={values.verifyEmail}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {(submitCount > 0 && errors.verifyEmail) && <Error>{errors.verifyEmail}</Error>}
                  </Field>
                </div>
                <div className="column is-6">
                  <Field label="Create Password">
                    <Input className="input"
                      name="password"
                      type="password"
                      value={values.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {(submitCount > 0 && errors.password) && <Error>{errors.password}</Error>}
                  </Field>
                  <p>Password minimum length is 6 characters.</p>
                </div>
                <div className="column is-6">
                  <Field label="Verify Password">
                    <Input className="input"
                      name="verifyPassword"
                      type="password"
                      value={values.verifyPassword}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {(submitCount > 0 && errors.verifyPassword) && <Error>{errors.verifyPassword}</Error>}
                  </Field>
                </div>
                <div className="column is-12">

                  <Checkbox
                    name="terms"
                    type="checkbox"
                    value={values.terms}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />

                  I Agree to the  <Link to='/terms-of-use'>Terms and Conditions</Link> of YWait.
                </div>
                <div className="column is-6">
                  <Recaptcha
                    sitekey="6LfmvbYUAAAAACR-ESDC7J224kKnDtzr64wrSNXy"
                    render="explicit"
                    theme="white"
                    verifyCallback={(response) => { setFieldValue("recaptcha", response); }}
                    onloadCallback={() => {}}
                  />

                  {(submitCount > 0 && errors.recaptcha) && <Error>{errors.recaptcha}</Error>}

                </div>
              </div>

              {(submitCount > 0 && errors.terms) && <Error>{errors.terms}</Error>}

              <div className="has-text-centered">
                <Button type='submit' secondary disabled={isSubmitting}>Create Account</Button>
              </div>

            </Form>
          )}
      />
    )
  }
}

export default compose(
  withRouter,
  graphql(LOGIN, { name: 'login' }),
  graphql(UPDATE_GLOBAL_STATE, { name: 'updateGlobalState' }),
  graphql(SIGNUP, { name: 'signup' }),
)(SignUpForm);