import gql from 'graphql-tag';

export default gql`
    query {
        patientPaymentMethods {
            statusCode
            userRole
            exceptions
            loggedInUser{
                id,
                title,
                firstName,
                lastName
              }
            result{
                id
                last4
                expMonth
                expYear
                address_zip
                brand
            }
        }
    }
`;