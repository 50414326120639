import React from 'react'
import { withRouter } from 'react-router';
import { compose } from "recompose";
import { Title } from '../components/Typography'
import PracticeHCPs from '../components/PracticeHCPs'

class Hcps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      successMessage: "",
      serverError: false
    }
  }
  

  render() {
    return (
      <React.Fragment>
        <Title className="has-text-centered">Add Providers To Your Practice</Title>
        <PracticeHCPs handleNext={this.props.handleNext} />
      </React.Fragment>
    )
  }
}

export default compose(
  withRouter,
)(Hcps);