import gql from "graphql-tag";

export default gql`
  mutation createPractice($practice: PracticeInputType!) {
    createPractice(practice: $practice) {
      statusCode
      userRole
      exceptions
      loggedInUser {
        id
        title
        firstName
        lastName
      }
      result {
        id
        ownerId
        firstName
        lastName
        email
        name
        accessCode
        phone
        address
        address2
        city
        state
        zip
        status
      }
    }
  }
`;
