import React from 'react'
import styled from 'styled-components'
import {theme} from './Global'

export const SchedulerViewToggleList = styled.ul`  
    
`;

export const SchedulerViewToggleListItem = styled.li`  
    display: inline-block;
    font-family: ${theme.font.medium};
    text-transform: uppercase;
    color: ${theme.color.light_gray};
    cursor: pointer;
    letter-spacing: 2px;

    &.selected {
      color: ${theme.color.text};
    }

    + li {
      margin-left: 50px;
    }
`;

export const SchedulerViewToggle = (props) => {
  return(
      <SchedulerViewToggleList>
      {
          props.values.map(value => 
            <SchedulerViewToggleListItem 
                key={value} 
                onClick={props.onClick.bind(this, value)} 
                className={props.currentValue === value ? `selected` :`` }>
                {value}
            </SchedulerViewToggleListItem>
          )
      }
      </SchedulerViewToggleList>
  );
};