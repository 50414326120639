import Moment from "moment";
import { extendMoment } from "moment-range";
import { withHandlers } from "recompose";
import GET_PRACTICE_APPOINTMENTS from "../../queries/getPracticeAppointments";

const addCalendarHandlers = withHandlers({
  slotClick: props => (slot, event) => {
    const { updatePracticeState, practiceState } = props;

    const newPracticeState = {
      ...practiceState,
      schedulerActiveFlow: "createAppointment",
      schedulerSelectedSlot: slot,
      schedulerSelectedAppointment: null
    };

    updatePracticeState({
      name: "updatePracticeState",
      variables: {
        practiceState: newPracticeState
      }
    });
  },

  appointmentClick: props => (appointment, event) => {
    const { updatePracticeState, practiceState } = props;
    event.stopPropagation();
    console.log("You want an appointment");
    const newPracticeState = {
      ...practiceState,
      schedulerActiveFlow: "showAppointment",
      schedulerSelectedSlot: null,
      schedulerSelectedAppointment: appointment
    };

    updatePracticeState({
      name: "updatePracticeState",
      variables: {
        practiceState: newPracticeState
      }
    });
  },

  appointmentEditClick: props => event => {
    const { updatePracticeState, practiceState } = props;

    const newPracticeState = {
      ...practiceState,
      schedulerActiveFlow: "editAppointment"
    };

    updatePracticeState({
      name: "updatePracticeState",
      variables: {
        practiceState: newPracticeState
      }
    });
  },

  appointmentEditOnceClick: props => event => {
    localStorage.setItem('editoccurence',"Once");
    const { updatePracticeState, practiceState } = props;

    const newPracticeState = {
      ...practiceState,
      schedulerActiveFlow: "editOnceAppointment"
    };

    updatePracticeState({
      name: "updatePracticeState",
      variables: {
        practiceState: newPracticeState
      }
    });
  },

  appointmentEditAllClick: props => event => {
    localStorage.setItem('editoccurence',"All");
    const { updatePracticeState, practiceState } = props;

    const newPracticeState = {
      ...practiceState,
      schedulerActiveFlow: "editAllAppointment"
    };

    updatePracticeState({
      name: "updatePracticeState",
      variables: {
        practiceState: newPracticeState
      }
    });
  },

  appointmentWithOccuranceDeleteClick: props => event => {
    const { updatePracticeState, practiceState } = props;

    const newPracticeState = {
      ...practiceState,
      schedulerActiveFlow: "deleteAppointmentWithOccurence"
    };

    updatePracticeState({
      name: "updatePracticeState",
      variables: {
        practiceState: newPracticeState
      }
    });
  },

  appointmentCreateClick: props => (data, action, failAction, event) => {
    const { createAppointment } = props;
    createAppointment({
      variables: data,
      awaitRefetchQueries: false,
      refetchQueries: [
        {
          query: GET_PRACTICE_APPOINTMENTS,
          variables: JSON.parse(
            localStorage.getItem("calendarAppointmentParams")
          ),
          fetchPolicy: "network-only"
        }
      ]
    }).then(res => {
      var data = res.data.createAppointment;
      if (!data || data.statusCode != 200) {
        failAction(data.exceptions);
      } else {
        action();
      }
      event.setSubmitting(false);
    });
  },

  appointmentUpdateClick: props => (data, action, failAction, event) => {
    const { updateAppointment } = props;

    updateAppointment({
      variables: data,
      awaitRefetchQueries: false,
      refetchQueries: [
        {
          query: GET_PRACTICE_APPOINTMENTS,
          variables: JSON.parse(
            localStorage.getItem("calendarAppointmentParams")
          ),
          fetchPolicy: "network-only"
        }
      ]
    }).then(res => {
      var data = res.data.updateAppointment;
      if (!data || data.statusCode != 200) {
        failAction(data.exceptions);
      } else {
        action();
      }
      event.setSubmitting(false);
    });
  },

  appointmentDeleteClick: props => event => {
    const { deleteAppointment, updatePracticeState, practiceState } = props;

    deleteAppointment({
      name: "deleteAppointment",
      variables: {
        appointmentId: practiceState.schedulerSelectedAppointment.appointmentId
      },
      refetchQueries: [
        {
          query: GET_PRACTICE_APPOINTMENTS,
          variables: JSON.parse(
            localStorage.getItem("calendarAppointmentParams")
          ),
          fetchPolicy: "network-only"
        }
      ]
    }).then(() => {
      const newPracticeState = {
        ...practiceState,
        schedulerActiveFlow: "",
        schedulerSelectedSlot: null,
        schedulerSelectedAppointment: null
      };

      updatePracticeState({
        name: "updatePracticeState",
        variables: {
          practiceState: newPracticeState
        }
      });
    });
  },

  appointmentDeleteAllClick: props => event => {
    
    const { deleteAllAppointments, updatePracticeState, practiceState } = props;

    deleteAllAppointments({
      name: "deleteAllAppointments",
      variables: {
        appointmentId: practiceState.schedulerSelectedAppointment.appointmentId
      },
      refetchQueries: [
        {
          query: GET_PRACTICE_APPOINTMENTS,
          variables: JSON.parse(
            localStorage.getItem("calendarAppointmentParams")
          ),
          fetchPolicy: "network-only"
        }
      ]
    }).then(() => {
      const newPracticeState = {
        ...practiceState,
        schedulerActiveFlow: "",
        schedulerSelectedSlot: null,
        schedulerSelectedAppointment: null
      };

      updatePracticeState({
        name: "updatePracticeState",
        variables: {
          practiceState: newPracticeState
        }
      });
    });
  },

  dayClick: props => (day, event) => {
    const { updatePracticeState, practiceState } = props;
    event.stopPropagation();

    const newPracticeState = {
      ...practiceState,
      schedulerView: "Day",
      schedulerSelectedDate: day.toDate()
    };

    updatePracticeState({
      name: "updatePracticeState",
      variables: {
        practiceState: newPracticeState
      }
    });
  },

  resetCalendarFlow: props => event => {
    const { updatePracticeState, practiceState } = props;

    const newPracticeState = {
      ...practiceState,
      schedulerActiveFlow: "",
      schedulerSelectedSlot: null,
      schedulerSelectedAppointment: null
    };

    updatePracticeState({
      name: "updatePracticeState",
      variables: {
        practiceState: newPracticeState
      }
    });
  },

  filterAppointments: props => (date, sliceType) => {
    const moment = extendMoment(Moment);

    return function(appointment) {
      return moment(appointment.dateTime).isBetween(
        date.clone().startOf(sliceType),
        date.clone().endOf(sliceType),
        null,
        "[]"
      );
    };
  },

  buildStyleProps: props => (index, appointment, appointments) => {
    const percentageCellWidth = 95;
    const pxCellHeight = 58;

    const moment = extendMoment(Moment);

    const srcApptRange = moment.range(
      moment(appointment.dateTime).clone(),
      moment(appointment.dateTime)
        .clone()
        .add("minutes", appointment.duration)
    );

    const apptsThatSharePartOfSlot = appointments.filter(
      appt =>
        moment
          .range(
            moment(appt.dateTime),
            moment(appt.dateTime).add("minutes", appt.duration)
          )
          .overlaps(srcApptRange) ||
        appt.appointmentId === appointment.appointmentId
    );

    return {
      width: percentageCellWidth / apptsThatSharePartOfSlot.length + "%",
      height: pxCellHeight * (appointment.duration / 60),
      top: pxCellHeight * (moment(appointment.dateTime).format("mm") / 60),
      left:
        apptsThatSharePartOfSlot.indexOf(appointment) *
          (percentageCellWidth / apptsThatSharePartOfSlot.length) +
        "%"
    };
  },

  buildDaysOfWeekNames: props => () => {
    return ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"];
  }
});

export default addCalendarHandlers;
