import gql from "graphql-tag";

export default gql`
  mutation createHcp($hcp: HCPInputType!, $practiceId: ID!) {
    createHcp(hcp: $hcp, practiceId: $practiceId) {
      statusCode
      userRole
      exceptions
      loggedInUser {
        id
        title
        firstName
        lastName
      }
      result {
        id
        userId
        title
        firstName
        lastName
        email
        defaultCost
        avatar
        status
      }
    }
  }
`;
