import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router';
import moment from "moment";
import { graphql } from 'react-apollo'
import { compose } from 'recompose';
import * as Yup from 'yup';
import { Formik } from 'formik'
import Recaptcha from 'react-recaptcha'
import LOGIN from '../mutations/login'
import Cleave from 'cleave.js/react';
import {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import { YwaitDayPickerInput } from '../components/DatePicker'
import { SelectedDateStyle } from '../components/Scheduler.styles.js'
import Notification from '../components/Notification'
import { theme, formatENUM } from '../components/Global'
import { Field, Input, InputWrapper, Error, Button } from '../components/Forms'
import SIGNUP from '../mutations/patient/createPatient'
import UPDATE_GLOBAL_STATE from '../mutations/updateGlobalState'
import CREATE_WAITLIST from '../mutations/patient/updateWaitlist.js'
import YWaitTable from '../components/Table.js'
import '../scss/table.css'



const Form = styled.form`
  margin: 0 auto;
  p {  
    color: ${theme.color.gray};
    a {
      color: ${theme.color.gray};
      text-decoration: underline;
    }
  }

  .input { margin-bottom: 10px }

  ${Error} { padding-bottom: 5px }

  .button {
    margin-top: 40px;
    min-width: 350px;
  }
  .group-startday { width: 350px; }
`

const Checkbox = styled.input`
  margin-right: 10px;
`

const RadioButton = styled.input`
  margin-right: 10px;
`

const dateRegExp = new RegExp("^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{4})*$");

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address.')
    .required('Email is required.'),
  terms: Yup.boolean().oneOf([true], 'Terms and Conditions is required.'),
  mobileno: Yup.string().matches(/^[0-9\-.()\s]+$/, 'Phone is not in the correct format.')
                    //  .min(13, 'Phone is too short (min 9 characters)')
                    //  .max(20, 'Phone is too long (max 20 characters)')
                     .required('Phone Number is required.')
});

let search = window.location.search;
let params = new URLSearchParams(search);
let waitlistId = params.get('waitlistId');

class WaitlistForm extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      serverError: false,
      serverMessage: 'Incorrect email or password. Please correct the error and try again, or click forgot password.',
      days: props.post.preferredTimeOfTheDay.split(','),
      selectedOption: props.post.notificationPreference.toLowerCase(),
      notifySelectedOption: props.post.notificationOn,
      defaultValues:{
        firstname:props.post.firstName,
        lastname:props.post.lastName,
        email:props.post.email,
        mobileno:props.post.mobileNo,
        dateTimeDay:props.post.notificationsCutOffDate == null?null: moment(props.post.notificationsCutOffDate).format('MM-DD-YYYY')
      },
      checkedArr : {
        monmorn : props.post.preferredTimeOfTheDay.split(',').indexOf("Monday-Morning")>-1,
        monafter : props.post.preferredTimeOfTheDay.split(',').indexOf("Monday-Afternoon")>-1,
            momeven : props.post.preferredTimeOfTheDay.split(',').indexOf("Monday-Evening")>-1,
            tuesmorn : props.post.preferredTimeOfTheDay.split(',').indexOf("Tuesday-Morning")>-1,
            tuesafter : props.post.preferredTimeOfTheDay.split(',').indexOf("Tuesday-Afternoon")>-1,
            tueseven : props.post.preferredTimeOfTheDay.split(',').indexOf("Tuesday-Evening")>-1,
            wenmorn :props.post.preferredTimeOfTheDay.split(',').indexOf("Wednesday-Morning")>-1,
            wenafter : props.post.preferredTimeOfTheDay.split(',').indexOf("Wednesday-Afternoon")>-1,
            weneven : props.post.preferredTimeOfTheDay.split(',').indexOf("Wednesday-Evening")>-1,
            thurmorn :props.post.preferredTimeOfTheDay.split(',').indexOf("Thursday-Morning")>-1,
            thurafter : props.post.preferredTimeOfTheDay.split(',').indexOf("Thursday-Afternoon")>-1,
            thureven : props.post.preferredTimeOfTheDay.split(',').indexOf("Thursday-Evening")>-1,
            frimorn : props.post.preferredTimeOfTheDay.split(',').indexOf("Friday-Morning")>-1,
            friafter : props.post.preferredTimeOfTheDay.split(',').indexOf("Friday-Afternoon")>-1,
            frieven : props.post.preferredTimeOfTheDay.split(',').indexOf("Friday-Evening")>-1
      }
      
    }
  }

  

  handleDaySelect  = (event)  => {
    
    var dayArr = [...this.state.days];
    const value = event.target.value
    const index = dayArr.findIndex(day => day === value);
    if(index > -1) {
        dayArr = [...dayArr.slice(0, index), ...dayArr.slice(index + 1)]
    } else {
        dayArr.push(value);
    }
    this.state.days = dayArr;
    this.setState({
      checkedArr : {
        monmorn : dayArr.indexOf("Monday-Morning")>-1,
        monafter : dayArr.indexOf("Monday-Afternoon")>-1,
        momeven : dayArr.indexOf("Monday-Evening")>-1,
        tuesmorn : dayArr.indexOf("Tuesday-Morning")>-1,
        tuesafter : dayArr.indexOf("Tuesday-Afternoon")>-1,
        tueseven : dayArr.indexOf("Tuesday-Evening")>-1,
        wenmorn : dayArr.indexOf("Wednesday-Morning")>-1,
        wenafter : dayArr.indexOf("Wednesday-Afternoon")>-1,
        weneven : dayArr.indexOf("Wednesday-Evening")>-1,
        thurmorn : dayArr.indexOf("Thursday-Morning")>-1,
        thurafter : dayArr.indexOf("Thursday-Afternoon")>-1,
        thureven : dayArr.indexOf("Thursday-Evening")>-1,
        frimorn : dayArr.indexOf("Friday-Morning")>-1,
        friafter : dayArr.indexOf("Friday-Afternoon")>-1,
        frieven : dayArr.indexOf("Friday-Evening")>-1
      }
    });
 }

 handleOptionChange = (changeEvent) => {
  this.setState({
    selectedOption: changeEvent.target.value
  });
}

handleNotifyOptionChange = (changeEvent) => {
      this.setState({
        notifySelectedOption: changeEvent.target.value
      });
    }

  mapSubmitData(values) {
    const apptDate =
      values.dateTimeDay != null ?
        moment(values.dateTimeDay).tz('America/Chicago') :
        null;
    return {
      waitlist: {
        firstName: values.firstname,
        lastName: values.lastname,
        email: values.email,
        mobileNo: values.mobileno,
        preferredTimeOfTheDay : this.state.days.join(','),
        notificationPreference : this.state.selectedOption,
        notificationOn : this.state.notifySelectedOption,
        notificationsCutOffDate : apptDate !== null ? moment.utc(apptDate).toISOString() : null
      },
      waitlistId: waitlistId
    }
  }
  

  handleSubmit = (values, actions) => {
    this.setState({ serverError: false, successMsg: false });
    this.props.signupwaitlist({
      name: 'signupwaitlist',
      variables: this.mapSubmitData(values)
    }).then((res) => {
      const payload = res.data['updateWaitlist'];

      if (!payload) {
        actions.setSubmitting(false);
        this.setState({ serverError: true, serverMessage: "Request Failed" });
        return;
      }

      if (payload.statusCode != 200) {
        actions.setSubmitting(false);
        this.setState({ serverError: true, serverMessage: formatENUM(payload.exceptions) });
        return;
      }
      window.location.href = '/are-you-new-patient';

    })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors,"graphQLErrors");
        actions.setSubmitting(false);
        this.setState({ serverError: true, serverMessage:graphQLErrors });
      });
  }

  closeAlert = () => {
    this.setState({ serverError: false, successMsg: false });
  }

  render() {
    return (
      <Formik
        initialValues={this.state.defaultValues}
        validationSchema={schema}
        onSubmit={this.handleSubmit}
        render={({
          values,
          errors,
          isSubmitting,
          submitCount,
          handleBlur,
          setFieldValue,
          handleChange,
          handleSubmit
        }) => (

            <Form onSubmit={handleSubmit}>

              {this.state.serverError && <Notification
                close={this.closeAlert}
                open={this.state.serverError}
                message={this.state.serverMessage}
              />}
              <div>
                    <p>Edit Waitlist Preferences</p>
                </div>
                <br/>
                <div className="radioNt">
                <RadioButton
                  name="turnon"
                  type="radio"
                  value="1"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  checked={this.state.notifySelectedOption === "1"}
                  onChange={this.handleNotifyOptionChange}
                />Turn ON notifications
              </div>
              <br />
              <div className="radioNt">
                <RadioButton
                  name="turnoff"
                  type="radio"
                  value="0"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  checked={this.state.notifySelectedOption === "0"}
                  onChange={this.handleNotifyOptionChange}
                />Turn OFF notifications
              </div>
              <br/>
              <div className="columns is-multiline">
                <div className="column is-6">
                  <Field label="First Name">
                    <Input className="input"
                      placeholder=""
                      name="firstname"
                      type="text"
                      value={values.firstname}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {(submitCount > 0 && errors.firstname) && <Error>{errors.firstname}</Error>}
                  </Field>
                </div>
                <div className="column is-6">
                  <Field label="Last Name">
                    <Input className="input"
                      placeholder=""
                      name="lastname"
                      type="text"
                      value={values.lastname}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {(submitCount > 0 && errors.lastname) && <Error>{errors.lastname}</Error>}
                  </Field>
                </div>
                
                
                <div className="column is-6">
                  <Field label="Email">
                    <Input className="input"
                      placeholder=""
                      name="email"
                      type="text"
                      value={values.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {(submitCount > 0 && errors.email) && <Error>{errors.email}</Error>}
                  </Field>
                </div>
                <div className="column is-6">
                  <Field label="Cell Number">
                    <InputWrapper>
                      <Cleave
                        name="mobileno"
                        type="text"
                        value={values.mobileno}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        options={{ numericOnly: true, blocks: [0, 3, 3, 4], delimiters: ["(", ") ", "-"] }}
                      />
                    </InputWrapper>
                    {(submitCount > 0 && errors.mobileno) && <Error>{errors.mobileno}</Error>}
                  </Field>
                </div>
              </div>
              <div>
                  <p>Choose preferred day and time of when you would like to see your physician</p>
                  <div className="column is-12">
                  <Field>
                  <table className="table">
                      <thead>
                        <tr>
                          <th>
                              Weekday
                          </th>
                          <th>
                              Morning (9am-12pm)
                          </th>
                          <th>
                              Afternoon (12pm-5pm)
                          </th>
                          <th>
                              Evening (5pm-9pm)
                          </th>
                          </tr>
                      </thead>
                      <tbody>
                      <tr>
                              <td>
                              Monday
                              </td>
                              <td>
                              <Checkbox name="monmorn" checked={this.state.checkedArr.monmorn} type="checkbox" value="Monday-Morning" onBlur={handleBlur} onChange={this.handleDaySelect}/>
                              </td>
                              <td>
                              <Checkbox name="monafter" checked={this.state.checkedArr.monafter} type="checkbox" value="Monday-Afternoon" onBlur={handleBlur} onChange={this.handleDaySelect}/>
                              </td>
                              <td>
                              <Checkbox name="moneven" checked={this.state.checkedArr.momeven} type="checkbox" value="Monday-Evening" onBlur={handleBlur} onChange={this.handleDaySelect}/>
                              </td>
                          </tr>
                          <tr>
                              <td>
                              Tuesday
                              </td>
                              <td>
                              <Checkbox name="one" checked={this.state.checkedArr.tuesmorn} type="checkbox" value="Tuesday-Morning" onBlur={handleBlur} onChange={this.handleDaySelect}/>
                              </td>
                              <td>
                              <Checkbox name="two" checked={this.state.checkedArr.tuesafter} type="checkbox" value="Tuesday-Afternoon" onBlur={handleBlur} onChange={this.handleDaySelect}/>
                              </td>
                              <td>
                              <Checkbox name="three" checked={this.state.checkedArr.tueseven} type="checkbox" value="Tuesday-Evening" onBlur={handleBlur} onChange={this.handleDaySelect}/>
                              </td>
                          </tr>
                          <tr>
                              <td>
                              Wednesday
                              </td>
                              <td>
                              <Checkbox name="one" checked={this.state.checkedArr.wenmorn} type="checkbox" value="Wednesday-Morning" onBlur={handleBlur} onChange={this.handleDaySelect}/>
                              </td>
                              <td>
                              <Checkbox name="two" checked={this.state.checkedArr.wenafter} type="checkbox" value="Wednesday-Afternoon" onBlur={handleBlur} onChange={this.handleDaySelect}/>
                              </td>
                              <td>
                              <Checkbox name="three" checked={this.state.checkedArr.weneven} type="checkbox" value="Wednesday-Evening" onBlur={handleBlur} onChange={this.handleDaySelect}/>
                              </td>
                          </tr>
                          <tr>
                              <td>
                              Thursday
                              </td>
                              <td>
                              <Checkbox name="one" checked={this.state.checkedArr.thurmorn} type="checkbox" value="Thursday-Morning" onBlur={handleBlur} onChange={this.handleDaySelect}/>
                              </td>
                              <td>
                              <Checkbox name="two" checked={this.state.checkedArr.thurafter} type="checkbox" value="Thursday-Afternoon" onBlur={handleBlur} onChange={this.handleDaySelect}/>
                              </td>
                              <td>
                              <Checkbox name="three" checked={this.state.checkedArr.thureven} type="checkbox" value="Thursday-Evening" onBlur={handleBlur} onChange={this.handleDaySelect}/>
                              </td>
                          </tr>
                          <tr>
                              <td>
                              Friday
                              </td>
                              <td>
                              <Checkbox name="one" checked={this.state.checkedArr.frimorn} type="checkbox" value="Friday-Morning" onBlur={handleBlur} onChange={this.handleDaySelect}/>
                              </td>
                              <td>
                              <Checkbox name="two" checked={this.state.checkedArr.friafter} type="checkbox" value="Friday-Afternoon" onBlur={handleBlur} onChange={this.handleDaySelect}/>
                              </td>
                              <td>
                              <Checkbox name="three" checked={this.state.checkedArr.frieven} type="checkbox" value="Friday-Evening" onBlur={handleBlur} onChange={this.handleDaySelect}/>
                              </td>
                          </tr>
                      </tbody>
                  </table>
                  </Field>
                  </div>
              </div>
              <br />
              <div>
                  <p>How would you prefer to receive notifications?</p>
              </div>
              <br />
              <div className="radio">
                <RadioButton
                  name="viaemail"
                  type="radio"
                  value="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  checked={this.state.selectedOption === 'email'}
                  onChange={this.handleOptionChange}
                />By Email
              </div>
              <br />
              <div className="radio">
                <RadioButton
                  name="viatext"
                  type="radio"
                  value="text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  checked={this.state.selectedOption === 'text'}
                  onChange={this.handleOptionChange}
                />By Text
              </div>
              <br />
              <div className="radio">
                <RadioButton
                  name="emailandtext"
                  type="radio"
                  value="bothemailandtext"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  checked={this.state.selectedOption === 'bothemailandtext'}
                  onChange={this.handleOptionChange}
                />Email And Text
              </div>
              <br />
              <Field label="Notify me until this date:" className="group-startday">
                            <style>{SelectedDateStyle}</style>
                            <YwaitDayPickerInput
                              name={'dateTimeDay'}
                              value={values.dateTimeDay}
                              formatDate={formatDate}
                              parseDate={parseDate}
                              format="MM-DD-YYYY"
                              placeholder="MM-DD-YYYY"
                              dayPickerProps={{
                                showOutsideDays: true,
                                format: "MM-DD-YYYY",
                                disabledDays: [
                                  {
                                    before: moment().add(1, 'days').toDate()
                                  }
                                ]
                              }}
                              onDayChange={(value) => { setFieldValue('dateTimeDay', value) }} />
                            {(submitCount > 0 && errors.dateTimeDay) && <Error>{errors.dateTimeDay}</Error>}
                          </Field>
              <br />
              <div>
                <Checkbox
                  name="terms"
                  type="checkbox"
                  value={values.terms}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />

                I Agree to the  <Link to='/terms-of-use'>Terms and Conditions</Link> of YWait.
            </div>

              {(submitCount > 0 && errors.terms) && <Error>{errors.terms}</Error>}

              <div className="has-text-centered">
                <Button type='submit' secondary disabled={isSubmitting}>Save</Button>
              </div>

            </Form>
          )}
      />
    )
  }
}

export default compose(
  withRouter,
  graphql(CREATE_WAITLIST,{name: 'signupwaitlist'})
)(WaitlistForm);