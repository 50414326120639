import React, { Component } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router';
import { graphql } from 'react-apollo'
import { compose } from "recompose";
import * as Yup from 'yup';
import { Formik } from 'formik'
import * as queryString from 'query-string';
import { formatENUM } from '../components/Global'

import Notification from '../components/Notification'
import { Field, Input, Error, Button } from '../components/Forms'
import RESET_PASSWORD from '../mutations/resetPassword'

const Form = styled.form`  
  margin: 0 auto;  
  .field {
    max-width: 410px;
    margin: 0 auto;
  }

  .button {
    margin-top: 40px;
    min-width: 50%;
  }
`

const schema = Yup.object().shape({
  token: Yup.string().required(),
  username: Yup.string().email().required(),
  password: Yup.string().required('New Password is required.'),
  verifyPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords are not the same!')
    .required('Verify Password is required.')
});

class PracticeResetPasswordForm extends Component {
  constructor(props) {
    super(props);
    
    const params = queryString.parse(this.props.location.search);
    
    this.state = {
      email: params.email || undefined,
      token: params.token || undefined,
      password: '',
      verifyPassword: '',
      serverMessage: 'Server error !.'
    };    
  }

  componentDidMount() {
  }

  handleSubmit = (values, actions) => {
    this.props.resetPassword({
      name: 'resetPassword', 
      variables: {
        email: values.username,
        token: values.token,
        password: values.password,
      }
    }).then((res) => {       
      
      var data = res.data.resetPassword;
      if (!data || data.statusCode != 200) {
      this.setState({ serverError: true, serverMessage:"Failed to reset password" });      
      actions.setSubmitting(false);
      return;
      }

      this.props.history.push('/login', { reset_password: true });
    })
    .catch(({ graphQLErrors }) => {
      actions.setSubmitting(false);
      this.setState({ serverError: true });      
    });
  }

  closeAlert = () => {
    this.setState({ serverError: false });
  }
  
  render() {             
    return (
      <Formik
        initialValues={{ 
          username: this.state.email,
          token: this.state.token,
          password: '', 
          verifyPassword: ''
        }}   
        validationSchema={schema}      
        onSubmit={this.handleSubmit}
        render={({  
          values,
          errors,         
          isSubmitting,
          submitCount,
          handleBlur,
          handleChange,
          handleSubmit           
        }) => (
          
          <Form onSubmit={handleSubmit}>
           

            {this.state.serverError && 
              <Notification close={this.closeAlert} 
                open={this.state.serverError} 
                message={formatENUM(this.state.serverMessage)} 
            />} 
            
            {(!values.username || !values.token) && 
              <Notification close={this.closeAlert} 
                open={true} 
                message={'Information is missing, please check your Inbox!'} 
            />}                              


            <Field label="Create Password">
              <Input className="input" 
                placeholder="Create Password"                
                name="password"
                type="password"
                value={values.password} 
                onBlur={handleBlur}
                onChange={handleChange} 
              />
              {(submitCount > 0 && errors.password) && <Error>{errors.password}</Error>}
            </Field>

                        
            <Field label="Verify Password">
              <Input className="input"
                placeholder="Verify Password"  
                name="verifyPassword" 
                type="password"
                value={values.verifyPassword} 
                onBlur={handleBlur}
                onChange={handleChange}                
              />
              {(submitCount > 0 && errors.verifyPassword) && <Error>{errors.verifyPassword}</Error>}
            </Field>
           
            <div className="has-text-centered">
              <Button type='submit' secondary disabled={isSubmitting}>Reset Password</Button>
            </div>        
                        
          </Form>      
        )}      
      />             
    )
  }
}

export default compose(
  withRouter,
  graphql(RESET_PASSWORD, { name: 'resetPassword' }),
)(PracticeResetPasswordForm);