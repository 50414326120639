import gql from "graphql-tag";

export default gql`
  mutation getPracticeByCode($accessCode: String!) {
    getPracticeByCode(accessCode: $accessCode) {
      statusCode
      userRole
      exceptions
      loggedInUser {
        id
        title
        firstName
        lastName
      }
      result {
        id
        firstName
        lastName
        email
        name
        phone
        address
        address2
        city
        state
        zip
      }
    }
  }
`;
